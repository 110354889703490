import React from "react";
import { Avatar } from "@material-ui/core";
import { StaffProps } from "~/Components/StaffAvatar/interfaces";

import styles from "./StaffAvatarStyle.module.scss";

export const StaffAvatar: React.FC<StaffProps> = (props) => {
  const { avatar, firstName, lastName, starRating, onClick, id } = props;

  const handleClick = () => {
    id && onClick && onClick(id);
  };

  return (
    <div
      className={styles.staff}
      onClick={handleClick}
      style={{ cursor: onClick ? "pointer" : "default" }}
    >
      <Avatar
        className={styles.img_box}
        alt={`${firstName} ${lastName}`}
        src={avatar}
      >
        {firstName.substr(0, 1)}
        {lastName.substr(0, 1)}
      </Avatar>
      <strong className={styles.name}>
        {firstName}
        <br />
        {lastName}
      </strong>
      {starRating && (
        <span className={styles.rating}>
          <i className="icon-star-full" /> {starRating}
        </span>
      )}
    </div>
  );
};
