import React from "react";
import Radio from "@material-ui/core/Radio";
import type { RadioProps } from "@material-ui/core";

export const StyledRadio = (props: RadioProps) => {

  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<span />}
      icon={<span />}
      {...props}
    />
  );
};