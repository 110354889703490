import DialogMain from "../../../DialogMain/DialogMain";

export const ModalContent = ({
  titleDialog,
  showModal,
  setShowModal,
  submitButtonText,
  onSubmit,
  setDefaultStep,
  dialogWindowClass,
  redirectTitle,
  redirectText,
  setStep,
  childContent,
  setPrevStep,
  ...rest
}) => {
  return (
    <DialogMain
      titleDialog={titleDialog}
      showModal={showModal}
      setShowModal={setShowModal}
      submitButtonText={submitButtonText}
      onSubmit={onSubmit}
      setDefaultStep={setDefaultStep}
      dialogWindowClass={dialogWindowClass}
      redirectTitle={redirectTitle}
      redirectText={redirectText}
      redirectLink={setStep}
      setPrevStep={setPrevStep}
      {...rest}
    >
      {childContent}
    </DialogMain>
  );
};
