import React, { useState } from "react";
import { Controller } from "react-hook-form";
import moment from "moment";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import clsx from "clsx";

import { DatePickerHook } from "~/Containers/SearchResultsPage/components/DatePicker/DatePickerHook";
import MuiDrawer from "~/Components/MuiDrawer";
import { MuiDrawerHook } from "~/Components/MuiDrawer/MuiDrawerHook";
import { CheckMobileHook } from "~/Components/MainHeader/CheckMobileHook";

import styles from "./DatePickerStyle.module.scss";

export const DatePicker: React.FC = () => {
  const {
    now,
    control,
    isPickerOpen,
    setIsPickerOpen,
    selectedDate,
    setSelectedDate,
    onChangeHandler,
    onClearHandler,
  } = DatePickerHook();
  const { isToggle, toggleDrawer } = MuiDrawerHook();
  const { isMobile } = CheckMobileHook();
  const [checkDate, setCheckDate] = useState(false)

  const ViewMobile = () => (
    <div role="presentation">
      <Controller
        control={control}
        name={"date"}
        render={({ field: { value, onChange, onBlur } }) => {
          const date = value ? moment(value) : null;
          return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                id="date-picker-dialog"
                className={styles.date_picker_mobile}
                variant="inline"
                margin="normal"
                initialFocusedDate={now}
                open={isToggle}
                format="dd MMM"
                disablePast
                value={date}
                onChange={(dateTime) => {
                  setSelectedDate(dateTime);
                }}
                onBlur={onBlur}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                ToolbarComponent={() => {
                  return (
                    <div className={styles.btn_box}>
                      <div
                        className={styles.btn_apply}
                        onClickCapture={() => {
                          onChangeHandler(selectedDate, onChange);
                          toggleDrawer();
                        }}
                      >
                        Apply
                      </div>
                      <div
                        className={styles.btn_clear}
                        onClickCapture={() => {
                          onClearHandler(onChange);
                          toggleDrawer();
                        }}
                      >
                        Clear filter
                      </div>
                    </div>
                  );
                }}
              />
            </MuiPickersUtilsProvider>
          );
        }}
      />
    </div>
  );

  return (
    <>
      <MuiDrawer anchor={"bottom"} open={isToggle} handleToggle={toggleDrawer}>
        {ViewMobile()}
      </MuiDrawer>
      <Controller
        control={control}
        name={"date"}
        render={({ field: { value, onChange, onBlur } }) => {
          const date = value ? moment(value) : null;
          return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                fullWidth
                id="date-picker-dialog"
                className={clsx(styles.date_picker, {
                  [styles.date_picker_selected]: (date !== null || isPickerOpen || isToggle),
                })}
                variant="inline"
                margin="normal"
                initialFocusedDate={now}
                open={isPickerOpen}
                onClick={
                  isMobile
                    ? toggleDrawer
                    : () => {
                      setIsPickerOpen(!isPickerOpen);
                    }
                }
                label="Date"
                format="dd MMM"
                disablePast
                value={date}
                onChange={(dateTime) => {
                  setSelectedDate(dateTime);
                  setCheckDate(true)
                }}
                onBlur={onBlur}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                ToolbarComponent={() => {
                  return (
                    <>
                      {
                        (checkDate || date) && (
                          <div className={styles.btn_box}>
                            <button
                              className={styles.btn_apply}
                              onClick={() => {
                                onChangeHandler(selectedDate, onChange);
                                setIsPickerOpen(!isPickerOpen);
                                setCheckDate(false)
                              }}
                            >
                              Apply
                            </button>
                            <button
                              className={styles.btn_clear}
                              onClick={() => {
                                onClearHandler(onChange);
                                setIsPickerOpen(!isPickerOpen);
                                setCheckDate(false)
                              }}
                            >
                              Clear filter
                            </button>
                          </div>
                        )
                      }
                    </>
                  );
                }}
              />
            </MuiPickersUtilsProvider>
          );
        }}
      />
    </>
  );
};
