import axios from "axios";
import { GOOGLE_API_KEY } from "../../constants";

export const GoogleApi = {
  getTimezone: ({lat, lng}) => axios.get("https://maps.googleapis.com/maps/api/timezone/json",
    {
      params: {
        location: `${lat},${lng}`,
        timestamp: Date.now() / 1000,
        key: GOOGLE_API_KEY,
      }
    })
};
