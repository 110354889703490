import React from "react";

import hair from "~/Assets/images/hair.png";
import beauty from "~/Assets/images/beauty.png";
import nail from "~/Assets/images/nail.png";
import spa from "~/Assets/images/spa.png";
import eye from "~/Assets/images/eye.png";
import fitness from "~/Assets/images/fitness.png";
import styles from "./CategoriesStyles.module.scss";
import type { CategoriesProps } from "./interfaces";
import type { CategoryProps } from "~/store/reducers/interfaces/mainInterfaces";
import { mainLayoutRoute, searchResultsPageRoute } from "~/constants";
import history from "~/utils/history";
import { CheckMobileHook } from "../MainHeader/CheckMobileHook";
import Slider from "react-slick";
import "slick-carousel/slick/slick.scss";
import "slick-carousel/slick/slick-theme.scss";

const images = {
  0: hair,
  1: beauty,
  2: nail,
  3: spa,
  4: eye,
  5: fitness,
};

const settings = {
  infinite: false,
  slidesToShow: 4,
  swipeToSlide: true,
};

export const Categories: React.FC<CategoriesProps> = ({ categories }) => {
  const gotoCategory = (category: CategoryProps) => () => {
    const { description = [] }: { description: any } = category;
    history.push(
      mainLayoutRoute.concat(
        searchResultsPageRoute,
        "?",
        description.map(({ id }) => `categoriesIds=${id}`).join("&"),
        "&sortBy=nearest"
      )
    );
  };
  const { isMobile } = CheckMobileHook();

  const renderItems = () => {
    return categories.map((category: CategoryProps, i) => (
      <div key={category.id} className={styles.category}>
        {category.code === "Other" ? (
          <button
            onClick={gotoCategory(category)}
            className={`${styles.btn_content} ${styles.btn_other}`}
          >
            <div className={styles.bnt_dots}>
              <span className={styles.bnt_dot} />
              <span className={styles.bnt_dot} />
              <span className={styles.bnt_dot} />
            </div>
          </button>
        ) : (
          <button
            onClick={gotoCategory(category)}
            className={styles.btn_content}
            style={{ backgroundImage: `url(${images[i]})` }}
          />
        )}
        <p className={styles.category_text}>{category.code}</p>
      </div>
    ));
  };

  return (
    <>
      {isMobile ? (
        <>
          <h2 className={styles.titleCategories}>Business categories</h2>
          <Slider {...settings} className={styles.container_categories}>
            {renderItems()}
          </Slider>
        </>
      ) : (
        <>
          <h2 className={styles.titleCategories}>Business categories</h2>
          <div className={styles.container_categories}>{renderItems()}</div>
        </>
      )}
    </>
  );
};
