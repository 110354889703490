import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  autocomplete: {
    [theme.breakpoints.down('sm')]: {
      padding: '16px 20px 0px',
    },
  },
  btnSubmit: {
    display: 'initial',
    position: 'absolute',
    right: 35,
    top: 106,
    zIndex: 2,
    color: '#FFFFFF',
  },
}));

export { useStyles };
