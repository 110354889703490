import { useEffect } from "react";

import { useAppDispatch, useAppSelector } from "~/reduxConfig";
import { myBookingsSelector } from "~/store/selectors";
import { onClearMyBookingsStoreAction, onLoadMyBookingsAction } from "~/store/actions/myBookingsActions";
import type { MyBookingsStateProps } from "~/store/reducers/interfaces/myBookingsInterfaces";

export const MyBookingsPageHook = () => {
  const {
    allBookings: {
      myUpcomingBookings = [],
      countMyUpcomingBookings,
      myPastBookings = [],
      countMyPastBookings
    } = {},
    isLoading,
  } = useAppSelector<MyBookingsStateProps>(myBookingsSelector);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(onLoadMyBookingsAction());

    return () => {
      dispatch(onClearMyBookingsStoreAction());
    };
  }, [dispatch]);

  return {
    myUpcomingBookings,
    countMyUpcomingBookings,
    myPastBookings,
    countMyPastBookings,
    isLoading,
  };
};