import React from 'react';

import './UserMarker.css';

interface Props {
  lat: number;
  lng: number;
}

const UserMarker: React.FC<Props> = () => {
  return (
    <div className='user-marker-out'>
      <div className='user-marker-in' />
    </div>
  );
};

export default UserMarker;
