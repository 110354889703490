import axios from "~/utils/api";

const customerProfileApi = {
  getProfile: () => axios.get("customer/profile-settings"),
  putProfile: (data) => axios.put("customer/profile-settings", data),
  putAccountPhone: (data) =>
    axios.put("customer/profile-settings/change-phone-number", data),
  getNotifications: (page) =>
    axios.get("notifications/customer", {
      params: {
        page,
        pageSize: 10,
      },
    }),
  readNotification: (idNotification) =>
    axios.put(`notifications/customer/read/${idNotification}`),
  onChangeSendSMSNotification: (data) =>
    axios.put("customer/profile-settings/sms-notification", {
      smsNotification: data,
    }),
};

export default customerProfileApi;
