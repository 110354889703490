import React, { useState } from "react";

type Coords = { x: number, y: number }

type UseMousePositionProps = (handler: () => void) =>
  {
    onMouseDown: (event: React.MouseEvent<HTMLElement>) => void,
    onMouseUp: (event: React.MouseEvent<HTMLElement>) => void,
  }

export const useMousePosition: UseMousePositionProps = (handler) => {
  const [downCoords, setDownCoords] = useState<Coords>();

  const onMouseDown = (event: React.MouseEvent<HTMLElement>) => {
    setDownCoords({x: event.pageX, y: event.pageY});
  };

  const onMouseUp = (event: React.MouseEvent<HTMLElement>) => {
    if (downCoords?.x !== event.pageX || downCoords?.y !== event.pageY) return;
    handler();
  };

  return {
    onMouseDown,
    onMouseUp
  };
};