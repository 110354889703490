import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import type { LocationReducerStateProps } from '~/store/reducers/interfaces/locationInterfaces';
import { timezoneDefault } from '~/constants';

export const DefaultLocation = {
  placeId: '',
  country: 'Australia',
  city: 'Sydney',
  timezone: timezoneDefault,
  lat: -33.8688197,
  lng: 151.2092955,
};

const locationReducer = createSlice({
  name: 'location',
  initialState: DefaultLocation,
  reducers: {
    setLocation: (state, action: PayloadAction<LocationReducerStateProps>) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const { setLocation } = locationReducer.actions;

export default locationReducer.reducer;
