import React from 'react';
import { Grid } from '@material-ui/core';
import { VenueDetails } from '~/Containers/VenuePage/components/VenueDetails';
import { BusinessHours } from '~/Containers/VenuePage/components/BusinessHours';
import { OurLocation } from '../OurLocation';
import { CheckMobileHook } from '~/Components/MainHeader/CheckMobileHook';

export const AboutVenue: React.FC = () => {
  const { isMobile } = CheckMobileHook();
  
  return (
    <Grid container spacing={0}>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <VenueDetails />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <BusinessHours />
      </Grid>
      {
        isMobile &&  <Grid item xs={12} sm={12} md={6} lg={6}>
        <OurLocation />
      </Grid>
      }
    </Grid>
  );
};
