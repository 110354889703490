import moment from "moment";
import pluralize from "pluralize";

import {
  AvailableCardVenuesEntity,
  VenueBusinessHoursEntity,
} from "~/store/reducers/interfaces/mainInterfaces";
import { MyBookingVenueEntity } from "~/store/reducers/interfaces/myBookingsInterfaces";
import { VenueProps } from "~/store/reducers/interfaces/venueInterfaces";

export function formatPrice(num: number | string, decimal = 2) {
  if (typeof num === "string") {
    num = parseFloat(num);
  }

  if (typeof num !== "number") {
    return num;
  }

  return Number(num).toFixed(decimal);
}

export function getGoogleMapLink(
  venue: MyBookingVenueEntity | AvailableCardVenuesEntity | VenueProps
) {
  const query =
    venue?.placeId?.length < 50
      ? `?q=place_id:${venue.placeId}`
      : `/${venue?.lat},${venue?.lng}`;

  return `https://www.google.com/maps/place${query}`;
}

export const getHighestPriceOption = (options) => {
  let highestPriceOption = options[0];
  options.forEach((item) => {
    const currentPrice = Number(
      highestPriceOption.specialPrice || highestPriceOption.price || 0
    );
    const currentItemPrice = Number(item.specialPrice || item.price || 0);
    if (currentItemPrice > currentPrice) {
      highestPriceOption = item;
    }
  });
  return highestPriceOption;
};

export const convertHours = (duration: number) => {
  return moment('2022-01-01 00:00:00').add(duration, 'hours').format("HH:mm:ss")
}

export function convertDurationString(duration) {
  if (!duration) return "0 min"

  let textHours = "";
  const [hours, minutes] = duration.split(":");
  const hourText = pluralize("hour", +hours);
  const minuteText = pluralize("min", +minutes);

  if (+hours < 1) {
    textHours = `${+minutes} ${minuteText}`;
  } else if (+hours >= 1 && +minutes === 0) {
    textHours = `${+hours} ${hourText}`;
  } else {
    textHours = `${+hours} ${hourText} ${+minutes} ${minuteText}`;
  }

  return textHours;
}

export function convertDuration(duration) {
  let textHours = "";
  const hours = moment.duration(duration, "hours").asHours();
  const timeHours = Math.floor(hours);
  const timeMinutes = hours - timeHours;
  const minutes = moment.duration(timeMinutes, "hours").asMinutes();
  const hourText = pluralize("hour", hours);
  const minuteText = pluralize("min", minutes);

  if (hours < 1) {
    textHours = `${minutes} ${minuteText}`;
  } else if (hours >= 1 && timeMinutes === 0) {
    textHours = `${hours} ${hourText}`;
  } else {
    const hoursFormat = moment.duration(timeHours, "hours").asHours();
    const hoursFormatText = pluralize("hour", hoursFormat);

    textHours = `${hoursFormat} ${hoursFormatText} ${minutes} ${minuteText}`;
  }

  return textHours;
}

const daysOfWeek = {
  0: "sunday",
  1: "monday",
  2: "tuesday",
  3: "wednesday",
  4: "thursday",
  5: "friday",
  6: "saturday",
};

const TIME_FORMAT = "HH:mm:ss";

export const checkTimeBusiness = (
  currentTimezone: string,
  venueTimezone: string,
  venueBusinessHours: VenueBusinessHoursEntity,
  isOffToday
) => {
  if (isOffToday) return null;

  const venueTimezoneValid = venueTimezone.includes("/")
    ? venueTimezone
    : currentTimezone;
  const newMomentCurr = moment().tz(currentTimezone);
  const today = daysOfWeek[newMomentCurr.day()];
  const startTime = venueBusinessHours[`${today}DayStart`];
  const endTime = venueBusinessHours[`${today}DayEnd`];

  if (endTime) {
    const nowTime = newMomentCurr.format(TIME_FORMAT);
    const currentTime = moment(nowTime, TIME_FORMAT).tz(currentTimezone).unix();
    const startTimeVenue = moment(startTime, TIME_FORMAT).tz(venueTimezoneValid).unix();
    const endTimeVenue = moment(endTime, TIME_FORMAT).tz(venueTimezoneValid).unix();

    if (startTimeVenue <= currentTime && currentTime < endTimeVenue) {
      const time = moment(endTime, TIME_FORMAT)
        .add(endTime.includes("59") ? 1 : 0, "m")
        .format("h:mm a");
      return `Open till ${time}`;
    }
    return null;
  }

  return null;
};
