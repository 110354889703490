import { Dispatch } from "redux";

import customerProfileApi from "~/services/api/customerProfileApi";
import authCustomerApi from "~/services/api/authCustomerApi";
import {
  setCustomerProfile,
  setMessage,
  setNotification,
  setIsLoading,
  setSMSNotification,
  setFirstNotification,
} from "~/store/reducers/customerProfileReducer";
import { accssesAccountAction, sessionActionTypes } from "../actions/sessionActions";
import { TOKEN } from "~/constants";
import cookie from "~/utils/cookie";

const onSetError = (response) => {
  if (typeof response.data?.message === "string") {
    throw new Error(response.data?.message);
  } else {
    throw new Error(response.data?.message[0]);
  }
};

export const onGetCustomerProfileAction = () => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await customerProfileApi.getProfile();
      dispatch(setCustomerProfile(response.data));
      dispatch({
        type: sessionActionTypes.UPDATE_NAME_ACTION_TYPE,
        payload: {
          firstName: response.data.firstName,
          lastName: response.data.lastName,
        },
      });
    } catch (error: any) {
      console.log("error", error);
    }
  };
};

export const onPutCustomerProfileAction = (data) => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await customerProfileApi.putProfile(data);

      if (response.status === 200) {
        dispatch(setCustomerProfile(response.data));
        dispatch({
          type: sessionActionTypes.UPDATE_NAME_ACTION_TYPE,
          payload: {
            firstName: response.data.firstName,
            lastName: response.data.lastName,
          },
        });
      } else {
        throw new Error("Something wrong");
      }
    } catch (error: any) {
      dispatch(setMessage(error.message));
    }
  };
};

export const onPutAccountPhoneAction = (data, callback) => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await customerProfileApi.putAccountPhone(data);

      if (response.status === 200) {
        callback();
      } else {
        throw new Error(response?.data?.message || "Something wrong");
      }
    } catch (error: any) {
      dispatch(setMessage(error.message));
    }
  };
};

export const onChangeEmailAction = (data, callback) => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await authCustomerApi.changeEmail(data);

      if (response.status === 200) {
        callback();
      } else {
        onSetError(response);
      }
    } catch (error: any) {
      dispatch(setMessage(error.message));
    }
  };
};

export const onChangeEmailConfirmAction = (data, callback) => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await authCustomerApi.confirmChangeEmail(data);

      if (response.status === 200) {
        const { accessToken, expiresIn, ...profile } = response.data;
        cookie.setCookie(TOKEN, accessToken, expiresIn / 60);
        dispatch(setCustomerProfile(profile));
        callback();
      } else {
        onSetError(response);
      }
    } catch (error: any) {
      dispatch(setMessage(error.message));
    }
  };
};

export const onChangePhoneConfirmAction = (data, callback) => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await authCustomerApi.confirmChangePhone(data);

      if (response.status === 200) {
        const { accessToken, expiresIn, ...profile } = response.data;
        cookie.setCookie(TOKEN, accessToken, expiresIn / 60);
        dispatch(setCustomerProfile(profile));
        callback();
      } else {
        onSetError(response);
      }
    } catch (error: any) {
      dispatch(setMessage(error.message));
    }
  };
};

export const onChangePasswordAction = (data, callback) => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await authCustomerApi.changePassword(data);

      if (response.status === 200) {
        callback();
      } else {
        onSetError(response);
      }
    } catch (error: any) {
      dispatch(setMessage(error.message));
    }
  };
};

export const forgotPasswordAction = (data, callback) => {
  return async (dispatch) => {
    try {
      const response = await authCustomerApi.forgotPassword(data);

      if (response.status === 200) {
        callback();
      } else {
        onSetError(response);
      }
    } catch (error: any) {
      dispatch(setMessage(error.message));
    }
  };
};

export const forgotConfirmationAction = (data, callback) => {
  return async (dispatch) => {
    try {
      const response = await authCustomerApi.confirmation(data);

      if (response.status === 200) {
        callback();
      } else {
        onSetError(response);
      }
    } catch (error: any) {
      dispatch(setMessage(error.message));
    }
  };
};

export const resetPasswordAction = (data, callback) => {
  return async (dispatch) => {
    try {
      const response = await authCustomerApi.resetPassword(data);

      if (response.status === 200) {
        callback();
      } else {
        onSetError(response);
      }
    } catch (error: any) {
      dispatch(setMessage(error.message));
    }
  };
};

export const getNotificationAction = (page) => {
  return async (dispatch) => {
    try {
      const response = await customerProfileApi.getNotifications(page);

      const newNotifications = response.data.notifications.filter((item) => !item.isRead)
      const lastNotifications = response.data.notifications.filter((item) => item.isRead)

      if (response.status === 200) {
        if (page === 0) {
          dispatch(setFirstNotification({
            ...response.data,
            newNotifications,
            lastNotifications,
          }));
        } else {
          dispatch(setNotification({
            ...response.data,
            newNotifications,
            lastNotifications,
          }));
        }
      }
    } catch (error: any) {
      console.log('Error: ', error)
    }
  };
};

export const readNotificationAction = (idNotification) => {
  return async (dispatch) => {
    try {
      const response = await customerProfileApi.readNotification(idNotification);

      if (response.status === 200) {
        dispatch(accssesAccountAction());
      }
    } catch (error: any) {
      console.log('Error: ', error)
    }
  };
};

export const onChangeLoadingCustomerProfile = (payload) => {
  return async (dispatch) => {
    try {
      dispatch(setIsLoading(payload))
    } catch (error: any) {
      console.log('Error: ', error)
    }
  };
}

export const onChangeSendSMSNotificationAction = (payload) => {
  return async (dispatch) => {
    dispatch(onChangeLoadingCustomerProfile(true))
    try {
      const response = await customerProfileApi.onChangeSendSMSNotification(payload);

      if (response.status === 200) {
        dispatch(setSMSNotification(response.data.smsNotification))
      }
    } catch (error: any) {
      console.log('Error: ', error)
      dispatch(onChangeLoadingCustomerProfile(false))
    }
  };
}
