import React, { useEffect } from "react";
import { Controller, useWatch } from "react-hook-form";
import moment from "moment-timezone";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker, MuiPickersUtilsProvider, } from "@material-ui/pickers";
import "date-fns";
import { useSelector } from "react-redux";

import { dateFormat } from "~/constants";
import { Message } from "~/Components/Message";
import type { DatePickerProps } from "~/Components/DatePicker/interfaces";
import { locationSelector } from "~/store/selectors"

import styles from "./DatePickerStyle.module.scss";

export const DatePicker: React.FC<DatePickerProps> = (props) => {
  const { timezone } = useSelector(locationSelector)
  const {
    formMethods: { control, trigger },
    fieldName,
    onChangeTimeError,
    timeSelect,
    timeStartEnd,
    onChangeDayOffError,
  } = props;
  const root = document.querySelector("#root");
  const { time: timeSelected, date, selectedServices, currentFieldIndex } = useWatch({ control });
  const defaultTimezone = timezone || "Australia/Sydney";

  useEffect(() => {
    onChangeDayOffError('')
    if (selectedServices?.length > 0) {
      const newData = selectedServices.filter((item, index) => {
        const time = item.selected.time
        return moment(`${date} ${+index === +currentFieldIndex ? timeSelected : time}`) < moment()
      })
      if (newData.length > 0) {
        onChangeTimeError('That time is in the past, please select a valid time for your appointment')
        return
      }
    }
    if (timeStartEnd[timeSelect[0]] == null) {
      onChangeTimeError('')
      return
    }
    if (date && timeSelected && moment(`${date} ${timeSelected}`) > moment()) {
      onChangeTimeError('')
      return
    }
    if (date && timeSelected && moment(`${date} ${timeSelected}`) < moment()) {
      onChangeTimeError('That time is in the past, please select a valid time for your appointment')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, timeSelected])

  const [, , , , , Z] = new Date().toString().split(" ");
  const minDate = moment.tz(new Date(), defaultTimezone).format("YYYY-MM-DD HH:mm:ss");
  const minDateString = `${minDate} ${Z}`;

  return (
    <Controller
      control={control}
      name={fieldName}
      render={({ field: { value, onChange }, fieldState: { error } }) =>
        <>
          <MuiPickersUtilsProvider libInstance={moment} utils={DateFnsUtils}>
            <KeyboardDatePicker
              autoOk={true}
              className={styles.date_picker}
              disableToolbar
              minDate={new Date(minDateString)}
              variant="inline"
              format={"dd-MMM-yyyy"}
              margin="normal"
              id="date-picker-inline"
              label="Date picker inline"
              value={value}
              labelFunc={() => value ? moment(value).format("dddd, DD MMM, YYYY") : "Date"}
              onChange={(dateTime) => {
                if (timeSelected) {
                  const dateSelect = moment(`${moment(dateTime).format(dateFormat)} ${timeSelected}`)
                  if (dateSelect < moment()) {
                    onChangeTimeError('That time is in the past, please select a valid time for your appointment')
                  } else {
                    onChangeTimeError('')
                  }
                }
                onChange(moment(dateTime).format(dateFormat));
                trigger(fieldName);
              }}
              onOpen={() => {
                root.classList.add("open_datepicker");
              }}
              onClose={() => {
                root.classList.remove("open_datepicker");
              }}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </MuiPickersUtilsProvider>
          <Message msg={error?.message} />
        </>
      }
    />);
};
