import { useCallback, useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import SaveNewCardForm from './SaveNewCardForm';
import { CircularProgress } from '@material-ui/core';
import { stripeApi } from '~/services/api/stripeApi';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

interface Props {
  onClose: () => void;
  showRememberCard?: boolean;
}

export function SaveNewCard({ onClose, showRememberCard }: Props) {
  const [clientSecret, setClientSecret] = useState('');
  const [error, setError] = useState(null);

  const createPayment = useCallback(async () => {
    try {
      const { data }: any = await stripeApi.setupPayment();
      setClientSecret(data.createSetupIntent.client_secret);
    } catch (error) {
      console.log('error', error);
    }
  }, []);

  useEffect(() => {
    createPayment();
  }, [createPayment]);

  const options = {
    clientSecret,
    appearance: {
      // theme: 'stripe',
      variables: {
        colorPrimary: '#3f51b5',
        fontFamily: 'Epilogue, Arial, Helvetica, sans-serif',
        borderRadius: '30px',
      },
      rules: {
        '.Input': {
          lineHeight: '17px',
          color: error ? 'var(--colorDanger)' : '',
          borderColor: error ? 'var(--colorDanger)' : '',
          boxShadow: error ? '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02), 0 0 0 2px var(--colorDanger)' : '',
        },
        '.Label': {
          color: error ? 'var(--colorDanger)' : '',
        }
      },
    },
  };

  return (
    <>
      {clientSecret ? (
        <Elements stripe={stripePromise} options={options}>
          <SaveNewCardForm
            onClose={onClose}
            showRememberCard={showRememberCard}
            setClientSecret={setClientSecret}
            createPayment={createPayment}
            error={error}
            setError={setError}
          />
        </Elements>
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress size={24} color='primary' />
        </div>
      )}
    </>
  );
}
