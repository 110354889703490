import { useState } from "react";
import moment from "moment";

import type { MyBookingItemProps } from "~/Containers/MyBookingsPage/interfaces";
import { viewedDateFormat } from "~/constants";
import { addressExtractor } from "~/utils/addressExtractor";

export const MyBookingItemHook = (props) => {
  const {
    booking: {
      id,
      venue,
      venueId,
      status,
      bookingServices,
      total,
      paymentMethod,
      type,
      invoice,
    },
    isUpcoming,
  } = props as MyBookingItemProps;
  const [showModal, setShowModal] = useState(false);
  const { logo, locationName } = venue;
  const displayedAddress = addressExtractor(venue);
  const bookingServicesCount = bookingServices?.reduce((total, item) => {
    if (item?.package) {
      return total = total + item.package.packageServices.length
    } else {
      return total = total + 1
    }
  }, 0)

  const startDateTime = bookingServices[0] ? bookingServices[0].startDateTime : new Date();
  const displayedDate = moment(startDateTime).format(viewedDateFormat);

  return {
    id,
    venueId,
    showModal,
    setShowModal,
    logo,
    locationName,
    displayedAddress,
    bookingServicesCount,
    status,
    type,
    displayedDate,
    countTotal: invoice[0]?.totalAmount || total,
    isUpcoming,
    bookingStartDate: startDateTime,
    paymentMethod,
  };
};
