import { useEffect } from "react";
import {
  TextField,
  Box,
  Grid,
  Button,
  Paper,
  Typography,
} from "@material-ui/core";
import { Message } from "~/Components/Message";
import { CurrentInput } from "./hooks";
import { confirmationInputNames, steps } from "~/constants";
import "./confirmationStyle.scss";

export const Confirmation = ({
  clearErrors,
  setFocus,
  register,
  setValue,
  getValues,
  errors,
  hideMessage,
  message,
  onSendAgain,
  value,
  setStep,
}) => {
  const currentInput = CurrentInput();
  const title = value === "phone" ? `+${getValues(value)}` : getValues(value);

  useEffect(() => {
    setFocus(confirmationInputNames[0]);
  }, [setFocus]);

  const clickHandler = () => clearErrors();

  const onClearValueOfFieldsAndSendAgain = () => {
    onSendAgain();
    setValue("digit1", "");
    setValue("digit2", "");
    setValue("digit3", "");
    setValue("digit4", "");
    setValue("digit5", "");
    currentInput.onClearValueOfFields();
    hideMessage();
  };

  const goToChangePhoneNumber = () => {
    setStep(steps.additionalInfo)
  }

  return (
    <div className="card-wrapper">
      <Paper variant="outlined">
        <Box
          component="p"
          textAlign="center"
          className="card-wrapper-info sub-theme-color "
        >
          Enter the 5-digit code we sent to <br />
          <span className="user-email">{title}</span>
        </Box>
        <form className="form">
          <Grid
            spacing={2}
            container
            justifyContent="center"
            alignItems="center"
            className="verification-wrapper"
          >
            {confirmationInputNames.map((inputItem) => (
              <Grid item key={inputItem}>
                <TextField
                  onChange={(e) =>
                    currentInput.onSetValueOfField(e, setValue, setFocus)
                  }
                  value={currentInput.valueOfFields[inputItem]}
                  error={Boolean(errors[inputItem])}
                  id={inputItem}
                  onClick={clickHandler}
                  onFocus={hideMessage}
                  className="verification-input-square"
                  variant="outlined"
                  inputProps={{ ...register(inputItem), maxLength: "1" }}
                />
              </Grid>
            ))}
          </Grid>

          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            className="send-again-verification-wrapper "
          >
            <Box
              className="sub-theme-verification"
              textAlign="center"
              component="p"
            >
              Didn't receive a code?
            </Box>

            <Box alignContent="center" className="send-again-btn">
              <Button onClick={onClearValueOfFieldsAndSendAgain}>
                Send it again
              </Button>
              <Typography>or</Typography>
              <Button onClick={goToChangePhoneNumber}>
                Change mobile number
              </Button>
            </Box>

            <Message msg={message} />
          </Box>
        </form>
      </Paper>
    </div>
  );
};
