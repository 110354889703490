import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";

import { OurStaffSlider } from "~/Containers/VenuePage/components/OurStaffSlider";
import { HeroSection } from "~/Containers/VenuePage/components/HeroSection";
import { VenuesNearbySlider } from "~/Components/VenuesNearbySlider";
import { PageSection } from "~/Components/PageSection";
import { AboutVenue } from "~/Containers/VenuePage/components/AboutVenue";
import { VenuePageHook } from "~/Containers/VenuePage/VenuePageHook";
import { OurServices } from "~/Containers/VenuePage/components/OurServices";
import { VenueReviews } from "~/Containers/VenuePage/components/VenueReviews";
import { OurLocation } from "~/Containers/VenuePage/components/OurLocation";
import { CheckMobileHook } from "~/Components/MainHeader/CheckMobileHook";
import history from "~/utils/history";

import styles from "./VenuePageStyle.module.scss";
import { useScrollBlock } from "../SearchResultsPage/useScrollBlock";
import { onOpen } from "~/store/actions/searchActions";
import { useAppDispatch, useAppSelector } from "~/reduxConfig";
import { SearchReducerStateProps } from "~/store/reducers/interfaces/searchInterfaces";
import { isLoggedInSelector, searchSelector, venueSelector } from "~/store/selectors";
import { VenueReducerState } from "~/store/reducers/interfaces/venueInterfaces";
import { VenuePageLocationStateProps } from "./interfaces";
import { bookingFlowPageRoute, flowsLayoutRoute } from "~/constants";

export const VenuePage: React.FC<RouteComponentProps<any>> = (props) => {
  const locationState = history.location.state as VenuePageLocationStateProps;
  const dateTime = locationState?.dateTime;
  const isLoggedIn = useAppSelector(isLoggedInSelector);

  const { fetchNewStaff, nearby, services } = VenuePageHook(props);
  const { isMobile } = CheckMobileHook();
  const [blockScroll, allowScroll] = useScrollBlock();
  const dispatch = useAppDispatch();
  const listService = services?.serviceCategories?.filter((item) => item.packages.length > 0 || item.services.length > 0)
  const isCheckListService = listService.length > 0

  const { isOpen } = useAppSelector<SearchReducerStateProps>(searchSelector);
  const { venue } = useAppSelector<VenueReducerState>(venueSelector);

  const TabData = [
    {
      title: 'Services',
      content: <OurServices {...services} />
    },
    {
      title: 'Our staff',
      content: <OurStaffSlider title="Our staff" onStaffEndReached={fetchNewStaff} />
    },
    {
      title: 'About',
      content: <AboutVenue />
    },
    {
      title: 'Reviews',
      content: <VenueReviews />
    },

    {
      title: 'Venues nearby',
      content: <VenuesNearbySlider
        title="Venues nearby"
        nearbyVenues={nearby?.nearbyVenues}
      />
    },
  ]

  const onChangeBookNow = () => {
    if (!isLoggedIn) {
      window.venue = { venue_id: venue.id };
      const element = document.getElementById("header-button-sign-up");
      element.click();
    } else {
      history.push(flowsLayoutRoute.concat(bookingFlowPageRoute, `/${venue.id}`), { ...(dateTime ? { dateTime } : {}) });
    }
  }

  useEffect(() => {
    dispatch(onOpen(false));
  }, [dispatch, isOpen]);

  if (isOpen) {
    blockScroll();
  } else {
    allowScroll();
  }


  return (
    <div className={styles.root}>
      <PageSection withDivider={false} isHome={isMobile} >
        <HeroSection TabData={TabData} isCheckListService={isCheckListService} />
      </PageSection>

      {
        !isMobile &&
        <>
          <PageSection>
            <AboutVenue />
          </PageSection>

          <PageSection withDivider={isCheckListService}>
            <OurServices {...services} />
          </PageSection>

          <PageSection>
            <OurStaffSlider title="Our staff" onStaffEndReached={fetchNewStaff} />
          </PageSection>

          <PageSection>
            <OurLocation />
          </PageSection>

          <PageSection>
            <VenueReviews />
          </PageSection>

          <PageSection>
            <VenuesNearbySlider
              title="Venues nearby"
              nearbyVenues={nearby?.nearbyVenues}
            />
          </PageSection>
        </>
      }
      {
        isMobile &&
        <div className={styles.btn_booking}>
          <button onClick={onChangeBookNow} >
            Book Now
          </button>
        </div>
      }
    </div>
  );
};
