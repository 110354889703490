import React, { useState } from "react";
import { Controller } from "react-hook-form";
import {
  TextField,
  InputLabel,
  Grid,
  Paper,
  InputAdornment,
  IconButton,
  Box,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import FacebookLogin from "react-facebook-login";
import { NavLink } from "react-router-dom";

import { Message } from "~/Components/Message";
import "./SignUpFormStyle.scss";
import { REACT_APP_ID, generalLayoutRoute, infoPageRoute } from "~/constants";

import LogoFB from "../../Assets/images/logo-fb.svg";

export const SignUpForm = ({
  control,
  getValues,
  hideMessage,
  message,
  signInFacebook,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const responseFacebook = (response) => {
    const { receiveDiscountsAndPromotions } = getValues();
    if (response?.accessToken) {
      const newData = {
        accessToken: response?.accessToken,
        email: response?.email || null,
        receiveDiscountsAndPromotions,
      };
      return signInFacebook(newData, true);
    }
  };

  const handleVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const typeOfPswInput = showPassword ? "text" : "password";
  const IconToShow = showPassword ? <VisibilityOff /> : <Visibility />;

  const renderLink = (title, state) => (
    <NavLink
      exact
      to={{
        pathname: `${generalLayoutRoute.concat(infoPageRoute)}?value=${state}`,
      }}
      target="_blank"
    >
      {title}
    </NavLink>
  );

  return (
    <div className="card-wrapper card-wrapper-second">
      <Paper variant="outlined">
        <form>
          <Grid container justifyContent="center">
            <Grid
              container
              item
              xs={12}
              className="input-wrapper wrapper-password-sign-up"
            >
              <InputLabel htmlFor="email" className="label">
                Enter your email
              </InputLabel>
              <Controller
                control={control}
                name="email"
                defaultValue=""
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <TextField
                      id="email"
                      className="text-field"
                      onFocus={hideMessage}
                      value={value}
                      onChange={(e) => onChange(e.target.value)}
                      variant="outlined"
                      error={Boolean(error)}
                    />
                    <Message msg={error?.message} />
                  </>
                )}
              />
            </Grid>

            <Grid
              container
              item
              xs={12}
              className="input-wrapper wrapper-password-sign-up"
            >
              <InputLabel htmlFor="password" className="label">
                Create password
              </InputLabel>
              <Controller
                control={control}
                name="password"
                defaultValue=""
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <div className="wrapper-input-password-sign-up">
                    <TextField
                      id="password"
                      className="text-field"
                      onFocus={hideMessage}
                      value={value}
                      onChange={(e) => onChange(e.target.value)}
                      type={typeOfPswInput}
                      variant="outlined"
                      error={Boolean(error)}
                    />
                    <InputAdornment
                      className="wrapper-icon-show-password"
                      position="end"
                    >
                      <IconButton
                        aria-label="Toggle password showPassword"
                        onClick={handleVisibility}
                      >
                        {IconToShow}
                      </IconButton>
                    </InputAdornment>
                    <Message msg={error?.types} type="passwordMsg" />
                  </div>
                )}
              />
            </Grid>

            <Box className="list-sign-in-with">
              <FacebookLogin
                appId={REACT_APP_ID}
                fields="name,email,picture"
                callback={responseFacebook}
                cssClass="wrapper-login-facebook"
                textButton="Sign Up with Facebook"
                icon={<img src={LogoFB} alt="logo" />}
              />
            </Box>

            <Grid item xs={12}>
              <Controller
                control={control}
                defaultValue={false}
                name="privacyPolicy"
                render={({
                  field: { onChange, onBlur, value, ref },
                  fieldState: { error },
                }) => (
                  <>
                    <FormControlLabel
                      className="align-items-flex-start checkboxLabel-control"
                      control={
                        <Checkbox
                          onBlur={onBlur}
                          onChange={onChange}
                          checked={value}
                          inputRef={ref}
                          color="primary"
                        />
                      }
                      label={
                        <span>
                          I agree to the{" "}
                          {renderLink("Glamezy’s privacy policy", 0)}
                          {", "}
                          {renderLink("website terms", 2)}
                          {" and "}
                          {renderLink("booking / cancellation terms.", 1)}
                        </span>
                      }
                    />
                    <Message msg={error?.message} />
                  </>
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                control={control}
                defaultValue={false}
                name="receiveDiscountsAndPromotions"
                render={({
                  field: { onChange, onBlur, value, ref },
                  fieldState: { error },
                }) => (
                  <>
                    <FormControlLabel
                      className="align-items-flex-start checkboxLabel-control"
                      control={
                        <Checkbox
                          onBlur={onBlur}
                          onChange={onChange}
                          checked={value}
                          inputRef={ref}
                          color="primary"
                        />
                      }
                      label="I am happy to receive Glamezy discounts and promotional material"
                    />
                    <Message msg={error?.message} />
                  </>
                )}
              />
            </Grid>

            <Message msg={message} />
          </Grid>
        </form>
      </Paper>
    </div>
  );
};
