import React from "react";

import styles from "./MarketingStyles.module.scss";
import ImgSaleNew from '~/Assets/images/sale-new.png';

export const Marketing = () => {
  return (
    <div
      className={styles.partnership_content}
    >
      <div className={styles.partnership_info}>
        <h2 className={styles.partnership_title}>
          Need any assistance with signing up or with marketing your business?
          <br />
          <span className={styles.partnership_subtitle}>
            {'Let us help you :)'}
          </span>
        </h2>
      </div>

      <div className={styles.partnership_box}>
        <img src={ImgSaleNew} alt="" />
      </div>
    </div>
  );
};
