import React, { useRef } from "react";
import Slider from "react-slick";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import {
  VenuePageLocationStateProps,
  VenueStaffSliderProps,
} from "~/Containers/VenuePage/interfaces";
import clsx from 'clsx';

import styles from "./OurStaffSliderStyle.module.scss";
import { StaffAvatar } from "~/Components/StaffAvatar";
import { useAppSelector } from "~/reduxConfig";
import { VenueReducerState } from "~/store/reducers/interfaces/venueInterfaces";
import { isLoggedInSelector, venueSelector } from "~/store/selectors";
import history from "~/utils/history";
import { bookingFlowPageRoute, flowsLayoutRoute } from "~/constants";
import { CheckMobileHook } from "~/Components/MainHeader/CheckMobileHook";

export const OurStaffSlider: React.FC<VenueStaffSliderProps> = (props) => {
  const { title, onStaffEndReached } = props;
  const {
    venue,
    staff: { venueStaffList = [] } = {},
  } = useAppSelector<VenueReducerState>(venueSelector);
  const slider = useRef(null);
  const isLoggedIn = useAppSelector(isLoggedInSelector);
  const { isMobile } = CheckMobileHook();

  const { dateTime } = (history.location.state as VenuePageLocationStateProps) || {};

  const onClickStaff = (staffId) => {
    if (!isLoggedIn) {
      window.venue = {
        venue_id: venue.id,
        staff: {
          staffId,
          ...(venueStaffList ? { dateTime } : {}),
        }
      };
      const element = document.getElementById("header-button-sign-up");
      element.click();
    } else {
      history.push(
        flowsLayoutRoute.concat(bookingFlowPageRoute, `/${venue.id}`),
        {
          staffId,
          ...(venueStaffList ? { dateTime } : {}),
        }
      );
    }
  };

  const settings = {
    dots: false,
    infinite: venueStaffList.length > 6 ? true : false,
    arrows: false,
    speed: 300,
    slidesToShow: venueStaffList.length > 6 ? 6 : venueStaffList.length,
    slidesToScroll: venueStaffList.length > 6 ? 1 : 0,
    centerMode: false,
    beforeChange: (_, newIndex) => {
      if (newIndex === venueStaffList.length - 1) {
        onStaffEndReached();
      }
    },
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          dots: false,
          infinite: false,
          arrows: false,
          speed: 300,
          slidesToShow: 3,
          slidesToScroll: 1,
          centerMode: false,
        }
      },
    ]
  };

  return (
    <>
      <div className={clsx(styles.header,{
        [styles.header_mobile]: isMobile
      })}>
        <h2>{title}</h2>
        <div className={styles.buttons_block}>
          <div className={styles.buttons_box}>
            <button
              className={`${styles.button} ${venueStaffList.length <= 6 ? styles.disebal_btn : ""
                }`}
              onClick={slider.current?.slickPrev}
            >
              <ArrowBackIosIcon fontSize={"small"} />
            </button>
            <button
              className={`${styles.button} ${venueStaffList.length <= 6 ? styles.disebal_btn : ""
                }`}
              onClick={slider.current?.slickNext}
            >
              <ArrowForwardIosIcon fontSize={"small"} />
            </button>
          </div>
        </div>
      </div>
      {venueStaffList?.length > 0 ? (
        <Slider {...settings} ref={slider} className="staff-slider">
          {venueStaffList.map((staff) => (
            <StaffAvatar {...staff} key={staff.id} onClick={onClickStaff} />
          ))}
        </Slider>
      ) : (
        <div className={styles.noStaff}>No staff</div>
      )}
    </>
  );
};
