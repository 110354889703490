import type { Dispatch } from "redux";

import {
  clearMyBookingsStore,
  fetchBookingById,
  fetchMyBookings
} from "~/store/reducers/myBookingsReducer";
import { customerBookingsApi } from "~/services/api/customerBookingsApi";

export const onLoadMyBookingsAction = () => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await customerBookingsApi.getMyBookings();
      dispatch(fetchMyBookings(response.data));
    } catch (error: any) {
      console.log("error", error);
    }
  };
};

export const onLoadBookingByIdAction = (bookingId: number) => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await customerBookingsApi.getBookingById(bookingId);
      dispatch(fetchBookingById(response.data));
    } catch (error: any) {
      console.log("error", error);
    }
  };
};

export const onClearMyBookingsStoreAction = () => (dispatch: Dispatch) => {
  dispatch(clearMyBookingsStore());
};