import axios from "~/utils/api";

export const venuesApi = {
  getVenueById: (venueId: number) =>
    axios.get(`customer/venues/${venueId}`),
  getVenueStaffById: ({venueId, page, pageSize}) =>
    // axios.get(`customer/venues/venue-staff/${venueId}`, {params: {page, pageSize}}),
    axios.get(`customer/venues/venue-staff/${venueId}`),
  getNearbyVenuesById: (venueId: number) =>
    axios.get(`customer/venues/nearby-venues/${venueId}`),
  getVenueServicesById: (venueId: number) =>
    axios.get(`customer/venues/venue-services/${venueId}`),
  getVenueReviewsById: (venueId: number) =>
    axios.get(`customer/venues/venue-reviews/${venueId}`),
};