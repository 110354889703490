import React, { useState, useEffect } from "react";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import clsx from "clsx";
import {
  Search as SearchIcon,
  Close as CloseIcon,
  GetApp as GetAppIcon,
  Work as WorkIcon,
  // ContactSupport as ContactSupportIcon,
  // HelpOutline as HelpOutlineIcon,
  Menu as MenuIcon,
} from "@material-ui/icons";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Drawer,
  AppBar,
} from "@material-ui/core";

import SignInButton from "~/Components/MainHeader/components/HeaderButtons/SignInButton";
// import BecomeAPartnerButton from "~/Components/MainHeader/components/HeaderButtons/BecomeAPartnerButton";
import logo from "~/Assets/images/logo.svg";
import logoScroll from "~/Assets/images/logo_scroll.svg";
import { Dialogs } from "./components/Dialogs";
import DialogsHook from "./components/Dialogs/DialogsHook";
import HeaderSearchVenues from "~/Components/MainHeader/components/HeaderSearchValue";
import DialogMain from "~/Components/DialogMain/DialogMain";
import { HeaderSearchLocation } from "~/Components/MainHeader/components/HeaderSearchLocation";
import { useAppSelector } from "~/reduxConfig";
import { isLoggedInSelector, locationSelector } from "~/store/selectors";
import { HeaderProps } from "~/Components/MainHeader/interfaces";
import { getLocationAction } from "~/store/actions/locationActions";
// import { PARTNER_LANDING_PAGE } from "~/constants";
import DialogMobile from "./components/DialogsMobile";
import { CheckMobileHook } from "./CheckMobileHook";
import { DialogMobileHook } from "./components/DialogsMobile/DialogMobileHook";
import styles from "./MainHeaderStyle.module.scss";
import { useStyles } from "./styles";
// import { MoreDropDown } from "./components/MoreDropDown";

const dataMenu = [
  {
    title: "Download Glamezy",
    link: "/",
    icon: <GetAppIcon />,
    disabled: true,
  },
  {
    title: "Careers",
    link: "/",
    icon: <WorkIcon />,
    disabled: true,
  },
  // {
  //   title: "Contact us",
  //   link: generalLayoutRoute.concat(contactPageRoute),
  //   icon: <ContactSupportIcon />,
  // },
];

interface Props {
  window?: () => Window;
  children: React.ReactElement;
}

function ElevationScroll(props: Props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });
  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

export const UnauthorizedHeader: React.FC<HeaderProps> = ({ handleClass }) => {
  const classes = useStyles();
  const { pathname, search } = useLocation();
  const { isMobile } = CheckMobileHook();
  const dispatch = useDispatch();
  const dialogsHook = DialogsHook();
  const { city, placeId } = useAppSelector(locationSelector);
  const isLoggedIn = useAppSelector(isLoggedInSelector);
  const [openBlockModal, setOpenBlockModal] = useState(false);
  const { openModalSearch, handleModalSearch } = DialogMobileHook();
  const [isToggle, setIsToggle] = React.useState(false);

  const query = new URLSearchParams(search);
  const signInCustomer = query.get("signInCustomer");


  const toggleDrawer = () => setIsToggle(!isToggle);

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 1,
  });

  const onOpenModal = () => {
    setOpenBlockModal(true);
  };

  const onCloseModal = () => {
    setOpenBlockModal(false);
  };

  const onShowModalSignUp = () => {
    dialogsHook.setStep(2);
    dialogsHook.setShowModal(true);
  };

  const openModalSignIn = () => dialogsHook.setShowModal(true);

  // const onClickPartner = () => {
  //   window.open(PARTNER_LANDING_PAGE, '_blank');
  // };

  useEffect(() => {
    if (!placeId) {
      dispatch(getLocationAction());
    }
  }, [dispatch, placeId]);

  useEffect(() => {
    if (!isLoggedIn && signInCustomer) {
      const element = document.getElementById("header-button-sign-in-ctm");
      element.click();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const list = () => (
    <div
      className={clsx(classes.list)}
      role="presentation"
      onClick={toggleDrawer}
    >
      <List>
        {dataMenu.map((text, index) => (
          <ListItem
            className={classes.listItem}
            button
            key={index}
            disabled={text.disabled}
            component={Link}
            to={text.link}
          >
            <ListItemIcon>{text.icon}</ListItemIcon>
            <ListItemText primary={text.title} />
          </ListItem>
        ))}
      </List>
      <div
        onClick={openModalSignIn}
        className={`${classes.signUp} ${classes.signIn}`}
      >
        <ListItemIcon>
          <i className="icon-user"></i>
        </ListItemIcon>
        <ListItemText primary={"Sign In"} />
      </div>
      <div onClick={onShowModalSignUp} className={classes.signUp}>
        <ListItemIcon>
          <i className="icon-user"></i>
        </ListItemIcon>
        <ListItemText primary={"Sign Up"} />
      </div>
      <div className={classes.divide} />
      {/* <div onClick={openModalSignIn} className={classes.signUp}>
        <ListItemIcon />
        <ListItemText primary={"Become a partner"} onClick={onClickPartner} />
      </div> */}
    </div>
  );

  return (
    <div className={`${"header-section"} ${handleClass}`}>
      <Dialogs {...dialogsHook} />
      <DialogMobile open={openModalSearch} handleClose={handleModalSearch} />
      <ElevationScroll>
        <AppBar
          className={clsx(styles.header, {
            [styles.header_scroll]: trigger,
            [styles.header_not_main]: pathname !== "/main/home" && !trigger && isMobile,
            [styles.disable_transition]: isMobile
          })}
        >
          <div className={classes.menuUnAuth}>
            <Link to="/" className={`${"header-logo"} ${styles.header_logo}`}>
              <img src={logo} className={"logo_img"} alt="Glamezy" />
              {(pathname.includes("info") || pathname.includes("about")) && trigger ? (
                <img src={logoScroll} alt="Glamezy" />
              ) : (
                <img
                  src={logoScroll}
                  className={clsx(
                    `${"logo_img_scroll"} ${styles.logo_img_scroll}`,
                    {
                      [styles.logo_img_not_main]:
                        pathname !== "/main/home" && !trigger && isMobile,
                    }
                  )}
                  alt="Glamezy"
                />
              )}
            </Link>
            {!isMobile && (
              <>
                <DialogMain
                  titleDialog="Location"
                  showModal={openBlockModal}
                  onOpen={onOpenModal}
                  onClose={onCloseModal}
                  showDialogActions={false}
                  openDialogButtonText={city}
                  openDialogButtonClass="submit-btn-save-changes with-icon with-arrow"
                >
                  <HeaderSearchLocation onClose={onCloseModal} />
                </DialogMain>
                <HeaderSearchVenues handleClose={handleModalSearch} />
              </>
            )}
          </div>
          <div className={classes.menuMobile}>
            {isMobile ? (
              isToggle ? (
                <CloseIcon
                  className={clsx(classes.iconClose, {
                    [classes.iconCloseScroll]: trigger,
                  })}
                  onClick={toggleDrawer}
                />
              ) : (
                <div
                  className={clsx(classes.iconMenu, {
                    [classes.iconScroll]: trigger,
                  })}
                >
                  <SearchIcon
                    onClick={handleModalSearch}
                    className={classes.iconSearch}
                  />
                  <MenuIcon onClick={toggleDrawer} />
                </div>
              )
            ) : (
              <>
                {/* <MoreDropDown /> */}
                <SignInButton setShowModal={dialogsHook.setShowModal} />
                <button
                  onClick={onShowModalSignUp}
                  className={clsx(
                    'header-button-sign-in',
                    'header-button-sign-up',
                    styles.button_sign_up
                  )}
                  id="header-button-sign-up"
                >
                  <i className="icon-user"></i> Sign Up
                </button>
                <Dialogs {...dialogsHook} />
                {/* <BecomeAPartnerButton /> */}
              </>
            )}
          </div>

          {/* use open sign up on mobile view */}
          <button
            style={{ position: "absolute", visibility: "hidden" }}
            onClick={onShowModalSignUp}
            className={"header-button-sign-in"}
            id="header-button-sign-up"
          >
            <i className="icon-user"></i> Sign Up
          </button>
          <Drawer
            className={classes.MuiDrawer}
            open={isToggle}
            onClose={toggleDrawer}
          >
            {list()}
          </Drawer>
        </AppBar>
      </ElevationScroll>
    </div>
  );
};
