import type { FC } from 'react';

import styles from './ResourceErrorStyle.module.scss';

interface ResourceErrorProps {
  error?: string;
  onRefetch?: () => void;
}

export const ResourceError: FC<ResourceErrorProps> = (props) => {
  const { error } = props;

  return (
    <div className={styles.wrapper_page_error}>
      <h2>
        {error}
      </h2>
      <a
        href="/"
        className={styles.button_error}
      >
        Go Back
      </a>
    </div>
  );
};
