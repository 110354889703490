import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { sessionSelector } from './store/selectors';
import { accssesAccountAction } from './store/actions/sessionActions';
import { geocodeExtractor } from '~/utils/geocodeExtractor';
import { setLocation } from '~/store/reducers/locationReducer';
import { GoogleApi } from './services/api/GoogleApi';

const google = window.google;
let geocoder;
let service;

export const RoutesHook = () => {
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector(sessionSelector);

  useEffect(() => {
    initGoogleServices();
    defineLocation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initGoogleServices = () => {
    service = new google.maps.places.AutocompleteService();
    geocoder = new google.maps.Geocoder();
  };

  const defineLocation = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      const { coords } = position;
      geocoder.geocode(
        {
          location: { lat: coords.latitude, lng: coords.longitude },
        },
        async (geocode) => {
          const isInArea = await areaCheck(geocode);
          if (!geocode || !isInArea) return;
          const placeId = geocodeExtractor.getPlaceId(geocode);
          const country = geocodeExtractor.getCountry(geocode);
          const city = geocodeExtractor.getCity(geocode);
          const fetchTimezone = await GoogleApi.getTimezone({ lat: coords.latitude, lng: coords.longitude });
          const timezone = fetchTimezone.data.timeZoneId;
          const location = {
            placeId,
            country,
            city,
            lat: coords.latitude,
            lng: coords.longitude,
            timezone,
          };
          dispatch(setLocation(location));
        }
      );
    });
  };

  const areaCheck = async (geocode) => {
    let response;
    await service.getPlacePredictions(
      {
        input: geocodeExtractor.getCity(geocode),
        types: ['(cities)'],
        componentRestrictions: {
          country: ['AU', 'NZ'],
        },
      },
      (predictions) => {
        response = !!predictions?.length;
      }
    );
    return response;
  };

  useEffect(() => {
    if (isLoggedIn) dispatch(accssesAccountAction());
  }, [dispatch, isLoggedIn]);

  return {
    isLoggedIn,
  };
};
