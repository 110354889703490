import { useCallback, useEffect, useMemo, useState } from "react";
import queryString from "query-string";
import { useForm, useWatch } from "react-hook-form";
import { debounce } from "lodash";
import { useTheme, useMediaQuery } from "@material-ui/core";

import history from "~/utils/history";
import { mainLayoutRoute, searchResultsPageRoute, searchSortBy } from "~/constants";
import { useAppDispatch, useAppSelector } from "~/reduxConfig";
import { locationSelector } from "~/store/selectors";
import {
  onClearSearchPageStoreAction,
  onLoadSearchResultAction,
  onLoadCategoriesAction,
  onOpen
} from "~/store/actions/searchActions";
import type { GetCustomerSearchRequest } from "~/store/actions/interfaces/searchActionsInterfaces";
import type { SearchFormProps } from "~/Containers/SearchResultsPage/interfaces";
import type { LocationReducerStateProps } from "~/store/reducers/interfaces/locationInterfaces";
import homePageApi from "~/services/api/homePageApi";

export const SearchResultsPageHook = (props) => {
  const { country, lat, lng } =
    useAppSelector<LocationReducerStateProps>(locationSelector);
  const dispatch = useAppDispatch();
  const {
    location: { search },
  } = props;
  const searchFields = useMemo(() => queryString.parse(search), [search]);
  const [open, setOpen] = useState(true);
  const [isFirst, setIsFirst] = useState(true);
  const [loadingTags, setLoadingTags] = useState(true);
  const [tags, setTags] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const fetchTags = async () => {
    try {
      const response = await homePageApi.getPopularTags();
      const tagsList = response?.data?.popularTags || [];
      setTags(tagsList);
    } catch (error) {
      console.log(error);
    }
    setLoadingTags(false);
  };

  useEffect(() => {
    fetchTags();
  }, []);

  useEffect(() => {
    if(isMobile) {
      handleDrawerClose()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[isMobile])

  const searchFormMethods = useForm<SearchFormProps>();
  const { control, reset } = searchFormMethods;
  const formValues = useWatch({ control });

  useEffect(() => {
    dispatch(onLoadCategoriesAction());
    return () => {
      dispatch(onClearSearchPageStoreAction());
    };
  }, [dispatch]);

  const debounceAction = debounce(async (values) => {
    dispatch(onLoadSearchResultAction(values));
  }, 300);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchActionCallback = useCallback(debounceAction, []);

  useEffect(() => {
    if (loadingTags) return;

    if (formValues.sortBy === undefined) {
      formValues.sortBy = searchSortBy.defaultValue;
    }

    Object.keys(formValues).forEach(
      (k) => !formValues[k] && delete formValues[k]
    );

    if (
      formValues.categoriesIds &&
      typeof formValues.categoriesIds === "string"
    ) {
      formValues.categoriesIds = [formValues.categoriesIds];
    }

    const searchRequest = queryString.stringify({ ...formValues });
    if (isFirst) {
      setIsFirst(false);
    } else {
      history.push(
        mainLayoutRoute.concat(searchResultsPageRoute, `?${searchRequest}`)
      );
    }

    const searchParams = {
      country,
      lat: `${lat}`,
      lng: `${lng}`,
      ...formValues,
    } as GetCustomerSearchRequest;

    if (searchParams.search) {
      const selectedTag = tags.filter(
        (tag) => tag.tagName.toLowerCase() === searchParams.search.toLowerCase()
      );

      if (selectedTag.length) {
        delete searchParams.search;
        searchParams.tagId = selectedTag[0].id;
      }
    }

    searchActionCallback(searchParams);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formValues,
    country,
    lat,
    lng,
    dispatch,
    tags,
    searchActionCallback,
    loadingTags,
  ]);

  useEffect(() => {
    const searchObj = searchFields.search ? { search: searchFields.search } : {};
    reset({
      ...searchFields,
      ...searchObj,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFields.search, reset]);

  const handleDrawerOpen = () => {
    setOpen(true);
    dispatch(onOpen(true))
  };

  const handleDrawerClose = () => {
    setOpen(false);
    dispatch(onOpen(false))
  };

  return {
    open,
    handleDrawerOpen,
    handleDrawerClose,
    searchFormMethods,
    searchFields,
  };
};
