import { combineReducers } from "@reduxjs/toolkit";
import sessionReducer from "~/store/reducers/sessionReducer";
import mainReducer from "~/store/reducers/mainReducer";
import locationReducer from "~/store/reducers/locationReducer";
import customerProfileReducer from "~/store/reducers/customerProfileReducer";
import venueReducer from "~/store/reducers/venueReducer";
import searchReducer from "~/store/reducers/searchReducer";
import bookingReducer from "~/store/reducers/bookingReducer";
import paymentMethodReducer from "~/store/reducers/paymentMethodReducer";
import myBookingsReducer from "~/store/reducers/myBookingsReducer";
import userLocationReducer from "./store/reducers/userLocationReducer";

const rootReducer = combineReducers({
  session: sessionReducer,
  main: mainReducer,
  location: locationReducer,
  customerProfile: customerProfileReducer,
  venuePage: venueReducer,
  searchPage: searchReducer,
  booking: bookingReducer,
  paymentMethod: paymentMethodReducer,
  myBookings: myBookingsReducer,
  userLocation: userLocationReducer,
});

export default rootReducer;
