import { useEffect, useState } from "react";
import type { RouteComponentProps } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "~/reduxConfig";
import { venueSelector } from "~/store/selectors";
import { VenueReducerState } from "~/store/reducers/interfaces/venueInterfaces";
import {
  onLoadNearbyVenuesAction,
  onLoadVenueAction,
  onLoadVenueReviewsAction,
  onLoadVenueServicesAction,
  onLoadVenueStaffAction,
} from "~/store/actions/venueActions";
import { StaffListPageSize } from "~/constants";
import { PaginationProps } from "~/Containers/VenuePage/interfaces";

export const VenuePageHook = (props: RouteComponentProps<any>) => {
  const initialPaginationState: PaginationProps = {
    currentPage: 1,
    lastPage: 0,
    total: 0
  };

  const {id} = props.match.params;
  const {staff, nearby, services} = useAppSelector<VenueReducerState>(venueSelector);
  const [staffListPagination, setStaffListPagination] = useState<PaginationProps>(initialPaginationState);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(onLoadVenueAction(+id));
    dispatch(onLoadVenueStaffAction({
      venueId: +id,
      page: 1,
      pageSize: StaffListPageSize
    }));
    dispatch(onLoadNearbyVenuesAction(+id));
    dispatch(onLoadVenueServicesAction(+id));
    dispatch(onLoadVenueReviewsAction(+id));
  }, [dispatch, id]);

  useEffect(() => {
    setStaffListPagination(prev => {
      return {
        ...prev,
        lastPage: Math.ceil(staff.count / StaffListPageSize),
        total: staff.count
      };
    });
  }, [staff]);

  const fetchNewStaff = () => {
    const {currentPage, lastPage} = staffListPagination;
    if (currentPage === lastPage) return;
    dispatch(onLoadVenueStaffAction({
      venueId: +id,
      page: currentPage + 1,
      pageSize: StaffListPageSize
    }));
    setStaffListPagination((prev) => ({...prev, currentPage: prev.currentPage + 1}));
  };

  return (
    {
      fetchNewStaff,
      nearby,
      services
    }
  );
};