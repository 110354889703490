import React from "react";
import moment from "moment";
import type { SelectedServicesProps } from "../../interfaces";

import styles from "./Service.module.scss";
import { formatPrice, convertDurationString } from "~/utils/helper";

export const Service: React.FC<SelectedServicesProps> = (props) => {
  const {
    serviceName,
    selected: { staffId, pricingOptionId, time, date },
    staff,
    pricingOptions = [],
    type = "service",
    ...rest
  } = props;
  const isService = type === "service";
  const selectedStaff = staff.find((staffItem) => staffItem.id === staffId);
  const selectedStaffFullName = selectedStaff
    ? `${selectedStaff.firstName} ${selectedStaff.lastName}`
    : "Anyone";
  const selectedDateTime = `${date} ${time}`;
  const selectedPricingOptions = pricingOptions.find(({id}) => id === pricingOptionId);
  const {
    variantName,
    price,
    specialPrice,
    duration,
    staffPricingOptions = [],
  } = selectedPricingOptions || {
    specialPrice: rest.specialPrice,
    price: rest.price,
    duration: rest.duration,
  };
  const staffPricing = staffPricingOptions.find(({staffId: id}) => id === staffId);
  const textDuration = convertDurationString(staffPricing?.duration || duration)
  const hourDuration = moment.duration(staffPricing?.duration || duration, "hours").asHours();
  const finalPrice = staffPricing?.specialPrice || staffPricing?.price || specialPrice || price;
  const viewPrice = `$${formatPrice(finalPrice)}`;
  const startTime = moment(selectedDateTime).format("h:mma");
  const endTime = moment(selectedDateTime).add(hourDuration, "h").format("h:mma");

  return (
    <div className={styles.booking_info}>
      <strong className={styles.heading}>
        {isService ? (
          <>
            {serviceName} • {variantName} <span>{viewPrice}</span>
          </>
        ) : (
          <>
            {rest.packageName}
            <span className={styles.packages}>
              {" "}
              ({rest.packageServices.length} services){" "}
            </span>
            <span>{viewPrice}</span>
          </>
        )}
      </strong>

      <p>
        {selectedStaffFullName} •{" "}
        <time>
          {startTime} - {endTime}
        </time>{" "}
        • {textDuration}
      </p>
    </div>
  );
};
