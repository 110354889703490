import type { VenueProps } from '~/store/reducers/interfaces/venueInterfaces';
import type { AvailableCardVenuesEntity } from '~/store/reducers/interfaces/mainInterfaces';
import type { MyBookingVenueEntity } from '~/store/reducers/interfaces/myBookingsInterfaces';
import type { PaymentVenueProps } from '~/Containers/PaymentFlowPage/interfaces';

export const addressExtractor = (
  venue:
    | VenueProps
    | AvailableCardVenuesEntity
    | MyBookingVenueEntity
    | PaymentVenueProps
) => {
  const { secondAddress, firstAddress, city, state, country, subpremise } = venue;

  const streetAddress = [secondAddress, firstAddress]
    .filter((item) => !!item || item !== '')
    .join(' ');

  const concatenatedFullAddress = [streetAddress, city, state, country];

  return subpremise ? `${subpremise}/${concatenatedFullAddress.join(", ")}` : concatenatedFullAddress.join(", ");
};

// get distance between two points
export const getDistance = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
) => {
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1); // deg2rad below
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in km
  return d.toFixed(2);
};

// convert degrees to radians
const deg2rad = (deg: number) => {
  return deg * (Math.PI / 180);
};
