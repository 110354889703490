import React from 'react';
import { NavLink } from 'react-router-dom';
import { generalLayoutRoute, infoPageRoute } from '~/constants';
import { AvailableCardVenuesEntity } from '~/store/reducers/interfaces/mainInterfaces'
import styles from './NoteAndPolicyStyle.module.scss';

interface Props {
  venue: AvailableCardVenuesEntity
}

export const NoteAndPolicy: React.FC<Props> = ({ venue }) => {
  return (
    <>
      <div className={styles.note}>
        <div className={styles.note_title}>Note from {venue.locationName}</div>

        <p className={styles.note_description}>
          {venue?.onlineBookingSettings?.bookingTermsAndConditions}
        </p>
      </div>

      <div className={styles.policy}>
        <div className={styles.policy_title}>Cancellation policy</div>

        <p className={styles.policy_description}>
          To cancel your session see the{' '}
          <NavLink
            to={generalLayoutRoute.concat(infoPageRoute, '?', 'value=1')}
            target='_blank'
          >
            cancellation policy
          </NavLink>
        </p>
      </div>
    </>
  );
};
