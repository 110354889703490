import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment-timezone";

import homePageApi from "~/services/api/homePageApi";
import { useAppSelector, useAppDispatch } from "~/reduxConfig";
import {
  onFirstLoadAvailableVenuesAction,
  onLoadAvailableVenuesAction,
  onLoadCategoriesAction,
  onLoadOtherVenuesAction,
  getBannersAction,
} from "~/store/actions/mainActions";
import { mainSelector, locationSelector } from "~/store/selectors";
import subscribeSchema from "~/services/validationServices/subscribeSchema";
import { OtherVenuesPageSize, VenueSliderPageSize } from "~/constants";
import type { mainReducerState } from "~/store/reducers/interfaces/mainInterfaces";
import type { PaginationProps } from "~/Containers/HomePage/interfaces";
import type { LocationReducerStateProps } from "~/store/reducers/interfaces/locationInterfaces";

type SubscribeFormProps = {
  subscribe: string;
};

const HomePageHook = () => {
  const initialPaginationState: PaginationProps = {
    currentPage: 1,
    lastPage: 0,
    total: 0
  };
  const dispatch = useAppDispatch();
  const { categories, otherVenues, availableVenues } = useAppSelector<mainReducerState>(mainSelector);
  const location = useAppSelector<LocationReducerStateProps>(locationSelector);
  const [availableVenuesPagination, setAvailableVenuesPagination] = useState<PaginationProps>(initialPaginationState);

  const subscribeForm = useForm<SubscribeFormProps>({
    resolver: yupResolver(subscribeSchema),
    mode: "onChange",
    criteriaMode: "all",
  });

  useEffect(() => {
    dispatch(getBannersAction())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const { placeId, city, ...locationForRequest } = location;
    if (placeId) {
      dispatch(onLoadCategoriesAction());
      dispatch(onLoadOtherVenuesAction({
        ...locationForRequest,
        timezone: undefined,
        page: 1,
        pageSize: OtherVenuesPageSize
      }));
      dispatch(onFirstLoadAvailableVenuesAction({
        ...locationForRequest,
        timezone: undefined,
        currentTime: moment.tz(location.timezone).toISOString(),
        page: availableVenuesPagination.currentPage,
        pageSize: VenueSliderPageSize
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    setAvailableVenuesPagination((prevState => {
      return {
        ...prevState,
        lastPage: Math.ceil(availableVenues.amountAvailableVenues / VenueSliderPageSize),
        total: availableVenues.amountAvailableVenues
      };
    })
    );
  }, [availableVenues]);

  const onAvailableVenuesEndReached = () => {
    if (availableVenuesPagination.currentPage === availableVenuesPagination.lastPage) return;
    const nextPage = availableVenuesPagination.currentPage + 1;
    setAvailableVenuesPagination((prevState => ({
      ...prevState,
      currentPage: nextPage
    }))
    );
    dispatch(onLoadAvailableVenuesAction({
      ...location,
      timezone: undefined,
      currentTime: moment.tz(location.timezone).toISOString(),
      page: nextPage,
      pageSize: VenueSliderPageSize
    }));
  };

  const onSubscribe = (data) => {
    subscribeForm.setValue("subscribe", "");
    homePageApi.subscribe({ email: data.subscribe });
  };

  return {
    categories,
    otherVenues,
    availableVenues,
    onAvailableVenuesEndReached,
    subscribeForm,
    onSubscribe,
  };
};

export default HomePageHook;
