import moment from "moment";

import { timeAmFormat } from "~/constants";
import type { BookingServiceItemProps } from "~/Containers/MyBookingsPage/interfaces";
import { convertDurationString } from "~/utils/helper";

export const BookingServiceItemHook = (props) => {
  const { bookingService, timezone } = props as BookingServiceItemProps;

  const {
    service,
    package: packageService,
    pricingOption,
    staff,
    startDateTime,
    endDateTime,
    duration,
    adjustBookingServicesPrice,
  } = bookingService;

  const [priceAdjustment] = adjustBookingServicesPrice;
  const { variantName } = pricingOption || {
    specialPrice: packageService?.specialPrice,
    price: packageService?.price,
  };
  const displayedPrice =
    priceAdjustment?.discountType === 'percentage'
      ? +priceAdjustment?.price / (1 - priceAdjustment?.discount / 100)
      : priceAdjustment?.discountType === 'currency'
        ? +priceAdjustment?.price + priceAdjustment?.discount
        : +priceAdjustment?.price;
  const displayedName = staff ? `${staff.firstName} ${staff.lastName}` : "Anyone";

  const startDisplayedTime = moment.tz(startDateTime, timezone).format(timeAmFormat);
  const endDisplayedTime = moment.tz(endDateTime, timezone).format(timeAmFormat);
  const displayedTime = `${startDisplayedTime}-${endDisplayedTime}`;
  const displayedDuration = convertDurationString(packageService?.duration || duration);
  const isPackage = !!packageService;

  return {
    serviceName: packageService?.packageName || service?.serviceName,
    variantName,
    displayedName,
    displayedPrice: displayedPrice,
    displayedTime,
    displayedDuration,
    priceAdjustment: priceAdjustment?.price,
    isPackage,
    serviceOfPackage: packageService?.packageServices.length,
    listServicePackage: packageService?.packageServices || [],
  };
};
