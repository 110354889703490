import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";

import type { HourFormLabelProps } from "~/Containers/BookingFlowPage/interfaces";

export const HourFormLabel: React.FC<HourFormLabelProps> = (props) => {
  const { time, disabled = false } = props;

  return (
    <>
      <FormControlLabel
        value={time.hour}
        disabled={disabled}
        control={<Radio />}
        label={time.hourAm} />
    </>
  );
};