import type { StaffEntity } from "~/store/reducers/interfaces/bookingInterfaces";

export const defaultPasswordMsg = [
  "Required Field",
  "At least 8 characters in length",
  "At least 1 upper and lower case letter",
  "At least 1 numeric and special characters",
  "No spaces allowed",
  "Оnly latin letters",
];

export const TAX_RATE = 0.1;

export const PARTNER_LANDING_PAGE = "https://partners.glamezy.com";

export const steps = {
  signIn: 1,
  signUp: 2,
  additionalInfo: 3,
  confirmation: 4,
  forgotPassword: 5,
  forgotPasswordCode: 6,
  forgotPasswordNewPassword: 7,
  changePhoneNumber: 8,
  sendOpt: 9,
};

export const pswMsgValidationType = "passwordMsg";
export const defaultValidationType = "simpleMsg";
export const TOKEN = "TOKEN";
export const forgotPasswordDescription = "Enter your email address, we will send instructions on how to reset your password to your email address";

export const mainLayoutRoute = "/main";
export const generalLayoutRoute = "/general";
export const userLayoutRoute = "/user";
export const flowsLayoutRoute = "/flow";
export const successLayoutRoute = "/success";
export const privacyPolicyLayoutRoute = "/privacy-policy";

export const homePageRoute = "/home";
export const searchResultsPageRoute = "/search";
export const venuePageRoute = "/venue";

export const userProfilePageRoute = "/user-profile";
export const bookingPageRoute = "/booking";
export const notificationPageRoute = "/notification";

export const contactPageRoute = "/contact";
export const infoPageRoute = "/info";
export const faqPageRoute = "/faq";
export const aboutPageRoute = "/about";

export const bookingFlowPageRoute = "/booking-flow";
export const paymentFlowPageRoute = "/payment-flow";

export const confirmationInputNames = [
  "digit1",
  "digit2",
  "digit3",
  "digit4",
  "digit5",
];

export const partnershipText = "Glamezy’s powerful booking platform that instantly connects customers with your beauty and wellness business. We help businesses grow sales, reach more customers and secure more bookings.";
export const subscripeText = "Psst! Stay in the know is easy-peasy. Just enter your email and subscribe to get exclusive offers delivered to your inbox.";
export const legalText = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis semper ante orci vel turpis in. Tristique viverra sem commodo enim in aliquam mollis. Sit lobortis est volutpat amet, maecenas donec adipiscing sollicitudin eu. Eget gravida non tellus id habitasse id.";
export const legalTextSub = "Euismod tellus pharetra cum ut viverra tortor convallis aliquet quis. Tempor gravida risus pellentesque aenean pulvinar. Integer viverra ut arcu sed sapien elit elementum. Pellentesque lectus sit fermentum mattis.";
export const partnershipTextUSerProfile = "Glamezy’s powerful booking platform that instantly connects customers with your beauty and wellness business. We help businesses grow sales, reach more customers and secure more bookings.";

export const OtherVenuesPageSize = 8;
export const VenueSliderPageSize = 10;
export const StaffListPageSize = 10;

export const titlesGeneralInfo = {
  helpAndFaq: {
    faqTitle: "Help and FAQ",
    faqText: "Have questions? you can find them here",
  },
  legalTitle: "Legal",
  contactUs: "Contact us",
  aboutUsTitle: "About us",
};

export const userNotification = {
  title: "Notifications",
  youHave: "You have",
  notification: "new notification",
  notifications: "new notifications",
}

export const stepsForProfile = {
  changePhoneNumber: 1,
  changeEmail: 2,
  changeEmailVerify: 3,
  changePassword: 4,
  forgotPassword: 5,
  forgotPasswordCode: 6,
  forgotPasswordNewPassword: 7,
  changePhoneVerify: 8,
};

export const stepsForMyBookingDialog = {
  main: 1,
  reschedule: 2,
  cancel: 3,
};

export const anyonePlaceholder = {
  id: 0,
  firstName: "Anyone",
  lastName: "",
  phone: "",
  accountId: null,
  businessId: null,
  roleId: null,
  avatar: "~/Assets/images/beauty.png",
  contactEmail: "",
  onlineBookings: true,
  jobTitle: "",
  notes: "",
  serviceProvider: true,
  effectivityDate: "",
  employmentStartDate: "",
  employmentEndDate: "",
  venueId: null
} as StaffEntity;

export const searchMap = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12650.827943200205!2d153.02444398759877!3d-27.477221517173493!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b915a104c721d41%3A0xaeb9c00d1ef00171!2sQUT%20Gardens%20Point%20Campus!5e0!3m2!1suk!2sua!4v1633962788625!5m2!1suk!2sua";

export const dateFormat = "YYYY-MM-DD";
export const viewedDateFormat = "dddd, DD MMM, YYYY";
export const timeFormat = "HH:mm";
export const timeAmFormat = "hh:mm a";
export const timeAmFormatShort = "h:mm a";
export const fullTimeFormat = "HH:mm:ss";

export const timeDividers = {
  "09:00": "Morning",
  "12:00": "Afternoon",
  "18:00": "Evening"
};

export const paymentPanels = {
  panel1: "panel1",
  panel2: "panel2",
  panel3: "panel3",
  panel4: "panel4",
};

export const searchSortBy = {
  name: "sortBy",
  defaultValue: "nearest",
  nearest: {
    value: "nearest",
    label: "Nearest",
  },
  priceLowHigh: {
    value: "priceLowHigh",
    label: "Price: Low - High",
  },
  priceHighLow: {
    value: "priceHighLow",
    label: "Price: High-Low",
  },
  newest: {
    value: "newest",
    label: "Newest",
  },
  topRated: {
    value: "topRated",
    label: "Top Rated",
  }
};

export const payType = {
  panel1: "creditCard",
  panel2: "payAtVenue",
  panel3: "afterpay",
  panel4: "paypal",
};

export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
export const REACT_APP_HOST = process.env.REACT_APP_HOST;
export const REACT_APP_SHARE_HOST = process.env.REACT_APP_SHARE_HOST;
export const timezoneDefault = "Australia/Sydney";
export const BANNER_COVID = "BANNER_COVID";
export const REACT_APP_ID = '418665913498745';
