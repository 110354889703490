import type { Dispatch } from "redux";

import { bookingApi } from "~/services/api/bookingApi";
import {
  clearBookingStore,
  clearCountBookingPrice,
  fetchBookingServices,
  fetchBookingStaff,
  fetchConfirmedBooking,
  fetchCountBookingPrice,
} from "~/store/reducers/bookingReducer";
import type {
  GetCountRequestProps,
  GetServicesRequestProps,
  GetStuffRequestProps,
  PostBookingConfirmProps,
} from "~/store/actions/interfaces/bookingActionsInterfaces";

export const onLoadBookingServicesAction = (params: GetServicesRequestProps) => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await bookingApi.getBookingServices(params);
      dispatch(fetchBookingServices(response.data));
    } catch (error: any) {
      console.log("error", error);
    }
  };
};

export const onLoadBookingStaffAction = (params: GetStuffRequestProps) => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await bookingApi.getBookingStaff(params);
      dispatch(fetchBookingStaff(response.data));
    } catch (error: any) {
      console.log("error", error);
    }
  };
};

export const onLoadBookingCountPriceAction = (params: GetCountRequestProps) => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await bookingApi.getCountBookingPrice(params);
      dispatch(fetchCountBookingPrice(response.data));
    } catch (error: any) {
      console.log("error", error);
    }
  };
};

export const onClearBookingCountPriceAction = () => {
  return async (dispatch: Dispatch) => {
    dispatch(clearCountBookingPrice());
  };
};

export const bookingConfirmAction = (params: PostBookingConfirmProps) => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await bookingApi.postBookingConfirm(params);
      dispatch(fetchConfirmedBooking(response.data));
    } catch (error: any) {
      console.log("error", error);
    }
  };
};

export const onClearBookingStoreAction = () => (dispatch: Dispatch) => {
  dispatch(clearBookingStore());
};