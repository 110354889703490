import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Grid, Avatar } from '@material-ui/core';
import { RouteComponentProps } from 'react-router-dom';

import history from '~/utils/history';
import { addressExtractor } from '~/utils/addressExtractor';
import {
  viewedDateFormat,
  bookingPageRoute,
  userLayoutRoute,
} from '~/constants';
import { Service } from './components/Service';
import { CheckMobileHook } from '~/Components/MainHeader/CheckMobileHook';
import { formatPrice } from '~/utils/helper';

import styles from './SuccessPageStyle.module.scss';

export const SuccessPage: React.FC<RouteComponentProps> = () => {
  const { isMobile } = CheckMobileHook();
  const [bookingData, setBookingData] = useState(null);

  useEffect(() => {
    const booking = JSON.parse(localStorage.getItem('booking'));
    booking ? setBookingData(booking) : goMyBooking();
  }, []);

  const goMyBooking = () => {
    history.push(userLayoutRoute.concat(bookingPageRoute));
  };

  const {
    venue,
    booking: {
      date = new Date(),
      selectedServices = [],
    } = {},
    countBookingPrice,
  } = bookingData || {};

  const { subtotal = 0, tax = 0, total = 0, discount } = countBookingPrice || {};
  let valueDiscount = 0;
  if (discount?.type === "PERCENTAGE") {
    const discountPercentage = (total / (1 - discount?.value / 100)) * discount?.value / 100;
    valueDiscount = discountPercentage > discount?.discountCap && discount?.discountCap !== 0 ? discount?.discountCap : discountPercentage
  } else {
    valueDiscount = discount?.value > discount?.discountCap && discount?.discountCap > 0 ? discount?.discountCap : discount?.value
  }

  return (
    <main className={styles.root}>
      <div className={styles.container}>
        <div className={styles.background_images}>
          <span className={styles.big_round} />
          <span className={styles.scissors} />
          <span className={styles.hairdryer} />
          <span className={styles.pomade} />
          <span className={styles.makeup_brush} />
        </div>

        <div className={styles.container_holder}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={6} lg={6} className={styles.success}>
              <h1>All Set!</h1>
              <span>You are all booked in. Please let the venue know when you arrive on the day.</span>
              {!isMobile && (
                <button onClick={goMyBooking}>View my bookings</button>
              )}
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} className={styles.card}>
              <section>
                <div className={styles.header}>
                  <Avatar className={styles.logo} src={venue?.logo}>
                    {venue?.locationName?.charAt(0) || ''}
                  </Avatar>

                  <div>
                    <h3 className={styles.info}>{venue?.locationName}</h3>
                    {venue && (
                      <span className={styles.info_description}>
                        {addressExtractor(venue)}
                      </span>
                    )}
                  </div>
                </div>
              </section>

              <section>
                <span className={styles.colored}>
                  {moment(date).format(viewedDateFormat)}
                </span>
              </section>

              {selectedServices?.map((service, index) => (
                <Service key={index} {...service} />
              ))}

              <section>
                <div className={styles.total}>
                  <h3>Subtotal</h3>
                  <span className={styles.colored}>${subtotal.toFixed(2) || ''}</span>
                </div>
                <div className={styles.total}>
                  <h3>GST included</h3>
                  <span className={styles.colored}>${tax.toFixed(2) || ''}</span>
                </div>
              </section>

              <section>
                {discount ? (
                  <div className={styles.total}>
                    <h3>Discount</h3>

                    <span className={styles.colored}>-${formatPrice(valueDiscount)}</span>
                  </div>
                ) : null}
                <div className={styles.total}>
                  <h3>Total</h3>
                  <span className={styles.colored}>${total.toFixed(2) || ''}</span>
                </div>
              </section>
            </Grid>
            {isMobile && (
              <Grid
                className={styles.gridButton}
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
              >
                <button className={styles.buttonMobile} onClick={goMyBooking}>
                  View my bookings
                </button>
              </Grid>
            )}
          </Grid>
        </div>
      </div>
    </main>
  );
};
