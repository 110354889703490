import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import { useFormContext } from 'react-hook-form';

import { FormCard } from '~/Components/FormCard';
import { Cards } from '../Cards';
import { paymentPanels } from '~/constants';
import { useStyles } from './useStyles';
import type { PaymentMethodsProps } from './interfaces';
import styles from './PaymentMethodsStyle.module.scss';

const ShowFormAndCards = ({
  cards,
  defaultCardId,
  setAddCardModal,
  onHideMessage,
  onChangeLoadingBooking,
}) => {
  const { setValue } = useFormContext();
  const [cardId, setCardId] = useState(defaultCardId);

  useEffect(() => {
    if (cards.length) {
      setCardId(defaultCardId);
    }
  }, [defaultCardId, cards]);

  useEffect(() => {
    setValue('cardId', cardId);
  }, [cardId, setValue]);

  return (
    <>
      {cards.length ? (
        <div>
          {cards.map((card) => (
            <Cards
              key={card.id}
              defaultCardId={defaultCardId}
              {...card}
              cardId={cardId}
              setCardId={setCardId}
            />
          ))}

          <div className={styles.btn_add}>
            <Button
              variant='outlined'
              color='primary'
              onClick={() => setAddCardModal(true)}
              className='white-btn'
            >
              Add card
            </Button>
          </div>
        </div>
      ) : (
        <FormCard
          hideMessage={onHideMessage}
          message=''
          checkboxLabel='Remember card'
          checkboxName='remember'
          onChangeLoadingBooking={onChangeLoadingBooking}
        />
      )}
    </>
  );
};

export const PaymentMethods: React.FC<PaymentMethodsProps> = ({
  cards,
  defaultCardId,
  setAddCardModal,
  expanded,
  handleChange,
  onHideMessage,
  onChangeLoadingBooking,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Accordion
        expanded={expanded === paymentPanels.panel1}
        onChange={handleChange(paymentPanels.panel1)}
        className={classes.accordionBlock}
      >
        <AccordionSummary
          aria-controls='panel1bh-content'
          id='panel1bh-header'
          className={`${classes.accordionSummary} ${styles.accordionSummary}`}
        >
          <Typography className={`${classes.heading} ${styles.heading}`}>
            Credit card
          </Typography>
        </AccordionSummary>
        <hr className={styles.top_line} />
        <AccordionDetails className={classes.accordionDetails}>
          <div className={styles.payment_venue}>
            {expanded === paymentPanels.panel1 && (
              <ShowFormAndCards
                cards={cards}
                defaultCardId={defaultCardId}
                setAddCardModal={setAddCardModal}
                onHideMessage={onHideMessage}
                onChangeLoadingBooking={onChangeLoadingBooking}
              />
            )}
          </div>
        </AccordionDetails>
      </Accordion>
      {/* <Accordion
        expanded={expanded === paymentPanels.panel2}
        onChange={handleChange(paymentPanels.panel2)}
        className={classes.accordionBlock}
      >
        <AccordionSummary
          aria-controls='panel2bh-content'
          id='panel2bh-header'
          className={`${classes.accordionSummary} ${styles.accordionSummary}`}
        >
          <Typography className={`${classes.heading} ${styles.heading}`}>
            Pay at venue
          </Typography>
        </AccordionSummary>
        <hr className={styles.top_line} />
        <AccordionDetails className={classes.accordionDetails}>
          <div className={styles.payment_venue}>
            <p className={styles.info_pay}>
              To pay at venue, you need to add your card info, the payment will
              not be charged. See the <a href='/'>reservation policy</a>
            </p>

            {expanded === paymentPanels.panel2 && (
              <ShowFormAndCards
                cards={cards}
                defaultCardId={defaultCardId}
                setAddCardModal={setAddCardModal}
                onHideMessage={onHideMessage}
              />
            )}
          </div>
        </AccordionDetails>
        <hr className={styles.bottom_line} />
      </Accordion> */}
      {/* <Accordion
        expanded={expanded === paymentPanels.panel3}
        onChange={handleChange(paymentPanels.panel3)}
        className={classes.accordionBlock}
      >
        <AccordionSummary
          aria-controls='panel3bh-content'
          id='panel3bh-header'
          className={`${classes.accordionSummary} ${styles.accordionSummary}`}
        >
          <Typography className={`${classes.heading} ${styles.heading}`}>
            Afterpay
          </Typography>
        </AccordionSummary>
        <hr className={styles.top_line} />
        <AccordionDetails
          className={`${classes.accordionDetails} ${styles.accordion_details}`}
        >
          <AfterpayMethod />
        </AccordionDetails>
        <hr className={styles.bottom_line} />
      </Accordion>
      <Accordion
        expanded={expanded === paymentPanels.panel4}
        onChange={handleChange(paymentPanels.panel4)}
        className={classes.accordionBlock}
      >
        <AccordionSummary
          aria-controls='panel4bh-content'
          id='panel4bh-header'
          className={`${classes.accordionSummary} ${styles.accordionSummary}`}
        >
          <Typography className={`${classes.heading} ${styles.heading}`}>
            Paypal
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <div className={styles.paypal_link}>
            <a href='/'>You will be redirected to Paypal website</a>
          </div>
        </AccordionDetails>
      </Accordion> */}
    </div>
  );
};
