import { useCallback, useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { IconButton, TextField, useMediaQuery, useTheme } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from '@material-ui/icons/Close';
import { debounce } from 'lodash'
import Highlighter from 'react-highlight-words';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import styles from "../HeaderSearchForm/HeaderSearchFormStyle.module.scss";
import { HeaderSearchValueHook } from "./HeaderSearchValueHook";
import { useAppSelector } from "~/reduxConfig";
import { LocationReducerStateProps } from "~/store/reducers/interfaces/locationInterfaces";
import { locationSelector } from "~/store/selectors";

import { useStyles } from './styles'

interface IHeaderSearchVenues {
  handleClose?: () => void;
}

const listTagInjectable = [
  'Laser Hair Removal',
  'Dermal Fillers',
  'Dermaplaning',
  'Anti-Wrinkle Injections',
  'Breast Enhancement',
  'Cheek Fillers',
  'Diathermy',
  'Fat Cavitation',
  'Laser Lipo',
  'Laser Treatments',
  'Lip Fillers',
  'Mesotherapy',
  'Spider Vein Treatment',
  'Varicose Veins Treatment',
];

const HeaderSearchVenues = ({ handleClose }: IHeaderSearchVenues) => {
  const location = useAppSelector<LocationReducerStateProps>(locationSelector);
  const {
    venues,
    setVenues,
    inputSearch,
    setInputSearch,
    isLoading,
    onSearchHandler,
    fetchTags
  } = HeaderSearchValueHook();
  const classes = useStyles();

  const sortTags = venues?.filter(
    (item) =>
      !item.name.includes("Laser") && !listTagInjectable.includes(item.name)
  );

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const debouncedSearchTerm = debounce((val: string) => {
    fetchTags(val)
  }, 500);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceCb = useCallback(debouncedSearchTerm, [location])

  const onChangeValueInput = (e) => {
    setInputSearch(e?.target?.value)
    setVenues([])
    debounceCb(e?.target?.value)
  }

  const [selectValue, setSelectValue] = useState("")
  const handleSearch = (value) => {
    if (typeof value === 'string') return
    onSearchHandler(value);
    isMobile && handleClose();
  }

  const handleSearchValue = () => {
    onSearchHandler(selectValue);
    handleClose();
  }

  const viewMobile = () => (
    <Autocomplete
      className={classes.autocomplete}
      id={'autocomplete'}
      freeSolo
      blurOnSelect
      onInputChange={onChangeValueInput}
      open={true}
      options={sortTags}
      groupBy={(option) => option.firstLetter}
      getOptionLabel={(option) => option?.name || ''}
      renderOption={(option) => (
        <div className={styles.popper_2} key={option.id}>
          <Highlighter
            className={styles.titleSearchForm}
            highlightClassName="textHighlighted"
            searchWords={[inputSearch]}
            autoEscape={true}
            textToHighlight={option.name}
          >
            {option.name}
          </Highlighter>
        </div>
      )}
      PaperComponent={(props) => {
        if (isLoading) {
          return <div className={styles.paper_3}>Loading...</div>;
        }

        return sortTags.length === 0 && !selectValue ? (
          <div className={styles.paper_3}>No service or venue</div>
        ) : (
          <div {...props} className={styles.paper_2} />
        );
      }

      }
      onChange={(event, value: any) => {
        setSelectValue(value);
      }}
      renderInput={(params) => {
        return (
          <div
            className={`${styles.search_panel} ${'search_panel'}`}
            ref={params.InputProps.ref}
          >
            <IconButton
              id="search_field"
              type="submit"
              className={styles.search_btn}
              aria-label="search"
            >
              {isMobile ? <CloseIcon onClick={handleClose} /> : <SearchIcon />}
            </IconButton>
            <TextField
              {...params}
              className={`${styles.search_input} ${'search_input'}`}
              label="Search for venues"
              margin="normal"
              variant="outlined"
              placeholder="Type venue or tag..."
              autoComplete="false"
              InputProps={{ ...params.InputProps, type: 'search' }}
            />
            <div className={classes.btnSubmit} onClick={handleSearchValue}>
              <ArrowForwardIcon />
            </div>
            <IconButton color="primary" aria-label="directions">
              <ArrowForwardIcon onClick={handleSearchValue} />
            </IconButton>
          </div>
        );
      }}
    />
  );

  return (
    isMobile ? viewMobile() :
      <Autocomplete
        className={classes.autocomplete}
        id={"autocomplete"}
        freeSolo
        blurOnSelect
        onInputChange={onChangeValueInput}
        options={sortTags}
        groupBy={(option) => option.firstLetter}
        getOptionLabel={(option) => option?.name || ""}
        renderOption={(option) => (
          <div className={styles.popper_2} key={option.id}>
            <Highlighter
              className={styles.titleSearchForm}
              highlightClassName='textHighlighted'
              searchWords={[inputSearch]}
              autoEscape={true}
              textToHighlight={option.name}
            >
              {option.name}
            </Highlighter>
          </div>
        )}
        PaperComponent={(props) => {
          if (isLoading) {
            return <div className={styles.paper_3}>Loading...</div>;
          }

          return sortTags.length === 0 ? (
            <div className={styles.paper_3}>No service or venue</div>
          ) : (
            <div {...props} className={styles.paper_2} />
          );
        }}
        onChange={(event, value) => {
          handleSearch(value);
        }}
        renderInput={(params) => {
          return (
            <div className={`${styles.search_panel} ${"search_panel"}`} ref={params.InputProps.ref}>
              <IconButton
                id="search_field"
                type="submit"
                className={styles.search_btn}
                aria-label="search"
              >
                {isMobile ? <CloseIcon onClick={handleClose} /> : <SearchIcon />}
              </IconButton>
              <TextField
                {...params}
                className={`${styles.search_input} ${"search_input"}`}
                label="Search for venues"
                margin="normal"
                variant="outlined"
                placeholder="Type venue or tag..."
                autoComplete="false"
                InputProps={{ ...params.InputProps, type: "search" }}
              />
            </div>
          );
        }}
      />
  );
};

export default HeaderSearchVenues;
