import React, { useState, useEffect, useRef } from "react";
import { useFormContext } from "react-hook-form";
import { searchSortBy } from "~/constants";

export const SortByHook = (searchFields) => {
  const { control } = useFormContext();

  const [open, setOpen] = useState<boolean>(false);
  const [sortBy, setSortBy] = useState<string>(searchSortBy.defaultValue);

  const anchorRef = useRef<HTMLButtonElement>(null);
  const prevOpen = useRef(open);

  useEffect(() => {
    if (searchFields.sortBy) {
      setSortBy(searchFields.sortBy);
    }
  }, [searchFields])

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleToggle = () => {
    setOpen((s) => !s);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  return {
    open,
    sortBy,
    control,
    anchorRef,
    setSortBy,
    handleClose,
    handleToggle,
    handleListKeyDown,
  }
}