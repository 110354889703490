import PhoneInput from "react-phone-input-2";
import { Controller } from "react-hook-form";
import { TextField, InputLabel, Grid, Paper } from "@material-ui/core";
import { Message } from "~/Components/Message";
import "./additionalInfoFormStyle.scss";
import "react-phone-input-2/lib/style.css";

export const AdditionalInfoForm = ({
  control,
  hideMessage,
  message,
}) => {
  return (
    <div className="card-wrapper">
      <Paper variant="outlined">
        <form>
          <Grid container justifyContent="center">
            <Grid container item xs={12} className="input-wrapper">
              <InputLabel htmlFor="firstName" className="label">
                First name
              </InputLabel>
              <Controller
                control={control}
                name="firstName"
                defaultValue=""
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <TextField
                      id="firstName"
                      className="text-field"
                      onFocus={hideMessage}
                      value={value}
                      onChange={(e) => onChange(e.target.value)}
                      variant="outlined"
                      error={Boolean(error)}
                    />
                    <Message msg={error?.message} />
                  </>
                )}
              />
            </Grid>

            <Grid container item xs={12} className="input-wrapper">
              <InputLabel htmlFor="lastName" className="label">
                Last name
              </InputLabel>
              <Controller
                control={control}
                name="lastName"
                defaultValue=""
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <TextField
                      id="lastName"
                      className="text-field"
                      onFocus={hideMessage}
                      value={value}
                      onChange={(e) => onChange(e.target.value)}
                      variant="outlined"
                      error={Boolean(error)}
                    />
                    <Message msg={error?.message} />
                  </>
                )}
              />
            </Grid>

            <Grid
              container
              justifyContent="center"
              className="input-wrapper grid-padding"
            >
              <Grid item xs={12}>
                <InputLabel className="label" htmlFor="phone">
                  Phone number
                </InputLabel>
                <Controller
                  control={control}
                  name="phone"
                  render={({
                    field: { onChange, onBlur, value, ref },
                    fieldState: { error },
                  }) => (
                    <>
                      <PhoneInput
                        onBlur={onBlur}
                        onChange={onChange}
                        onFocus={hideMessage}
                        value={value}
                        id="phone"
                        country="au"
                        placeholder="XXX XXX XXX"
                        inputRef={ref}
                      />
                      <Message msg={error?.message} />
                    </>
                  )}
                />
              </Grid>
            </Grid>
            <Message msg={message} />
          </Grid>
        </form>
      </Paper>
    </div>
  );
};
