/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState, useMemo } from "react";
import { useFieldArray, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import type { UseFieldArrayReturn } from "react-hook-form";
import { useLocation } from "react-router-dom";
import moment from "moment-timezone";

import { useAppDispatch, useAppSelector } from "~/reduxConfig";
import { bookingSelector, venueSelector } from "~/store/selectors";

import {
  onClearBookingStoreAction,
  onLoadBookingServicesAction,
  onLoadBookingStaffAction,
} from "~/store/actions/bookingActions";
import { bookingFlowSchema } from "~/services/validationServices/bookingFlowSchema";
import type { BookingReducerStateProps } from "~/store/reducers/interfaces/bookingInterfaces";
import type {
  BookingFlowPageLocationStateProps,
  BookingFormProps,
} from "~/Containers/BookingFlowPage/interfaces";
import { dateFormat, timeFormat, timezoneDefault } from "~/constants";
import { SelectedServiceProps } from "~/Containers/BookingFlowPage/interfaces";
import { VenueReducerState } from "~/store/reducers/interfaces/venueInterfaces";
import { onLoadVenueAction } from "~/store/actions/venueActions";

export const BookingFlowPageHook = (props) => {
  const { venue } = useAppSelector<VenueReducerState>(venueSelector);
  const { state }: any = useLocation();

  const locationState = props.location
    .state as BookingFlowPageLocationStateProps;
  const [isFirstAppendDone, setIsFirstAppendDone] = useState<boolean>(true);
  const [selectStaff, setSelectStaff] = useState<any>({});
  const { id } = props.match.params;
  const dispatch = useAppDispatch();
  const { staff, fetchedServices, fetchedPackages, countBookingPrice } =
    useAppSelector<BookingReducerStateProps>(bookingSelector);
  const bookingFormMethods = useForm<BookingFormProps>({
    resolver: yupResolver(bookingFlowSchema),
    defaultValues: {
      venueId: +id,
      staffId: 0,
      selectedServices: [],
    },
  });

  const defaultDateTime = useMemo(() => {
    const nowDate = moment.tz(new Date(), venue?.timezone || timezoneDefault).format(dateFormat);
    const locationTime = locationState?.dateTime?.time || locationState?.service?.dateTime?.time;
    const locationDate = locationState?.dateTime?.date || locationState?.service?.dateTime?.date;

    const preparedTime = (val) => {
      const initialDuration = moment.duration(val).asHours();
      const newDuration = Math.ceil(initialDuration * 2) * 0.5;
      return moment("00:00", timeFormat)
        .add(newDuration, "hours")
        .format(timeFormat);
    };

    return {
      date: locationDate || nowDate,
      time: locationTime ? preparedTime(locationTime) : "09:00",
    };
  }, [locationState]);

  const { control, setValue, reset } = bookingFormMethods;
  const bookingFormFieldArrayMethods = useFieldArray<BookingFormProps>({
    control,
    name: "selectedServices",
  }) as UseFieldArrayReturn<BookingFormProps, "selectedServices", "id">;
  const { append, fields } = bookingFormFieldArrayMethods;

  const fieldsValues = useWatch<BookingFormProps>({ control });
  const { venueId, staffId, selectedServices, date, time, currentFieldIndex } =
    fieldsValues;

  const daySelect = moment(date).format("dddd").toLowerCase();
  const timeSelect = venue?.venueBusinessHours
    ? Object.keys(venue?.venueBusinessHours[0])?.filter((item) =>
      item.includes(daySelect)
    )
    : [];
  let timeStartEnd = {};
  timeSelect?.forEach((item) => {
    timeStartEnd = {
      ...timeStartEnd,
      [item]: venue?.venueBusinessHours[0][item],
    };
  });

  const fetchServices = useCallback(
    (selectedStaffId?: number) => {
      dispatch(
        onLoadBookingServicesAction({
          venueId: id,
          ...(selectedStaffId !== 0 ? { staffId: selectedStaffId } : {}),
        })
      );

      return () => {
        dispatch(onClearBookingStoreAction());
      };
    },
    [dispatch, id]
  );

  const fetchStaff = useCallback(() => {
    const startDateTime = moment
      .tz(
        moment(bookingFormMethods.getValues("date")).format(
          "YYYY-MM-DDT00:00:00"
        ),
        venue?.timezone || timezoneDefault
      )
      .toISOString();
    const endDateTime = moment
      .tz(
        moment(bookingFormMethods.getValues("date")).format(
          "YYYY-MM-DDT23:59:59"
        ),
        venue?.timezone || timezoneDefault
      )
      .toISOString();

    dispatch(
      onLoadBookingStaffAction({ venueId: id, startDateTime, endDateTime })
    );
    setSelectStaff({});
  }, [dispatch, id, bookingFormMethods.getValues("date"), venue?.timezone]);

  useEffect(() => {
    fetchStaff();
    if (locationState?.staffId) {
      setValue("staffId", locationState?.staffId || 0);
    }
  }, [dispatch, fetchStaff, locationState?.staffId, setValue]);

  useEffect(() => {
    fetchServices(staffId);
  }, [fetchServices, staffId]);

  useEffect(() => {
    if (timeStartEnd[timeSelect[0]] == null) return;
    if (fields.length === 0) return;
    selectedServices.forEach((_, index) => {
      setValue(`selectedServices.${index}.selected.date`, date);
    });
  }, [date]);

  useEffect(() => {
    if (timeStartEnd[timeSelect[0]] == null) return;
    if (!currentFieldIndex) return;
    setValue(`selectedServices.${+currentFieldIndex}.selected.time`, time);
  }, [time]);

  useEffect(() => {
    if (!currentFieldIndex || selectedServices.length === 0) return;
    setValue("time", selectedServices[+currentFieldIndex].selected.time);
  }, [currentFieldIndex]);

  useEffect(() => {
    setValue("currentFieldIndex", fields.length === 0 ? undefined : "0");
  }, [fields]);

  useEffect(() => {
    if (!locationState) return;
    if (!isFirstAppendDone) return;
    if (fetchedServices.length === 0) return;

    if (locationState?.selectedServices) {
      const selectedBServices = locationState.selectedServices;
      setValue("date", selectedBServices[0]?.selected.date);
      setValue("time", selectedBServices[0]?.selected.time);
      setValue("staffId", selectedBServices[0]?.selected.staffId);

      append(
        selectedBServices.map((item) => ({
          ...item,
          id: item.selected.serviceId,
        }))
      );
    }

    if (locationState?.service) {
      if (locationState?.service?.type === "service") {
        const selectedService = fetchedServices.find(
          (service) => service.id === locationState.service.serviceId
        );
        if (!selectedService) return
        const pricingOpt = selectedService.pricingOptions.find(
          (item) => item.id === locationState.service.pricingOptionId
        );
        const staffSelected =
          pricingOpt.staffPricingOptions?.length > 0
            ? pricingOpt.staffPricingOptions.find(s => s.price === null && s.specialPrice === null) || pricingOpt.staffPricingOptions[0]
            : selectedService.staff.find((item) => item.id !== 0);

        const sStaffId = staffSelected?.staffId || staffSelected?.id || 0;
        const selectedValues = {
          selected: {
            serviceId: locationState.service.serviceId,
            staffId: sStaffId,
            pricingOptionId: locationState.service.pricingOptionId,
            ...defaultDateTime,
            type: "service",
          },
          type: "service",
        } as Pick<SelectedServiceProps, "selected">;

        setValue("date", defaultDateTime.date);
        append({
          ...selectedService,
          ...selectedValues,
        });
      } else {
        const selectedPackage = fetchedPackages.find(
          (p) => p.packageId === locationState.service.serviceId
        );
        if (selectedPackage) {
          const staffs =
            selectedPackage.staff.filter(item => !!item.staffId) ||
            [];
          const staffSelected = staffs[Math.floor(Math.random() * staffs.length)];
          const sStaffId = staffSelected?.staffId || 0;
          const selectedValues = {
            selected: {
              serviceId: locationState.service.serviceId,
              staffId: sStaffId,
              ...defaultDateTime,
              type: "package",
            },
          } as Pick<SelectedServiceProps, "selected">;

          setValue("date", defaultDateTime.date);
          append({
            ...selectedPackage,
            ...selectedValues,
          });
        }
      }
    }

    if (locationState?.dateTime) {
      setValue("date", defaultDateTime.date);
      setValue("time", defaultDateTime.time);
    }

    if (!locationState?.dateTime && !locationState?.service) {
      setValue("date", defaultDateTime.date);
    }

    setIsFirstAppendDone(false);
  }, [append, fetchedServices, isFirstAppendDone, locationState]);

  useEffect(() => {
    !venue.id && dispatch(onLoadVenueAction(id));
  }, []);

  useEffect(() => {
    // wait for the venue and fetched services to be loaded
    if (state?.rebookServices && venue?.id && fetchedServices?.length) {
      const newFormData: BookingFormProps = {
        currentFieldIndex: "0",
        date: state.rebookServices[0].date,
        selectedServices: state.rebookServices
          .map((item) => {
            const selectedService = item.type === 'service' ? fetchedServices.find(
              (service) => service.id === item.serviceId
            ) : fetchedPackages.find(
              (service) => service.packageId === item.serviceId
            );
            return selectedService ? { ...selectedService, selected: item } : null;
          })
          .filter(Boolean),
        staffId: state.rebookServices[0].staffId,
        time: state.rebookServices[0].time,
        venueId: venue.id,
      };
      reset(newFormData);
    }
  }, [state?.rebookServices, venue, fetchedServices]);

  return {
    staff,
    bookingFormMethods,
    bookingFormFieldArrayMethods,
    venueId,
    selectedServices,
    countBookingPrice,
    date,
    timeSelect,
    timeStartEnd,
    selectStaff,
    setSelectStaff,
  };
};
