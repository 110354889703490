import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';

import { VenueCard } from '~/Components/VenueCard';
import { useAppSelector } from '~/reduxConfig';
import { searchSelector } from '~/store/selectors';
import type { VenueCardResultsProps } from '~/Containers/SearchResultsPage/interfaces';
import type { SearchReducerStateProps } from '~/store/reducers/interfaces/searchInterfaces';

import styles from './VenueCardResultsStyle.module.scss';

export const VenueCardResults: React.FC<VenueCardResultsProps> = ({
  horizontal,
}) => {
  const { searchResult, isRequesting } =
    useAppSelector<SearchReducerStateProps>(searchSelector);

  return (
    <div
      className={
        horizontal
          ? `${styles.container} ${styles.horizontal}`
          : `${styles.container}`
      }
    >
      {isRequesting ? (
        <Box className={styles.loading}>
          <CircularProgress />
        </Box>
      ) : searchResult.length > 0 ? (
        searchResult.map((venue) => <VenueCard key={venue.id} venue={venue} />)
      ) : (
        <h1 className={styles.mt16}>No venues found</h1>
      )}
    </div>
  );
};
