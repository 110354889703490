import * as yup from "yup";
import { defaultPasswordMsg } from "../../constants";
import isValidABN from "is-valid-abn";

export const validationModules = {
  simplePsw: yup
    .string()
    .required("Required Field")
    .matches(RegExp("^[^ЁёА-я]+$"), "Don't use Cyrillic"),
  hardPsw: yup
    .string()
    .required(defaultPasswordMsg[0])
    .min(8, defaultPasswordMsg[1])
    .matches(RegExp("^[^ЁёА-я]+$"), defaultPasswordMsg[5])
    .matches(RegExp("^(?=.*[a-z])(?=.*[A-Z]).*$"), defaultPasswordMsg[2])
    .matches(
      RegExp('^(?=.*\\d)(?=.*[!_@#$%^&*(),.?":{}|<>]).*$'),
      defaultPasswordMsg[3]
    )
    .matches(RegExp("^\\S+$"), defaultPasswordMsg[4]),
  passwordConfirmation: yup
    .string()
    .required(defaultPasswordMsg[0])
    .oneOf([yup.ref("password"), null], "Passwords must match"),
  email: yup
    .string("Must be a string")
    .required("Required Field")
    .email("Enter a valid email")
    .matches(RegExp("^[^ЁёА-я]+$"), "Don't use Cyrillic")
    .min(4, "Must be longer than or equal to 4 characters"),
  firstAndLastName: yup
    .string("Must be a string")
    .required("Required Field")
    .matches(RegExp("^[^ЁёА-я]+$"), "Don't use Cyrillic")
    /* .min(4, "Must be longer than or equal to 4 characters") */
    .max(20, "Must be shorter than or equal to 20 characters"),
  defaultStringRule: yup
    .string("Must be a string")
    .required("Required Field")
    .matches(RegExp("^[^ЁёА-я]+$"), "Don't use Cyrillic")
    .min(2, "Must be longer than or equal to 2 characters")
    .max(20, "Must be shorter than or equal to 20 characters"),
  defaultNonRequiredStringRule: yup
    .string("Must be a string"),
  defaultNumberRule: yup
    .number("Make a choice")
    .required("Required Field")
    .typeError("Make a choice"),
  dayRule: yup
    .string("Make a choice")
    .required("Required Field")
    .matches(RegExp("^(0?[1-9]|[12][0-9]|3[01])$"), "01 - 31 or 1 - 31"),
  monthRule: yup
    .string("Make a choice")
    .required("Required Field")
    .matches(RegExp("^(0?[1-9]|1[0-2])$"), "01 - 12 or 1 - 12"),
  yearRule: yup
    .number("Make a choice")
    .required("Required Field")
    .min(new Date().getFullYear() - 100, `${new Date().getFullYear() - 100} - ${new Date().getFullYear() - 1}`)
    .max(new Date().getFullYear() - 1, `${new Date().getFullYear() - 100} - ${new Date().getFullYear() - 1}`)
    .typeError("Make a choice"),
  defaultConfirmationRule: yup
    .number()
    .required(),
  abn: yup
    .string("Must be a string")
    .max(20, "Must be shorter than or equal to 20 characters")
    .test("is valid abn", "Unknown ABN", (abn) =>
      abn ? /^[\d]+$/ig.test(abn) && isValidABN(abn) : true
    ),
  privacyPolicy: yup
    .boolean()
    .oneOf([true], "The terms and conditions must be accepted."),
};
