import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";
import { ClickAwayListener } from "@material-ui/core";
import { useDispatch } from "react-redux";

import { BookingHeadingBox } from "~/Containers/MyBookingsPage/components/BookingHeadingBox";
import { MyBookingDialog } from "~/Containers/MyBookingsPage/components/MyBookingDialog";
import { MyBookingItemHook } from "~/Containers/MyBookingsPage/components/MyBookingItem/MyBookingItemHook";
import type { MyBookingItemProps } from "~/Containers/MyBookingsPage/interfaces";
import { formatPrice } from "~/utils/helper";
import { ChangeCardDialog } from "../MyBookingDialog/ChangeCardDialog";

import styles from "./MyBookingItemStyle.module.scss";
import history from "~/utils/history";
import { viewedDateFormat } from "~/constants";
import { stripeApi } from "~/services/api/stripeApi";
import { onLoadMyBookingsAction } from "~/store/actions/myBookingsActions";

export const CARD_VALID_STATUSES = ["valid", "insufficient_funds"];

export const MyBookingItem: React.FC<MyBookingItemProps> = (props) => {
  const {
    id,
    venueId,
    showModal,
    setShowModal,
    logo,
    locationName,
    displayedAddress,
    bookingServicesCount,
    status,
    type,
    displayedDate,
    countTotal,
    isUpcoming,
    bookingStartDate,
    paymentMethod,
  } = MyBookingItemHook(props);
  const dispatch = useDispatch();
  const [showInvalidCard, setShowInvalidCard] = useState(false);
  const [showChangeCardModal, setShowChangeCardModal] = useState(false);
  const [changingCard, setChangingCard] = useState(false);
  const [isOpened, setIsOpened] = useState(false);
  const query = useMemo(() => new URLSearchParams(history.location.search), []);

  const handleClick = () => {
    setShowModal(true);
  };

  const onToggleInvalidCard = (show) => () => {
    setShowInvalidCard(show);
  };

  const onChangeCard = (e) => {
    e.stopPropagation();
    setShowChangeCardModal(true);
  };

  const onCloseChangeCardModal = () => {
    setShowChangeCardModal(false);
  };

  const submitChangeCard = async (cardId) => {
    setChangingCard(true);
    try {
      await stripeApi.changeCardId(props.booking.id, cardId);
      dispatch(onLoadMyBookingsAction());
      setShowChangeCardModal(false);
    } catch (error) {
      console.log("submitChangeCard error", error);
    }
    setChangingCard(false);
  };

  useEffect(() => {
    const bookingId = +query.get("booking_id");

    if (props.booking.id === bookingId && !isOpened) {
      setShowModal(true)
      setIsOpened(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.booking, query]);

  if (!bookingStartDate) {
    return null;
  }

  const invalidCard = paymentMethod && !CARD_VALID_STATUSES.includes(paymentMethod.status);

  return (
    <div className={styles.box} style={{zIndex: showInvalidCard ? 3 : 2}}>
      <div className={styles.booking_box} onClick={handleClick}>
        <BookingHeadingBox
          id={id}
          logo={logo}
          heading={locationName}
          address={displayedAddress}
          status={status}
          type={type}
        />

        <div className={styles.service_box}>
          <span>
            {bookingServicesCount} service{bookingServicesCount > 1 ? "s" : ""}
          </span>
          <time>{displayedDate}</time>
        </div>
        <div className={styles.total_box}>
          Total
          <div className={styles.rightSide}>
            {isUpcoming && invalidCard && (
              <div onMouseEnter={onToggleInvalidCard(true)}>
                <div className={styles.invalid}>Invalid card</div>
                {showInvalidCard && (
                  <ClickAwayListener onClickAway={onToggleInvalidCard(false)}>
                    <div className={styles.warningWrap}>
                      <div className={styles.triangle}></div>
                      <p>
                        Card number ending in{" "}
                        <span className={styles.bold}>**** {paymentMethod.card.last4}</span>{" "}
                        used for this booking is no longer valid.
                      </p>
                      <button onClick={onChangeCard}>Change card</button>
                    </div>
                  </ClickAwayListener>
                )}
              </div>
            )}
            <span className={styles.cost}>${formatPrice(countTotal)}</span>
          </div>
        </div>
      </div>

      {isUpcoming && invalidCard && (
        <ChangeCardDialog
          showModal={showChangeCardModal}
          onCloseModal={onCloseChangeCardModal}
          onChangeCard={submitChangeCard}
          cardId={paymentMethod.id}
          isRequesting={changingCard}
          title={moment(bookingStartDate).format(viewedDateFormat)}
        />
      )}

      <MyBookingDialog
        showModal={showModal}
        setShowModal={setShowModal}
        displayedDate={displayedDate}
        venueId={venueId}
        bookingId={id}
        status={status}
        isPastBooking={props.isPastBooking}
      />
    </div>
  );
};
