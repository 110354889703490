import { Box } from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";
import {
  defaultPasswordMsg,
  pswMsgValidationType,
  defaultValidationType,
} from "~/constants";
import "./message.scss";

export const Message = ({ msg, type = defaultValidationType }) => {
  if (msg && type === pswMsgValidationType) {
    const arrayMsg = Object.values(msg).flat();
    let currentClasses, currentIcon;

    return (
      <Box>
        {defaultPasswordMsg.map((item, key) => {
          if(!!arrayMsg.length && arrayMsg[0] === defaultPasswordMsg[0]) {
            currentClasses = "alert-message-color small-msg"
            currentIcon = <ClearIcon fontSize="small" className="alert-message-color" />
          } else {
            const isConditionNotDone = arrayMsg.includes(item);
            currentClasses = isConditionNotDone ? "alert-message-color" : "success-message-color";
            currentIcon = isConditionNotDone ? (
              <ClearIcon fontSize="small" className="alert-message-color" />
            ) : (
              <DoneIcon fontSize="small" className="success-message-color" />
            );
          }

          return (
            <Box className="msg-wrapper" key={item.concat(key)}>
              {currentIcon}
              <Box
                component = "p"
                className = {`small-msg ${currentClasses}`}
                key = {item.concat(key)}
              >
                {item}
              </Box>
            </Box>
          );
        })}
      </Box>
    );
  }
  return (
    <Box
      textAlign="center"
      className="alert-message alert-message-color"
      component="p"
    >
      {msg}
    </Box>
  );
};
