import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  menuMobile: {},
  menuUnAuth: {
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  list: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  fullList: {
    width: 'auto',
  },
  MuiDrawer: {
    '&.MuiDrawer-root .MuiDrawer-paper.MuiDrawer-paperAnchorLeft': {
      width: '100%',
      height: 'calc(100% - 65px)',
      top: 65,
      overflowY: 'auto',
    },
    '& .MuiBackdrop-root': {
      background: 'none !important',
    }
  },
  iconSearch: {
    marginRight: theme.spacing(2),
  },
  iconScroll: {
    '&>svg': {
      color: '#651fc1 !important',
    },
    '&>div>button>span': {
      color: '#651fc1 !important',
    },
    '&>div>button>span>svg': {
      fill: '#651fc1 !important',
    },
  },
  iconUnScroll: {
    '&>svg': {
      color: '#ffff',
    },
    '&>div>button>span': {
      color: '#ffff',
    },
    '&>div>button>span>svg': {
      fill: '#ffff !important',
    },
  },
  iconMenu: {
    display: 'flex',
    alignItems: 'center',
  },
  iconDrop: {},
  iconClose: {},
  iconCloseScroll: {
    color: '#651fc1',
  },
  listItem: {
    '&:after': {
      bottom: 0,
      content: '""',
      position: 'absolute',
      right: 0,
      height: 1,
      background: 'rgb(231, 232, 233)',
      width: '100%',
      left: 20,
    },

    padding: '16px 20px',
    '&>div>span': {
      fontSize: '17px !important',
      fontWeight: 700,
      lineHeight: '24px',
      color: '#651fc1',
    },
  },
  divide: {
    background: 'rgb(242, 242, 247)',
    height: 24,
    border: 'none',
    width: '100%',
  },
  signIn: {
    position: 'relative',
    '&:after': {
      bottom: 0,
      content: '""',
      position: 'absolute',
      right: 0,
      height: 1,
      background: 'rgb(231, 232, 233)',
      width: '100%',
      left: 20,
    },
  },

  signUp: {
    padding: '16px 20px',
    display: 'flex',
    alignItems: 'center',
    '&>div>span': {
      fontSize: '17px !important',
      fontWeight: 700,
      lineHeight: '24px',
      color: '#651fc1',
    },
  },
  searchIcon: {
    minWidth: 40,
  },
}));

export { useStyles };
