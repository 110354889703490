import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root : {
    '& .MuiDialog-container': {
      '& .MuiPaper-root' : {
        '& .MuiDialogTitle-root' : {
          backgroundColor: 'red'
        }
      }
    }
  },
  dialogTitle : {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    padding: theme.spacing(3,0),
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '22px'
  },
  dialogTitleIcon : {
    position: 'absolute',
    left: 16,
    '& >svg' : {
      width: 24,
    }
  },
  dialogContent: {
    borderTopColor: '#FAFAFA !important'
  }
}));

export { useStyles };
