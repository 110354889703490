type GeocoderResult = google.maps.GeocoderResult;

export const geocodeExtractor = {
  getCountry: (geocode: GeocoderResult[]) => geocode[0].address_components.find(element =>
    element.types.includes("country") && element.types.includes("political")
  ).long_name,
  getCity: (geocode: GeocoderResult[]) => geocode[0].address_components.find(element =>
    element.types.includes("locality") && element.types.includes("political")
  )?.long_name,
  getStreet: (geocode: GeocoderResult[]) => geocode[0].address_components.find(element =>
    element.types.includes("route"))?.long_name,
  getStreetNumber: (geocode: GeocoderResult[]) => geocode[0].address_components.find(element =>
    element.types.includes("street_number"))?.long_name,
  getZipCode: (geocode: GeocoderResult[]) => geocode[0].address_components.find(element =>
    element.types.includes("postal_code")
  )?.long_name,
  getLatLng: (geocode: GeocoderResult[]) => ({
    lat: geocode[0].geometry.location.lat(),
    lng: geocode[0].geometry.location.lng()
  }),
  getPlaceId: (geocode: GeocoderResult[]) => (geocode[0].place_id)
};
