import DialogMain from "~/Components/DialogMain/DialogMain";
import { ChangePhoneForm } from "~/Components/ChangePhoneForm";
import { Confirmation } from "~/Components/Confirmation";
import { ForgotPassword } from "~/Components/ForgotPassword";
import { NewPasswordSet } from "~/Components/NewPasswordSet";
import { ChangeEmailForm } from "~/Components/ChangeEmailForm";
import { ChangePasswordForm } from "~/Components/ChangePasswordForm";

export const DialogContent = ({
  step,
  steps,
  control,
  message,
  showModal,
  setStep,
  onSubmit,
  hideMessage,
  setShowModal,
  clearErrors,
  setFocus,
  register,
  setValue,
  getValues,
  errors,
  onSendAgain,
  onSendAgainForReset,
}) => {
  return (
    <>
      {step === steps.changePhoneNumber && (
        <DialogMain
          showModal={showModal}
          setShowModal={setShowModal}
          onSubmit={onSubmit}
          titleDialog="Change phone number"
          submitButtonText="Continue"
          dialogWindowClass={"modal-dialog-medium"}
        >
          <ChangePhoneForm
            control={control}
            hideMessage={hideMessage}
            message={message}
          />
        </DialogMain>
      )}

      {step === steps.changePhoneVerify && (
        <DialogMain
          showModal={showModal}
          setShowModal={setShowModal}
          onSubmit={onSubmit}
          titleDialog="Reset phone"
          submitButtonText="Reset"
          dialogWindowClass={"modal-dialog-medium"}
        >
          <Confirmation
            clearErrors={clearErrors}
            setFocus={setFocus}
            register={register}
            setValue={setValue}
            getValues={getValues}
            errors={errors}
            hideMessage={hideMessage}
            message={message}
            onSendAgain={onSendAgain}
            value="phone"
          />
        </DialogMain>
      )}

      {step === steps.changeEmail && (
        <DialogMain
          showModal={showModal}
          setShowModal={setShowModal}
          onSubmit={onSubmit}
          titleDialog="Change email"
          submitButtonText="Continue"
          dialogWindowClass={"modal-dialog-medium"}
        >
          <ChangeEmailForm
            control={control}
            hideMessage={hideMessage}
            message={message}
          />
        </DialogMain>
      )}

      {step === steps.changeEmailVerify && (
        <DialogMain
          showModal={showModal}
          setShowModal={setShowModal}
          onSubmit={onSubmit}
          titleDialog="Reset email"
          submitButtonText="Reset"
          dialogWindowClass={"modal-dialog-medium"}
        >
          <Confirmation
            clearErrors={clearErrors}
            setFocus={setFocus}
            register={register}
            setValue={setValue}
            getValues={getValues}
            errors={errors}
            hideMessage={hideMessage}
            message={message}
            onSendAgain={onSendAgain}
            value="email"
          />
        </DialogMain>
      )}

      {step === steps.changePassword && (
        <DialogMain
          showModal={showModal}
          setShowModal={setShowModal}
          onSubmit={onSubmit}
          titleDialog="Change password"
          submitButtonText="Continue"
          dialogWindowClass={"modal-dialog-medium"}
        >
          <ChangePasswordForm
            control={control}
            hideMessage={hideMessage}
            message={message}
            setStep={() => setStep(steps.forgotPassword)}
          />
        </DialogMain>
      )}

      {step === steps.forgotPassword && (
        <DialogMain
          showModal={showModal}
          setShowModal={setShowModal}
          onSubmit={onSubmit}
          titleDialog="Forgot password"
          submitButtonText="Reset password"
          dialogWindowClass={"modal-dialog-medium"}
        >
          <ForgotPassword
            control={control}
            hideMessage={hideMessage}
            message={message}
          />
        </DialogMain>
      )}

      {step === steps.forgotPasswordCode && (
        <DialogMain
          showModal={showModal}
          setShowModal={setShowModal}
          setPrevStep={() => setStep(steps.forgotPassword)}
          onSubmit={onSubmit}
          titleDialog="Reset password"
          submitButtonText="Reset"
          dialogWindowClass={"modal-dialog-medium"}
        >
          <Confirmation
            clearErrors={clearErrors}
            setFocus={setFocus}
            register={register}
            setValue={setValue}
            getValues={getValues}
            errors={errors}
            hideMessage={hideMessage}
            message={message}
            onSendAgain={onSendAgainForReset}
            value="emailReset"
          />
        </DialogMain>
      )}

      {step === steps.forgotPasswordNewPassword && (
        <DialogMain
          showModal={showModal}
          setShowModal={setShowModal}
          onSubmit={onSubmit}
          titleDialog="Enter new password"
          submitButtonText="Reset password"
          dialogWindowClass={"modal-dialog-medium"}
        >
          <NewPasswordSet
            control={control}
            hideMessage={hideMessage}
            message={message}
          />
        </DialogMain>
      )}
    </>
  );
};
