import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button } from "@material-ui/core";

import styles from "./ChangeCardDialogStyle.module.scss";
import { SaveNewCard } from "~/Containers/PaymentFlowPage/components/PaymentMethods/StripePayement/SaveNewCard";
import DialogMain from "~/Components/DialogMain/DialogMain";
import { useAppSelector } from "~/reduxConfig";
import { customerProfileSelector, sessionSelector } from "~/store/selectors";
import { Cards } from "~/Containers/PaymentFlowPage/components/Cards";
import {
  onDeleteCreditCardAction,
  onGetCreditCardsAction,
} from "~/store/actions/creditCardsAction";
import { EditCard } from "~/Containers/UserProfilePage/components/PaymentMethods/EditCard";
import { stripeApi } from "~/services/api/stripeApi";

interface Props {
  showModal: boolean;
  onCloseModal: () => void;
  onChangeCard: (id: string) => void;
  cardId: string;
  title: string;
  isRequesting?: boolean;
}

export const ChangeCardDialog: React.FC<Props> = (props) => {
  const { showModal, onCloseModal, onChangeCard, cardId, title, isRequesting } = props;
  const { cards } = useAppSelector(customerProfileSelector);
  const customer = useAppSelector(sessionSelector);
  const [selectedCard, setSelectedCard] = useState(null);
  const [cardById, setCardById] = useState(null);
  const [showAddCardModal, setShowAddCardModal] = useState(false);
  const [editCardId, setEditCardId] = useState(null);
  const [cardValues, setCardValues] = useState({
    cardName: "",
    isDefault: true,
  });
  const dispatch = useDispatch();

  const onSubmitChangeCard = () => {
    if (selectedCard?.id !== cardId) {
      onChangeCard(selectedCard.id);
    }
  };

  const handleChangeCard = (id) => {
    setSelectedCard(cards.find((card) => card.id === id));
  };

  const onClickAddCard = () => {
    setShowAddCardModal(true);
  };

  const onCloseAddCardModal = () => {
    setShowAddCardModal(false);
  };

  const onSubmitNewCard = () => {
    document.getElementById("saveNewCardPaymentBtn")?.click();
  };

  const onEditCardClick = (id) => {
    const card = cards.find((item) => item.id === id);

    setCardValues({
      cardName: card.billing_details.name,
      isDefault: true,
    });
    setEditCardId(id);
  };

  const onUpdateCard = (newValues) => {
    setCardValues(newValues);
  };

  const onSubmitEditCard = async () => {
    const { cardName, isDefault } = cardValues;

    if (isDefault) {
      const card = cards.find((item) => item.id === editCardId);
      card && setSelectedCard(card);
    }
  
    try {
      await stripeApi.updatePaymentMethod({
        cardId: editCardId,
        stripeCustomerId: customer.stripeCustomerId,
        creditCardDto: {
          cardName,
          isDefault,
        },
      });
      dispatch(onGetCreditCardsAction());
    } catch (error) {
      console.log(error);
    }
    setEditCardId(null);
  };

  const resetEditCard = () => {
    setEditCardId(null);
    setCardValues({
      cardName: "",
      isDefault: true,
    });
  };

  const onRemoveCard = () => {
    if (editCardId === selectedCard?.id) {
      setSelectedCard(null);
    }
    dispatch(onDeleteCreditCardAction(editCardId, resetEditCard));
  };

  useEffect(() => {
    dispatch(onGetCreditCardsAction());
  }, [dispatch]);

  useEffect(() => {
    if (cards.length > 0 && !selectedCard) {
      const currentCard = cards.find((item) => item.id === cardId);
      if (currentCard) {
        setSelectedCard(currentCard);
        setCardById(currentCard);
      }
    }
  }, [cardId, cards, selectedCard, showModal]);

  return (
    <>
      <DialogMain
        showModal={showAddCardModal ? false : showModal}
        setShowModal={onCloseModal}
        titleDialog={title}
        dialogWindowClass="modal-dialog-service"
        onSubmit={onSubmitChangeCard}
        submitButtonText={isRequesting ? "Updating card" : "Save payment method"}
        footerBtnAlignmentClass={`btn-big ${
          (!selectedCard || selectedCard.id === cardId || isRequesting) ? "btn-big--disable" : ""
        }`}
      >
        <>
          {cardById && (
            <>
              <Cards
                {...cardById}
                cardId={selectedCard?.id}
                setCardId={handleChangeCard}
                onEditCard={onEditCardClick}
              />
              <div className={styles.divider} />
              <p className={styles.otherCardText}>Other cards</p>
            </>
          )}
          {cards.filter(card => card.id !== cardId).map((card) => (
            <Cards
              key={card.id}
              {...card}
              cardId={selectedCard?.id}
              setCardId={handleChangeCard}
              onEditCard={onEditCardClick}
            />
          ))}

          <Button
            variant="outlined"
            color="primary"
            onClick={onClickAddCard}
            className="white-btn"
          >
            Add card
          </Button>
        </>
      </DialogMain>
      {showAddCardModal && (
        <DialogMain
          showModal={showAddCardModal}
          setShowModal={setShowAddCardModal}
          onSubmit={onSubmitNewCard}
          openDialogButtonClass="white-btn"
          titleDialog="Add card"
          submitButtonText="Save card"
          dialogWindowClass={"modal-dialog-medium"}
        >
          <SaveNewCard onClose={onCloseAddCardModal} />
        </DialogMain>
      )}
      {editCardId && (
        <DialogMain
          showModal={!!editCardId}
          setShowModal={resetEditCard}
          onSubmit={onSubmitEditCard}
          titleDialog="Change card"
          submitButtonText="Save changes"
          dialogWindowClass={"modal-dialog-medium"}
          secondButtonClass="btn-text"
          submitSecondButtonText="Delete card"
          onSubmitSecond={onRemoveCard}
        >
          <EditCard values={cardValues} onChange={onUpdateCard} />
        </DialogMain>
      )}
    </>
  );
};
