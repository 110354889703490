import React, { useState, useEffect } from "react";
import { Collapse, Typography } from '@material-ui/core';

import styles from "./VenueDetailsStyle.module.scss";
import { useAppSelector } from "~/reduxConfig";
import { VenueReducerState } from "~/store/reducers/interfaces/venueInterfaces";
import { venueSelector } from "~/store/selectors";

export const VenueDetails: React.FC = () => {
  const { venue } = useAppSelector<VenueReducerState>(venueSelector);
  const { locationName, description, tags } = venue;
  const [showMore, setShowMore] = useState(false);
  const [text, setText] = useState("");
  const [enableShowMore, setEnableShowMore] = useState(
    description?.split(' ').length > 24
  );

  const toggleShowMore = () => setShowMore(!showMore);

  useEffect(() => {
    if (enableShowMore) {
      const desArr = description?.split(' ');
      const result = showMore
        ? description
        : desArr.slice(0, 24).join(' ') + '...';
      setText(result);
    }
  }, [description, enableShowMore, showMore]);

  useEffect(() => {
    if (description?.split(' ').length < 24) {
      setText(description)
    } else {
      setEnableShowMore(description?.split(' ').length > 24);
    }
  }, [description]);

  if (!tags) return null;
  return (
    <div className={styles.container}>
      <h1>{`About ${locationName}`}</h1>
      <div className={styles.description}>
        {text && (
          <Collapse in={showMore} collapsedSize={enableShowMore ? 48 : 24}>
            <Typography className={styles.title} variant='body1' color='textSecondary'>
              {text}
            </Typography>
          </Collapse>
        )}

        {
          description?.split(' ').length > 24 &&
          <div onClick={toggleShowMore} className={styles.btn_show_description}>
            {showMore ? "Show Less" : "Show More"}
          </div>
        }
      </div>
      <div className={styles.tags}>
        {tags.map(item => <span className="status tag" key={item.id}>{item.tagName}</span>)}
      </div>
    </div>
  );
};

