import { Controller } from "react-hook-form";
import { useSelector } from "react-redux";

import { Pricing } from "~/Components/PricePicker/components/Pricing";
import type { PricePickerProps } from "~/Components/PricePicker/interfaces";
import { bookingSelector } from "~/store/selectors";

import styles from "./PricePickerStyle.module.scss";

export const PricePicker: React.FC<PricePickerProps> = (props) => {
  const {
    formMethods: { control, setValue, getValues },
    fieldName,
    pricingOptions,
    onServiceOptions,
    staff = [],
    selectedService,
    onChangePricingOption,
    serviceSelected,
  } = props;
  const { staff: staffBooking } = useSelector(bookingSelector);

  return (
    <Controller
      control={control}
      name={fieldName}
      defaultValue={selectedService?.id || pricingOptions[0]?.id}
      render={({ field: { onChange, value } }) => {
        return (
          <div className={styles.radio_section}>
            {pricingOptions.map((pricing) => (
              <div className={styles.radio_section} key={pricing.id}>
                <div className={styles.form_radio_btn} key={pricing.id}>
                  <Pricing
                    pricing={pricing}
                    onChange={() => {
                      onChangePricingOption && onChangePricingOption(pricing.id);
                      onChange(pricing.id);
                      setValue("staffPricingOptionId", null);
                      setValue("staffId", null);
                    }}
                    value={!getValues()?.staffPricingOptionId && !serviceSelected?.selected?.staffPricingOptionId && value}
                    onServiceOptions={onServiceOptions}
                  />
                </div>
                {pricing.staffPricingOptions?.map((staffPricing) => {
                  const staffShift = staffBooking?.bookingStaffList?.find((item) => item?.id === staffPricing?.staffId)

                  if (staffPricing.price == null || staffShift?.customStaffShifts.length === 0) {
                    return <div key={staffPricing.id} />
                  }

                  const selectedStaff = staff.find(
                    (stt) => stt.id === staffPricing.staffId
                  );

                  if (!selectedStaff) return (<div key={staffPricing.id} />)

                  return (
                    <div
                      className={styles.form_radio_btn}
                      key={staffPricing.id}
                    >
                      <Pricing
                        pricing={{
                          ...staffPricing,
                          variantName: selectedStaff
                            ? `By ${selectedStaff?.firstName || ''} ${selectedStaff?.lastName || ''}`
                            : pricing.variantName,
                        }}
                        onChange={() => {
                          onChangePricingOption && onChangePricingOption(pricing.id, staffPricing.id);
                          onChange(pricing.id);
                          setValue("staffPricingOptionId", staffPricing.id);
                          setValue("staffId", staffPricing.staffId);
                        }}
                        checked={
                          getValues()?.staffPricingOptionId === staffPricing.id ||
                          serviceSelected?.selected?.staffPricingOptionId === staffPricing.id ||
                          selectedService?.id === staffPricing.id
                        }
                        onServiceOptions={onServiceOptions}
                        isStaffPricingOption
                        staffPricingOptionId={getValues()?.staffPricingOptionId || serviceSelected?.selected?.staffPricingOptionId}
                      />
                    </div>
                  );
                })}
              </div>
            ))}
          </div>
        );
      }}
    />
  );
};
