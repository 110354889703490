import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.scss";
import "slick-carousel/slick/slick-theme.scss";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import { Card, CircularProgress } from "@material-ui/core";
import { useSelector } from "react-redux";

import styles from "./ServiceSliderStyle.module.scss";
import { mainSelector } from "~/store/selectors";
import { CheckMobileHook } from '../MainHeader/CheckMobileHook';

type Coords = { x: number, y: number }

interface IProps {
  executeScroll: (id: string) => void;
}

export const ServiceSlider: React.FC<IProps> = ({ executeScroll }) => {
  const { isMobile } = CheckMobileHook();
  const { banners, isLoadingBanner } = useSelector(mainSelector);
  const history = useHistory();
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    speed: 300,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
    ]
  };

  const [downCoords, setDownCoords] = useState<Coords>();

  const goToPage = (linkPage) => () => {
    history.push(linkPage)
  }

  const goToInstagram = () => {
    const win = window.open("https://www.instagram.com/glamezyau/", "_blank");
    win.focus();
  }

  const goToScroll = (id: string) => () => {
    const newId = id.slice(1)
    if (newId) {
      executeScroll(newId)
    }
  }

  const onMouseDown = (event: React.MouseEvent<HTMLElement>) => {
    setDownCoords({ x: event.pageX, y: event.pageY });
  };

  const onMouseUp = (handler: any) => (event: React.MouseEvent<HTMLElement>) => {
    if (downCoords?.x !== event.pageX || downCoords?.y !== event.pageY) return;
    handler();
  };

  return (
    <section className={styles.slider_holder}>
      <Slider
        {...settings}
        className={`${"service-slider"} ${styles.service_slider}`}
      >
        {isLoadingBanner && Array(3).fill(0).map((_, index) => {
          return (
            <Card
              key={index}
              className={styles.wrapper_slide_box}
            >
              <div
                className={clsx(styles.slide_box, styles.slide_box_3)}
              >
                <CircularProgress className={styles.loading_icon} />
              </div>
            </Card>
          )
        })}
        {banners?.length > 0 &&
          banners?.map((item: any, index: number) => {
            return (
              <Card
                key={index}
                className={styles.wrapper_slide_box}
                onMouseDown={onMouseDown}
                onMouseUp={onMouseUp(
                  item?.linkAction === "SCROLL_TO" ?
                    goToScroll(item.link)
                    : item?.linkAction === "CHANGE_PAGE" ?
                      goToPage(item.link) : goToInstagram
                )}
              >
                <div
                  className={clsx(styles.slide_box, {
                    [styles.slide_box_2]: item.descriptionPosition === "right"
                  })}
                  style={{ backgroundImage: `url(${item.imageUrl})` }}
                >
                  <div className={styles.text}>
                    <div>
                      <p className={styles.text_title}>{item.title}</p>
                      {item.description && <p>{item.description}</p>}
                    </div>
                    <button className={"white-btn"}>{item.buttonName}</button>
                  </div>
                </div>
              </Card>
            )
          })}
        {banners?.length > 0 && !isMobile &&
          banners?.map((item: any, index: number) => {
            return (
              <Card
                key={index}
                className={styles.wrapper_slide_box}
                onMouseDown={onMouseDown}
                onMouseUp={onMouseUp(
                  item?.linkAction === "SCROLL_TO" ?
                    goToScroll(item.link)
                    : item?.linkAction === "CHANGE_PAGE" ?
                      goToPage(item.link) : goToInstagram
                )}
              >
                <div
                  className={clsx(styles.slide_box, {
                    [styles.slide_box_2]: item.descriptionPosition === "right"
                  })}
                  style={{ backgroundImage: `url(${item.imageUrl})` }}
                >
                  <div className={styles.text}>
                    <div>
                      <p className={styles.text_title}>{item.title}</p>
                      {item.description && <p>{item.description}</p>}
                    </div>
                    <button className={"white-btn"}>{item.buttonName}</button>
                  </div>
                </div>
              </Card>
            )
          })}
      </Slider>
    </section>
  );
};
