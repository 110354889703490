import { useCallback, useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { CircularProgress } from "@material-ui/core";

import StripeForm from "./StripeForm/StripeForm";
import { FormCardProps } from "~/Components/FormCard/interfaces";
import { stripeApi } from "~/services/api/stripeApi";
import { Message } from "~/Components/Message";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

export const StripePayment = (props: FormCardProps) => {
  const [clientSecret, setClientSecret] = useState("");
  const [error, setError] = useState("");
  const [errorCard, setErrorCard] = useState(null);

  const setupStripCard = useCallback(async () => {
    try {
      const { data }: any = await stripeApi.setupPayment();
      setClientSecret(data.createSetupIntent.client_secret);
    } catch (err) {
      console.log("err", err);
      setError("Connection error");
    }
  }, []);

  useEffect(() => {
    setupStripCard();
  }, [setupStripCard]);

  const options = {
    clientSecret,
    appearance: {
      theme: "stripe",
      variables: {
        colorPrimary: "#3f51b5",
        fontFamily: "Epilogue, Arial, Helvetica, sans-serif",
        borderRadius: "30px",
      },
      rules: {
        '.Input': {
          lineHeight: '17px',
          color: errorCard ? 'var(--colorDanger)' : '',
          borderColor: errorCard ? 'var(--colorDanger)' : '',
          boxShadow: errorCard ? '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02), 0 0 0 2px var(--colorDanger)' : '',
        },
        '.Label': {
          color: errorCard ? 'var(--colorDanger)' : '',
        }
      },
    },
  };

  return (
    <>
      {clientSecret ? (
        <Elements options={options as any} stripe={stripePromise}>
          <StripeForm
            {...props}
            setClientSecret={setClientSecret}
            setupStripCard={setupStripCard}
            onChangeLoadingBooking={props.onChangeLoadingBooking}
            errorCard={errorCard}
            setErrorCard={setErrorCard}
          />
        </Elements>
      ) : (
        <div style={{ display: "flex", justifyContent: "center" }}>
          {error ? (
            <Message msg={error} />
          ) : (
            <CircularProgress size={24} color="primary" />
          )}
        </div>
      )}
    </>
  );
};
