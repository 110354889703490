import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Rating from "@material-ui/lab/Rating";
import moment from 'moment'
import StarRateIcon from '@material-ui/icons/StarRate';
import { CheckMobileHook } from "~/Components/MainHeader/CheckMobileHook";
import type { VenueReviewProps } from "~/store/reducers/interfaces/venueInterfaces";

import styles from "./VenueReviewStile.module.scss";

export const VenueReview: React.FC<VenueReviewProps> = (props) => {
  const {
    author_name,
    profile_photo_url,
    rating,
    text,
    time
  } = props;
  const period = moment(`${time}`, "X").fromNow();
  const { isMobile } = CheckMobileHook();

  const viewMobile = () => (
    <div className={styles.info}>
      <Avatar
        className={styles.avatar}
        alt={author_name}
        src={profile_photo_url || author_name}
      />
      <div>
        <div className={styles.author_name}>
          <h3>{author_name}</h3>
        </div>
        <div className={styles.header_bottom}>
          <div>
            <StarRateIcon />
            <div className={styles.rating}>
              <span className={styles.current_rating}>{rating}</span>
            </div>
          </div>
          <span>{period}</span>
        </div>
      </div>
    </div>
  );

  return (
    <div className={styles.container}>
      {isMobile ? (
        viewMobile()
      ) : (
      <div className={styles.info}>
        <Avatar
          className={styles.avatar}
          alt={author_name}
          src={profile_photo_url || author_name} />
        <div className={styles.author_name}>
          <h3>{author_name}</h3>
          <span>{period}</span>
        </div>
        <div className={styles.rating}>
          <span className={styles.current_rating}>{rating}</span>
          <span>/5</span>
        </div>
        <Rating
          name="read-only"
          value={rating}
          readOnly
          precision={0.1}
          size="large" />
      </div>
      )}
      <span className={styles.text}>{text}</span>
    </div>

  );
};
