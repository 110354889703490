import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import { Box, CircularProgress } from '@material-ui/core';

import { DialogActions, DialogContent, DialogTitle } from './DialogComponents';
import type { DialogMainProps } from './interfaces';

const DialogMain: React.FC<DialogMainProps> = (props) => {
  const {
    onOpen = undefined,
    onClose = undefined,
    titleDialog,
    titleDialogClassName = '',
    showModal = false,
    setShowModal,
    submitButtonText,
    submitButtonDisabled = false,
    onSubmit,
    onSubmitSecond,
    setDefaultStep,
    dialogWindowClass,
    submitSecondButtonText,
    footerBtnAlignmentClass,
    firstButtonClass,
    secondButtonClass,
    redirectTitle,
    redirectText,
    redirectLink,
    setPrevStep,
    showDialogActions = true,
    openDialogButtonText,
    openDialogButtonClass = 'submit-btn-save-changes',
    errorReschedule = '',
    step = undefined,
    isClosed = false,
    disableSubmitButton = false,
    isLoadingSubmitButton = false,
    isLoadingSecondButton = false,
    isNotClickOutside = false,
  } = props;
  const [openModal, setOpenModal] = useState(false);

  const handleClickOpen = () => {
    setOpenModal(true);
    if (setShowModal) {
      setShowModal(true);
    }
  };

  const handleClose = () => {
    setOpenModal(false);
    if (setShowModal) {
      setShowModal(false);
    }
    if (setDefaultStep) {
      setDefaultStep();
    }
  };

  const onSubmitAndCloseModal = () => {
    onSubmit(() => {
      handleClose();
    });
  };

  const notOnClickOutside = () => {
    return
  }

  return (
    <div>
      {openDialogButtonText && (
        <Button
          disableRipple
          variant='outlined'
          color='primary'
          onClick={onOpen || handleClickOpen}
          className={openDialogButtonClass}
        >
          {openDialogButtonText}
        </Button>
      )}
      <Dialog
        classes={{
          paper: `modal-dialog modal-dialog-main ${dialogWindowClass}`,
        }}
        onClose={!isNotClickOutside ? (onClose || handleClose) : notOnClickOutside}
        aria-labelledby='customized-dialog-title'
        open={openModal || showModal}
      >
        {setPrevStep && (
          <Button onClick={setPrevStep} className='back-button-wrapper'>
            <ArrowBackIosRoundedIcon />
          </Button>
        )}

        <DialogTitle onClose={onClose || handleClose} className={titleDialogClassName}>{titleDialog}</DialogTitle>

        <DialogContent dividers>
          <div className='auto-scroll-box'>{props.children}</div>
        </DialogContent>

        {showDialogActions && (
          <DialogActions className={`${errorReschedule ? 'wrapper-bottom-modal-reschedule' : ''}`}>
            <div className={`btn-dialog-box ${footerBtnAlignmentClass}`}>
              <div className="btn-dialog-box-wrapper-btn">
                <Button
                  onClick={onSubmitAndCloseModal}
                  form='my-form-id'
                  color='primary'
                  className={
                    firstButtonClass
                      ? firstButtonClass
                      : 'submit-btn-save-changes'
                  }
                  disabled={
                    submitButtonDisabled ||
                    (errorReschedule !== '' && step === 2) ||
                    (isClosed && step === 2) ||
                    disableSubmitButton
                  }
                  endIcon={isLoadingSubmitButton && <CircularProgress className='icon-loading-btn-modal' />}
                >
                  {submitButtonText}
                </Button>

                {submitSecondButtonText && (
                  <Button
                    onClick={onSubmitSecond}
                    form='my-form-id'
                    color='primary'
                    className={
                      secondButtonClass
                        ? secondButtonClass
                        : 'submit-btn-save-changes'
                    }
                    endIcon={isLoadingSecondButton && <CircularProgress className='icon-loading-btn-second-modal' />}
                  >
                    {submitSecondButtonText}
                  </Button>
                )}
              </div>

              {errorReschedule && step === 2 && (
                <p className="text-error-reschedule">{errorReschedule}</p>
              )}
            </div>
          </DialogActions>
        )}

        {redirectTitle && redirectText && redirectLink && (
          <Box
            onClick={redirectLink}
            textAlign='center'
            component='p'
            className='card-footer'
            style={{ cursor: 'pointer' }}
          >
            {redirectText}&nbsp;
            <span style={{ color: '#5b13b7' }}>{redirectTitle}</span>
          </Box>
        )}
      </Dialog>
    </div>
  );
};

export default DialogMain;
