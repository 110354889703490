import moment from "moment-timezone";
import { useMemo } from "react";
import { useWatch } from 'react-hook-form';

import { timeAmFormat, timeFormat } from "~/constants";
import type { TimeRadioPickerProps, TimeProps } from "~/Components/TimeRadioPicker/interfaces";
import { bookingSelector } from "~/store/selectors";
import { useAppSelector } from "~/reduxConfig";
import type { BookingReducerStateProps } from "~/store/reducers/interfaces/bookingInterfaces";
import { convertStartEndTime } from './help';

export const TimeRadioPickerHook = (props) => {
  const {
    formMethods,
    fieldName,
    timeSelect,
    staffShift,
    timezone
  } = props as TimeRadioPickerProps;
  const { control, trigger } = formMethods;
  const { selectedServices, staffId } = useWatch({ control });
  const { staff } = useAppSelector<BookingReducerStateProps>(bookingSelector);
  const timeSelectedServices = selectedServices ? selectedServices[0]?.selected?.time : '';
  const bookingStaff = staff.bookingStaffList.find((item) => item.id === staffId);
  const listStaffShift = [];

  if (staffId === 0) {
    staff.bookingStaffList.forEach((item) => {
      if (item.customStaffShifts.length > 0) {
        const staffShift = {
          startDateTime: item.customStaffShifts[0].startDateTime,
          endDateTime: item.customStaffShifts[0].endDateTime,
        }
        listStaffShift.push(staffShift)
      }
    })
  }

  const hourStartDayStaffShift = staffShift?.startDateTime && moment.tz(staffShift.startDateTime, timezone).hours();
  const minStartDayStaffShift = staffShift?.startDateTime && moment.tz(staffShift.startDateTime, timezone).minutes() / 60;
  const hourEndDayStaffShift = staffShift?.endDateTime && moment.tz(staffShift.endDateTime, timezone).hours();
  const minEndDayStaffShift = staffShift?.endDateTime && moment.tz(staffShift.endDateTime, timezone).minutes() / 60;

  const hourStartSelected = +(timeSelectedServices?.split(':')[0]) || 0;
  const minStartSelected = +(timeSelectedServices?.split(':')[1]) / 60 || 0;

  const hourStartDay = hourStartDayStaffShift || 0;
  const minStartDay = minStartDayStaffShift || 0;
  const hourEndDay = hourEndDayStaffShift || 0;
  const minEndDay = minEndDayStaffShift || 0;

  const startHourSelectService = (hourStartSelected + minStartSelected)
  const startHour = (hourStartDay + minStartDay);
  const endWorkingHour = (hourEndDay + minEndDay);
  const stringSelectService = selectedServices.toString();

  const hourDuration = startHour - Math.floor(startHour)
  const hourFloor = Math.floor(startHour)
  let startWorkingHour = hourDuration === 0 ? startHour : hourFloor + 0.25;
  if (hourDuration > 0.75) {
    startWorkingHour = hourFloor + 1;
  } else if (hourDuration > 0.5) {
    startWorkingHour = hourFloor + 0.75;
  } else if (hourDuration > 0.25) {
    startWorkingHour = hourFloor + 0.5;
  }

  const workingMorningHours = useMemo(() => {
    if ((!staffShift?.startDateTime || !staffShift?.endDateTime) && staffId !== 0) return
    let workingHours = [] as TimeProps[];
    if (staffId === 0) {
      const listStaffShiftMorning = listStaffShift.reduce((arr, item) => {
        const { startWorkingHour, endWorkingHour } = convertStartEndTime(item.startDateTime, item.endDateTime, timezone, 'morning')

        if (startWorkingHour >= 12) {
          return arr
        }

        const listTimeShift = arr
        const listHourTimeShift = listTimeShift.map((item) => item.hourAm)
        for (let i = startWorkingHour; i < (endWorkingHour < 12 ? endWorkingHour : 12); i = i + 0.25) {
          const timeMoment = moment("0", "H").add(i, "hours");
          const hourAm = timeMoment.format(timeAmFormat);
          if (!listHourTimeShift.includes(hourAm)) {
            const hour = timeMoment.format(timeFormat);
            listTimeShift.push({ hour, hourAm });
            listHourTimeShift.push(hourAm)
          }

        }

        return listTimeShift
      }, [])

      const newWorkingHours = listStaffShiftMorning.sort((staffA, staffB) => {
        if (moment(`2022-01-01 ${staffA.hourAm}`) < moment(`2022-01-01 ${staffB.hourAm}`)) {
          return -1;
        }
        if (moment(`2022-01-01 ${staffA.hourAm}`) > moment(`2022-01-01 ${staffB.hourAm}`)) {
          return 1;
        }
        return 0;
      })

      workingHours = newWorkingHours;
    } else {
      if (startWorkingHour > 12) return
      for (let i = startWorkingHour; i < (endWorkingHour < 12 ? endWorkingHour : 12); i = i + 0.25) {
        const timeMoment = moment("0", "H").add(i, "hours");
        const hour = timeMoment.format(timeFormat);
        const hourAm = timeMoment.format(timeAmFormat);
        workingHours.push({ hour, hourAm });
      }
    }

    selectedServices.forEach(element => {
      const [hours, minutes] = element.selected.time.split(":")
      const data = {
        hour: element.selected.time,
        hourAm: moment(`2022-01-01 ${element.selected.time}`).format("hh:mm a")
      }
      const checkTime = workingHours.filter((item) => item.hour === data.hour).length
      if (minutes !== "00" && minutes !== "15" && minutes !== "30" && minutes !== "45" && checkTime === 0) {
        if (+hours < 12) {
          const index = (workingHours.findIndex(item => moment(`2022-01-01 ${item.hour}`) > moment(`2022-01-01 ${data.hour}`)))
          if (index === -1) {
            workingHours.push(data)
          } else {
            workingHours.splice(index, 0, data)
          }
        }
      }
    });

    if (bookingStaff?.breakTimes?.length > 0 && workingHours?.length > 0) {
      const listBreakTimeMorning = bookingStaff?.breakTimes.reduce((arr, item) => {
        const { startWorkingHour, endWorkingHour } = convertStartEndTime(item.startDateTime, item.endDateTime, timezone, 'morning')

        if (startWorkingHour >= 12) {
          return arr
        }
        const listBreakTime = []
        for (let i = startWorkingHour; i < (endWorkingHour < 12 ? endWorkingHour : 12); i = i + 0.25) {
          const timeMoment = moment("0", "H").add(i, "hours");
          const hourAm = timeMoment.format(timeAmFormat);
          listBreakTime.push(hourAm);
        }

        return [...arr, ...listBreakTime]
      }, [])
      if (listBreakTimeMorning?.length > 0) {
        const newData = workingHours.filter((item) => {
          return !listBreakTimeMorning.includes(item.hourAm)
        })
        workingHours = newData
      }
    }

    if (bookingStaff?.blockedTimes?.length > 0 && workingHours?.length > 0) {
      const listBlockedTimeMorning = bookingStaff?.blockedTimes.reduce((arr, item) => {
        const { startWorkingHour, endWorkingHour } = convertStartEndTime(item.startDateTime, item.endDateTime, timezone, 'morning')

        if (startWorkingHour >= 12) {
          return arr
        }
        const listBreakTime = []
        for (let i = startWorkingHour; i < (endWorkingHour < 12 ? endWorkingHour : 12); i = i + 0.25) {
          const timeMoment = moment("0", "H").add(i, "hours");
          const hourAm = timeMoment.format(timeAmFormat);
          listBreakTime.push(hourAm);
        }

        return [...arr, ...listBreakTime]
      }, [])

      if (listBlockedTimeMorning?.length > 0) {
        const newData = workingHours.filter((item) => {
          return !listBlockedTimeMorning.includes(item.hourAm)
        })
        workingHours = newData
      }
    }

    if (bookingStaff?.bookingTimes?.length > 0 && workingHours?.length > 0) {
      const listBookingTimeMorning = bookingStaff?.bookingTimes.reduce((arr, item) => {
        const itemEndDateTime = moment.tz(item.endDateTime, timezone).add(item.extraTime).toISOString()
        const { startWorkingHour, endWorkingHour } = convertStartEndTime(item.startDateTime, itemEndDateTime, timezone, 'morning')

        if (startWorkingHour >= 12) {
          return arr
        }
        const listBookingTime = []
        for (let i = startWorkingHour; i < (endWorkingHour < 12 ? endWorkingHour : 12); i = i + 0.25) {
          const timeMoment = moment("0", "H").add(i, "hours");
          const hourAm = timeMoment.format(timeAmFormat);
          if (!listBookingTime.includes(hourAm)) {
            listBookingTime.push(hourAm);
          }
        }

        return [...arr, ...listBookingTime]
      }, [])

      if (listBookingTimeMorning?.length > 0) {
        const newData = workingHours.filter((item) => {
          return !listBookingTimeMorning.includes(item.hourAm)
        })
        workingHours = newData
      }
    }

    return workingHours;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeSelect, stringSelectService, staffId, startWorkingHour, endWorkingHour]);

  const workingAfternoonHours = useMemo(() => {
    if ((!staffShift?.startDateTime || !staffShift?.endDateTime) && staffId !== 0) return
    let workingHours = [] as TimeProps[];
    if (staffId === 0) {
      const listStaffShiftAfternoon = listStaffShift.reduce((arr, item) => {
        const { startWorkingHour, endWorkingHour } = convertStartEndTime(item.startDateTime, item.endDateTime, timezone, 'afternoon')

        if (endWorkingHour < 12 || startWorkingHour > 18) {
          return arr
        }

        const listTimeShift = arr
        const listHourTimeShift = listTimeShift.map((item) => item.hourAm)
        for (let i = startWorkingHour > 12 ? startWorkingHour : 12; i < (endWorkingHour < 18 ? endWorkingHour : 18); i = i + 0.25) {
          const timeMoment = moment("0", "H").add(i, "hours");
          const hourAm = timeMoment.format(timeAmFormat);
          if (!listHourTimeShift.includes(hourAm)) {
            const hour = timeMoment.format(timeFormat);
            listTimeShift.push({ hour, hourAm });
            listHourTimeShift.push(hourAm)
          }

        }

        return listTimeShift
      }, [])

      const newWorkingHours = listStaffShiftAfternoon.sort((staffA, staffB) => {
        if (moment(`2022-01-01 ${staffA.hourAm}`) < moment(`2022-01-01 ${staffB.hourAm}`)) {
          return -1;
        }
        if (moment(`2022-01-01 ${staffA.hourAm}`) > moment(`2022-01-01 ${staffB.hourAm}`)) {
          return 1;
        }
        return 0;
      })

      workingHours = newWorkingHours;
    } else {
      if (endWorkingHour < 12 || startWorkingHour > 18) return
      for (let i = startWorkingHour > 12 ? startWorkingHour : 12; i < (endWorkingHour < 18 ? endWorkingHour : 18); i = i + 0.25) {
        const timeMoment = moment("0", "H").add(i, "hours");
        const hour = timeMoment.format(timeFormat);
        const hourAm = timeMoment.format(timeAmFormat);
        workingHours.push({ hour, hourAm });
      }
    }

    selectedServices.forEach(element => {
      const [hours, minutes] = element.selected.time.split(":")
      const data = {
        hour: element.selected.time,
        hourAm: moment(`2022-01-01 ${element.selected.time}`).format("hh:mm a")
      }
      const checkTime = workingHours.filter((item) => item.hour === data.hour).length
      if (minutes !== "00" && minutes !== "15" && minutes !== "30" && minutes !== "45" && checkTime === 0) {
        if (+hours >= 12 && +hours < 18) {
          const index = (workingHours.findIndex(item => moment(`2022-01-01 ${item.hour}`) > moment(`2022-01-01 ${data.hour}`)))
          if (index === -1) {
            workingHours.push(data)
          } else {
            workingHours.splice(index, 0, data)
          }
        }
      }
    });

    if (bookingStaff?.breakTimes?.length > 0 && workingHours?.length > 0) {
      const listBreakTimeAfternoon = bookingStaff?.breakTimes.reduce((arr, item) => {
        const { startWorkingHour, endWorkingHour } = convertStartEndTime(item.startDateTime, item.endDateTime, timezone, 'afternoon')

        if (endWorkingHour < 12 || startWorkingHour >= 18) {
          return arr
        }
        const listBreakTime = []
        for (let i = startWorkingHour; i < (endWorkingHour < 18 ? endWorkingHour : 18); i = i + 0.25) {
          const timeMoment = moment("0", "H").add(i, "hours");
          const hourAm = timeMoment.format(timeAmFormat);
          listBreakTime.push(hourAm);
        }

        return [...arr, ...listBreakTime]
      }, [])
      if (listBreakTimeAfternoon?.length > 0) {
        const newData = workingHours.filter((item) => {
          return !listBreakTimeAfternoon.includes(item.hourAm)
        })
        workingHours = newData
      }
    }

    if (bookingStaff?.blockedTimes?.length > 0 && workingHours?.length > 0) {
      const listBlockedTimeAfternoon = bookingStaff?.blockedTimes.reduce((arr, item) => {
        const { startWorkingHour, endWorkingHour } = convertStartEndTime(item.startDateTime, item.endDateTime, timezone, 'afternoon')

        if (endWorkingHour < 12 || startWorkingHour >= 18) {
          return arr
        }
        const listBreakTime = []
        for (let i = startWorkingHour; i < (endWorkingHour < 18 ? endWorkingHour : 18); i = i + 0.25) {
          const timeMoment = moment("0", "H").add(i, "hours");
          const hourAm = timeMoment.format(timeAmFormat);
          listBreakTime.push(hourAm);
        }

        return [...arr, ...listBreakTime]
      }, [])

      if (listBlockedTimeAfternoon?.length > 0) {
        const newData = workingHours.filter((item) => {
          return !listBlockedTimeAfternoon.includes(item.hourAm)
        })
        workingHours = newData
      }
    }

    if (bookingStaff?.bookingTimes?.length > 0 && workingHours?.length > 0) {
      const listBookingTimeAfternoon = bookingStaff?.bookingTimes.reduce((arr, item) => {
        const itemEndDateTime = moment.tz(item.endDateTime, timezone).add(item.extraTime).toISOString()
        const { startWorkingHour, endWorkingHour } = convertStartEndTime(item.startDateTime, itemEndDateTime, timezone, 'afternoon')

        if (endWorkingHour < 12 || startWorkingHour >= 18) {
          return arr
        }
        const listBookingTime = []
        for (let i = startWorkingHour; i < (endWorkingHour < 18 ? endWorkingHour : 18); i = i + 0.25) {
          const timeMoment = moment("0", "H").add(i, "hours");
          const hourAm = timeMoment.format(timeAmFormat);
          if (!listBookingTime.includes(hourAm)) {
            listBookingTime.push(hourAm);
          }
        }

        return [...arr, ...listBookingTime]
      }, [])

      if (listBookingTimeAfternoon?.length > 0) {
        const newData = workingHours.filter((item) => {
          return !listBookingTimeAfternoon.includes(item.hourAm)
        })
        workingHours = newData
      }
    }

    return workingHours;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeSelect, stringSelectService, staffId, startWorkingHour, endWorkingHour]);

  const workingEveningHours = useMemo(() => {
    if ((!staffShift?.startDateTime || !staffShift?.endDateTime) && staffId !== 0) return
    let workingHours = [] as TimeProps[];

    if (staffId === 0) {
      const listStaffShiftEvening = listStaffShift.reduce((arr, item) => {
        const { startWorkingHour, endWorkingHour } = convertStartEndTime(item.startDateTime, item.endDateTime, timezone, 'evening')

        if (endWorkingHour < 18) {
          return arr
        }

        const listTimeShift = arr
        const listHourTimeShift = listTimeShift.map((item) => item.hourAm)
        for (let i = startWorkingHour > 18 ? startWorkingHour : 18; i <= endWorkingHour; i = i + 0.25) {
          const timeMoment = moment("0", "H").add(i, "hours");
          const hourAm = timeMoment.format(timeAmFormat);
          if (!listHourTimeShift.includes(hourAm)) {
            const hour = timeMoment.format(timeFormat);
            listTimeShift.push({ hour, hourAm });
            listHourTimeShift.push(hourAm)
          }

        }

        return listTimeShift
      }, [])

      const newWorkingHours = listStaffShiftEvening.sort((staffA, staffB) => {
        if (moment(`2022-01-01 ${staffA.hourAm}`) < moment(`2022-01-01 ${staffB.hourAm}`)) {
          return -1;
        }
        if (moment(`2022-01-01 ${staffA.hourAm}`) > moment(`2022-01-01 ${staffB.hourAm}`)) {
          return 1;
        }
        return 0;
      })

      workingHours = newWorkingHours;
    } else {
      if (endWorkingHour < 18) return
      for (let i = startWorkingHour > 18 ? startWorkingHour : 18; i <= endWorkingHour; i = i + 0.25) {
        const timeMoment = moment("0", "H").add(i, "hours");
        const hour = timeMoment.format(timeFormat);
        const hourAm = timeMoment.format(timeAmFormat);
        workingHours.push({ hour, hourAm });
      }
    }

    selectedServices.forEach(element => {
      const [hours, minutes] = element.selected.time.split(":")
      const data = {
        hour: element.selected.time,
        hourAm: moment(`2022-01-01 ${element.selected.time}`).format("hh:mm a")
      }
      const checkTime = workingHours.filter((item) => item.hour === data.hour).length
      if (minutes !== "00" && minutes !== "15" && minutes !== "30" && minutes !== "45" && checkTime === 0) {
        if (+hours >= 18) {
          const index = (workingHours.findIndex(item => moment(`2022-01-01 ${item.hour}`) > moment(`2022-01-01 ${data.hour}`)))
          if (index === -1) {
            workingHours.push(data)
          } else {
            workingHours.splice(index, 0, data)
          }
        }
      }
    });

    if (bookingStaff?.breakTimes?.length > 0 && workingHours?.length > 0) {
      const listBreakTimeEvening = bookingStaff?.breakTimes.reduce((arr, item) => {
        const { startWorkingHour, endWorkingHour } = convertStartEndTime(item.startDateTime, item.endDateTime, timezone, 'evening')

        if (endWorkingHour < 18) {
          return arr
        }
        const listBreakTime = []
        for (let i = startWorkingHour; i <= endWorkingHour; i = i + 0.25) {
          const timeMoment = moment("0", "H").add(i, "hours");
          const hourAm = timeMoment.format(timeAmFormat);
          listBreakTime.push(hourAm);
        }

        return [...arr, ...listBreakTime]
      }, [])
      if (listBreakTimeEvening?.length > 0) {
        const newData = workingHours.filter((item) => {
          return !listBreakTimeEvening.includes(item.hourAm)
        })
        workingHours = newData
      }
    }

    if (bookingStaff?.blockedTimes?.length > 0 && workingHours?.length > 0) {
      const listBlockedTimeEvening = bookingStaff?.blockedTimes.reduce((arr, item) => {
        const { startWorkingHour, endWorkingHour } = convertStartEndTime(item.startDateTime, item.endDateTime, timezone, 'evening')

        if (endWorkingHour < 18) {
          return arr
        }
        const listBreakTime = []
        for (let i = startWorkingHour; i <= endWorkingHour; i = i + 0.25) {
          const timeMoment = moment("0", "H").add(i, "hours");
          const hourAm = timeMoment.format(timeAmFormat);
          listBreakTime.push(hourAm);
        }

        return [...arr, ...listBreakTime]
      }, [])

      if (listBlockedTimeEvening?.length > 0 && workingHours?.length > 0) {
        const newData = workingHours.filter((item) => {
          return !listBlockedTimeEvening.includes(item.hourAm)
        })
        workingHours = newData
      }
    }

    if (bookingStaff?.bookingTimes?.length > 0 && workingHours?.length > 0) {
      const listBookingTimeEvening = bookingStaff?.bookingTimes.reduce((arr, item) => {
        const itemEndDateTime = moment.tz(item.endDateTime, timezone).add(item.extraTime).toISOString()
        const { startWorkingHour, endWorkingHour } = convertStartEndTime(item.startDateTime, itemEndDateTime, timezone, 'evening')

        if (endWorkingHour < 18) {
          return arr
        }
        const listBookingTime = []
        for (let i = startWorkingHour; i <= endWorkingHour; i = i + 0.25) {
          const timeMoment = moment("0", "H").add(i, "hours");
          const hourAm = timeMoment.format(timeAmFormat);
          if (!listBookingTime.includes(hourAm)) {
            listBookingTime.push(hourAm);
          }
        }

        return [...arr, ...listBookingTime]
      }, [])

      if (listBookingTimeEvening?.length > 0) {
        const newData = workingHours.filter((item) => {
          return !listBookingTimeEvening.includes(item.hourAm)
        })
        workingHours = newData
      }
    }

    return workingHours;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeSelect, stringSelectService, staffId, startWorkingHour, endWorkingHour]);

  return {
    control,
    trigger,
    fieldName,
    startWorkingHour,
    startHourSelectService,
    timeSelectedServices,
    workingMorningHours,
    workingAfternoonHours,
    workingEveningHours
  };
};