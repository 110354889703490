import React, { useRef } from "react";
import Slider from "react-slick";
import { FormControlLabel } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";

import { anyonePlaceholder } from "~/constants";
import { StyledRadio } from "~/Components/StyledRadio";
import type { StaffPickerSliderProps } from "~/Components/StaffPicker/interfaces";

import styles from "~/Components/StaffPicker/StaffPickerStyle.module.scss";

const settings = {
  dots: false,
  infinite: false,
  slidesToShow: 5,
  slidesToScroll: 4,
  arrows: false,
  speed: 300,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: false,
        dots: false,
      },
    },
  ],
};

export const StaffPickerSlider: React.FC<StaffPickerSliderProps> = (props) => {
  const staffList = [anyonePlaceholder, ...props.staffList];
  const slider = useRef(null);

  return (
    <Slider {...settings} ref={slider} className={"booking-staff-slider"}>
      {staffList?.length > 0 &&
        staffList.map((staff) => (
          <FormControlLabel
            key={staff.id}
            value={`${staff.id}`}
            control={<StyledRadio />}
            className={`${"align-items-flex-start checkboxLabel-control avatar-check"}  ${
              styles.avatar_box
            }`}
            label={
              <>
                <Avatar
                  alt={`${staff.firstName} ${staff.lastName}`}
                  src={staff.avatar}
                >
                  {`${staff.firstName.substr(0, 1)}${staff.lastName.substr(
                    0,
                    1
                  )}`}
                </Avatar>
                <span className="check-name">
                  {staff.firstName + " " + staff.lastName}
                </span>
              </>
            }
          />
        ))}
    </Slider>
  );
};
