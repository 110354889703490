const defineNextInput = (currentInput, allInputs) => {
  const currentIndex = allInputs.indexOf(currentInput);

  const nextIndex =
    currentIndex + 1 === allInputs.length ? 0 : currentIndex + 1;

  return allInputs[nextIndex];
};

export default defineNextInput;
