import React, { useState } from 'react';
import { Grid } from '@material-ui/core';

import { VenueReview } from '~/Containers/VenuePage/components/VenueReview';
import { useAppSelector } from '~/reduxConfig';
import { venueSelector } from '~/store/selectors';
import { NoReviews } from '~/Containers/VenuePage/components/NoReviews';
import { VenueRating } from '~/Containers/VenuePage/components/VenueRating';
import type { VenueReducerState } from '~/store/reducers/interfaces/venueInterfaces';

import styles from './VenueReviewsStile.module.scss';

const ViewAllReviews = () => (
  <>
    <span>View all Reviews&nbsp;</span>
    <i className='icon-chevron-down' />
  </>
);
const HideReviews = () => (
  <>
    <span>Hide reviews&nbsp;</span>
    <i className='icon-chevron-up' />
  </>
);

export const VenueReviews: React.FC = () => {
  const {
    venue: { googleRatingTotal },
    reviews = [],
  } = useAppSelector<VenueReducerState>(venueSelector);
  const [isShowAllReviews, setIsShowAllReviews] = useState<boolean>(false);

  const toggleShow = () => {
    setIsShowAllReviews(!isShowAllReviews);
  };

  if (!googleRatingTotal && reviews.length === 0) return <NoReviews />;
  return (
    <Grid
      container
      spacing={2}
      justifyContent={'space-around'}
      alignContent={'center'}
      className={styles.container}
    >
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <VenueRating />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} className={styles.reviews_block}>
        <h1>
          <span className={styles.colored}>{googleRatingTotal || reviews.length || 0}</span>
          &nbsp;Reviews
        </h1>
        {reviews
          .filter((_, index) => isShowAllReviews || index < 2)
          .map((review) => (
            <section key={review.time}>
              <VenueReview {...review} />
            </section>
          ))}
        <button className={'white-btn'} onClick={toggleShow}>
          {!isShowAllReviews ? <ViewAllReviews /> : <HideReviews />}
        </button>
      </Grid>
    </Grid>
  );
};
