import React from "react";
import type { RouteComponentProps } from "react-router-dom";
import { Redirect, Route, Switch } from "react-router-dom";

import { homePageRoute, mainLayoutRoute } from "~/constants";
import { SuccessPage } from "~/Containers/SuccessPage";

export const SuccessLayout: React.FC<RouteComponentProps<any>> = (props) => {
  const {match} = props;

  return (
    <>
      <Switch>
        <Route
          path={match.path}
          render={(p) => <SuccessPage {...p} />}
        />

        <Redirect to={mainLayoutRoute.concat(homePageRoute)} />

      </Switch>
    </>
  );
};
