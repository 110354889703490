import React from "react";
import clsx from "clsx";

import { SortBy } from "~/Containers/SearchResultsPage/components/SortBy";
import { CategoryAutocomplete } from "~/Containers/SearchResultsPage/components/CategoryAutocomplete";
import { DatePicker } from "~/Containers/SearchResultsPage/components/DatePicker";
import { TimePicker } from "~/Containers/SearchResultsPage/components/TimePicker";
import type { SearchResultsFiltersProps } from "~/Containers/SearchResultsPage/interfaces";

import styles from "./SearchResultsFiltersStyle.module.scss";

export const SearchResultsFilters: React.FC<SearchResultsFiltersProps> = ({horizontal, searchFields}) => {
  return (
    <div className={`
        ${clsx(styles.filter_box, {
          [styles.filter_box_active]: horizontal,
        })}`}>
      <SortBy searchFields={searchFields} />
      {/* <PriceSelect /> */}
      <CategoryAutocomplete searchFields={searchFields} />
      <DatePicker />
      <TimePicker />
      {/* <button className={styles.clear_filters} onClick={onClearFilters}>Clear filters</button> */}
    </div>
  )
}