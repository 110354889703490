import * as yup from "yup";
import { validationModules } from "./yupObjectValidation";

const signIn = {
  emailSignIn: validationModules.email,
  passwordSignIn: validationModules.hardPsw,
  email: validationModules.email,
  password: validationModules.hardPsw,
  firstName: validationModules.defaultStringRule,
  lastName: validationModules.defaultStringRule,
  phone: validationModules.defaultStringRule,
  privacyPolicy: validationModules.privacyPolicy,
  digit1: validationModules.defaultConfirmationRule,
  digit2: validationModules.defaultConfirmationRule,
  digit3: validationModules.defaultConfirmationRule,
  digit4: validationModules.defaultConfirmationRule,
  digit5: validationModules.defaultConfirmationRule,
  emailReset: validationModules.email,
  newPassword: validationModules.simplePsw,
  newPasswordRepeat: validationModules.simplePsw,
};

const signInSchema = yup.object().shape(signIn);

export default signInSchema;
