import React from "react";

import styles from "./MobileSettingsStyles.module.scss";
import ImgMobileSetting from "~/Assets/images/mobile-settings.png";
import DownloadAndroid from "~/Assets/images/google-play-icon.png";
import DownloadIos from "~/Assets/images/app-store-icon.png";

export const MobileSettings = () => {
  return (
    <div className={styles.partnership_content}>
      <div className={styles.partnership_box}>
        <img src={ImgMobileSetting} alt="" />
      </div>

      <div className={styles.partnership_info}>
        <h2 className={styles.partnership_title}>
          <span className={styles.partnership_subtitle}>Download</span> Glamezy
          <br />
          <span>Mobile App</span>
        </h2>

        <p className={styles.partnership_description}>
          Glamezy mobile app is the fastest and the best way for you to unlock
          the world of beauty, wellness and fitness. We bring glam to your hands
          by making our app accessible and easy to use while you are on the
          move!
        </p>
        <div className={styles.partnership_download}>
          <h3 className={styles.partnership_download_title}>
            Now available on
          </h3>
          <div className={styles.partnership_button}>
            <a
              href="https://apps.apple.com/au/app/glamezy/id1639870596"
              target="_blank"
              rel="noreferrer"
            >
              <img src={DownloadIos} alt="" />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.qriomatrix.glamezy&pli=1"
              target="_blank"
              rel="noreferrer"
            >
              <img src={DownloadAndroid} alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
