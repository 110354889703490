import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import type {
  BookingReducerStateProps,
  FetchedServiceForBookingProps,
  BookingStaffProps,
  CountBookingPriceProps,
} from "~/store/reducers/interfaces/bookingInterfaces";

const initialCountBookingPrice = {
  subtotal: 0,
  tax: 0,
  total: 0,
};

const initialState = {
  fetchedServices: [],
  fetchedPackages: [],
  staff: {
    bookingStaffList: [],
    count: null,
    closeDateEnabled: false,
  },
  countBookingPrice: initialCountBookingPrice,
  confirmedBooking: {},
} as BookingReducerStateProps;

const bookingReducer = createSlice({
  name: "booking",
  initialState,
  reducers: {
    fetchBookingServices: (
      state,
      action: PayloadAction<{
        fetchedServices: FetchedServiceForBookingProps[];
        fetchedPackages: FetchedServiceForBookingProps[];
      }>
    ) => {
      const { fetchedServices, fetchedPackages } = action.payload;
      state.fetchedServices = fetchedServices;
      state.fetchedPackages = fetchedPackages;
    },
    fetchBookingStaff: (state, action: PayloadAction<BookingStaffProps>) => {
      state.staff = action.payload;
    },
    fetchCountBookingPrice: (
      state,
      action: PayloadAction<CountBookingPriceProps>
    ) => {
      state.countBookingPrice = action.payload;
    },
    clearCountBookingPrice: (state) => {
      state.countBookingPrice = initialCountBookingPrice;
    },
    fetchConfirmedBooking: (state, action: PayloadAction<Object>) => {
      state.confirmedBooking = action.payload;
    },
    clearBookingStore: () => initialState,
  },
});

export const {
  fetchBookingServices,
  fetchBookingStaff,
  fetchCountBookingPrice,
  fetchConfirmedBooking,
  clearCountBookingPrice,
  clearBookingStore,
} = bookingReducer.actions;

export default bookingReducer.reducer;
