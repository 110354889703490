import { useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import moment from 'moment-timezone';

import { useAppSelector } from "~/reduxConfig";
import { myBookingsSelector } from "~/store/selectors";
import type { MyBookingsStateProps } from "~/store/reducers/interfaces/myBookingsInterfaces";
import type { MyBookingFormProps } from "~/Containers/MyBookingsPage/interfaces";

export const RescheduleBookingHook = () => {
  const { bookingById: { venue } } = useAppSelector<MyBookingsStateProps>(myBookingsSelector);
  const myBookingFormMethods = useFormContext<MyBookingFormProps>();
  const { time, bookingServiceId, selectedServices } = useWatch({ control: myBookingFormMethods.control })

  useEffect(() => {
    if (selectedServices.length === 0) return;

    const newData = selectedServices.reduce((arr: any[], item: any) => {
      if (+item.id === +bookingServiceId) {
        const dayStart = moment(item.startDateTime).format('YYYY-MM-DD')
        const hourSplit = item.duration.split(':');
        const hour = +hourSplit[0] || 0
        const min = +hourSplit[1] / 60 || 0
        const hours = hour + min

        arr.push({
          ...item,
          startDateTime: moment(`${dayStart} ${time}`).tz(venue.timezone).toISOString(),
          endDateTime: moment(`${dayStart} ${time}`).tz(venue.timezone).add(hours, 'hours').toISOString(),
          selected: {
            ...item.selected,
            time,
          }
        })
      } else {
        arr.push(item)
      }
      return arr;
    }, [])

    myBookingFormMethods.setValue('selectedServices', newData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time])

  useEffect(() => {
    if (selectedServices.length > 0) {
      const data = selectedServices.find((item) => +item.id === +bookingServiceId)
      const startTime = moment(data?.startDateTime).format("HH:mm")
      myBookingFormMethods.setValue('time', startTime)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingServiceId])

  return { selectedServices, myBookingFormMethods };
};