import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { TextField } from "@material-ui/core";

import { Message } from "~/Components/Message";
import type { InputFieldProps } from "../../interfaces";

export const InputField: React.FC<InputFieldProps> = ({
  name,
  onHideMessage,
  placeholder = "",
  type = "text",
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      defaultValue=""
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <>
          <TextField
            type={type}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            className="text-field"
            onFocus={onHideMessage}
            placeholder={placeholder}
            variant="outlined"
            error={Boolean(error)}
          />
          <Message msg={error?.message} />
        </>
      )}
    />
  );
};
