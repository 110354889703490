import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import clsx from 'clsx';
import { TimePickerHook } from '~/Containers/SearchResultsPage/components/TimePicker/TimePickerHook';
import { MuiDrawerHook } from '~/Components/MuiDrawer/MuiDrawerHook';
import { CheckMobileHook } from '~/Components/MainHeader/CheckMobileHook';
import MuiDrawer from '~/Components/MuiDrawer';

import styles from './TimePickerStyle.module.scss';

export const TimePicker: React.FC = () => {
  const {
    now,
    control,
    isPickerOpen,
    setIsPickerOpen,
    selectedTame,
    setSelectedTime,
    timeArea,
    setTimeArea,
    viewTimeConverter,
    onChangeHandler,
    onClearHandler,
  } = TimePickerHook();

  const { isToggle, toggleDrawer } = MuiDrawerHook();
  const { isMobile } = CheckMobileHook();
  const [isShow, setIsShow] = useState(false)

  const renderTime = (time) => {
    return (
      <div className={styles.select_am_pm} onClickCapture={() => {
        setTimeArea(time)
        setIsShow(false)
      }}>
        <span className={timeArea === time && styles.select_time}>
          {time}
        </span>
      </div>
    )
  }

  const ViewMobile = () => (
    <div role="presentation">
      <Controller
        control={control}
        name={'time'}
        render={({ field: { value, onChange } }) => {
          const viewedTime = viewTimeConverter(value);
          return (
            <div className="root_time">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardTimePicker
                  className={styles.time_picker}
                  variant="inline"
                  initialFocusedDate={now}
                  open={isToggle}
                  onClick={() => {
                    setIsPickerOpen(!isPickerOpen);
                  }}
                  margin="normal"
                  id="time-picker"
                  label="Time"
                  value={viewedTime}
                  onChange={setSelectedTime}
                  KeyboardButtonProps={{
                    'aria-label': 'change time',
                  }}
                  ToolbarComponent={() => {
                    return (
                      <>
                        <div className={styles.form_control}>
                          <div className={styles.value_time} onClickCapture={() => setIsShow(!isShow)}>{timeArea}</div>
                          {
                            isShow && (
                              <div className={styles.wrapper_select_am_pm}>
                                {renderTime("AM")}
                                {renderTime("PM")}
                              </div>
                            )
                          }
                        </div>
                        <div className={styles.btn_box}>
                          <div
                            className={styles.btn_apply}
                            onClickCapture={() => {
                              onChangeHandler(selectedTame, onChange);
                              toggleDrawer()
                            }}
                          >
                            Apply
                          </div>
                          <div
                            className={styles.btn_clear}
                            onClickCapture={() => {
                              onClearHandler(onChange);
                              toggleDrawer()
                            }}
                          >
                            Clear filter
                          </div>
                        </div>
                      </>
                    );
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
          );
        }}
      />
    </div>
  );

  return (
    <>
      <MuiDrawer anchor={'bottom'} open={isToggle} handleToggle={toggleDrawer}>
        {ViewMobile()}
      </MuiDrawer>
      <Controller
        control={control}
        name={'time'}
        render={({ field: { value, onChange } }) => {
          const viewedTime = viewTimeConverter(value);

          return (
            <div className="root_time">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardTimePicker
                  className={clsx(styles.time_picker, {
                    [styles.time_picker_selected]: (viewedTime !== null || isPickerOpen || isToggle)
                  })}
                  variant="inline"
                  initialFocusedDate={now}
                  open={isPickerOpen}
                  onClick={
                    isMobile ? toggleDrawer : () => {
                      setIsPickerOpen(!isPickerOpen);
                    }
                  }
                  margin="normal"
                  id="time-picker"
                  label="Time"
                  value={viewedTime}
                  onChange={setSelectedTime}
                  KeyboardButtonProps={{
                    'aria-label': 'change time',
                  }}
                  ToolbarComponent={() => {
                    return (
                      <>
                        <FormControl className={styles.form_control}>
                          <Select
                            defaultValue={'AM'}
                            value={timeArea}
                            onChange={(event) => {
                              const val = event.target.value as string;
                              setTimeArea(val);
                            }}
                            MenuProps={{
                              anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                              },
                              getContentAnchorEl: null,
                            }}
                          >
                            <MenuItem value={'AM'}>AM</MenuItem>
                            <MenuItem value={'PM'}>PM</MenuItem>
                          </Select>
                        </FormControl>
                        <div className={styles.btn_box}>
                          <button
                            className={styles.btn_apply}
                            onClick={() => {
                              onChangeHandler(selectedTame, onChange);
                              setIsPickerOpen(!isPickerOpen);
                            }}
                          >
                            Apply
                          </button>
                          <button
                            className={styles.btn_clear}
                            onClick={() => {
                              onClearHandler(onChange);
                              setIsPickerOpen(!isPickerOpen);
                            }}
                          >
                            Clear filter
                          </button>
                        </div>
                      </>
                    );
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
          );
        }}
      />
    </>
  );
};
