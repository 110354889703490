import { useFormContext, useWatch } from "react-hook-form";
import { BookingFormProps } from "~/Containers/BookingFlowPage/interfaces";

export const SelectedServicesHook = () => {
  const { control, getValues, setValue } = useFormContext<BookingFormProps>();
  const fields = useWatch<BookingFormProps>({ control });
  const { selectedServices } = fields as Required<BookingFormProps>;

  return {
    selectedServices,
    getBookingFormValues: getValues,
    setBookingFormValues: setValue,
  };
};
