import { useCallback, useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import { IconButton } from "@material-ui/core";
import { Add, Remove } from "@material-ui/icons";
import supercluster from "points-cluster";

import { DefaultLocation } from "~/store/reducers/locationReducer";
import Marker from "./Marker/Marker";
import MarkerCluster from "./MarkerCluster/MarkerCluster";
import { Cluster, MapState, MapWrapperProps } from "./MapTypes";
import { AvailableCardVenuesEntity } from "~/store/reducers/interfaces/mainInterfaces";
import { useAppSelector } from "~/reduxConfig";
import { userLocationSelector } from "~/store/selectors";
import UserMarkerComponent from "./UserMarker/UserMarker";
import "./Map.css";

const Map = (props: MapWrapperProps) => {
  const { venues = [], isSingleView = false , handleSelectSlide, hideZoomControl } = props;
  const userLocation = useAppSelector(userLocationSelector);
  const [map, setMapState] = useState<google.maps.Map | null>(null);
  const [clusters, setClusters] = useState<Cluster[]>([]);
  const [mapOpts, setMapOpts] = useState<MapState>({
    center: DefaultLocation,
    zoom: 8,
    bounds: null,
  });

  const refMapCallback = (_map: any) => {
    if (_map) {
      setMapState(_map.map_);
    }
  };

  const getClusters = useCallback(() => {
    const markers = venues.map((venue: AvailableCardVenuesEntity ,index: number) => ({
      id: venue.id,
      lat: +venue.lat,
      lng: +venue.lng,
      venue: venue,
      index: index,
    }));

    const clustersMarker = supercluster(markers, {
      minZoom: 0,
      maxZoom: 13,
      radius: 60,
    });

    return clustersMarker(mapOpts);
  }, [mapOpts, venues]);

  const createClusters = useCallback(() => {
    const newCluster = mapOpts.bounds
      ? getClusters().map(({ wx, wy, numPoints, points }) => ({
        lat: wy,
        lng: wx,
        numPoints,
        id: points[0].id,
        venue: points[0].venue,
        points,
        index: points[0].index,
      }))
      : [];

    setClusters(newCluster);
  }, [getClusters, mapOpts.bounds]);

  const onHandleZoom = (isZoomIn = false) => {
    return () => {
      if (map) {
        map.setZoom(map.getZoom() + 1 * (isZoomIn ? 1 : -1));
      }
    };
  };

  const handleMapChange = ({ center, zoom, bounds }) => {
    setMapOpts({ center, zoom, bounds });
  };

  useEffect(() => {
    if (venues.length && map) {
      createClusters();
    }
  }, [mapOpts, createClusters, venues.length, map]);


  useEffect(() => {
    if (map) {
      // fit bounds venues
      if (venues.length > 0) {
        const currentBounds = new google.maps.LatLngBounds();
        venues.forEach(({ lat, lng }) => {
          currentBounds.extend(new google.maps.LatLng(+lat, +lng));
        });
        map.fitBounds(currentBounds);
        map.setZoom(10);
      }
    }
  }, [map, venues]);

  return (
    <>
      {!hideZoomControl && (
        <div className="map-contrl">
          <IconButton onClick={onHandleZoom(true)}>
            <Add />
          </IconButton>
          <IconButton onClick={onHandleZoom(false)}>
            <Remove />
          </IconButton>
        </div>
      )}
      <GoogleMapReact
        center={DefaultLocation}
        defaultZoom={13}
        ref={refMapCallback}
        onChange={handleMapChange}
      >
        {clusters.map((item) => {
          if (item.numPoints === 1) {
            return (
              <Marker
                index={item.index}
                key={item.id}
                lat={item.lat}
                lng={item.lng}
                venue={item.venue}
                isSingleView={isSingleView}
                handleSelectSlide={handleSelectSlide}
              />
            );
          }
          return (
            <MarkerCluster
              key={item.id}
              lat={item.lat}
              lng={item.lng}
              amount={item.numPoints}
            />
          );
        })}
        {userLocation && (
          <UserMarkerComponent lat={userLocation.lat} lng={userLocation.lng} />
        )}
      </GoogleMapReact>
    </>
  );
};

export default Map;
