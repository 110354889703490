import React from "react";
import type { RouteComponentProps } from "react-router-dom";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";

import {
  bookingFlowPageRoute,
  flowsLayoutRoute,
  homePageRoute,
  mainLayoutRoute,
  paymentFlowPageRoute,
  successLayoutRoute
} from "~/constants";
import { BookingFlowPage } from "~/Containers/BookingFlowPage";
import { PaymentFlowPage } from "~/Containers/PaymentFlowPage";
import { CustomerHeader } from "~/Components/CustomerHeader";
import { SuccessLayout } from "~/Layouts/SuccessLayout";
import { CheckMobileHook } from "~/Components/MainHeader/CheckMobileHook";

import styles from "./FlowsLayoutStyle.module.scss";

export const FlowsLayout: React.FC<RouteComponentProps<any>> = (props) => {
  const { isMobile } = CheckMobileHook();

  const {match} = props;
  const location = useLocation()

  if (location.pathname === flowsLayoutRoute.concat(successLayoutRoute)) {
    return <SuccessLayout {...props} />;
  }
  
  return (
    <div className={isMobile ? styles.customer_section_mobile : styles.customer_section}>
      { !isMobile && <CustomerHeader /> } 
      <main>

        <section className={styles.container}>

          <Switch>

            <Route
              path={match.path.concat(bookingFlowPageRoute, "/:id")}
              render={(p) => <BookingFlowPage {...p} />}
            />

            <Route
              path={match.path.concat(paymentFlowPageRoute, "/:id")}
              render={(p) => <PaymentFlowPage {...p} />}
            />

            {/* <Route
              path={match.path.concat(successLayoutRoute)}
              render={(p) => <SuccessLayout {...p} />}
            /> */}

            <Redirect to={mainLayoutRoute.concat(homePageRoute)} />

          </Switch>

        </section>

      </main>
    </div>
  );
};
