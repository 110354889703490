import { Controller } from "react-hook-form";
import { TextField, InputLabel, Grid, Paper } from "@material-ui/core";
import { Message } from "~/Components/Message";

export const ChangeEmailForm = ({ control, hideMessage, message }) => {
  return (
    <div className="card-wrapper">
      <Paper variant="outlined">
        <form>
          <Grid container justifyContent="center">
            <Grid container item xs={12} className="input-wrapper">
              <InputLabel className="label">Enter new email</InputLabel>
              <Controller
                control={control}
                name="email"
                defaultValue=""
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <TextField
                      className="text-field"
                      onFocus={hideMessage}
                      value={value}
                      onChange={(e) => onChange(e.target.value)}
                      variant="outlined"
                      error={Boolean(error)}
                    />
                    <Message msg={error?.message} />
                  </>
                )}
              />
            </Grid>

            <Grid container item xs={12} className="input-wrapper">
              <InputLabel className="label">Current password</InputLabel>
              <Controller
                control={control}
                name="password"
                defaultValue=""
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <TextField
                      className="text-field"
                      onFocus={hideMessage}
                      value={value}
                      onChange={(e) => onChange(e.target.value)}
                      type="password"
                      variant="outlined"
                      error={Boolean(error)}
                    />
                    <Message msg={error?.message} />
                  </>
                )}
              />
            </Grid>

            <Message msg={message} />
          </Grid>
        </form>
      </Paper>
    </div>
  );
};
