import React, { useState } from "react";
import { FormProvider, useWatch } from "react-hook-form";
import moment from "moment";

import DialogMain from "~/Components/DialogMain/DialogMain";
import { MyBookingDialogContent } from "../MyBookingDialogContent";
import { MyBookingDialogHook } from "~/Containers/MyBookingsPage/components/MyBookingDialog/MyBookingDialogHook";
import type { BookingDialogsProps } from "~/Containers/MyBookingsPage/interfaces";

export const MyBookingDialog: React.FC<BookingDialogsProps> = (props) => {
  const [cancelError, setCancelError] = useState(false)
  const {
    myBookingFormMethods,
    showModal,
    setShowModal,
    titleDialog,
    step,
    firstDialogButton,
    secondDialogButton,
    onChangeTimeError,
    onChangeDayOffError,
    timeError,
    dayOffError,
    venue
  } = MyBookingDialogHook({...props, setCancelError });
  const { date } = useWatch({ control: myBookingFormMethods.control });

  const onCloseCancelErrorModal = () => {
    setCancelError(false);
  };

  const daySelect = moment(date).format("dddd").toLowerCase();
  const timeSelect = venue?.venueBusinessHours
    ? Object.keys(venue?.venueBusinessHours[0])?.filter((item) =>
        item.includes(daySelect)
      )
    : [];
  let timeStartEnd = {};
  timeSelect?.forEach((item) => {
    timeStartEnd = {
      ...timeStartEnd,
      [item]: venue?.venueBusinessHours[0][item],
    };
  });

  const isClosed = timeStartEnd[timeSelect[0]] == null;

  return (
    <>
      <FormProvider {...myBookingFormMethods}>
        <DialogMain
          showModal={showModal}
          setShowModal={setShowModal}
          titleDialog={titleDialog}
          dialogWindowClass="modal-dialog-service"
          errorReschedule={timeError || dayOffError}
          step={step}
          isClosed={isClosed}
          {...firstDialogButton()}
          {...secondDialogButton()}
        >
          <>
            <MyBookingDialogContent
              step={step}
              onChangeTimeError={onChangeTimeError}
              onChangeDayOffError={onChangeDayOffError}
              timeSelect={timeSelect}
              timeStartEnd={timeStartEnd}
            />

            {/* add after implementing this function on back*/}
            {/*<LeaveReview />*/}
          </>
        </DialogMain>
      </FormProvider>
      {cancelError && (
        <DialogMain
          showModal={cancelError}
          setShowModal={onCloseCancelErrorModal}
          titleDialog={"Cancel Booking Failed"}
          dialogWindowClass="modal-dialog-service"
          onSubmit={onCloseCancelErrorModal}
          submitButtonText={"Close"}
          footerBtnAlignmentClass={"btn-big"}
        >
          <p style={{ textAlign: "center" }}>
            Transaction failed when charge cancel booking
          </p>
        </DialogMain>
      )}
    </>
  );
};
