import React from 'react';
import {
  TextField,
  InputLabel,
  Grid,
  Paper,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';

interface EditCardProps {
  values: {
    cardName: string;
    isDefault: boolean;
  };
  onChange: (values: { cardName: string; isDefault: boolean }) => void;
}

export const EditCard: React.FC<EditCardProps> = ({ values, onChange }) => {
  const onChangeCardName = (e) => {
    onChange({
      ...values,
      cardName: e.target.value,
    });
  };

  const onChangeIsDefault = () => {
    onChange({
      ...values,
      isDefault: !values.isDefault,
    });
  };

  return (
    <div className='card-wrapper'>
      <Paper variant='outlined'>
        <form>
          <Grid container justifyContent='center'>
            <Grid container item xs={12} className='input-wrapper'>
              <InputLabel className='label'>Name on card</InputLabel>
              <TextField
                className='text-field'
                value={values.cardName}
                placeholder='Your name'
                onChange={onChangeCardName}
                variant='outlined'
              />
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                className='align-items-flex-start checkboxLabel-control'
                control={
                  <Checkbox
                    onChange={onChangeIsDefault}
                    checked={values.isDefault}
                    color='primary'
                  />
                }
                label={'Default payment method'}
              />
            </Grid>
          </Grid>
        </form>
      </Paper>
    </div>
  );
};
