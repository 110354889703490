import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment-timezone";
import { useSelector } from "react-redux";

import history from "~/utils/history";
import addCardSchema from "~/services/validationServices/addCardSchema";
import paymentFlowSchema from "~/services/validationServices/paymentFlowSchema";
import SetDataForBookingConfirm from "./SetDataForBookingConfirm";
import { useAppSelector, useAppDispatch } from "~/reduxConfig";
import { paymentMethodSelector, sessionSelector } from "~/store/selectors";
import { hideMessage } from "~/store/reducers/paymentMethodReducer";
import {
  onGetCreditCardsAction,
  onGetDefaultCreditCardAction,
  onPostCreditCardAction,
  onLoadVenueAction,
  onCreateBookingAction,
} from "~/store/actions/paymentMethodAction";
import { paymentPanels, flowsLayoutRoute, successLayoutRoute } from "~/constants";
import { bookingApi } from "~/services/api/bookingApi";
import { CountBookingPriceProps, ErrorProps } from "./interfaces";

const PaymentFlowPageHook = (params, location) => {
  const dispatch = useAppDispatch();
  const { cards, venue, defaultCardId, message } = useAppSelector(paymentMethodSelector);
  const { receiveDiscountsAndPromotions } = useSelector(sessionSelector);
  const booking = {
    venueId: +params.id,
    services: location?.state?.selectedServices?.map((service) => {
      const payload = {
        pricingOptionId: service.selected.pricingOptionId,
        staffPricingOptionId: "null",
        staffId: service.selected.staffId,
        serviceId: service.selected.serviceId,
        packageId: service.packageId,
      }
      if (service.type === "package") {
        delete payload.serviceId;
        delete payload.pricingOptionId
        delete payload.staffPricingOptionId;
      } else {
        delete payload.packageId;
      }
      return payload;
    })
  }

  const [addCardModal, setAddCardModal] = useState(false);
  const [sortedCards, setSortedCards] = useState([]);
  const [expanded, setExpanded] = useState<boolean | string>(false);
  const [isLoadingBooking, setIsLoadingBooking] = useState(false);

  const [isShowModalDiscount, setIsShowModalDiscount] = useState(false);
  const [countBookingPrice, setCountBookingPrice] = useState<CountBookingPriceProps>(location.state.countBookingPrice)
  const [discountCode, setDiscountCode] = useState("");
  const [error, setError] = useState<ErrorProps>({ errorDiscount: "", errorClearDiscount: "" })

  const onShowModalDiscount = () => {
    setIsShowModalDiscount(true);
    if (countBookingPrice?.discount?.code) {
      setDiscountCode(countBookingPrice?.discount.code)
    }
    if (error?.errorClearDiscount) {
      setError((prev) => ({ ...prev, errorClearDiscount: "" }));
    }
  }

  const onCloseModalDiscount = () => {
    setIsShowModalDiscount(false);
    setDiscountCode("");
    setError((prev) => ({ ...prev, errorDiscount: "" }));
  }

  const onChangeValueDiscount = (e) => {
    const value = e.target.value;
    setDiscountCode(value);
  }

  const onFocusDiscount = () => {
    setError((prev) => ({ ...prev, errorDiscount: "" }));
  }

  const onSubmitUseDiscount = async () => {
    const dataCountBooking = {
      ...booking,
      discountCode,
    }
    try {
      const response = await bookingApi.getCountBookingPrice(dataCountBooking);
      if (response?.status === 200) {
        setCountBookingPrice(response?.data);
        onCloseModalDiscount();
      } else {
        const messageError = response?.data?.message
        setError((prev) => ({ ...prev, errorDiscount: messageError }));
      }
    } catch (error) {
      console.log('Error: ', error)
    }
  }

  const onClearDiscount = async () => {
    try {
      const response = await bookingApi.getCountBookingPrice(booking);
      if (response?.status === 200) {
        setCountBookingPrice(response?.data);
      } else {
        const messageError = response?.data?.message
        setError((prev) => ({ ...prev, errorClearDiscount: messageError }));
      }
    } catch (error) {
      console.log('Error: ', error)
    }
  }

  const cardForm = useForm({
    resolver: yupResolver(addCardModal ? addCardSchema : paymentFlowSchema),
    mode: "onChange",
    criteriaMode: "all",
  });

  const {
    getValues,
    reset,
    clearErrors,
    trigger,
    formState: { errors },
    control,
  } = cardForm;

  useEffect(() => {
    dispatch(onGetCreditCardsAction());
  }, [dispatch]);

  useEffect(() => {
    dispatch(onLoadVenueAction(params.id));
  }, [params, dispatch]);

  useEffect(() => {
    cards.length && dispatch(onGetDefaultCreditCardAction(cards[0].customer));
  }, [dispatch, cards]);

  useEffect(() => {
    if (addCardModal) {
      reset({ ...getValues() });
      clearErrors();
      dispatch(hideMessage());
    }
  }, [addCardModal, reset, clearErrors, getValues, dispatch]);

  useEffect(() => {
    if (cards.length) {
      setSortedCards(
        [...cards].sort((a, b) => {
          if (a.id < b.id) {
            return -1;
          }
          if (a.id > b.id) {
            return 1;
          }
          return 0;
        })
      );
    } else {
      setSortedCards([]);
    }
  }, [cards]);

  useEffect(() => {
    dispatch(hideMessage());
  }, [expanded, dispatch]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const onHideMessage = () => dispatch(hideMessage());

  const onSubmitAddCard = () => {
    document.getElementById('saveNewCardPaymentBtn')?.click();
  };

  const onSubmitAddCardOld = (data) => {
    const { cardName, cardNumber, cardDate, cardCVV, isDefault } = data;
    const date = cardDate.split("/");

    dispatch(
      onPostCreditCardAction(
        {
          cardName,
          cardNumber: cardNumber.replace(/\s/g, ""),
          expMonth: date[0] || "",
          expYear: date[1] || "",
          cvv: cardCVV,
          isDefault,
        },
        () => setAddCardModal(false)
      )
    );
  };

  const onChangeLoadingBooking = (value: boolean) => {
    setIsLoadingBooking(value)
  }

  const getServices = (timezone, selectedServices) => {
    return selectedServices.map((service) => {
      const {
        pricingOptions = [],
        selected: { pricingOptionId, serviceId, staffId, date, time },
        // staff,
        type = 'service',
        ...rest
      } = service;

      const pricingOption = pricingOptions.find(
        (option) => option.id === pricingOptionId
      );
      const { duration, addExtraTime, staffPricingOptions = [] } = pricingOption || {
        duration: rest.duration,
        addExtraTime: '00:00:000',
      };
      const staffPricingOption = staffPricingOptions.find(opt => opt.staffId === staffId);
      const payload = {
        time: moment.tz(`${date} ${time}`, timezone).toISOString(),
        serviceId,
        pricingOptionId,
        staffPricingOptionId: staffPricingOption?.id || null,
        duration,
        staffId,
        extraTime: addExtraTime,
        packageId: rest.packageId,
      }
      if (type === 'service') {
        delete payload.packageId;
      } else {
        delete payload.serviceId;
        delete payload.staffPricingOptionId;
        delete payload.pricingOptionId;
      }
      return payload;
    });
  };

  const onCreateBooking = async (data, cardId) => {
    setIsLoadingBooking(true);
    const {
      venue: venueData,
      rememberCard = false,
      bookingNotes = '',
      termsConditions,
      paymentType,
      receivePromotion,
      booking: { selectedServices = [] },
      discountId,
    } = data;
    const bookingPayload = {
      venueId: venueData.id,
      bookingServices: getServices(venueData.timezone, selectedServices),
      bookingNotes,
      paymentType,
      paymentAmount: countBookingPrice.total,
      isRemember: rememberCard,
      termsConditions,
      cardId: cardId,
      receivePromotion,
      discountId,
    };

    try {
      const response = await bookingApi.newBooking(bookingPayload);
      if (response.status === 201) {
        history.push(flowsLayoutRoute.concat(successLayoutRoute));
      }
    } catch (error) {
      console.log('booking error', error);
    }
    setIsLoadingBooking(false)
  };

  const onSubmitConfirmBooking = async () => {
    await trigger(["receive", "termsConditions"]);
    const { termsConditions: agreeTerms } = errors;

    if (agreeTerms) {
      return;
    }

    // valid data
    const { receive, remember, description, termsConditions, cardId } = getValues();
    const booking = {
      rememberCard: remember || false,
      bookingNotes: description,
      termsConditions: termsConditions || false,
      venue,
      paymentType: 'creditCard',
      booking: location.state,
      receivePromotion: receiveDiscountsAndPromotions ? receiveDiscountsAndPromotions : (receive || false),
      discountId: countBookingPrice?.discount?.id || null,
    };
    localStorage.setItem('booking', JSON.stringify({ ...booking, countBookingPrice }));

    // button for input new card
    const btn = document.getElementById('paymentSubmitBtn');

    if (btn) {
      btn.click();
    } else {
      // button for current card
      console.log('paymentByCardIdBtn cardId', cardId);
      await onCreateBooking(booking, cardId);
    }
  };

  const onSubmitConfirmBooking2 = async () => {
    if (cards.length && expanded) {
      await trigger(["receive", "termsConditions"]);
      const { receive: receiveErr, termsConditions: termsConditionsErr } = errors;

      if (receiveErr || termsConditionsErr) {
        return;
      } else {
        const { cardId, description, receive, termsConditions } = getValues();
        dispatch(
          onCreateBookingAction(
            new SetDataForBookingConfirm({ params, cardId, description, receive, termsConditions, location, expanded }).withCardId(),
            () => {
              localStorage.setItem('booking', JSON.stringify({ booking: location.state, venue }));
              history.push(flowsLayoutRoute.concat(successLayoutRoute))
            }
          )
        );
        return;
      }
    }

    if (!cards.length && (expanded === paymentPanels.panel1 || expanded === paymentPanels.panel2)) {
      await trigger(["cardName", "cardNumber", "cardDate", "cardCVV", "receive", "termsConditions"]);
      const {
        cardName: cardNameErr,
        cardNumber: cardNumberErr,
        cardDate: cardDateErr,
        cardCVV: cardCVVErr,
        receive: receiveErr,
        termsConditions: termsConditionsErr,
      } = errors;

      if (cardNameErr || cardNumberErr || cardDateErr || cardCVVErr || receiveErr || termsConditionsErr) {
        return;
      } else {
        const { cardName, cardNumber, cardDate, cardCVV, remember, description, receive, termsConditions } = getValues();
        dispatch(
          onCreateBookingAction(
            new SetDataForBookingConfirm({ params, cardName, cardNumber, cardDate, cardCVV, remember, description, receive, termsConditions, location, expanded }).withoutCardId(),
            () =>
              history.push(flowsLayoutRoute.concat(successLayoutRoute), {
                booking: location.state,
                venue,
              })
          )
        );
        return;
      }
    }
  };

  return {
    venue,
    control,
    message,
    expanded,
    cardForm,
    sortedCards,
    addCardModal,
    defaultCardId,
    isLoadingBooking,
    isShowModalDiscount,
    countBookingPrice,
    discountCode,
    error,
    handleChange,
    onHideMessage,
    setAddCardModal,
    onSubmitAddCard,
    onSubmitAddCardOld,
    onSubmitConfirmBooking,
    onSubmitConfirmBooking2,
    onChangeLoadingBooking,
    onShowModalDiscount,
    onCloseModalDiscount,
    onFocusDiscount,
    onSubmitUseDiscount,
    onChangeValueDiscount,
    onClearDiscount,
  };
};

export default PaymentFlowPageHook;
