import React from "react";
import moment from "moment";

import type { BookingServiceItemProps } from "~/Containers/MyBookingsPage/interfaces";
import styles from "./BookingServiceItemStyle.module.scss";
import { BookingServiceItemHook } from "~/Containers/MyBookingsPage/components/BookingServiceItem/BookingServiceItemHook";
import { convertDurationString, convertHours, formatPrice, getHighestPriceOption } from "~/utils/helper";

export const BookingServiceItem: React.FC<BookingServiceItemProps> = (props) => {
  const {
    serviceName,
    variantName,
    displayedName,
    displayedPrice,
    displayedTime,
    displayedDuration,
    priceAdjustment,
    isPackage,
    serviceOfPackage,
    listServicePackage,
  } = BookingServiceItemHook(props);
  const hasPriceAdjustment = priceAdjustment && Number(priceAdjustment) !== Number(displayedPrice);

  return (
    <div className={styles.booking_info}>
      <strong
        className={styles.heading}>
        {serviceName} {isPackage ? (
          <span className={styles.packages}> ({serviceOfPackage} services) </span>
        ) : (
          <>
            &nbsp;•&nbsp;{variantName}
          </>
        )}
        <span className={hasPriceAdjustment ? styles.old_cost : ''}>&nbsp;${formatPrice(displayedPrice)}</span>
        {hasPriceAdjustment && (
          <span className={styles.price_adjustment}>
            ${formatPrice(priceAdjustment)}
          </span>
        )}
      </strong>
      <p>{displayedName}&nbsp;•&nbsp;
        <time>{displayedTime}</time>
        &nbsp;•&nbsp;{displayedDuration}
      </p>
      {
        serviceOfPackage > 0 && (
          <ul>
            {
              listServicePackage.map((item: any, index) => {
                const value = getHighestPriceOption(item.pricingOptions)
                const timeDuration = value?.duration ? moment.duration(value.duration).asHours() : 0
                const timeExtraTime = value?.addExtraTime ? moment.duration(value.addExtraTime).asHours() : 0
                const timeString = convertHours(timeDuration + timeExtraTime)
                const duration = convertDurationString(timeString)

                return <li key={index}>{value && `${value.variantName} • `}{duration}</li>
              })
            }
          </ul>
        )
      }
    </div>
  );
};