import * as yup from "yup";
import { validationModules } from "./yupObjectValidation";

const profileSettings = {
  firstName: validationModules.defaultStringRule,
  lastName: validationModules.defaultStringRule,
  day: validationModules.dayRule,
  month: validationModules.monthRule,
  year: validationModules.yearRule,
};

const profileSettingsSchema = yup.object().shape(profileSettings);

export default profileSettingsSchema;
