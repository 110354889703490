import React from "react";
import { Avatar } from "@material-ui/core";

import defaultLogo from "~/Assets/images/logo-visions.svg";
import type { BookingHeadingBoxProps } from "~/Containers/MyBookingsPage/interfaces";

import styles from "./BookingHeadingBoxStyle.module.scss";

export const BookingHeadingBox: React.FC<BookingHeadingBoxProps> = (props) => {
  const { id, logo, heading, address, status, type } = props;
  const displayedStatus = status[0].toUpperCase() + status.slice(1);
  let statusBooking = displayedStatus;

  if (displayedStatus === 'Rescheduled' && type === 'online') {
    statusBooking = 'Confirmed'
  } else if (displayedStatus === 'Rescheduled' && type === 'walk-in') {
    statusBooking = 'Open'
  }

  return (
    <div className={styles.heading_box}>
      <Avatar
        className={styles.logo}
        alt=""
        src={logo || defaultLogo}
      />
      <div className={styles.name_address}>
        <h4>{heading}</h4>
        <address>{address}</address>
        <div>{`Booking number: #${id}`}</div>
      </div>
      <div className={styles.status_box}>
        <span className={`${"status"} ${statusBooking.toLocaleLowerCase()} ${styles.status}`}>{statusBooking}</span>
      </div>
    </div>
  );
};