import React from "react";
import type { IntroProps } from "~/Components/Intro/interfaces";

import styles from "./HeadingTextStyle.module.scss";

export const HeadingText: React.FC<IntroProps> = ({ heading, text }) => {
  return (
    <div className={styles.block_heading}>
      <h1>{heading}</h1>
      <p>{text}</p>
    </div>
  )
}