import React, { useState } from "react";
import Slider from "react-slick";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ShareOutlinedIcon from "@material-ui/icons/ShareOutlined";
import { FacebookShareButton} from "react-share";
import { useParams } from "react-router-dom";
import { Tooltip } from "@material-ui/core"

import { useAppSelector } from "~/reduxConfig";
import { venueSelector } from "~/store/selectors";
import type { VenueReducerState } from "~/store/reducers/interfaces/venueInterfaces";
import { CheckMobileHook } from "~/Components/MainHeader/CheckMobileHook";

import styles from "./HeroSliderStyles.module.scss";
import { REACT_APP_SHARE_HOST } from "~/constants";

interface IUseParam {
  id: string;
}
const settings = {
  dots: true,
  speed: 300,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
};

export const HeroSlider: React.FC = () => {
  const { id }: IUseParam = useParams()
  const slider = React.useRef(null);
  const { venue } = useAppSelector<VenueReducerState>(venueSelector);
  const { venueImages, locationName } = venue;
  const { isMobile } = CheckMobileHook();
  const [showTooltip, setShowTooltip] = useState(false)

  const onClickShareBtn = () => {
    document.getElementById("facebook-share").click()
  }

  const onClickCopyLink = () => {
    let textField = document.createElement('textarea')
    textField.innerText = `${REACT_APP_SHARE_HOST}/share-venue/venue/${id}`
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
    setShowTooltip(true)
  }

  const onClosedTooltip = () => {
    setShowTooltip(false)
  }

  return (
    <div className={styles.container}>
      <Slider
        {...settings}
        ref={slider}
      >
        {venueImages.length > 0
          ? venueImages.map(image => <div className={styles.venueImages} key={image.id}><img src={image?.imageUrl} alt="" /></div>)
          : [<div className={styles.image_placeholder} key={"imagePlaceholder"}><h1>No images</h1></div>]
        }
      </Slider>
      <div className={styles.action_buttons}>
        <div className={styles.share_button}>
          <ShareOutlinedIcon fontSize={"small"} /> Share
          <div className={styles.modal_share_button}>
            <div className={styles.modal_share_button_content}>
              <button onClick={onClickShareBtn} className={styles.modal_share_btn}>Share Facebook</button>
              <Tooltip
                open={showTooltip}
                title={"Copied link!"}
                leaveDelay={300}
                onClose={onClosedTooltip}>
                <button onClick={onClickCopyLink} className={styles.modal_share_btn}>Copy Link</button>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>

      <div className={`${styles.action_buttons} ${styles.action_buttons_2}`}>
        <FacebookShareButton
          url={`${REACT_APP_SHARE_HOST}/share-venue/venue/${id}`}
          quote={`${locationName} Glamezy venue`}
          hashtag={"#glamezy"}
          className={styles.share_button}
          id="facebook-share"
        >
          Share
        </FacebookShareButton>
      </div>

      {!isMobile && venueImages.length > 0 &&
        <div className={styles.slider_buttons}>
          <button
            onClick={slider.current?.slickPrev}>
            <ArrowBackIosIcon fontSize={"small"} />
          </button>
          <button
            onClick={slider.current?.slickNext}>
            <ArrowForwardIosIcon fontSize={"small"} />
          </button>
        </div>
      }
    </div>
  );
};