import React from 'react';
import { VenueCard } from '~/Components/VenueCard';
import styles from './OtherVenuesStyles.module.scss';
import type { OtherVenuesProps } from './interfaces';
import type { AvailableCardVenuesEntity } from '~/store/reducers/interfaces/mainInterfaces';

export const OtherVenues: React.FC<OtherVenuesProps> = ({ otherVenues }) => {
  return (
    <>
      <div className={styles.title}>
        <h2>Other venues</h2>
      </div>

      <div className={styles.venues_list}>
        {otherVenues?.length ? (
          otherVenues?.map((venue: AvailableCardVenuesEntity) => (
            <div key={venue.id} className={styles.venues_item}>
              <VenueCard isHome={true} venue={venue} />
            </div>
          ))
        ) : (
          <h3 style={{ color: '#aaa' }}>
            Glamezy is coming soon to your city!
          </h3>
        )}
      </div>
    </>
  );
};
