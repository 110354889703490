import React, { useEffect } from "react";
import { Controller, useWatch } from "react-hook-form";
import moment from "moment-timezone";
import { useSelector } from "react-redux";
import {
  FormLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Select,
  MenuItem,
  ListSubheader
} from "@material-ui/core";

import { Message } from "~/Components/Message";
import { TimeRadioPickerHook } from "~/Components/TimeRadioPicker/TimeRadioPickerHook";
import { HourFormLabel } from "~/Containers/BookingFlowPage/components/HourFormLabel";
import { TimeRadioPickerProps } from "~/Components/TimeRadioPicker/interfaces";

import styles from "./TimeRadioPickerStyle.module.scss";
import { venueSelector, bookingSelector, locationSelector } from "../../store/selectors";

export const TimeRadioPicker: React.FC<TimeRadioPickerProps> = (props) => {
  const { venue } = useSelector(venueSelector)
  const { staff } = useSelector(bookingSelector)
  const { timezone } = useSelector(locationSelector)
  const {
    fieldName,
    control,
    trigger,
    startWorkingHour,
    startHourSelectService,
    timeSelectedServices,
    workingMorningHours,
    workingAfternoonHours,
    workingEveningHours,
  } = TimeRadioPickerHook(props);
  const {
    onChangeTimeError,
    timeError,
    formMethods: { setValue },
    timeStartEnd,
    timeSelect,
    isReschedule,
  } = props;
  const { date: daySelect, time: timeSelected, staffId } = useWatch({ control });

  useEffect(() => {
    if (
      startWorkingHour &&
      startHourSelectService &&
      startWorkingHour < startHourSelectService
    ) {
      setValue("time", timeSelectedServices);
      return;
    }

    const firstHourSelect =
      timeStartEnd[timeSelect[0]] != null &&
      timeStartEnd[timeSelect[0]].slice(0, 5);
    if (
      firstHourSelect &&
      moment(`${daySelect} ${firstHourSelect}`) > moment(`${daySelect} ${timeSelected}`)
    ) {
      setValue("time", firstHourSelect);
      return;
    }
    if (timeSelected && timeSelected !== "unspecified" && firstHourSelect) {
      setValue("time", timeSelected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [daySelect]);

  const renderContent = (value, onChange) => (
    !isReschedule ? (
      <RadioGroup
        className="aria-label"
        aria-label="timeMorning"
        name="time"
        value={value}
        onChange={(event, val) => {
          if (daySelect) {
            const newTime = moment(`${daySelect} ${val}`);
            onChangeTimeError(newTime < moment() ? "That time is in the past, please select a valid time for your appointment" : "");
          }

          onChange(event);
          trigger(fieldName);
        }}
      >
        <FormControlLabel
          disabled
          value="unspecified"
          control={<Radio />}
          label=""
          className={styles.not_specified}
        />
        {
          workingMorningHours?.length > 0 && (
            < FormLabel
              className="legend"
              component="legend"
            >
              <span>Morning</span>
            </FormLabel>
          )}
        {
          workingMorningHours?.map((time) => (
            <HourFormLabel
              key={time.hour}
              time={time}
              disabled={moment.tz(`${daySelect || moment.tz(moment(), venue.timezone || timezone).format("YYYY-MM-DD")} ${time.hour}`, venue.timezone || timezone) < moment.tz(moment(), venue.timezone || timezone)}
            />
          ))
        }
        {
          workingAfternoonHours?.length > 0 && (
            < FormLabel
              className="legend"
              component="legend"
            >
              <span>Afternoon</span>
            </FormLabel>
          )}
        {
          workingAfternoonHours?.map((time) => (
            <HourFormLabel
              key={time.hour}
              time={time}
              disabled={moment.tz(`${daySelect || moment.tz(moment(), venue.timezone || timezone).format("YYYY-MM-DD")} ${time.hour}`, venue.timezone || timezone) < moment.tz(moment(), venue.timezone || timezone)}
            />
          ))
        }
        {
          workingEveningHours?.length > 0 && (
            < FormLabel
              className="legend"
              component="legend"
            >
              <span>Evening</span>
            </FormLabel>
          )}
        {
          workingEveningHours?.map((time) => (
            <HourFormLabel
              key={time.hour}
              time={time}
              disabled={moment.tz(`${daySelect || moment.tz(moment(), venue.timezone || timezone).format("YYYY-MM-DD")} ${time.hour}`, venue.timezone || timezone) < moment.tz(moment(), venue.timezone || timezone)}
            />
          ))
        }
      </RadioGroup>
    ) : (
      <Select
        className={styles.selectTimePicker}
        value={value}
        onChange={onChange}
        displayEmpty
        MenuProps={{
          className: styles.menu,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
      >
        <MenuItem className={styles.disabled} value='' disabled>
          Choose time
        </MenuItem>
        {
          workingMorningHours?.length > 0 && (
            <ListSubheader className={styles.ListSubheader} value={workingMorningHours[0].hour}><span>Morning</span></ListSubheader>
          )
        }
        {
          workingMorningHours?.length > 0 && (
            workingMorningHours.map((item, index) => (
              <MenuItem
                key={index}
                className={styles.MenuItem}
                value={item.hour}
                disabled={moment.tz(`${moment.tz(moment(daySelect || moment()), timezone).format("YYYY-MM-DD")} ${item.hour}`, timezone) < moment.tz(moment(), timezone)}
              >
                {item.hourAm}
              </MenuItem>
            ))
          )
        }
        {
          workingAfternoonHours?.length > 0 && (
            <ListSubheader className={styles.ListSubheader} value={workingAfternoonHours[0].hour}><span>Afternoon</span></ListSubheader>
          )
        }
        {
          workingAfternoonHours?.length > 0 && (
            workingAfternoonHours.map((item, index) => (
              <MenuItem
                key={index}
                className={styles.MenuItem}
                value={item.hour}
                disabled={moment.tz(`${moment.tz(moment(daySelect || moment()), timezone).format("YYYY-MM-DD")} ${item.hour}`, timezone) < moment.tz(moment(), timezone)}
              >
                {item.hourAm}
              </MenuItem>
            ))
          )
        }
        {
          workingEveningHours?.length > 0 && (
            <ListSubheader className={styles.ListSubheader} value={workingEveningHours[0].hour}><span>Evening</span></ListSubheader>
          )
        }
        {
          workingEveningHours?.length > 0 && (
            workingEveningHours.map((item, index) => (
              <MenuItem
                key={index}
                className={styles.MenuItem}
                value={item.hour}
                disabled={moment.tz(`${moment.tz(moment(daySelect || moment()), timezone).format("YYYY-MM-DD")} ${item.hour}`, timezone) < moment.tz(moment(), timezone)}
              >
                {item.hourAm}
              </MenuItem>
            ))
          )
        }
      </Select>
    )
  )

  return (
    <Controller
      control={control}
      name={fieldName}
      defaultValue={"unspecified"}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <FormControl className={!isReschedule ? "fieldset-radio" : "select-formControl"} component="fieldset">
          {(workingMorningHours?.length > 0 || workingAfternoonHours?.length > 0 || workingEveningHours?.length > 0) && !staff.closeDateEnabled ? (
            renderContent(value, onChange)
          ) : staff.closeDateEnabled ? (
            <div className={isReschedule ? styles.wrapperTimeSelect : ''}>Closed</div>
          ) : workingMorningHours?.length === 0 && workingAfternoonHours?.length === 0 && workingEveningHours?.length === 0 && staffId === 0 ? (
            <div className={isReschedule ? styles.wrapperTimeSelect : ''}>All staff is unavailable</div>
          ) : (
            <div className={isReschedule ? styles.wrapperTimeSelect : ''}>Staff is unavailable on this date</div>
          )}
          <Message msg={error?.message || timeError} />
        </FormControl>
      )}
    />
  );
};
