import React, { useState } from 'react';
import { Box, ClickAwayListener } from '@material-ui/core';
import { AvailableCardVenuesEntity } from '~/store/reducers/interfaces/mainInterfaces';

import marker from '../../../Assets/images/marker_map.svg';
import { VenueCard } from '~/Components/VenueCard';
import './Marker.css';
import { CheckMobileHook } from '~/Components/MainHeader/CheckMobileHook';

interface Props {
  venue?: AvailableCardVenuesEntity;
  lat: number;
  lng: number;
  isSingleView?: boolean;
  handleSelectSlide?: (value: number) => void;
  index?: number;
}

const Marker: React.FC<Props> = (props) => {
  const { isMobile } = CheckMobileHook()
  const { venue, isSingleView , handleSelectSlide , index } = props;
  const [showCard, setShowCard] = useState(false);

  const onClickMarker = () => {
    setShowCard(true);
  };

  const onClickAway = () => {
    setShowCard(false);
  };

  if (!venue || isSingleView) {
    return (
      <div className='marker-wrap' onClick={onClickMarker}>
        <img src={marker} alt='marker' />
      </div>
    );
  }

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <div
        className="marker-wrap"
        onClick={() => {
          onClickMarker();
          handleSelectSlide(index);
        }}
        style={{ backgroundColor: showCard ? '#8461F3' : '#000' }}
      >
        <img src={marker} alt="marker" />
        {!isMobile && showCard && (
          <Box className="marker-card">
            <VenueCard venue={venue} />
          </Box>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default Marker;
