import authCustomerApi from "../../services/api/authCustomerApi";
import customerAccountApi from "../../services/api/customerAccountApi";
import { bookingFlowPageRoute, flowsLayoutRoute, TOKEN } from "~/constants";
import history from "~/utils/history";
import cookie from "~/utils/cookie";

export const sessionActionTypes = {
  SIGN_UP_ACTION_TYPE: "SIGN_UP_ACTION_TYPE",
  SIGN_IN_ACTION_TYPE: "SIGN_IN_ACTION_TYPE",
  UPDATE_NAME_ACTION_TYPE: "UPDATE_NAME_ACTION_TYPE",
  LOG_OUT_ACTION_TYPE: "LOG_OUT_ACTION_TYPE",
  SET_MESSAGE_ACTION_TYPE: "SET_MESSAGE_ACTION_TYPE",
  CLEAR_MESSAGE_ACTION_TYPE: "CLEAR_MESSAGE_ACTION_TYPE",
};

export const accssesAccountAction = () => {
  return async (dispatch) => {
    try {
      const response = await customerAccountApi.accssesAccount();

      if (response.data.isConfirmed) {
        dispatch({
          type: sessionActionTypes.SIGN_IN_ACTION_TYPE,
          payload: response.data,
        });
      } else {
        cookie.removeCookie(TOKEN);
        dispatch({
          type: sessionActionTypes.LOG_OUT_ACTION_TYPE,
        });
      }
    } catch (error: any) {
      console.log("error", error);
    }
  };
};

export const signUpAction = (data) => {
  return async (dispatch) => {
    try {
      const response = await authCustomerApi.signUp(data);

      if (response?.status === 201) {
        cookie.setCookie(TOKEN, response.data.token.accessToken, response.data.token.expiresIn / 60);
        dispatch({
          type: sessionActionTypes.SIGN_UP_ACTION_TYPE,
          payload: {
            firstName: response.data.firstName,
            lastName: response.data.lastName,
          },
        });
        if (window.venue.venue_id) {
          history.push(flowsLayoutRoute.concat(bookingFlowPageRoute, `/${window.venue.venue_id}`),
            window.venue.type === "bookService" ? { service: window.venue.service } : window.venue.staff)
          window.venue = {}
        }
      } else {
        throw new Error(response.data?.message);
      }
    } catch (error: any) {
      dispatch({
        type: sessionActionTypes.SET_MESSAGE_ACTION_TYPE,
        payload: error.message,
      });
    }
  };
};

export const onChangeInformation = (data, callback) => {
  return async (dispatch) => {
    try {
      const response = await authCustomerApi.changeInformation(data);

      if (response?.status === 200) {
        callback();
      } else {
        if (typeof response.data?.message === "string") {
          throw new Error(response.data?.message);
        } else {
          throw new Error(response.data?.message[0]);
        }
      }
    } catch (error: any) {
      dispatch({
        type: sessionActionTypes.SET_MESSAGE_ACTION_TYPE,
        payload: error.message,
      });
    }
  };
}

export const additionalInfoAction = (data, callback) => {
  return async (dispatch) => {
    try {
      const response = await authCustomerApi.additionalInfo(data);

      if (response.status === 200) {
        callback();
      } else {
        throw new Error(response.data?.message);
      }
    } catch (error: any) {
      dispatch({
        type: sessionActionTypes.SET_MESSAGE_ACTION_TYPE,
        payload: error.message,
      });
    }
  };
};

export const sendOptAction = (data, callback) => {
  return async (dispatch) => {
    try {
      const response = await authCustomerApi.sendOpt(data);

      if (response.status === 200) {
        if (callback) {
          callback();
        }
      } else {
        throw new Error(response.data?.message);
      }
    } catch (error: any) {
      dispatch({
        type: sessionActionTypes.SET_MESSAGE_ACTION_TYPE,
        payload: error.message,
      });
    }
  };
};

export const confirmOptAction = (data, callback) => {
  return async (dispatch) => {
    try {
      const response = await authCustomerApi.confirmOpt(data);

      if (response.status === 200) {
        
        callback();
      } else {
        throw new Error(response.data?.message);
      }
    } catch (error: any) {
      dispatch({
        type: sessionActionTypes.SET_MESSAGE_ACTION_TYPE,
        payload: error.message,
      });
    }
  };
};

export const signUpCodeVerificationAction = (data) => {
  return async (dispatch) => {
    try {
      const response = await authCustomerApi.verification(data);

      if (response.status === 200) {
        dispatch({
          type: sessionActionTypes.SIGN_UP_ACTION_TYPE,
          payload: {
            firstName: response.data.firstName,
            lastName: response.data.lastName,
          },
        });
        cookie.setCookie(TOKEN, response.data.accessToken, response.data.expiresIn / 60);
        if (window.venue.venue_id) {
          history.push(flowsLayoutRoute.concat(bookingFlowPageRoute, `/${window.venue.venue_id}`),
            window.venue.type === "bookService" ? { service: window.venue.service } : window.venue.staff)
          window.venue = {}
        }
      } else {
        throw new Error(response.data?.message);
      }
    } catch (error: any) {
      dispatch({
        type: sessionActionTypes.SET_MESSAGE_ACTION_TYPE,
        payload: error.message,
      });
    }
  };
};

export const signInFacebookAction = (data, callback) => {
  return async (dispatch) => {
    try {
      const response = await authCustomerApi.signInFacebook(data);
      if (response.status === 400) {
        throw new Error(response.data?.message);
      }

      if (response?.status === 200) {
        cookie.setCookie(TOKEN, response?.data?.accessToken, response?.data?.expiresIn / 60);
        if (response?.data?.isConfirmed) {
          dispatch({
            type: sessionActionTypes.SIGN_IN_ACTION_TYPE,
            payload: response?.data,
          });
          if (window.venue.venue_id) {
            history.push(flowsLayoutRoute.concat(bookingFlowPageRoute, `/${window.venue.venue_id}`),
              window.venue.type === "bookService" ? { service: window.venue.service } : window.venue.staff)
            window.venue = {}
          }
        } else {
          callback(response?.data?.phone, response?.data?.firstName, response?.data?.lastName);
        }
      }
    } catch (error) {
      console.log('Error', error)
    }
  }
}

export const signInAction = (data, callback) => {
  return async (dispatch) => {
    try {
      const response = await authCustomerApi.signIn(data);

      if (response.data.statusCode === 401) {
        throw new Error(response.data?.message);
      }

      if (response.data.statusCode === 400) {
        throw new Error(response.data?.message);
      }

      const { accessToken, expiresIn, ...user } = response.data;
      cookie.setCookie(TOKEN, accessToken, expiresIn / 60);

      if (user.isConfirmed) {
        dispatch({
          type: sessionActionTypes.SIGN_IN_ACTION_TYPE,
          payload: user,
        });
        if (window.venue.venue_id) {
          history.push(flowsLayoutRoute.concat(bookingFlowPageRoute, `/${window.venue.venue_id}`),
            window.venue.type === "bookService" ? { service: window.venue.service } : window.venue.staff)
          window.venue = {}
        }
      } else {
        callback(user.phone, user.firstName, user.lastName);
      }
    } catch (error: any) {
      dispatch({
        type: sessionActionTypes.SET_MESSAGE_ACTION_TYPE,
        payload: error.message,
      });
    }
  };
};

export const forgotPasswordAction = (data, callback) => {
  return async (dispatch) => {
    try {
      await authCustomerApi.forgotPassword(data);
      callback();
    } catch (error: any) {
      dispatch({
        type: sessionActionTypes.SET_MESSAGE_ACTION_TYPE,
        payload: error.message,
      });
    }
  };
};

export const forgotConfirmationAction = (data, callback) => {
  return async (dispatch) => {
    try {
      const response = await authCustomerApi.confirmation(data);

      if (response.status === 200) {
        callback();
      } else {
        throw new Error(response.data?.message);
      }
    } catch (error: any) {
      dispatch({
        type: sessionActionTypes.SET_MESSAGE_ACTION_TYPE,
        payload: error.message,
      });
    }
  };
};

export const resetPasswordAction = (data, callback) => {
  return async (dispatch) => {
    try {
      const response = await authCustomerApi.resetPassword(data);

      if (response.data.statusCode === 400) {
        if (typeof response.data?.message === "string") {
          throw new Error(response.data?.message);
        } else {
          throw new Error(response.data?.message[0]);
        }
      }
      callback();
    } catch (error: any) {
      dispatch({
        type: sessionActionTypes.SET_MESSAGE_ACTION_TYPE,
        payload: error.message,
      });
    }
  };
};

export const logOutAction = () => {
  return async (dispatch) => {
    cookie.removeCookie(TOKEN);
    dispatch({
      type: sessionActionTypes.LOG_OUT_ACTION_TYPE,
    });
  }
};

export const clearMessageAction = () => ({
  type: sessionActionTypes.CLEAR_MESSAGE_ACTION_TYPE,
});
