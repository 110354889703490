import { useState, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { searchSelector } from "~/store/selectors";
import { useAppSelector } from "~/reduxConfig";
import { CheckMobileHook } from "~/Components/MainHeader/CheckMobileHook";

const setDefaultValues = (categoriesIds) => {
  if (categoriesIds && typeof categoriesIds === "object") {
    return [...categoriesIds];
  }
  return typeof categoriesIds === "string" ? [categoriesIds] : [];
};

export const CategoryAutocompleteHook = (searchFields) => {
  const searchParams = setDefaultValues(searchFields.categoriesIds);

  const { setValue, getValues } = useFormContext();

  const { categories } = useAppSelector(searchSelector);

  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [values, setValues] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [searchCategories, setSearchCategories] = useState(searchParams);
  const [checkedAll, setCheckedAll] = useState(true);
  const [isApply, setApply] = useState(false);
  const [focus, setFocus] = useState(false);
  const { isMobile } = CheckMobileHook();
  const [searchTagId, setSearchTagId] = useState("");
  const stringSelectCategories = selectedCategories?.map((item) => item.id).join()
  const stringCategoriesIds = typeof getValues('categoriesIds') === 'string' ? getValues('categoriesIds') : getValues('categoriesIds')?.join()

  useEffect(() => {
    if (searchCategories.length > 0 && searchParams.length === 0) {
      setSearchCategories(searchParams);
    }
  }, [searchParams, searchCategories]);

  useEffect(() => {
    if (searchFields.categoriesIds) {
      setSearchCategories(setDefaultValues(searchFields.categoriesIds));
    }
  }, [searchFields]);

  useEffect(() => {
    const checkedIds = selectedCategories.filter(({ id }) => id === 0);

    if (!searchCategories.length) {
      if (!checkedIds.length) {
        return setValues([]);
      }
    }

    if (checkedIds.length) {
      setValues(checkedIds);
    } else {
      const checkedCategories = categories.filter((category) => category.description.some(({ id }) => searchCategories.includes(String(id))));
      setValues(checkedCategories);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchCategories, categories]);

  useEffect(() => {
    const checkAll = values.filter((category) => category.id === 0);
    if (checkAll.length && values.length > 1 && values[0]?.id === 0) {
      const newData = values.filter((category) => category.id !== 0);
      setSelectedCategories(newData);
      setValues(newData);
    } else if (checkAll.length && values.length && values[0]?.id !== 0) {
      setSelectedCategories(checkAll);
    } else {
      setSelectedCategories(values);
      setCheckedAll(true);
    }
  }, [values]);

  const onChangeHandler = () => {
    !isMobile && setIsPickerOpen(prev => !prev);
    isMobile && setApply(true);

    setFocus(true);
    if (selectedCategories[0]?.id === 0) {
      setCheckedAll(false);
    }
  };

  const onClearHandler = () => {
    setSelectedCategories([]);
    setValues([]);
    !isMobile && setIsPickerOpen(prev => !prev);
    if (isMobile) {
      if (stringCategoriesIds) {
        setValue("categoriesIds", [])
      }
    }
  };

  useEffect(() => {
    if (searchFields?.search !== searchTagId) {
      setSearchTagId(searchFields?.search)
      return
    }
    if (isPickerOpen) return;
    if (!isApply && isMobile) return;
    if (!stringCategoriesIds && !stringSelectCategories) return;
    if (isMobile && !stringCategoriesIds && !stringSelectCategories) return;

    const checkedIds = selectedCategories.some(({ id }) => id === 0);

    if (isApply && isMobile) {
      setApply(false)
    }

    if (checkedIds) {
      return setValue("categoriesIds", []);
    }

    const checkedSelectedIds = selectedCategories.map((category) => category.description.map(({ id }) => id)).flat();
    setValue("categoriesIds", checkedSelectedIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValue, isApply, isPickerOpen, stringSelectCategories, stringCategoriesIds]);

  return {
    focus,
    checkedAll,
    categories,
    isPickerOpen,
    selectedCategories,
    setValues,
    onClearHandler,
    onChangeHandler,
    setIsPickerOpen,
    stringSelectCategories,
  };
};
