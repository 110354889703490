import React from 'react';
import { FormProvider } from 'react-hook-form';
import { RouteComponentProps, useParams, useLocation, useHistory } from 'react-router-dom';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ClearIcon from '@material-ui/icons/Clear';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Box, Button, IconButton } from '@material-ui/core';

import PaymentFlowPageHook from './PaymentFlowPageHook';
import DialogMain from '~/Components/DialogMain/DialogMain';
import { HeadingText } from '~/Components/HeadingText/HeadingText';
import { PaymentMethods } from '~/Containers/PaymentFlowPage/components/PaymentMethods';
import { Note } from '~/Containers/PaymentFlowPage/components/Note';
import { NoteAndPolicy } from '~/Containers/PaymentFlowPage/components/NoteAndPolicy';
import { BookingInfo } from './components/BookingInfo';
import { SaveNewCard } from './components/PaymentMethods/StripePayement/SaveNewCard';

import styles from './PaymentFlowPageStyle.module.scss';
import { CheckMobileHook } from '~/Components/MainHeader/CheckMobileHook';
import type { BookingProps } from './interfaces';
import { Message } from '~/Components/Message';

export const PaymentFlowPage: React.FC<RouteComponentProps> = () => {
  const { isMobile } = CheckMobileHook();
  const history = useHistory();
  const params: { id: string } = useParams();
  const location: { state: BookingProps } = useLocation();

  const handleBack = () => {
    history.goBack();
  };

  const {
    venue,
    control,
    message,
    expanded,
    cardForm,
    sortedCards,
    addCardModal,
    defaultCardId,
    isLoadingBooking,
    isShowModalDiscount,
    countBookingPrice,
    discountCode,
    error,
    handleChange,
    onHideMessage,
    setAddCardModal,
    onSubmitAddCard,
    onSubmitConfirmBooking,
    onChangeLoadingBooking,
    onShowModalDiscount,
    onCloseModalDiscount,
    onFocusDiscount,
    onSubmitUseDiscount,
    onChangeValueDiscount,
    onClearDiscount,
  } = PaymentFlowPageHook(params, location);

  const onCloseAddCardModal = () => {
    setAddCardModal(false);
  };

  const renderContentDiscount = () => {
    return (
      <Box className={styles.wrapper_content_discount}>
        <Box className={styles.wrapper_input}>
          <Box className={styles.title_input}>
            Enter a discount code
          </Box>
          <Box className={styles.content_input}>
            <input
              type="text"
              value={discountCode}
              onChange={onChangeValueDiscount}
              onFocus={onFocusDiscount}
            />
          </Box>
        </Box>
        {
          error?.errorDiscount && <Message msg={error?.errorDiscount} />
        }
      </Box>
    )
  }

  return (
    <>
      {addCardModal && (
        <DialogMain
          showModal={addCardModal}
          setShowModal={setAddCardModal}
          onSubmit={onSubmitAddCard}
          openDialogButtonClass='white-btn'
          titleDialog='Add card'
          submitButtonText='Save card'
          dialogWindowClass={'modal-dialog-medium'}
        >
          <SaveNewCard onClose={onCloseAddCardModal} showRememberCard />
        </DialogMain>
      )}

      {isShowModalDiscount && (
        <DialogMain
          showModal={isShowModalDiscount}
          setShowModal={null}
          onSubmit={onSubmitUseDiscount}
          onClose={onCloseModalDiscount}
          openDialogButtonClass='white-btn'
          titleDialog='Use a discount'
          submitButtonText='Use discount'
        >
          {renderContentDiscount()}
        </DialogMain>
      )}

      {isMobile ? (
        <div className={styles.header_booking_mobile}>
          <div>Add payment</div>
          <div className={styles.header_icon} onClick={handleBack}>
            <ArrowBackIosIcon />
          </div>
        </div>
      ) : (
        <HeadingText
          heading="Review and confirmation"
          text="Select payment method and review your booking"
        />
      )}

      <section className={styles.customer_columns}>
        <div className={styles.col}>
          <div className={`${styles.column_block} ${styles.column_left}`}>
            <h2>Payment Confirmation</h2>
            <div className={styles.wrapper_text_column_left}>
              <p className={styles.text_column_left}>
                Almost there! Your card will not charged today. Your payment will be processed
                on the day of your appointment. Please provide a valid credit or debit card to secure your booking
              </p>
            </div>
            <FormProvider {...cardForm}>
              <PaymentMethods
                onHideMessage={onHideMessage}
                cards={sortedCards}
                defaultCardId={defaultCardId}
                setAddCardModal={setAddCardModal}
                expanded={expanded}
                handleChange={handleChange}
                onChangeLoadingBooking={onChangeLoadingBooking}
              />
              <hr />

              <Box className={styles.wrapper_discount_code} position="relative">
                <Button
                  onClick={onShowModalDiscount}
                  className={styles.title_discount_code}
                  disableRipple
                >
                  {
                    countBookingPrice?.discount?.type === "CURRENCY"
                      ? (
                        <span>
                          Discount used{" "}•{" "}
                          <span className={styles.title_discount_code_purple}>
                            {`-$${+countBookingPrice?.discount?.value > +countBookingPrice?.discount?.discountCap
                              && +countBookingPrice?.discount?.discountCap > 0
                              ? countBookingPrice?.discount?.discountCap
                              : countBookingPrice?.discount?.value}`}
                          </span>
                        </span>
                      )
                      : countBookingPrice?.discount?.type === "PERCENTAGE"
                        ? (
                          <span>
                            Discount used{" "}•{" "}
                            <span className={styles.title_discount_code_purple}>
                              {`-${countBookingPrice?.discount?.value}%`}
                            </span>
                          </span>
                        ) : "Use discount code"
                  }
                </Button>
                <Box display="flex" alignItems="center" position="absolute" right="20px">
                  {countBookingPrice?.discount ? (
                    <IconButton
                      onClick={onClearDiscount}
                      className={styles.button_clear_discount}
                      disableRipple
                    >
                      <ClearIcon fontSize="inherit" />
                    </IconButton>
                  ) : <ChevronRightIcon onClick={onShowModalDiscount} fontSize="inherit" />}
                </Box>
              </Box>

              {
                error?.errorClearDiscount && (
                  <Box marginTop="8px">
                    <Message msg={error?.errorClearDiscount} />
                  </Box>
                )
              }

              {countBookingPrice?.discount?.discountCap > 0 && (
                <Box className={styles.box_discount_cap}>
                  {`Maximum of $${countBookingPrice?.discount?.discountCap} can be discounted`}
                </Box>
              )
              }

              <Note />
            </FormProvider>
            <NoteAndPolicy venue={venue} />
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.column_block}>
            <BookingInfo
              venue={venue}
              params={params}
              control={control}
              message={message}
              expanded={expanded}
              booking={location.state}
              countBookingPrice={countBookingPrice}
              isLoadingBooking={isLoadingBooking}
              onSubmitConfirmBooking={onSubmitConfirmBooking}
              cards={sortedCards}
            />
          </div>
        </div>
      </section>
    </>
  );
};
