import type { Dispatch } from "redux";

import { searchApi } from "~/services/api/searchApi";
import businessTypeApi from "~/services/api/businessTypeApi";
import { clearSearchPageStore, fetchSearchResult, fetchCategories, searchLoading , handleOpen } from "~/store/reducers/searchReducer";
import type { GetCustomerSearchRequest } from "~/store/actions/interfaces/searchActionsInterfaces";
import type { CategoryProps } from "~/store/reducers/interfaces/searchInterfaces";
import { searchSortBy } from '~/constants'
import { AvailableCardVenuesEntity } from '../reducers/interfaces/mainInterfaces'

const getUniqueCategories = (categories: CategoryProps[]): CategoryProps[] => {
  return Object.values(
    categories.reduce((ac, category) => {
      if (ac.hasOwnProperty(category.code)) {
        ac[category.code].description.push({
          id: category.id,
          description: category.description,
        });
      } else {
        ac[category.code] = {
          id: category.id,
          code: category.code,
          description: [
            {
              id: category.id,
              description: category.description,
            },
          ],
        };
      }

      return ac;
    }, {})
  );
};

function handleSortData(sortBy: string, data: Array<AvailableCardVenuesEntity>) {
  switch (sortBy) {
    case searchSortBy.nearest.value:
      return data.sort((a, b) => +a.distance - +b.distance);
    case searchSortBy.newest.value:
      return data.sort((a, b) => b.id - a.id);
    case searchSortBy.priceHighLow.value:
      return data.sort((a, b) => b.avgServicePrice - a.avgServicePrice);
    case searchSortBy.priceLowHigh.value:
      return data.sort((a, b) => a.avgServicePrice - b.avgServicePrice);
    default:
      return data;
  }
}

export const onLoadSearchResultAction = (params: GetCustomerSearchRequest) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(searchLoading(true));
      const { sortBy, ...body } = params;
      const { data: { amountSearchResults = 0 , searchResult = [] } } = await searchApi.getCustomerSearch(body);
      const newData = handleSortData(sortBy, searchResult);
      dispatch(fetchSearchResult({ amountSearchResults, searchResult: newData }));
    } catch (error: any) {
      console.log("error", error);
    }
    dispatch(searchLoading(false));
  };
};

export const onOpen = (data) => {
  return async (dispatch: Dispatch) => {
    dispatch(handleOpen(data));
  };
};

export const onLoadCategoriesAction = () => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await businessTypeApi.get();
      const categories: CategoryProps[] = getUniqueCategories(response.data);
      dispatch(fetchCategories(categories));
    } catch (error: any) {
      console.log("error", error);
    }
  };
};

export const onClearSearchPageStoreAction = () => (dispatch: Dispatch) => {
  dispatch(clearSearchPageStore())
};