import { sessionActionTypes } from "../actions/sessionActions";
import { TOKEN } from "../../constants";
import cookie from "~/utils/cookie";

const isLoggedIn = cookie.checkCookie(TOKEN);

const initState = {
  isLoggedIn,
  firstName: "",
  lastName: "",
  message: "",
  totalNotSeenNotification: 0,
};

const sessionReducer = (state = initState, action) => {
  switch (action.type) {
    case sessionActionTypes.SIGN_UP_ACTION_TYPE:
      return {
        ...state,
        isLoggedIn: true,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
      }

    case sessionActionTypes.SIGN_IN_ACTION_TYPE:
      return {
        ...state,
        isLoggedIn: true,
        ...action.payload
      };

    case sessionActionTypes.UPDATE_NAME_ACTION_TYPE:
      return {
        ...state,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
      };

    case sessionActionTypes.LOG_OUT_ACTION_TYPE:
      return {
        ...state,
        isLoggedIn: false,
        firstName: "",
        lastName: "",
      };

    case sessionActionTypes.SET_MESSAGE_ACTION_TYPE:
      return {
        ...state,
        message: action.payload,
      };

    case sessionActionTypes.CLEAR_MESSAGE_ACTION_TYPE:
      return {
        ...state,
        message: "",
      };

    default:
      return state;
  }
};

export default sessionReducer;
