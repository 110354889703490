import React, { useCallback, useEffect, useRef } from "react";
import { FormProvider } from "react-hook-form";
import type { RouteComponentProps } from "react-router-dom";

import partnershipImg from "../../Assets/images/partnership-1.png";
import HomePageHook from "./HomePageHook";
import MainVisualSlider from "~/Components/MainVisualSlider/MainVisualSlider";
import { ServiceSlider } from "~/Components/ServiceSlider";
import { Categories } from "~/Components/Categories";
import { VenueSlider } from "~/Components/VenueSlider";
import { Partnership } from "~/Components/Partnership";
import { Subscribe } from "~/Components/Subscribe";
import { OtherVenues } from "~/Components/OtherVenues";
import { partnershipText } from "~/constants";
import { PageSection } from "~/Components/PageSection";
import { MobileSettings } from './components/MobileSettings';
import { Influencer } from './components/Influencer';
import { Marketing } from './components/Marketing';
import { Marketing2 } from './components/Marketing2';

import { CheckMobileHook } from '~/Components/MainHeader/CheckMobileHook';

declare global {
  interface Window {
    fwSettings: any;
  }
}

window.fwSettings = window.fwSettings || {};

export const HomePage: React.FC<RouteComponentProps<any>> = () => {
  const {
    categories,
    otherVenues,
    availableVenues,
    onAvailableVenuesEndReached,
    subscribeForm,
    onSubscribe,
  } = HomePageHook();
  const { isMobile } = CheckMobileHook();

  const myRefInfluencer = useRef(null)
  const myRefNewsLetter = useRef(null)

  const scrollToRef = (ref) => {
    window.scrollTo(0, ref.current.offsetTop - (isMobile ? 100 : 200))
  }

  const executeScroll = (id: string) => {
    if (id === 'influencer') {
      scrollToRef(myRefInfluencer)
    }
    if (id === 'newsLetter') {
      scrollToRef(myRefNewsLetter)
    }
  };


  const removeWidget = () => {
    const freshContainer = document.getElementById("freshworks-container");
    const freshFrame = document.getElementById("freshworks-frame");
    const freshworksScript = document.getElementById("freshworksScript");
    freshContainer && freshContainer.remove();
    freshFrame && freshFrame.remove();
    freshworksScript && freshworksScript.remove();
  };

  const loadWidget = useCallback(() => {
    removeWidget();
    window.fwSettings = { widget_id: 51000001755 };
    const script = document.createElement("script");
    script.id = "freshworksScript";
    script.type = "text/javascript";
    script.src = "https://aus-widget.freshworks.com/widgets/51000001755.js";
    document.body.appendChild(script);
  }, []);

  useEffect(() => {
    loadWidget();
    return removeWidget;
  }, [loadWidget]);

  return (
    <main>
      <MainVisualSlider />
      <ServiceSlider executeScroll={executeScroll} />
      {/* <BannerCovid /> */}
      <PageSection>
        <Categories categories={categories} />
      </PageSection>

      <PageSection>
        <MobileSettings />
      </PageSection>

      <PageSection paddingRight="pr_0">
        <VenueSlider
          title="Available now"
          venues={availableVenues.availableVenues}
          onVenuesEndReached={onAvailableVenuesEndReached}
        />
      </PageSection>

      <PageSection>
        <Partnership
          description={partnershipText}
          imgUrl={partnershipImg}
        />
      </PageSection>

      <PageSection>
        <OtherVenues otherVenues={otherVenues} />
      </PageSection>

      <PageSection myRef={myRefInfluencer}>
        <Influencer />
      </PageSection>

      <PageSection myRef={myRefNewsLetter}>
        <FormProvider {...subscribeForm}>
          <Subscribe onSubscribe={onSubscribe} />
        </FormProvider>
      </PageSection>

      <PageSection isHome isDriverSecond>
        <Marketing />
      </PageSection>

      <PageSection isHome isDriverSecond>
        <Marketing2 />
      </PageSection>
    </main>
  );
};
