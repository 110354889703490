import React from "react";
import { PageSectionProps } from "~/Components/PageSection/interfaces";
import clsx from 'clsx';

export const PageSection: React.FC<PageSectionProps> = ({
  withDivider = true,
  children,
  paddingRight = "",
  isHome,
  isDriverSecond,
  myRef
}) => {
  return (<>
    {withDivider && <hr className={clsx('divider', {
      'driver-second': isDriverSecond
    })} />}
    <section
      ref={myRef}
      className={clsx(`main-container-section ${paddingRight}`, {
        [`main-container-section_card`]: isHome
      })}>
      {children}
    </section>
  </>);
};