import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { AuthorizedHeader } from "~/Components/MainHeader/AuthorizedHeader";
import { UnauthorizedHeader } from "~/Components/MainHeader/UnauthorizedHeader";
// import { Faq } from "~/Containers/GeneralPages/HelpAndFAQPage/Faq";
import { ContactUsPage } from "~/Containers/GeneralPages/ContactUsPage";
import { LegalPage } from "~/Containers/GeneralPages/LegalPage/LegalPage";
import AboutUsPage from "~/Containers/GeneralPages/AboutUsPage";
import {
  contactPageRoute,
  // faqPageRoute,
  infoPageRoute,
  mainLayoutRoute,
  aboutPageRoute
} from "~/constants";
import type { GeneralLayoutProps } from "~/Layouts/GeneralLayout/interfaces";

import styles from "./GeneralPagesStyle.module.scss";

export const GeneralLayout: React.FC<GeneralLayoutProps> = (props) => {
  const { isLoggedIn, match } = props;

  return (
    <>
      {isLoggedIn
        ? <AuthorizedHeader handleClass="static-header" />
        : <UnauthorizedHeader handleClass="static-header" />
      }
      <main>
        <section className={styles.info_section}>

          <Switch>

            <Route
              path={match.path.concat(contactPageRoute)}
              render={() => <ContactUsPage />}
            />

            {/* <Route
              path={match.path.concat(faqPageRoute)}
              render={() => <Faq />}
            /> */}

            <Route
              path={match.path.concat(infoPageRoute)}
              render={() => <LegalPage />}
            />

            <Route
              path={match.path.concat(aboutPageRoute)}
              render={() => <AboutUsPage />}
            />

            <Redirect to={mainLayoutRoute} />

          </Switch>
        </section>
      </main>
    </>
  );
};
