import * as yup from "yup";
import { validationModules } from "./yupObjectValidation";

const subscribe = {
  subscribe: validationModules.email,
};

const subscribeSchema = yup.object().shape(subscribe);

export default subscribeSchema;
