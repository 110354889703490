import React from "react";
import { FormProvider } from "react-hook-form";
import { InputLabel, Grid, Paper, Button } from "@material-ui/core";
import ProfileSettingsHook from "./ProfileSettingsHook";
import { Message } from "~/Components/Message";
import { UploadPhoto } from "../UploadPhoto";
import { InputField } from "../InputField";
import styles from "./ProfileSettingsStyle.module.scss";

export const ProfileSettings: React.FC = () => {
  const profileSettingsHook = ProfileSettingsHook();
  const {
    profileSettingsForm,
    onSubmit,
    message,
    onHideMessage,
    previewImage,
    selectFile,
    currentFile,
    showSubmitBtn,
  } = profileSettingsHook;

  return (
    <div className="card-wrapper">
      <Paper variant="outlined">
        <FormProvider {...profileSettingsForm}>
          <form onSubmit={profileSettingsForm.handleSubmit(onSubmit)}>
            <div className={styles.text}>
              <div className={styles.title_box}>
                <h2 className={styles.title}>Your profile</h2>
              </div>
              <p className={styles.subtitle}>
                You can change your name and photo here
              </p>
            </div>

            <Grid
              container
              justifyContent="flex-start"
              className={styles.form_container}
            >
              <div className={styles.left_box}>
                <UploadPhoto
                  previewImage={previewImage}
                  selectFile={selectFile}
                  currentFile={currentFile}
                  message={message}
                />
              </div>

              <div className={styles.right_box}>
                <Grid
                  container
                  item
                  xs={12}
                  className={`${styles.right_box_info} input-wrapper`}
                >
                  <InputLabel className="label">First name</InputLabel>
                  <InputField name="firstName" onHideMessage={onHideMessage} />
                </Grid>

                <Grid
                  container
                  item
                  xs={12}
                  className={`${styles.right_box_info} input-wrapper`}
                >
                  <InputLabel className="label">Last name</InputLabel>
                  <InputField name="lastName" onHideMessage={onHideMessage} />
                </Grid>

                <Grid
                  container
                  item
                  xs={12}
                  className={`${styles.right_box_info} input-wrapper`}
                >
                  <InputLabel className="label">Date of birth</InputLabel>

                  <div className={styles.birdth}>
                    <div className={styles.date}>
                      <InputField
                        name="day"
                        onHideMessage={onHideMessage}
                        placeholder="DD"
                      />
                    </div>

                    <div className={styles.date}>
                      <InputField
                        name="month"
                        onHideMessage={onHideMessage}
                        placeholder="MM"
                      />
                    </div>

                    <div>
                      <InputField
                        name="year"
                        onHideMessage={onHideMessage}
                        placeholder="YYYY"
                        type="number"
                      />
                    </div>
                  </div>
                </Grid>

                <Message msg={message} />
              </div>
            </Grid>
            {showSubmitBtn && (
              <Button
                variant="outlined"
                color="primary"
                className={`submit-btn-save-changes ${styles.btnSave}`}
                type="submit"
              >
                Save changes
              </Button>
            )}
          </form>
        </FormProvider>
      </Paper>
    </div>
  );
};
