import { isEmpty } from "lodash";

import { SignInForm } from "~/Components/SignInForm";
import { SignUpForm } from "~/Components/SignUpForm";
import { Confirmation } from "~/Components/Confirmation";
import { ForgotPassword } from "~/Components/ForgotPassword";
import { NewPasswordSet } from "~/Components/NewPasswordSet";
import { ModalContent } from "../ModalContent";
import { AdditionalInfoForm } from "~/Components/AdditionalInfoForm";

export const Dialogs = ({
  step,
  steps,
  errors,
  control,
  message,
  showModal,
  setStep,
  onSubmit,
  register,
  setFocus,
  setValue,
  getValues,
  hideMessage,
  onSendAgain,
  clearErrors,
  setShowModal,
  onSendAgainForReset,
  errorMessage,
  signInFacebook,
  signUpState,
  signUpFb,
  signInFb,
  setSignUpFb,
  setSignInFb,
}) => {
  return (
    <>
      {step === steps.signIn && (
        <ModalContent
          showModal={showModal}
          setShowModal={setShowModal}
          setDefaultStep={() => setStep(steps.signIn)}
          setStep={() => setStep(steps.signUp)}
          onSubmit={onSubmit}
          titleDialog="Sign in"
          submitButtonText="Sign in"
          dialogWindowClass={"modal-dialog-medium"}
          redirectTitle="Sign up"
          redirectText="Don't have an account?"
          titleDialogClassName="auth-title"
          childContent={
            <SignInForm
              control={control}
              hideMessage={hideMessage}
              message={message}
              setStep={() => setStep(steps.forgotPassword)}
              onSubmit={onSubmit}
              signInFacebook={signInFacebook}
            />
          }
        />
      )}

      {step === steps.signUp && (
        <ModalContent
          showModal={showModal}
          setShowModal={setShowModal}
          setDefaultStep={() => setStep(steps.signIn)}
          setStep={() => setStep(steps.signIn)}
          onSubmit={onSubmit}
          titleDialog="Sign Up"
          titleDialogClassName="auth-title"
          submitButtonText="Continue"
          dialogWindowClass={"modal-dialog-medium"}
          redirectTitle="Sign in"
          redirectText="Already have an account?"
          childContent={
            <SignUpForm
              control={control}
              getValues={getValues}
              hideMessage={hideMessage}
              message={errorMessage || message}
              signInFacebook={signInFacebook}
            />
          }
        />
      )}

      {step === steps.additionalInfo && (
        <ModalContent
          showModal={showModal}
          setShowModal={setShowModal}
          setDefaultStep={() => setStep(steps.signIn)}
          setPrevStep={() => {
            if (signInFb) {
              setStep(steps.signIn);
              setSignInFb(false);
            }
            if (signUpFb) {
              setStep(steps.signUp);
              setSignUpFb(false);
            }
            if (!isEmpty(signUpState)) {
              setStep(steps.signUp);
            }
            setValue("phone", "");
            setValue("firstName", "");
            setValue("lastName", "");
          }}
          onSubmit={onSubmit}
          titleDialogClassName="auth-title"
          titleDialog="Additional info"
          submitButtonText="Continue"
          dialogWindowClass={"modal-dialog-medium"}
          childContent={
            <AdditionalInfoForm
              control={control}
              hideMessage={hideMessage}
              message={message}
            />
          }
        />
      )}

      {step === steps.changePhoneNumber && (
        <ModalContent
          showModal={showModal}
          setShowModal={setShowModal}
          setDefaultStep={() => setStep(steps.signIn)}
          onSubmit={onSubmit}
          titleDialogClassName="auth-title"
          titleDialog="Additional info"
          submitButtonText="Continue"
          dialogWindowClass={"modal-dialog-medium"}
          childContent={
            <AdditionalInfoForm
              control={control}
              hideMessage={hideMessage}
              message={message}
            />
          }
        />
      )}

      {step === steps.confirmation && (
        <ModalContent
          showModal={showModal}
          setShowModal={setShowModal}
          setDefaultStep={() => setStep(steps.signIn)}
          setPrevStep={() => {
            setStep(steps.additionalInfo);
          }}
          onSubmit={onSubmit}
          titleDialogClassName="auth-title"
          titleDialog="Confirmation Code"
          submitButtonText="Verify"
          dialogWindowClass={"modal-dialog-medium"}
          childContent={
            <Confirmation
              clearErrors={clearErrors}
              setFocus={setFocus}
              register={register}
              setValue={setValue}
              getValues={getValues}
              errors={errors}
              hideMessage={hideMessage}
              message={message}
              onSendAgain={onSendAgain}
              value="phone"
              setStep={setStep}
            />
          }
        />
      )}

      {step === steps.forgotPassword && (
        <ModalContent
          showModal={showModal}
          setShowModal={setShowModal}
          setDefaultStep={() => setStep(steps.signIn)}
          onSubmit={onSubmit}
          titleDialog="Forgot password"
          titleDialogClassName="auth-title"
          submitButtonText="Reset password"
          dialogWindowClass={"modal-dialog-medium"}
          childContent={
            <ForgotPassword
              control={control}
              hideMessage={hideMessage}
              message={errorMessage || message}
            />
          }
        />
      )}

      {step === steps.forgotPasswordCode && (
        <ModalContent
          showModal={showModal}
          setShowModal={setShowModal}
          setDefaultStep={() => setStep(steps.signIn)}
          setPrevStep={() => setStep(steps.forgotPassword)}
          onSubmit={onSubmit}
          titleDialog="Reset password"
          submitButtonText="Reset"
          dialogWindowClass={"modal-dialog-medium"}
          childContent={
            <Confirmation
              clearErrors={clearErrors}
              setFocus={setFocus}
              register={register}
              setValue={setValue}
              getValues={getValues}
              errors={errors}
              hideMessage={hideMessage}
              message={message}
              onSendAgain={onSendAgainForReset}
              value="emailReset"
            />
          }
        />
      )}

      {step === steps.forgotPasswordNewPassword && (
        <ModalContent
          showModal={showModal}
          setShowModal={setShowModal}
          setDefaultStep={() => setStep(steps.signIn)}
          onSubmit={onSubmit}
          titleDialog="Enter new password"
          submitButtonText="Reset password"
          dialogWindowClass={"modal-dialog-medium"}
          childContent={
            <NewPasswordSet
              control={control}
              hideMessage={hideMessage}
              message={message}
            />
          }
        />
      )}
    </>
  );
};
