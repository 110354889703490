import { Drawer } from '@material-ui/core';

function MuiDrawer({ anchor, children, open, handleToggle }) {
  return (
    <Drawer anchor={anchor} open={open} onClose={handleToggle}>
      {children}
    </Drawer>
  );
}

export default MuiDrawer;
