import { NavLink, useLocation } from "react-router-dom";

import logo from "~/Assets/images/logo.svg";
import VisaIcon from "~/Assets/images/footer/visa-icon.svg";
import MastercardIcon from "~/Assets/images/footer/mastercard-icon.svg";
import {
  bookingFlowPageRoute,
  flowsLayoutRoute,
  generalLayoutRoute,
  infoPageRoute,
  PARTNER_LANDING_PAGE,
  successLayoutRoute,
  aboutPageRoute,
} from "~/constants";
import { useAppSelector } from "~/reduxConfig";
import { SearchReducerStateProps } from "~/store/reducers/interfaces/searchInterfaces";
import { searchSelector } from "~/store/selectors";
import { CheckMobileHook } from "../MainHeader/CheckMobileHook";

import styles from "./MainFooterStyle.module.scss";

const NavLinks = [
  { title: "Privacy", state: 0 },
  { title: "Booking terms", state: 2 },
  { title: "Cancellation policy", state: 1 },
];

export const MainFooter = () => {
  const { isOpen } = useAppSelector<SearchReducerStateProps>(searchSelector);
  const location = useLocation();
  const { isMobile } = CheckMobileHook();
  if (location.pathname === flowsLayoutRoute.concat(successLayoutRoute)) {
    return null;
  }
  if (
    location.pathname.includes(flowsLayoutRoute.concat(bookingFlowPageRoute)) &&
    isMobile
  ) {
    return null;
  }

  if (isOpen && isMobile) {
    return null;
  }

  return (
    <footer className={styles.footer}>
      <div className={styles.footer_holder}>
        {!isMobile && (
          <div className={styles.left_section}>
            <a href="/" className={styles.footer_logo}>
              <img src={logo} alt="Glamezy" />
            </a>
            <strong className={styles.title}>We accept:</strong>
            <ul className={styles.item_logo}>
              <li>
                <img src={VisaIcon} alt="visa" />
              </li>
              <li>
                <img src={MastercardIcon} alt="mastercard" />
              </li>
              {/* <li>
                <img src={MaestroIcon} alt="maestro" />
              </li>
              <li>
                <img src={AmericanExpressIcon} alt="american express" />
              </li>
              <li>
                <img src={JCBIcon} alt="JCB" />
              </li>
              <li>
                <img src={UnionIcon} alt="union" />
              </li> */}
            </ul>
            {/* <ul className={`${styles.item_logo} ${styles.item_download}`}>
              <li>
                <a href="/">
                  <img className={styles.logo_download} src={appStore} alt="Download on the App Store" />
                </a>
              </li>
              <li>
                <a href="/">
                  <img src={androidApp} alt="ANDROID APP ON Google play" />
                </a>
              </li>
            </ul> */}
          </div>
        )}
        <div className={styles.right_section}>
          <div className={styles.col}>
            <strong className={styles.heading}>About Glamezy</strong>
            <ul>
              {/* <li>
                <NavLink exact to={generalLayoutRoute.concat(contactPageRoute)}>
                  Contact us
                </NavLink>
              </li> */}
              <li>
                <NavLink to={{ pathname: generalLayoutRoute.concat(aboutPageRoute) }}>About us</NavLink>
              </li>
            </ul>
          </div>
          <div className={styles.col}>
            <strong className={styles.heading}>For Partners</strong>
            <ul>
              <li>
                <a href={PARTNER_LANDING_PAGE} target="_blank" rel="noreferrer">
                  Partner with us
                </a>
              </li>
            </ul>
          </div>
          <div className={styles.col}>
            <strong className={styles.heading}>Follow us</strong>
            <ul className={styles.social_icons}>
              <li>
                <a
                  href="https://www.instagram.com/glamezyau"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <i className="icon-instagram" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/glamezyau"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <i className="icon-facebook" />
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/glamezyau"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <i className="icon-twitter" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/glamezyau"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <i className="icon-linkedin" />
                </a>
              </li>
            </ul>
          </div>
          <div className={styles.col}>
            <strong className={styles.heading}>Get Help</strong>
            <ul>
              <li>
                <a href="mailto:customer@glamezy.com">customer@glamezy.com</a>
              </li>
              <li>
                <a
                  href="https://glamezyau.freshdesk.com/support/solutions"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Customer Help
                </a>
              </li>
              <li>
                <a
                  href="https://glamezyau.freshdesk.com/support/home"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Partner Help
                </a>
              </li>
            </ul>
          </div>

          {isMobile && (
            <div className={styles.col}>
              <strong className={styles.heading}>Legal</strong>
              <ul>
                {NavLinks.map((item) => (
                  <li key={item.title}>
                    <NavLink
                      exact
                      to={{
                        pathname: generalLayoutRoute.concat(infoPageRoute),
                        state: { value: item.state },
                      }}
                    >
                      {item.title}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>

      {!isMobile && (
        <div className={styles.footer_frame}>
          <p>©2022 Glamezy</p>
          <ul>
            {NavLinks.map((item) => (
              <li key={item.title}>
                <NavLink
                  exact
                  to={{
                    pathname: generalLayoutRoute.concat(infoPageRoute),
                    state: { value: item.state },
                  }}
                >
                  {item.title}
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
      )}

      {isMobile && (
        <>
          <div className={styles.left_section}>
            <a href="/" className={styles.footer_logo}>
              <img src={logo} alt="Glamezy" />
            </a>
            <strong className={styles.title}>We accept:</strong>
            <ul className={styles.item_logo}>
              <li>
                <img className={styles.logoCard} src={VisaIcon} alt="visa" />
              </li>
              <li>
                <img
                  className={styles.logoCard}
                  src={MastercardIcon}
                  alt="mastercard"
                />
              </li>
              {/* <li>
                <img
                  className={styles.logoCard}
                  src={MaestroIcon}
                  alt="maestro"
                />
              </li>
              <li>
                <img
                  className={styles.logoCard}
                  src={AmericanExpressIcon}
                  alt="american express"
                />
              </li>
              <li>
                <img className={styles.logoCard} src={JCBIcon} alt="JCB" />
              </li>
              <li>
                <img className={styles.logoCard} src={UnionIcon} alt="union" />
              </li> */}
            </ul>
            {/* <ul className={`${styles.item_logo} ${styles.item_download}`}>
              <li>
                <a href="/">
                  <img className={styles.logo_download} src={appStore} alt="Download on the App Store" />
                </a>
              </li>
              <li>
                <a href="/">
                  <img src={androidApp} alt="ANDROID APP ON Google play" />
                </a>
              </li>
            </ul> */}
          </div>

          <p className={styles.textCopy}>©2022 Glamezy</p>
        </>
      )}
    </footer>
  );
};
