import { Dispatch } from "redux";

import {
  loadCategories,
  loadOtherVenues,
  firstLoadAvailableVenues,
  nextLoadAvailableVenues,
  getPopularTags,
  getBannersRequest,
  getBannersSucceed,
  getBannersFailed
} from "~/store/reducers/mainReducer";
import homePageApi from "~/services/api/homePageApi";
import { getUniqueCategories } from "~/utils/getUniqueCategories";
import type { AvailableVenuesStateProps } from "~/store/reducers/interfaces/mainInterfaces";

import serviceImg1 from "~/Assets/images/serviceSlider/service-img-01.png";
import serviceImg2 from "~/Assets/images/serviceSlider/service-img-02.png";
import serviceImg3 from "~/Assets/images/serviceSlider/service-img-03.png";

export const bannerDefault = [
  {
    link: '/main/search?search=Manicure',
    imageUrl: serviceImg1,
    title: 'Launch Offer:',
    description: 'Win a $100 gift card weekly.',
    buttonName: 'Book Now',
    descriptionPosition: 'left',
  },
  {
    link: '/main/search?sortBy=nearest',
    imageUrl: serviceImg2,
    title: 'Launch Offer:',
    description: 'Get $5 e-gift card for all bookings over $100.',
    buttonName: 'Explore',
    descriptionPosition: 'right',
  },
  {
    link: 'https://www.instagram.com/glamezyau/',
    imageUrl: serviceImg3,
    title: 'Follow Us on Insta',
    description: '',
    buttonName: 'Follow Us',
    descriptionPosition: 'left',
  }
]

const loadAvailableVenues = (reducer, data) => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await homePageApi.getAvailableVenues(data);
      dispatch(reducer(response.data as AvailableVenuesStateProps));
    } catch (error: any) {
      console.log("error", error);
    }
  };
};

export const onLoadCategoriesAction = () => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await homePageApi.categories();
      dispatch(loadCategories(getUniqueCategories(response.data[0])));
    } catch (error: any) {
      console.log("error", error);
    }
  };
};

export const onLoadOtherVenuesAction = (data) => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await homePageApi.otherVenues(data);
      if (response.status === 200) {
        const newData = response.data.otherVenues.sort((a, b) => +a.distance - +b.distance)

        dispatch(loadOtherVenues(newData));
      }
    } catch (error: any) {
      console.log("error", error);
    }
  };
};

export const getListPopularTagAction = () => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await homePageApi.getListPopularTag();
      if (response.status === 200) {
        const newData = response.data;

        dispatch(getPopularTags(newData));
      }
    } catch (error: any) {
      console.log("error", error);
    }
  };
};

export const getBannersAction = () => {
  return async (dispatch: Dispatch) => {
    dispatch(getBannersRequest());
    try {
      const response = await homePageApi.getBannersService();
      if (response.status === 200) {
        const newData = response.data;

        dispatch(getBannersSucceed(newData?.length > 0 ? newData : bannerDefault));
      }
    } catch (error: any) {
      dispatch(getBannersFailed(bannerDefault));
    }
  };
}

export const onFirstLoadAvailableVenuesAction = (data) => loadAvailableVenues(firstLoadAvailableVenues, data);

export const onLoadAvailableVenuesAction = (data) => loadAvailableVenues(nextLoadAvailableVenues, data);