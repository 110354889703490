import React from "react";
import { Card, CardContent, CardHeader } from "@material-ui/core";
import history from "~/utils/history";
import queryString from "query-string";
import clsx from "clsx";
import { useLocation } from "react-router";

import { mainLayoutRoute, venuePageRoute } from "~/constants";
import { useMousePosition } from "~/utils/useMousePosition";
import { addressExtractor } from "~/utils/addressExtractor";
import type { VenueCardProps } from "~/Components/VenueCard/interfaces";

import Flags from "~/Containers/VenuePage/components/Flags/Flags";
import styles from "./VenueCardStyle.module.scss";
import { CheckMobileHook } from "../MainHeader/CheckMobileHook";
import { useAppSelector } from "~/reduxConfig";
import { SearchReducerStateProps } from "~/store/reducers/interfaces/searchInterfaces";
import { locationSelector, searchSelector } from "~/store/selectors";
import { checkTimeBusiness } from "~/utils/helper";

export const VenueCard: React.FC<VenueCardProps> = ({ venue, isHome }) => {
  const {
    id,
    venueImages,
    locationName,
    googleRating,
    googleRatingTotal,
    distance,
    venueBusinessHours = [],
    tags = [],
    flags = [],
    timezone,
    isOffToday,
  } = venue;
  const { pathname }: any = useLocation();
  const { isMobile } = CheckMobileHook();
  const { timezone: currentTimezone } = useAppSelector(locationSelector);
  const { isOpen: isOpenMap } = useAppSelector<SearchReducerStateProps>(searchSelector);

  const locationSearch = queryString.parse(history.location.search);
  const dateTime =
    locationSearch.date || locationSearch.time
      ? {
        ...(locationSearch.date ? { date: locationSearch?.date } : {}),
        ...(locationSearch.time ? { time: locationSearch?.time } : {}),
      }
      : undefined;

  const redirectToVenuePage = () => {
    history.push(mainLayoutRoute.concat(venuePageRoute, `/${id}`), {
      ...(dateTime ? { dateTime } : {}),
    });
  };

  const checkMousePositionHook = useMousePosition(redirectToVenuePage);
  const businessTime = checkTimeBusiness(currentTimezone, timezone, venueBusinessHours[0], isOffToday);

  return (
    <Card
      className={clsx(styles.container, {
        [styles.container_mobile_venue]: pathname.includes("venue"),
        [styles.container_mobile]: isMobile && isOpenMap,
        [styles.container_mobile_home]: isHome && isMobile,
      })}
      {...checkMousePositionHook}
    >
      <CardHeader
        component={() => (
          <div
            className={clsx(styles.image_container, {
              [styles.image_container_mobile]: isMobile && isOpenMap,
              [styles.image_container_mobile_home]: isHome && isMobile,
            })}
          >
            {venueImages.length > 0 ? (
              <img src={venueImages[0]?.imageUrl} alt="" />
            ) : (
              <div className={styles.image_placeholder}>
                <h4>No image</h4>
              </div>
            )}

            {/* Add after realise this functions on back*/}
            {/*<IconButton*/}
            {/*  aria-label="add to favorites"*/}
            {/*  className={styles.favorite_icon}*/}
            {/*>*/}
            {/*  <i className={`${"icon-favourite"} ${styles.icon_favourite}`}>*/}
            {/*    <span className="path1" />*/}
            {/*    <span className="path2" />*/}
            {/*  </i>*/}
            {/*</IconButton>*/}
            { }
            <div
              className={clsx(styles.openTime, {
                [styles.openTimeMobile]: isMobile
              })}
              style={{
                backgroundColor: businessTime ? "#39B18D" : "#000",
              }}
            >
              {businessTime || "Closed"}
            </div>

            <div
              className={clsx(styles.distance, {
                [styles.distance_mobile]: isMobile && isOpenMap,
              })}
            >
              {distance} km
            </div>
          </div>
        )}
      />
      <div
        className={clsx(styles.card_holder, {
          [styles.card_holder_mobile]: isMobile && isOpenMap,
          [styles.card_holder_mobile_home]: isHome && isMobile,
        })}
      >
        <CardContent
          className={clsx(styles.title, {
            [styles.title_mobile]: isMobile && isOpenMap,
          })}
        >
          <h2>{locationName}</h2>
          <span>{addressExtractor(venue)}</span>
        </CardContent>
        <CardContent
          className={clsx(styles.info, {
            [styles.info_mobile]: isMobile && isOpenMap,
          })}
        >
          <Flags flags={flags} />

          <span className={styles.starWrap}>
            <i className={"icon-star-full"} />
            <span>{googleRating || 0}</span>
            <span className={styles.votes}>({googleRatingTotal || 0})</span>
          </span>

          {/* Must be used after rating implementation on back*/}
          {/*  <span>&#183;</span>*/}
          {/*  <span className={styles.price}>$$</span>*/}
        </CardContent>
        <div
          className={clsx(styles.tagsWrap, {
            [styles.tagsWrap_mobile]: isMobile,
          })}
        >
          {tags.map((tag) => (
            <span key={tag.id} className={styles.tag}>
              #{tag.tagName}
            </span>
          ))}
        </div>
      </div>
    </Card>
  );
};
