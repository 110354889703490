import { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { isEmpty } from "lodash";

import { VenueReducerState } from "~/store/reducers/interfaces/venueInterfaces";
import { userLocationSelector, venueSelector } from "~/store/selectors";
import { useAppSelector } from "~/reduxConfig";
import MapWrapper from "~/Components/Map/Map";
import { addressExtractor, getDistance } from "~/utils/addressExtractor";
import styles from "./OurLocation.module.scss";
import { AvailableCardVenuesEntity } from "~/store/reducers/interfaces/mainInterfaces";
import { getGoogleMapLink } from "~/utils/helper";
import { CheckMobileHook } from "~/Components/MainHeader/CheckMobileHook";

export const OurLocation: React.FC = () => {
  const { venue } = useAppSelector<VenueReducerState>(venueSelector);
  const userLocation = useAppSelector(userLocationSelector);
  const [distance, setDistance] = useState<string | number>(0);
  const { isMobile } = CheckMobileHook();

  useEffect(() => {
    if (isEmpty(userLocation) || isEmpty(venue)) {
      return;
    }

    const { lat, lng } = userLocation;
    const { lat: venueLat, lng: venueLng } = venue;
    const newDistance = getDistance(lat, lng, +venueLat, +venueLng);
    setDistance(newDistance);
  }, [userLocation, venue]);

  return (
    <Grid className={styles.grid} container spacing={0}>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        {isMobile ? (
          <div>
            <div className={styles.title_location}>
              <h1 className={styles.title}>Our Location</h1>

              <a
                href={
                  venue.placeId?.length < 50
                    ? `https://www.google.com/maps/place?q=place_id:${venue.placeId}`
                    : `https://www.google.com/maps/place/${venue?.lat},${venue?.lng}`
                }
                className={styles.viewOnMap}
                target="_blank"
                rel="noreferrer noopener"
              >
                View on map
              </a>
            </div>

            <p>{addressExtractor(venue)}</p>
            {distance !== 0 && (
              <p className={styles.distance}>{distance}km from you</p>
            )}
          </div>
        ) : (
        <div>
          <h1 className={styles.title}>Our Location</h1>
          <p>{addressExtractor(venue)}</p>
          {distance !== 0 && (
            <p className={styles.distance}>{distance}km from you</p>
          )}
          <a
            href={getGoogleMapLink(venue)}
            className={styles.viewOnMap}
            target="_blank"
            rel="noreferrer noopener"
          >
            View on map
          </a>
        </div>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <div className={styles.mapWrap}>
          <MapWrapper
            venues={isEmpty(venue) ? [] : [venue as AvailableCardVenuesEntity]}
            isSingleView
          />
        </div>
      </Grid>
    </Grid>
  );
};
