import React, { useState } from "react";
import { Grid, Tab, Tabs } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import history from "~/utils/history";
import defaultLogo from "~/Assets/images/logo-visions.svg";
import { useAppSelector } from "~/reduxConfig";
import { isLoggedInSelector, venueSelector } from "~/store/selectors";
import { HeroSlider } from "~/Containers/VenuePage/components/HeroSlider";
import { addressExtractor } from "~/utils/addressExtractor";
import type { VenueReducerState } from "~/store/reducers/interfaces/venueInterfaces";
import { CheckMobileHook } from "~/Components/MainHeader/CheckMobileHook";
import { TabPanel } from "~/Components/TabPanel";
import { bookingFlowPageRoute, flowsLayoutRoute } from "~/constants";
import { IHeroSection, ITabData, VenuePageLocationStateProps } from "~/Containers/VenuePage/interfaces";
import Flags from '../Flags/Flags'

import useStyles from './styles'
import styles from "./HeroSectionStyles.module.scss";

declare global {
  interface Window {
    venue: any;
  }
}

window.venue = window.venue || {};


export function HeroSection({ TabData, isCheckListService }: IHeroSection) {
  const locationState = history.location.state as VenuePageLocationStateProps;
  const dateTime = locationState?.dateTime;
  const isLoggedIn = useAppSelector(isLoggedInSelector);
  const { venue, staff } = useAppSelector<VenueReducerState>(venueSelector);
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const disableBtn = staff?.venueStaffList?.length === 0 || !isCheckListService

  const {
    logo,
    locationName,
    venueImages,
    googleRating,
    googleRatingTotal,
    flags = [],
  } = venue;
  const { isMobile } = CheckMobileHook();

  const goBack = () => {
    history.goBack();
  };

  const onChangeBookNow = () => {
    if (!isLoggedIn) {
      window.venue = { venue_id: venue.id };
      const element = document.getElementById("header-button-sign-up");
      element.click();
    } else {
      history.push(flowsLayoutRoute.concat(bookingFlowPageRoute, `/${venue.id}`), { ...(dateTime ? { dateTime } : {}) });
    }
  }

  if (!venueImages) return null;

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const viewMobile = () => {
    return (
      <div className={styles.info_block}>
        <div className={styles.info_block_head}>
          <div className={styles.info_title}>
            <h1>{locationName}</h1>
            <div className={styles.logo}>
              <img src={logo || defaultLogo} alt="" />
            </div>
          </div>
          <span className={styles.address}>{addressExtractor(venue)}</span>
          <div className={styles.flags}>
            <Flags flags={flags} />
          </div>
          <div className={styles.info}>
            <i className={'icon-star-full'} />
            <span>{googleRating || 0}</span>
            <span className={styles.votes}>({googleRatingTotal || 0})</span>
          </div>
          {
            <Tabs
              orientation={isMobile ? 'horizontal' : 'vertical'}
              value={value}
              onChange={handleChange}
              className={`${classes.origin} ${styles.origin}`}
            >
              {TabData?.map((item: ITabData, index: number) => (
                <Tab
                  key={index}
                  label={item.title}
                  id={`vertical-tab-${index}`}
                  className="asd"
                />
              ))}
            </Tabs>
          }
        </div>

        <div>
          {TabData?.map((item: ITabData, index: number) => (
            <TabPanel
              key={index}
              value={value}
              index={index}
              className={classes.tabpanel}
            >
              {item.content}
            </TabPanel>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <Grid container spacing={0}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          {
            isMobile ? viewMobile() : <div className={styles.info_block}>
              <div className={styles.backLink}
                onClick={goBack}
              >
                <ArrowBackIosIcon fontSize={"inherit"} />
                <span>Back</span>
              </div>
              <div className={styles.logo}>
                <img src={logo || defaultLogo} alt="" />
              </div>
              <h1>{locationName}</h1>
              <span className={styles.address}>{addressExtractor(venue)}</span>
              <div className={styles.info}>
                <i className={"icon-star-full"} />
                <span>{googleRating || 0}</span>
                <span className={styles.votes}>({googleRatingTotal || 0})</span>

                {/* Must be used after rating implementation on back*/}
                {/*<span>&#183;</span>*/}
                {/*<span>$$</span>*/}

              </div>

              <div className={styles.flags}>
                <Flags flags={flags} />
              </div>

              <button
                className={`${styles.book} ${disableBtn && styles.book_disable}`}
                onClick={onChangeBookNow}
                disabled={disableBtn}
              >
                Book now
              </button>
            </div>
          }
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <HeroSlider />
        </Grid>
      </Grid>

      {isMobile && (
        <div className={styles.backLinkMoBile} onClick={goBack}>
          <ArrowBackIosIcon fontSize={'inherit'} />
        </div>
      )}

    </div>
  );
}