import { PayloadAction } from "@reduxjs/toolkit";
import { BookingCategoriesProps } from "~/store/reducers/interfaces/bookingInterfaces";
import { anyonePlaceholder } from "~/constants";

export const bookingMiddlewares = {
  onLoadServicesMiddleware:
    () => (next) => (action: PayloadAction<any>) => {
      let fetchedServices = [];
      let fetchedPackages = [];

      if (action.type === "booking/fetchBookingServices") {
        const { bookingServiceCategories } =
          action.payload as BookingCategoriesProps;
        bookingServiceCategories.forEach((category) => {
          const { services, description, packages, ...restCategoryKeys } =
            category;
          const newService = services.map((service) => {
            const { staff } = service;
            const newStaff = [anyonePlaceholder, ...staff];

            return {
              ...restCategoryKeys,
              ...service,
              staff: newStaff,
              categoryDescription: description,
            };
          });
          const newPackages = packages.map((item) => {
            const { staffsOfPackage, ...restItem } = item;
            const newStaff = [anyonePlaceholder, ...staffsOfPackage];

            return {
              ...restCategoryKeys,
              ...restItem,
              serviceCategoryId: restCategoryKeys.id,
              staff: newStaff,
              packageId: restItem.id,
              type: "package",
            };
          });
          fetchedServices.push(...newService);
          fetchedPackages.push(...newPackages);
        });
        action.payload = { fetchedServices, fetchedPackages };
      }

      next(action);
    },
};
