import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
      backgroundColor: 'transparent',
    },
    loading: {
      height: '100%',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: 60,
    },
  })
);

function LoadingScreen() {
  const classes = useStyles();
  return (
    <div className={classes.loading}>
      <CircularProgress color="primary" />
    </div>
  );
}

export default LoadingScreen;
