import moment from "moment-timezone";
import { useDispatch } from "react-redux";
import clsx from "clsx";

import history from "~/utils/history";
import styles from "./CardNotificationStyle.module.scss";
import { timezoneDefault } from "~/constants";
import { readNotificationAction } from "~/store/actions/customerProfileAction";

import IconNewBooking from "~/Assets/images/logo-booking.png";
import IconExperience from "~/Assets/images/logo-experience.png";
import IconPercent from "~/Assets/images/logo-percent.svg";
import IconStar from "~/Assets/images/logo-star.svg";
import IconDone from "~/Assets/images/logo-done.svg";
import CreditCard from "~/Assets/images/credit-card.svg";

const NOTIFICATION_TYPES = {
  NEW_BOOKING: "NEW_BOOKING",
  CANCELED_BOOKING: "CANCELED_BOOKING",
  RESCHEDULE_ON_BOOKING: "RESCHEDULE_ON_BOOKING",
  NO_SHOW: "NO_SHOW",
  INVALID_CARD: "INVALID_CARD",
  UPCOMING_BOOKING: "UPCOMING_BOOKING",
  experience: "experience",
  discount: "discount",
  rating_booking: "rating_booking",
  complete_booking: "complete_booking",
  change_card: "change_card",
};

const NOTIFICATION_ACTIONS = {
  NEW_BOOKING: {
    img: IconNewBooking,
    textBtn: "See details",
    link: "/user/booking",
  },
  CANCELED_BOOKING: {
    img: IconNewBooking,
    textBtn: "See details",
    link: "/user/booking",
  },
  RESCHEDULE_ON_BOOKING: {
    img: IconExperience,
    textBtn: "See details",
    link: "/user/booking",
  },
  NO_SHOW: {
    img: IconStar,
    textBtn: "See details",
    link: "/user/booking",
  },
  INVALID_CARD: {
    img: CreditCard,
    textBtn: "Change card",
    link: "/user/booking",
  },
  UPCOMING_BOOKING: {
    img: IconStar,
    textBtn: "See details",
    link: "/user/booking",
  },
  experience: {
    img: IconExperience,
    textBtn: "Leave a review",
    link: "/experience",
  },
  discount: {
    img: IconPercent,
    textBtn: "Explore",
    link: "/discount",
  },
  rating_booking: {
    img: IconStar,
    textBtn: "Show review",
    link: "/rating_booking",
  },
  complete_booking: {
    img: IconDone,
    textBtn: "See the details",
    link: "/user/booking",
  },
  change_card: {
    img: CreditCard,
    textBtn: "Change card",
    link: "/user/booking",
  },
};

interface IProps {
  notification: {
    id: number;
    notificationObject: {
      entityObject: {
        entity: string;
      };
      entityTypeId: number;
    };
    venue: {
      locationName: string;
      timezone: string;
      logo: string;
    };
    date: string;
    oldDate: string;
    type: string;
    nameVenue: string;
    discount: number;
    cardNumber?: string;
    bookingId: number;
    createdAt: string;
    updatedAt: string;
  };
  index: number;
  lengthValue: number;
}

const CardNotification = (props: IProps) => {
  const {
    notification: {
      id,
      notificationObject,
      venue,
      bookingId,
      updatedAt,
      createdAt,
      date,
      oldDate,
      nameVenue,
      discount,
      cardNumber,
    },
    index,
    lengthValue,
  } = props;
  const dispatch = useDispatch();
  const timezone = venue.timezone || timezoneDefault;

  const onClickBtnCard = () => {
    dispatch(readNotificationAction(id))
    if (
      notificationObject.entityObject.entity === NOTIFICATION_TYPES.INVALID_CARD
    ) {
      history.push(
        NOTIFICATION_ACTIONS[
          notificationObject.entityObject.entity
        ].link.concat("?card_invalid=true&booking_id=", bookingId)
      );
    } else {
      history.push(
        NOTIFICATION_ACTIONS[
          notificationObject.entityObject.entity
        ].link.concat("?booking_id=", bookingId)
      );
    }
  };

  const getContent = () => {
    switch (notificationObject.entityObject.entity) {
      case NOTIFICATION_TYPES.NEW_BOOKING:
        return (
          <span className={styles.value_title}>
            You have successfully booked with{" "}
            <span className={styles.text_bold_notification}>
              {venue.locationName}
            </span>{" "}
            on{" "}
            <span className={styles.text_bold_notification}>
              {moment.tz(date, timezone).format("DD MMM")}
            </span>.{" "}
            <span className={styles.booking_id_text} onClick={onClickBtnCard}>{`Booking ${bookingId}`}</span>
          </span>
        );
      case NOTIFICATION_TYPES.CANCELED_BOOKING:
        return (
          <span className={styles.value_title}>
            {notificationObject.entityTypeId === 202 ? (
              "You"
            ) : (
              <span className={styles.text_bold_notification}>
                {venue.locationName}
              </span>
            )}{" "}
            cancelled your booking on{" "}
            <span className={styles.text_bold_notification}>
              {moment.tz(date, timezone).format("DD MMM")}
            </span>.{" "}
            <span className={styles.booking_id_text} onClick={onClickBtnCard}>{`Booking ${bookingId}`}</span>
          </span>
        );
      case NOTIFICATION_TYPES.RESCHEDULE_ON_BOOKING:
        return (
          <span className={styles.value_title}>
            {notificationObject.entityTypeId === 203 ? (
              "You"
            ) : (
              <span className={styles.text_bold_notification}>
                {venue.locationName}
              </span>
            )}{" "}
            rescheduled your booking from{" "}
            <span className={styles.text_bold_notification}>
              {moment
                .tz(oldDate, timezone)
                .format("DD MMM hh:mm A")}
            </span>{" "}
            to{" "}
            <span className={styles.text_bold_notification}>
              {moment
                .tz(date, timezone)
                .format("DD MMM hh:mm A")}
            </span>.{" "}
            <span className={styles.booking_id_text} onClick={onClickBtnCard}>{`Booking ${bookingId}`}</span>
          </span>
        );
      case NOTIFICATION_TYPES.UPCOMING_BOOKING:
        return (
          <span className={styles.value_title}>
            You have an upcoming booking tomorrow at{" "}
            <span className={styles.text_bold_notification}>
              {venue.locationName}
            </span>
            .{" "}
            <span className={styles.booking_id_text} onClick={onClickBtnCard}>{`Booking ${bookingId}`}</span>
          </span>
        );
      case NOTIFICATION_TYPES.NO_SHOW:
        return (
          <span className={styles.value_title}>
            You missed your appointment{" "}
            <span className={styles.text_bold_notification}>
              Booking {bookingId}
            </span>{" "}
            on{" "}
            <span className={styles.text_bold_notification}>
              {moment.tz(date, timezone).format("DD MMM")}
            </span>{" "}
            with{" "}
            <span className={styles.text_bold_notification}>
              {venue.locationName}
            </span>
            . Penalty charges were applied as per our cancellation policy.{" "}
            <span className={styles.booking_id_text} onClick={onClickBtnCard}>{`Booking ${bookingId}`}</span>
          </span>
        );

      case NOTIFICATION_TYPES.experience:
        return (
          <span className={styles.value_title}>
            Yesterday you have visited{" "}
            <span className={styles.text_bold_notification}>{nameVenue}</span>,
            Tell us about your experience.{" "}
            <span className={styles.booking_id_text} onClick={onClickBtnCard}>{`Booking ${bookingId}`}</span>
          </span>
        );
      case NOTIFICATION_TYPES.discount:
        return (
          <span className={styles.value_title}>
            Get{" "}
            <span className={styles.text_bold_notification}>
              {discount}% free
            </span>{" "}
            on every haircut until{" "}
            <span className={styles.text_bold_notification}>
              {moment.tz(moment(), timezone).format("DD of MMMM")}
            </span>.{" "}
            <span className={styles.booking_id_text} onClick={onClickBtnCard}>{`Booking ${bookingId}`}</span>
          </span>
        );
      case NOTIFICATION_TYPES.rating_booking:
        return (
          <span className={styles.value_title}>
            You have left your review on{" "}
            <span className={styles.text_bold_notification}>{nameVenue}</span>.{" "}
            <span className={styles.booking_id_text} onClick={onClickBtnCard}>{`Booking ${bookingId}`}</span>
          </span>
        );
      case NOTIFICATION_TYPES.complete_booking:
        return (
          <span className={styles.value_title}>
            <span className={styles.text_bold_notification}>{nameVenue}</span>{" "}
            confirmed your booking.{" "}
            <span className={styles.booking_id_text} onClick={onClickBtnCard}>{`Booking ${bookingId}`}</span>
          </span>
        );
      case NOTIFICATION_TYPES.INVALID_CARD:
        return (
          <span className={styles.value_title}>
            There is an issue with the card number ending in
            <span
              className={styles.text_bold_notification}
            >{`**** ${cardNumber} `}</span>
            used for booking{" "}
            <span className={styles.text_bold_notification}>#{bookingId}</span>{" "}
            in{" "}
            <span className={styles.text_bold_notification}>
              {venue.locationName}
            </span>
            . Please nominate a new card.{" "}
            <span className={styles.booking_id_text} onClick={onClickBtnCard}>{`Booking ${bookingId}`}</span>
          </span>
        );
      default:
        return null;
    }
  };

  return (
    <div
      className={clsx(styles.wrapper_value_notification, {
        [styles.border_bottom_notification]: index === lengthValue - 1,
        [styles[`icon_bg_${notificationObject.entityObject.entity}`]]: !!venue.logo,
      })}
    >
      <div className={styles.wrapper_img_notification}>
        <img
          src={
            venue.logo ||
            NOTIFICATION_ACTIONS[notificationObject.entityObject.entity]?.img ||
            IconExperience
          }
          alt="icon-venue"
        />
      </div>
      <div className={styles.wrapper_value_text}>
        <div className={styles.wrapper_value_title}>{getContent()}</div>
        <div className={styles.wrapper_value_btn}>
          <span onClick={onClickBtnCard} className={styles.value_btn}>
            {
              NOTIFICATION_ACTIONS[notificationObject.entityObject.entity]
                ?.textBtn
            }
          </span>
        </div>
        <div className={styles.wrapper_create_notification}>
          <span className={styles.create_notification}>
            {moment
              .tz(updatedAt || createdAt, venue?.timezone || timezoneDefault)
              .format("Do of MMMM YYYY • hh:mm a")}
          </span>
        </div>
      </div>
    </div>
  );
};

export default CardNotification;
