import React from 'react';
import Slider from 'react-slick';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import styles from './VenueSliderStyle.module.scss';
import { VenueCard } from '~/Components/VenueCard';
import type { VenueSliderProps } from '~/Components/VenueSlider/interfaces';

export const VenueSlider: React.FC<VenueSliderProps> = (props) => {
  const { title, isViewAllVisible = false, venues, onVenuesEndReached } = props;
  const slider = React.useRef(null);

  const settings = {
    dots: false,
    infinite: venues?.length > 4 ? true : false,
    arrows: false,
    speed: 300,
    slidesToShow: venues?.length > 4 ? 4 : venues?.length,
    slidesToScroll: venues?.length > 4 ? 1 : 0,
    centerMode: false,
    beforeChange: (_, newIndex) => {
      if (newIndex === 0) {
        onVenuesEndReached();
      }
    },
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          className: "center",
          centerMode: true,
          infinite: true,
          centerPadding: "30px",
          slidesToShow: venues?.length > 2 ? 2 : venues?.length,
          speed: 500
        }
      },
      {
        breakpoint: 768,
        settings: {
          className: "center",
          centerMode: true,
          infinite: true,
          centerPadding: "60px",
          slidesToShow: venues?.length > 2 ? 2 : venues?.length,
          speed: 500
        }
      },
      {
        breakpoint: 480,
        settings: {
          className: "center",
          centerMode: true,
          infinite: true,
          centerPadding: "60px",
          slidesToShow: 1,
          speed: 500
        }
      },
    ]
  };

  return (
    <>
      <div className={styles.header}>
        <h2>{title}</h2>
        <div className={styles.buttons_block}>
          {isViewAllVisible && (
            <div className={styles.allLink}>
              <span>View all</span>
              <ArrowForwardIosIcon fontSize={'small'} />
            </div>
          )}
          <div className={styles.buttons_box}>
            <button
              className={`${styles.button} ${venues?.length <= 4 ? styles.disebal_btn : ''
                }`}
              onClick={slider.current?.slickPrev}
            >
              <ArrowBackIosIcon fontSize={'small'} />
            </button>
            <button
              className={`${styles.button} ${venues?.length <= 4 ? styles.disebal_btn : ''
                }`}
              onClick={slider.current?.slickNext}
            >
              <ArrowForwardIosIcon fontSize={'small'} />
            </button>
          </div>
        </div>
      </div>
      {venues?.length > 0 ? (
        <Slider {...settings} ref={slider} className={'venue-slider'}>
          {venues?.map((venue) => (
            <VenueCard isHome={true} key={venue.id} venue={venue} />
          ))}
        </Slider>
      ) : (
        <h3 style={{ color: '#aaa' }}>Glamezy is coming soon to your city!</h3>
      )}
    </>
  );
};
