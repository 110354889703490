import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  tabsRoot: {
    [theme.breakpoints.between('sm', 'md')]: {
      padding: '0px 20px',
    },
    '& .MuiTabs-indicator': {
      display: 'none',
    },

    '& .MuiTab-textColorInherit.Mui-selected': {
      backgroundColor: '#ffff',
      margin: '0px 6px',
      color: '#8243df',
    },
    '& .MuiTab-textColorInherit': {
      background: ' #7F2FEA',
      opacity: 1,
      color: '#ffff',
      [theme.breakpoints.between('sm', 'md')]: {
        minWidth: '46%',
      },
    },
    '& .MuiTabs-flexContainer': {
      width: '100%',
      justifyContent: 'space-between',
    },
  },
}));
