import React, { useEffect, useRef, useState } from "react";
import { FormProvider } from "react-hook-form";
import { Sticky, StickyContainer } from "react-sticky";
import clsx from "clsx";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CssBaseline from "@material-ui/core/CssBaseline";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { useScrollTrigger } from "@material-ui/core";
import { RouteComponentProps } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.scss";
import "slick-carousel/slick/slick-theme.scss";
import "slick-carousel/slick/slick.css";

import { CheckMobileHook } from "~/Components/MainHeader/CheckMobileHook";
import { VenueCardResults } from "~/Containers/SearchResultsPage/components/VenueCardResults";
import { SearchResultsFilters } from "~/Containers/SearchResultsPage/components/SearchResultsFilters";
import Map from "~/Components/Map/Map";
import { SearchResultsPageHook } from "~/Containers/SearchResultsPage/SearchResultsPageHook";
import { useAppSelector } from "~/reduxConfig";
import { SearchReducerStateProps } from "~/store/reducers/interfaces/searchInterfaces";
import { searchSelector } from "~/store/selectors";
import { VenueCard } from "~/Components/VenueCard";
import { useScrollBlock } from "./useScrollBlock";

import styles from "./SearchResultsPageStyle.module.scss";
import { useStyles } from "./SearchResultsPageStyles";

export const SearchResultsPage: React.FC<RouteComponentProps<any>> = (
  props
) => {
  const classes = useStyles();
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "20px",
    slidesToShow: 1,
    swipeToSlide: true,
  };
  const {
    open,
    handleDrawerOpen,
    handleDrawerClose,
    searchFormMethods,
    searchFields,
  } = SearchResultsPageHook(props);
  const { searchResult , isOpen } =
    useAppSelector<SearchReducerStateProps>(searchSelector);

  const { isMobile } = CheckMobileHook();
  const [blockScroll, allowScroll] = useScrollBlock ();
  const [slideIndex, setSlideIndex] = useState(10);
  const slider = useRef<Slider>(null);
  
  const handleSelectSlide = (value: number) => {
    setSlideIndex(value)
  }

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 1,
  });

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  if(isOpen) {
     blockScroll()
  }
  else {
     allowScroll()
  }


  useEffect(() => {
    slider.current?.slickGoTo(slideIndex);
  }, [slideIndex]);

  return (
    <div className={`${classes.root} ${styles.root}`}>
      {searchResult.length > 0 && isMobile && !open && (
        <div
          className={clsx(styles.showMapBtn, {
            [styles.showMapBtnFixed]: trigger,
          })}
          onClick={() => {
            handleDrawerOpen();
            scrollToTop();
          }}
        >
          <div className={styles.mapBtn}>
            <i className="icon-show-map" />
            <span>Map</span>
          </div>
        </div>
      )}

      <div
        className={`
        ${clsx(classes.content, {
          [classes.contentShift]: open,
        })} ${clsx(styles.scroll_box, {
          [styles.scroll_box_shift]: open,
        })}
        `}
      >
        <div className={styles.scroll_box_holder}>
          <StickyContainer>
            <CssBaseline />
            <AppBar
              className={`
                ${clsx(classes.appBar, {
                [classes.appBarShift]: open,
              })} ${clsx(styles.appBar, {
                [styles.appBar_active]: open,
              })}`
              }
            >
              <Toolbar>
                <div className={`
                  ${clsx(styles.heading, {
                  [styles.heading_active]: open,
                })}`
                }>
                  <h1><span>{searchResult.length}</span> beauty venues nearby</h1>
                </div>
                <Sticky topOffset={-16}>
                  {({style, isSticky,}) => (
                    <div style={style} className={`a
                        ${clsx(styles.search_box, {
                          // [styles.search_box_active]: open,
                          [styles.search_box_fixed]:  (trigger && isMobile),
                        })}`}
                    >
                      <div className={styles.sticky_holder}>
                        <FormProvider {...searchFormMethods}>
                        <SearchResultsFilters horizontal={isSticky || isMobile} searchFields={searchFields} />
                        </FormProvider>
                        {!isMobile && (
                          <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="end"
                            onClick={handleDrawerOpen}
                            className={clsx(open && classes.hide)}
                          >
                            <i className="icon-show-map" /> Show on map
                          </IconButton>
                        )}
                      </div>
                    </div>
                  )}
                </Sticky>
              </Toolbar>
            </AppBar>
            <section
              className={`
              ${clsx(styles.search_results_section, {
                [styles.search_results_section_active]: open,
                [styles.search_results_section_active_map]: open && isMobile,
              })}`}
            >
              <div className={styles.col1}>
                <div className={styles.venue_card}>
                  <VenueCardResults horizontal={open} />
                </div>
              </div>
            </section>
          </StickyContainer>
        </div>
      </div>

      <Drawer
        className={clsx(`${classes.drawer} ${styles.drawer}`, {
          [styles.drawer_mobile]: open && isMobile,
        })}
        variant="persistent"
        anchor="right"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={`${classes.drawerHeader} ${styles.drawerHeader}`}>
          <IconButton onClick={handleDrawerClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <Map venues={searchResult} handleSelectSlide={handleSelectSlide} />
        {isMobile && open && (
          <Slider ref={slider}
            {...settings}
            className={clsx(`${styles.container} ${styles.horizontal} ${styles.sliderMobile}`,{
              [styles.hidden]: !slider.current,
              [styles.show]: !!slider.current
            })}
          >
            {searchResult.map((venue) => (
              <VenueCard key={venue.id} venue={venue} />
            ))}
          </Slider>
        )}

        {isMobile && open && (
          <div className={styles.searchResultMapMobile}>
            <span>{searchResult.length}</span> beauty venues nearby
          </div>
        )}
      </Drawer>
    </div>
  );
};