import React from "react";
import { Controller } from "react-hook-form";
import RadioGroup from "@material-ui/core/RadioGroup";

import { StaffPickerSlider } from "~/Components/StaffPicker/components/StaffPickerSlider";
import type { StaffPickerProps } from "~/Components/StaffPicker/interfaces";

import styles from "./StaffPickerStyle.module.scss";

export const StaffPicker: React.FC<StaffPickerProps> = (props) => {
  const {
    formMethods: { control },
    fieldName,
    staffList,
  } = props;

  return (
    <Controller
      control={control}
      name={fieldName}
      defaultValue={0}
      render={({ field: { value, onChange } }) => (
        <RadioGroup
          value={`${value}`}
          onChange={(event) => {
            onChange(+event.target.value);
          }}
          aria-label="gender"
          name="customized-radios"
          className={styles.check_block}
        >
          <StaffPickerSlider staffList={staffList} />
        </RadioGroup>
      )}
    />
  );
};
