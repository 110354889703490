import moment from "moment";
import { useFormContext } from "react-hook-form";

import type { RescheduleBookingServiceItemProps, MyBookingFormProps } from "~/Containers/MyBookingsPage/interfaces";
import { convertDurationString } from "~/utils/helper";

export const RescheduleBookingServiceItemHook = (props) => {
  const { bookingService, timezone } = props as RescheduleBookingServiceItemProps;
  const {
    id,
    service,
    package: packageService,
    pricingOption,
    staff,
    startDateTime,
    endDateTime,
    duration,
    staffPricingOption,
  } = bookingService;
  const { register } = useFormContext<MyBookingFormProps>();
  const { variantName, specialPrice, price } = pricingOption || {
    specialPrice: packageService?.specialPrice,
    price: packageService?.price,
  };
  const displayedPrice = staffPricingOption?.specialPrice || staffPricingOption?.price || specialPrice || price;
  const displayedName = staff ? `${staff.firstName} ${staff.lastName}` : "Anyone";
  const displayedInitials = staff ? `${staff.firstName[0]}${staff.lastName[0]}`.toUpperCase() : "A";
  const startDisplayedTime = moment.tz(startDateTime, timezone).format("hh:mma");
  const endDisplayedTime = moment.tz(endDateTime, timezone).format("hh:mma");
  const displayedTime = `${startDisplayedTime} - ${endDisplayedTime}`;
  const displayedDuration = convertDurationString(packageService?.duration || duration);

  return {
    serviceName: packageService?.packageName || service?.serviceName,
    variantName,
    displayedPrice,
    avatar: staff?.avatar,
    displayedInitials,
    displayedName,
    displayedTime,
    displayedDuration,
    id,
    isPackage: !!packageService,
    serviceOfPackage: packageService?.packageServices.length,
    register
  };
};