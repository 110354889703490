import React, { useEffect, useState } from "react";
import { FormProvider } from "react-hook-form";
import { RouteComponentProps, useParams, useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import moment from "moment-timezone";

import {
  flowsLayoutRoute,
  paymentFlowPageRoute,
  timezoneDefault,
} from "~/constants";
import axios from "~/utils/api";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import DialogMain from "~/Components/DialogMain/DialogMain";
import { DatePicker } from "~/Components/DatePicker/DatePicker";
import { HeadingText } from "~/Components/HeadingText/HeadingText";
import { StaffPicker } from "~/Components/StaffPicker";
import { BookingFlowPageHook } from "~/Containers/BookingFlowPage/BookingFlowPageHook";
import { SummarySection } from "~/Containers/BookingFlowPage/components/SummarySection";
import { TimeRadioPicker } from "~/Components/TimeRadioPicker";
import { CheckMobileHook } from "~/Components/MainHeader/CheckMobileHook";
import AccordionMui from "~/Components/AccordionMui/AccordionMui";

import styles from "./BookingFlowPageStyle.module.scss";
import { useAppSelector } from "~/reduxConfig";
import { VenueReducerState } from "~/store/reducers/interfaces/venueInterfaces";
import { venueSelector, bookingSelector } from "~/store/selectors";
import { BookingFlowPageLocationStateProps } from "./interfaces";
import { StaffEntity } from "~/store/reducers/interfaces/bookingInterfaces";

export const BookingFlowPage: React.FC<RouteComponentProps> = (props) => {
  const { isMobile } = CheckMobileHook();
  const history = useHistory();
  const params: {
    id: string;
  } = useParams();
  const { venue } = useAppSelector<VenueReducerState>(venueSelector);
  const { staff: staffBooking } = useAppSelector(bookingSelector);

  const handleBack = () => {
    history.goBack();
  };
  const {
    staff,
    bookingFormMethods,
    bookingFormFieldArrayMethods,
    selectedServices,
    countBookingPrice,
    date,
    timeSelect,
    timeStartEnd,
    selectStaff,
    setSelectStaff,
  } = BookingFlowPageHook(props);
  const [timeError, setTimeError] = useState("");
  const [dayOffError, setDayOffError] = useState("");
  const [staffError, setStaffError] = useState(false);
  const [finalStaffs, setFinalStaffs] = useState(staff?.bookingStaffList || []);

  const onChangeTimeError = (value: string) => {
    setTimeError(value);
  };

  const onChangeDayOffError = (value: string) => {
    setDayOffError(value);
  };

  const onSubmit = async () => {
    const noStaff = selectedServices.some(
      (service) => !service.selected.staffId
    );

    if (noStaff) {
      setStaffError(true);
      return;
    }

    const newData = selectedServices?.map((item) => {
      const duration = moment.duration(item.duration || item.pricingOptions[0].duration).asHours();
      const startDateTime = moment.tz(`${item.selected.date} ${item.selected.time}`, venue?.timezone || timezoneDefault).toISOString()
      const endDateTime = moment.tz(moment(`${item.selected.date} ${item.selected.time}`).add(duration, "hours").format("YYYY-MM-DD HH:mm"), venue?.timezone || timezoneDefault).toISOString()

      return {
        staffId: item.selected.staffId,
        startDateTime,
        endDateTime,
      }
    })

    const response: any = await axios.post(`/venue/${params.id}/enable-closed-date`, {
      time: date,
      services: newData,
    })

    if (response?.data?.isClosedDate) {
      onChangeDayOffError(`The venue has closed on ${date}`)
    } else if (response?.data?.message) {
      onChangeDayOffError(response?.data?.message)
    } else {
      history.push(
        flowsLayoutRoute.concat(paymentFlowPageRoute, `/${params.id}`),
        { selectedServices, countBookingPrice, date });
    }
  };

  const onClose = () => {
    setStaffError(false);
  };

  useEffect(() => {
    if (bookingFormMethods.getValues("staffId") && staff?.bookingStaffList?.length > 0) {
      const data = staff.bookingStaffList.find((item) => item.id === bookingFormMethods.getValues("staffId"))
      if (data?.id !== 0) {
        setSelectStaff(data)
      }
    } else {
      setSelectStaff({})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingFormMethods.getValues("staffId"), staff?.bookingStaffList])

  useEffect(() => {
    const locationState = props.location
      .state as BookingFlowPageLocationStateProps;
    if (locationState?.service) {
      const staffObj = {};
      selectedServices?.forEach(({ staff: staffList }) => {
        staffList.forEach(staffItem => {
          if (staffItem.id) {
            staffObj[staffItem.id] = staffItem
          }
        })
      });
      const list: StaffEntity[] = Object.values(staffObj).length > 0
        ? Object.values(staffObj)
        : (staff?.bookingStaffList || []);
      setFinalStaffs(list)
    } else {
      setFinalStaffs(staff?.bookingStaffList || [])
    }
  }, [props.location, selectedServices, staff?.bookingStaffList])

  return (
    <FormProvider {...bookingFormMethods}>
      {staffError && (
        <DialogMain
          titleDialog="Staff Required"
          showModal={staffError}
          openDialogButtonClass="btn-text"
          submitButtonText={"Close"}
          onSubmit={onClose}
          onClose={onClose}
          dialogWindowClass="modal-dialog-service"
        >
          <p style={{ textAlign: "center", paddingTop: 20 }}>
            Select staff for service!
          </p>
        </DialogMain>
      )}

      <div className={isMobile ? styles.container_mobile : styles.container_desktop}>
        <Grid
          className={styles.container_top}
          container
          spacing={isMobile ? 0 : 2}
        >
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {isMobile ? (
              <div className={styles.header_booking_mobile}>
                <div>Booking Service</div>
                <div className={styles.header_icon} onClick={handleBack}>
                  <ArrowBackIosIcon />
                </div>
              </div>
            ) : (
              <HeadingText
                heading="Book a venue"
                text="Select service, staff, date and time"
              />
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} className={styles.col}>
            <div className={styles.column_block}>
              <h2>Select staff</h2>
              <StaffPicker
                staffList={finalStaffs}
                formMethods={bookingFormMethods}
                fieldName={"staffId"}
              />

              {/* add when this function will be implemented on back*/}
              {/*<div className={styles.info_text}>*/}
              {/*  <p>The available date and time will be based on the schedule of the chosen staff member.</p>*/}
              {/*</div>*/}

              {
                bookingFormMethods.getValues("staffId") !== 0 && (
                  <div className={styles.wrapper_text_select_client}>
                    <span className={styles.text_select_client}>
                      The available date and time will be based on the schedule of the chosen staff member.
                    </span>
                  </div>
                )
              }

              <h2>Select date</h2>
              <DatePicker
                formMethods={bookingFormMethods}
                fieldName={"date"}
                disablePast
                timeSelect={timeSelect}
                timeStartEnd={timeStartEnd}
                onChangeTimeError={onChangeTimeError}
                onChangeDayOffError={onChangeDayOffError}
              />
              {isMobile ? (
                <>
                  <h2>Select time</h2>
                  <AccordionMui
                    title={"Time"}
                    detail={
                      <TimeRadioPicker
                        fieldName="time"
                        formMethods={bookingFormMethods}
                        timeSelect={timeSelect}
                        timeStartEnd={timeStartEnd}
                        timeError={timeError}
                        onChangeTimeError={onChangeTimeError}
                        staffShift={selectStaff?.customStaffShifts?.length > 0 && selectStaff?.customStaffShifts[0]}
                        timezone={venue?.timezone || timezoneDefault}
                      />
                    }
                  />
                </>
              ) : (
                <>
                  <h2>Select time</h2>
                  <TimeRadioPicker
                    fieldName="time"
                    formMethods={bookingFormMethods}
                    timeSelect={timeSelect}
                    timeStartEnd={timeStartEnd}
                    timeError={timeError}
                    onChangeTimeError={onChangeTimeError}
                    staffShift={selectStaff?.customStaffShifts?.length > 0 && selectStaff?.customStaffShifts[0]}
                    timezone={venue?.timezone || timezoneDefault}
                  />
                </>
              )}
            </div>
          </Grid>

          {/* view mobile */}
          {!(
            selectedServices?.length === 0 ||
            timeError !== "" ||
            timeStartEnd[timeSelect[0]] == null ||
            dayOffError !== ""
          ) && isMobile && (
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                className={isMobile ? styles.col_bottom : styles.col}
              >
                <div className={styles.column_block}>
                  <div className={styles.heading}>
                    <h2>Booking summary</h2>
                  </div>
                  <SummarySection
                    formMethods={bookingFormMethods}
                    fieldArrayFormMethods={bookingFormFieldArrayMethods}
                    hourSelect={timeSelect}
                    timeStartEnd={timeStartEnd}
                  />
                </div>
              </Grid>
            )}

          {/* view desktop */}
          {!isMobile && (
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              className={isMobile ? styles.col_bottom : styles.col}
            >
              <div className={styles.column_block}>
                <div className={styles.heading}>
                  <h2>Booking summary</h2>
                </div>
                <SummarySection
                  formMethods={bookingFormMethods}
                  fieldArrayFormMethods={bookingFormFieldArrayMethods}
                  hourSelect={timeSelect}
                  timeStartEnd={timeStartEnd}
                />
              </div>
              <div className={styles.btn_box}>
                {dayOffError && (
                  <p className={styles.error_day_off}>{dayOffError}</p>
                )}
                <button
                  disabled={
                    selectedServices?.length === 0 ||
                    timeError !== "" ||
                    timeStartEnd[timeSelect[0]] == null ||
                    dayOffError !== "" ||
                    staffBooking.closeDateEnabled
                  }
                  className={`${"purple-btn"} ${styles.purple_btn}`}
                  onClick={onSubmit}
                >
                  Select and continue
                </button>
              </div>
            </Grid>
          )}
        </Grid>
        {/* button fixed */}
        {isMobile &&
          (bookingFormFieldArrayMethods.fields.length === 0 ? (
            <SummarySection
              isButton={true}
              formMethods={bookingFormMethods}
              fieldArrayFormMethods={bookingFormFieldArrayMethods}
              hourSelect={timeSelect}
              timeStartEnd={timeStartEnd}
            />
          ) : (
            <div className={styles.btn_box_mobile}>
              {dayOffError && (
                <p className={styles.error_day_off}>{dayOffError}</p>
              )}
              <button
                disabled={
                  selectedServices?.length === 0 ||
                  timeError !== "" ||
                  timeStartEnd[timeSelect[0]] == null ||
                  dayOffError !== "" ||
                  staffBooking.closeDateEnabled
                }
                className={styles.btn_select}
                onClick={onSubmit}
              >
                Select and continue
              </button>
            </div>
          ))}
      </div>
    </FormProvider>
  );
};