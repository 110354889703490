import creditCardsApi from "~/services/api/creditCardsApi";
import defaultApi from "~/services/api/defaultApi";
import { stripeApi } from '~/services/api/stripeApi'
import { loadCustomerCards, loadDefaultCard, setMessage } from "~/store/reducers/customerProfileReducer";
import {
  loadCustomerCards as paymentMethodLoadCards,
  loadDefaultCard as paymentMethodLoadCardDefault
} from "~/store/reducers/paymentMethodReducer";

const onSetError = () => {
  throw new Error("Something wrong");
};

export const onGetCreditCardsAction = () => {
  return async (dispatch) => {
    try {
      const { data: { data } } = await creditCardsApi.getCreditCards();
      dispatch(loadCustomerCards(data));
      dispatch(paymentMethodLoadCards(data));
    } catch (error: any) {
      console.log("error", error);
    }
  };
};

export const onGetDefaultCreditCardAction = (customerId) => {
  return async (dispatch) => {
    try {
      const { data: { invoice_settings } = {} } = await defaultApi.getDefaultCard(customerId);
      dispatch(loadDefaultCard(invoice_settings?.default_payment_method));
      dispatch(paymentMethodLoadCardDefault(invoice_settings?.default_payment_method));
    } catch (error: any) {
      console.log("error", error);
    }
  };
};

export const onPostCreditCardAction = (payload, callback) => {
  return async (dispatch) => {
    try {
      const response = await creditCardsApi.postCreditCard(payload);

      if (response.status === 200) {
        dispatch(onGetCreditCardsAction());
        callback();
        return;
      }

      onSetError();
    } catch (error: any) {
      dispatch(setMessage(error.message));
    }
  };
};

export const onPutCreditCardAction = (cardId, payload, callback) => {
  return async (dispatch) => {
    try {
      const response = await creditCardsApi.putCreditCard(cardId, payload);

      if (response.status === 200) {
        dispatch(onGetCreditCardsAction());
        callback();
        return;
      }

      onSetError();
    } catch (error: any) {
      dispatch(setMessage(error.message));
    }
  };
};

export const onDeleteCreditCardAction = (cardId, callback) => {
  return async (dispatch) => {
    try {
      const response = await stripeApi.removePaymentMethod(cardId);

      if (response.status === 200) {
        dispatch(onGetCreditCardsAction());
        callback();
        return;
      }

      onSetError();
    } catch (error: any) {
      dispatch(setMessage(error.message));
    }
  };
};
