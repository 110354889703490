import { useFormContext, useWatch } from 'react-hook-form';
import moment from 'moment';
import { useSelector } from 'react-redux';

import type {
  BookingFormProps,
  SelectedServiceItemProps,
} from '~/Containers/BookingFlowPage/interfaces';
import { bookingSelector } from "~/store/selectors";

export const SelectedServiceItemHook = (props) => {
  const { staff: staffBooking } = useSelector(bookingSelector);
  const bookingFormMethods = useFormContext<BookingFormProps>();
  const { currentFieldIndex } = useWatch<BookingFormProps>({
    control: bookingFormMethods.control,
  });
  const {
    selectedService: { serviceName, pricingOptions, staff, selected, id, packageName, ...rest },
    index,
  } = props as SelectedServiceItemProps;

  const { time, staffId, date } = selected;

  const selectedPricingOption = pricingOptions?.find(
    (pricingOption) => pricingOption.id === selected.pricingOptionId
  );
  const staffPricingOpt: any = selectedPricingOption?.staffPricingOptions?.find(
    (staffPricingOption: any) => staffPricingOption.staffId === selected.staffId
  );

  const selectedStaff = staff?.find((s) => s?.id === staffId);
  const selectedStaffName = selectedStaff ? `${selectedStaff.firstName} ${selectedStaff.lastName}` : 'Anyone';
  const price = staffPricingOpt?.specialPrice || staffPricingOpt?.price || selectedPricingOption?.specialPrice || selectedPricingOption?.price;
  const pricingOptionName = selectedPricingOption?.variantName;
  const fullDuration = rest.duration || selectedPricingOption?.duration;

  const startTime = moment(`${date} ${time}`).format("hh:mm a")
  const endTime = moment(`${date} ${time}`).add(fullDuration).format("hh:mm a")
  const timePeriod = `${startTime} - ${endTime}`;

  const staffIds = staff.map((item) => item.id)
  const newDataStaffBooking = staffBooking?.bookingStaffList?.filter(
    (item) =>
      item?.customStaffShifts?.length > 0 && staffIds?.includes(item.id)
  );

  return {
    pricingOptionName,
    serviceName: packageName || serviceName,
    selectedStaffName,
    selectedStaff,
    price: Number(rest.specialPrice || rest.price || price),
    fullDuration,
    timePeriod,
    bookingFormMethods,
    index,
    id: selected?.serviceId || id,
    currentFieldIndex,
    staff: [staff[0], ...newDataStaffBooking],
    time,
    amountService: rest.packageServices?.length || 0,
  };
};
