import axios from "axios";
import { TOKEN } from "~/constants";
import { config } from "~/config"
import cookie from "./cookie";

const token = cookie.getCookie(TOKEN);

const api = axios.create({
  baseURL: config.host || window.location.origin,
  headers: {
    Authorization: `Bearer ${token}`,
  }
});

api.interceptors.request.use(
  (request) => {
    return request;
  },
  (error) => {
    console.log("request error:", error);
  }
);

api.interceptors.response.use(
  (response) => response,
  (error) => error.response,
);

export default api;
