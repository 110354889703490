import React from "react";
import { titlesGeneralInfo } from "~/constants";
import { Intro } from "~/Components/Intro";

import styles from "./ContactUsPageStyle.module.scss";

export const ContactUsPage: React.FC = () => {
  const { contactUs } = titlesGeneralInfo;

  return (
    <>
      <Intro heading={contactUs} />
      <div className={styles.container}>
        <div className={styles.container_holder}>
          <div className={styles.col}>
            <h2>Our contacts</h2>
            <ul className={styles.item_info}>
              <li>
                <a href="tel:+76786546655"><span className={styles.circle}><i className="icon-phone"></i></span> +7 678 654 66 55</a>
              </li>
              <li>
                <a href="mailto:glamezy.info@gmail.com"><span className={styles.circle}><i className={`${"icon-mail"} ${styles.icon_mail}`}></i></span> glamezy.info@gmail.com</a>
              </li>
            </ul>
            <h2>How we can help you?</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rhoncus rhoncus, odio pretium id vulputate leo ultrices accumsan, libero. Donec volutpat massa blandit non.</p>
          </div>
          <div className={styles.col}>
            <div className={styles.heading}>
              <h2>Our Adress</h2>
              <a
                href="https://www.google.com/maps?ll=-27.496503,153.011994&z=14&t=m&hl=en&gl=US&mapclient=embed&cid=4787732246264183311"
                target='_blank'
                rel="noreferrer noopener"
              >
                View on map
              </a>
            </div>
            <div className={styles.map}>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7078.192367883419!2d153.012522!3d-27.497384!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x4271711c8a427a0f!2sThe%20University%20of%20Queensland%20Art%20Museum!5e0!3m2!1sen!2s!4v1638946910956!5m2!1sen!2s"
                title="title"
                width="522"
                height="254"
                style={{ border: 0 }}
                loading="lazy"
              />
            </div>
            <p className={styles.location}>James and Mary Emelia, St Lucia QLD 4072</p>
          </div>
        </div>
      </div>
    </>
  )
}