import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.scss";
import "slick-carousel/slick/slick-theme.scss";
import visualImg1 from '~/Assets/images/visual/image_02.png';
import visualImg2 from '~/Assets/images/visual/image_01.png';
import visualImg3 from '~/Assets/images/visual/image_03.png';
// import visualImg4 from '~/Assets/images/visual/visual-img-4.png';

import styles from './MainVisualSliderStyle.module.scss';


export default function MainVisualSlider() {
  const settings = {
    dots: false,
    infinite: true,
    autoplaySpeed: 10000,
    speed: 2000,
    fade: true,
    autoplay: true,
    arrows: false,
    cssEase: 'linear'
  };

  return (
    <section className={styles.visual}>
      <div className={styles.visual_holder}>
        <div className={styles.visual_text}>
          <h1>Book GLAM Instantly Wherever You Are!</h1>
          <p>The best way to book your next beauty appointment</p>
        </div>
        <Slider {...settings} className={styles.visual_slider}>
          <div>
            <img src={visualImg1} alt="description" />
          </div>
          <div>
            <img src={visualImg2} alt="description" />
          </div>
          <div>
            <img src={visualImg3} alt="description" />
          </div>
        </Slider>
      </div>
    </section>
  );
}