import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { TextField, Grid, Button } from "@material-ui/core";
import clsx from "clsx";

import subscribe from "../../Assets/images/subscribe.png";

import { Message } from "~/Components/Message";
import { SubscribeProps } from "./interfaces";
import { subscripeText } from "~/constants";
import styles from "./styles.module.scss";
import { CheckMobileHook } from "~/Components/MainHeader/CheckMobileHook";

export const Subscribe: React.FC<SubscribeProps> = ({ onSubscribe }) => {
  const { handleSubmit, control } = useFormContext();
  const { isMobile } = CheckMobileHook();

  return (
    <div className={clsx(styles.subscribe_content, {
      [styles.subscribe_content_mobile]: isMobile
    })}>
      <div className={styles.subscribe_info}>
        <h2 className={styles.subscribe_title}>
          Join the Inner Circle
          <br />
          <span className={styles.subscribe_subtitle}>Stay Connected!</span>
        </h2>

        <p className={styles.subscribe_description}>{subscripeText}</p>

        <form
          onSubmit={handleSubmit(onSubscribe)}
          className={styles.subscribe_form}
        >
          <Grid container item xs={12} className={styles.subscribe_field}>
            <Controller
              control={control}
              name="subscribe"
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <>
                  <TextField
                    className="text-field"
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    variant="outlined"
                    error={Boolean(error)}
                    placeholder="Your e-mail"
                  />

                  <Message msg={error?.message} />
                </>
              )}
            />
          </Grid>

          <Button
            variant="outlined"
            color="primary"
            type="submit"
            className={`${styles.subscribe_btn} submit-btn-save-changes`}
          >
            Subscribe
          </Button>
        </form>
      </div>

      <div className={styles.subscribe_box}>
        <img src={subscribe} alt="" />
      </div>
    </div>
  );
};
