import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import type {
  AvailableVenuesStateProps,
  mainReducerState,
  CategoryProps,
  AvailableCardVenuesEntity,
} from "./interfaces/mainInterfaces";

const initialState = {
  categories: [],
  otherVenues: [],
  availableVenues: {
    availableVenues: [],
    amountAvailableVenues: 0
  },
  popularTags: [],
  isLoadingBanner: false,
  banners: [],
} as mainReducerState;

const mainReducer = createSlice({
  name: "main",
  initialState,
  reducers: {
    loadCategories: (state, action: PayloadAction<CategoryProps[]>) => {
      state.categories = action.payload;
    },
    loadOtherVenues: (state, action: PayloadAction<AvailableCardVenuesEntity[]>) => {
      state.otherVenues = action.payload;
    },
    firstLoadAvailableVenues: (state, action: PayloadAction<AvailableVenuesStateProps>) => {
      state.availableVenues = action.payload;
    },
    nextLoadAvailableVenues: (state, action: PayloadAction<AvailableVenuesStateProps>) => {
      const { availableVenues, amountAvailableVenues } = action.payload;
      state.availableVenues = {
        availableVenues: [...state.availableVenues.availableVenues, ...availableVenues],
        amountAvailableVenues
      };
    },
    getPopularTags: (state, action) => {
      state.popularTags = action.payload
    },
    getBannersRequest: (state: any) => {
      state.isLoadingBanner = true;
    },
    getBannersSucceed: (state: any, action) => {
      state.isLoadingBanner = false;
      state.banners = action.payload;
    },
    getBannersFailed: (state: any, action) => {
      state.isLoadingBanner = false;
      state.banners = action.payload;
    },
  },
}
);

export const {
  loadCategories,
  loadOtherVenues,
  firstLoadAvailableVenues,
  nextLoadAvailableVenues,
  getPopularTags,
  getBannersRequest,
  getBannersSucceed,
  getBannersFailed
} = mainReducer.actions;

export default mainReducer.reducer;
