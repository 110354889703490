import React from "react";

import { stepsForMyBookingDialog } from "~/constants";
import { BookingCard } from "~/Containers/MyBookingsPage/components/BookingCard";
import { CancelBooking } from "../CancelBooking/CancelBooking";
import { RescheduleBooking } from "~/Containers/MyBookingsPage/components/RescheduleBooking";
import type { MyBookingDialogContentProps } from "~/Containers/MyBookingsPage/interfaces";

export const MyBookingDialogContent: React.FC<MyBookingDialogContentProps> = (
  props
) => {
  const {
    step,
    onChangeTimeError,
    onChangeDayOffError,
    timeSelect,
    timeStartEnd,
  } = props;

  switch (step) {
    case stepsForMyBookingDialog.main:
      return <BookingCard />;
    case stepsForMyBookingDialog.reschedule:
      return (
        <RescheduleBooking
          onChangeTimeError={onChangeTimeError}
          onChangeDayOffError={onChangeDayOffError}
          timeSelect={timeSelect}
          timeStartEnd={timeStartEnd}
        />
      );
    case stepsForMyBookingDialog.cancel:
      return <CancelBooking />;
    default:
      return null;
  }
};
