import { useState } from "react";
import history from "~/utils/history";
import { useForm } from "react-hook-form";

import { useAppSelector } from "~/reduxConfig";
import { VenueReducerState } from "~/store/reducers/interfaces/venueInterfaces";
import { venueSelector } from "~/store/selectors";
import type { ServicesProps, VenuePageLocationStateProps } from "~/Containers/VenuePage/interfaces";

export const ServicesHook = (props) => {
  const {
    service,
    value,
    index,
    packageService,
  } = props as ServicesProps;

  const locationState = history.location.state as VenuePageLocationStateProps;
  const dateTime = locationState?.dateTime;

  const servicesFormMethods = useForm();
  const {getValues} = servicesFormMethods;
  const {venue} = useAppSelector<VenueReducerState>(venueSelector);
  const serviceName = packageService?.packageName || service?.serviceName;
  const description = packageService?.description || service?.description;
  const pricingOptions = service?.pricingOptions || [];

  const [serviceOptions, setServiceOptions] = useState({
    price: "",
    specialPrice: "",
    duration: "",
    durationTitle: "",
  });

  const onServiceOptions = (options) => {
    setServiceOptions({...serviceOptions, ...options});
  };

  return {
    service,
    packageService,
    value,
    index,
    dateTime,
    getValues,
    venue,
    pricingOptions,
    serviceName,
    description,
    serviceOptions,
    servicesFormMethods,
    onServiceOptions
  };
};