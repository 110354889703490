import axios from "~/utils/api";

const authCustomerApi = {
  signIn: (payload) => axios.post("customer/auth-customer/sign-in", payload),
  signUp: (payload) => axios.post("customer/auth-customer/sign-up/v2", payload),
  additionalInfo: (payload) => axios.put("customer/auth-customer/additional-info", payload),
  verification: (payload) => axios.put("customer/auth-customer/verify-account", payload),
  resendVerification: () => axios.post("customer/auth-customer/resend-account-verification"),
  forgotPassword: (payload) => axios.post("customer/auth-customer/forgot-password", payload),
  confirmation: (payload) => axios.post("customer/auth-customer/confirmation-forgot-password-code", payload),
  resetPassword: (payload) => axios.put("customer/auth-customer/reset-password", payload),
  changePassword: (payload) => axios.put("customer/auth-customer/change-password", payload),
  changeEmail: (payload) => axios.post("customer/auth-customer/change-email", payload),
  confirmChangeEmail: (payload) => axios.put("/customer/auth-customer/confirm-change-email", payload),
  validateEmail: (email) => axios.get("/customer/auth-customer/validate-email", {
    params: {
      email,
    },
  }),
  confirmChangePhone: (payload) => axios.put("/customer/auth-customer/confirm-change-phone", payload),
  changePhone: (payload) => axios.put("customer/profile-settings/change-phone-number", payload),
  changeInformation: (payload) => axios.put("customer/auth-customer/information", payload),
  signInFacebook: (payload) => axios.post("customer/auth-customer/grant-access", payload),
  sendOpt: (payload) => axios.post("customer/auth-customer/otp", payload),
  confirmOpt: (payload) => axios.post("customer/auth-customer/confirm-otp", payload),
};

export default authCustomerApi;
