import { useFormContext, useWatch } from "react-hook-form";

import { useEffect, useMemo } from "react";
import { onClearBookingCountPriceAction, onLoadBookingCountPriceAction } from "~/store/actions/bookingActions";
import { useAppDispatch, useAppSelector } from "~/reduxConfig";
import { bookingSelector } from "~/store/selectors";
import type { BookingReducerStateProps } from "~/store/reducers/interfaces/bookingInterfaces";
import type { BookingFormProps } from "~/Containers/BookingFlowPage/interfaces";
import type { GetCountRequestProps } from "~/store/actions/interfaces/bookingActionsInterfaces";


export const TotalSectionHook = () => {
  const { control } = useFormContext<BookingFormProps>();
  const dispatch = useAppDispatch();
  const { selectedServices = [], venueId } = useWatch<BookingFormProps>({ control });
  const { countBookingPrice } = useAppSelector<BookingReducerStateProps>(bookingSelector);
  const requestData = useMemo<GetCountRequestProps>(() => ({
    venueId,
    services: selectedServices.map((service) => {
      const payload = {
        pricingOptionId: service.selected.pricingOptionId,
        staffPricingOptionId: "null",
        staffId: service.selected.staffId !== 0
          ? service.selected.staffId
          : (service.staff[1] || service.staff[0])?.id,
        serviceId: service.id || service.selected.serviceId,
        packageId: service.packageId,
      }
      if (service.type === "package") {
        delete payload.serviceId;
        delete payload.pricingOptionId
        delete payload.staffPricingOptionId;
      } else {
        delete payload.packageId;
      }
      return payload;
    })
  }), [selectedServices, venueId]);

  useEffect(() => {
    if (selectedServices.length === 0) {
      dispatch(onClearBookingCountPriceAction());
      return;
    }
    dispatch(onLoadBookingCountPriceAction(requestData));
  }, [selectedServices, requestData, dispatch]);

  return { countBookingPrice };
};