import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { setLocation } from "~/store/reducers/locationReducer";
import { useAppSelector } from "~/reduxConfig";
import { locationSelector } from "~/store/selectors";
import { geocodeExtractor } from "~/utils/geocodeExtractor";
import type { LocationReducerStateProps } from "~/store/reducers/interfaces/locationInterfaces";
import type { AutocompletePlaceProps } from "~/Components/MainHeader/components/HeaderSearchLocation/interfaces";
import { GoogleApi } from "~/services/api/GoogleApi";

type AutocompletePrediction = google.maps.places.AutocompletePrediction;

let service;
let geocoder;

export const HeaderSearchLocationHook = () => {
  const dispatch = useDispatch();
  const { placeId } = useAppSelector<LocationReducerStateProps>(locationSelector);
  const google = window.google;

  const [selectedPlace, setSelectedPlace] = useState<AutocompletePlaceProps>({ description: "", place_id: "" });
  const [places, setPlaces] = useState<AutocompletePrediction[]>([]);

  useEffect(() => {
    initGoogleServices();
    if (placeId) {
      geocoder.geocode({ placeId }, (results) => {
        setSelectedPlace({ description: results[0].formatted_address, place_id: results[0].place_id });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placeId]);

  const initGoogleServices = () => {
    service = new google.maps.places.AutocompleteService();
    geocoder = new google.maps.Geocoder();
  };

  const onInputChange = (value) => {
    service.getPlacePredictions({
      input: value,
      types: ["(cities)"],
      componentRestrictions: { country: ["AU", "NZ"] }
    }, (response) => {
      setPlaces(response || [{ description: "", place_id: "" }]);
    });
  };

  const onSelect = async (value) => {
    setSelectedPlace(value);
    if (!value) return;
    geocoder.geocode({ placeId: value.place_id }, async (geocode) => {
      const country = geocodeExtractor.getCountry(geocode);
      const city = geocodeExtractor.getCity(geocode);
      const latLng = geocodeExtractor.getLatLng(geocode);
      const fetchTimezone = await GoogleApi.getTimezone(latLng);
      const timezone = fetchTimezone.data.timeZoneId;
      const location: LocationReducerStateProps = {
        placeId: value.place_id,
        country,
        city,
        timezone,
        ...latLng
      };
      dispatch(setLocation(location));
    });
  };

  return {
    selectedPlace,
    places,
    onInputChange,
    onSelect,
  };
};
