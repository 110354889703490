import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";

import type {
  PricingOptionFormProps,
  ServiceForSelectionProps,
} from "~/Containers/BookingFlowPage/interfaces";
import { SelectedServicesHook } from "../SelectedServices/SelectedServicesHook";

export const AddServiceItemHook = (props) => {
  const {
    service,
    isService = true,
    onPickServicesForRemove,
    onPickServicesForAdd,
    onPickServicesForChange,
  } = props as ServiceForSelectionProps;
  const { selectedServices, getBookingFormValues, setBookingFormValues } = SelectedServicesHook();

  const defaultPricingOptionId = selectedServices.find(
    (selectedService) => selectedService.id === service.id
  )?.selected.pricingOptionId;

  const [isServiceAdded, setIsServiceAdded] = useState<boolean>(() =>
    selectedServices.some(
      (selectedService) => selectedService.selected?.serviceId === service.id
    )
  );

  const pricingOptionFormMethods = useForm<PricingOptionFormProps>();
  const {
    formState: { isDirty },
    watch,
  } = pricingOptionFormMethods;
  const { pricingOptionId, staffPricingOptionId } = watch();

  const pickedService = useMemo(
    () => ({
      serviceId: service.id,
      pricingOptionId,
      staffPricingOptionId,
    }),
    [pricingOptionId, service.id, staffPricingOptionId]
  );

  const [serviceOptions, setServiceOptions] = useState({
    price: "",
    specialPrice: "",
    duration: "",
    durationTitle: "",
  });

  useEffect(() => {
    if (!isDirty) return;
    onPickServicesForChange(pickedService);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty, pickedService]);

  const onServiceOptions = (options) => {
    setServiceOptions({ ...serviceOptions, ...options });
  };

  const onChange = (data) => {
    if (isServiceAdded) {
      onPickServicesForRemove(data);
    } else {
      onPickServicesForAdd(data);
    }
    setIsServiceAdded(!isServiceAdded);
  };

  return {
    service,
    isService,
    pricingOptionFormMethods,
    onServiceOptions,
    defaultPricingOptionId,
    isServiceAdded,
    onChange,
    serviceOptions,
    getBookingFormValues,
    setBookingFormValues,
  };
};
