import React from "react";
import { IconButton, TextField } from "@material-ui/core";

import { HeaderSearchLocationHook } from "~/Components/MainHeader/components/HeaderSearchLocation/HeaderSearchLocationHook";
import Autocomplete, { AutocompleteRenderInputParams } from "@material-ui/lab/Autocomplete";
import SearchIcon from "@material-ui/icons/Search";
import type { AutocompletePlaceProps } from "~/Components/MainHeader/components/HeaderSearchLocation/interfaces";
import { IProps } from './interfaces';

import styles from "./HeaderSearchLocationStyle.module.scss";

interface IHeaderSearchLocation {
  onClose?: () => void;
}

export const HeaderSearchLocation: React.FC<IProps> = ({ onClose } : IHeaderSearchLocation) => {
  const headerSearchLocationHook = HeaderSearchLocationHook();

  const {
    selectedPlace,
    places,
    onInputChange,
    onSelect,
  } = headerSearchLocationHook;

  return (
    <div className={styles.container}>
      <Autocomplete
        id="autocomplete"
        options={places}
        placeholder="Search for city"
        autoHighlight
        value={selectedPlace}
        noOptionsText="No results"
        getOptionLabel={place => place.description}
        getOptionSelected={place => typeof place.description === "string"}
        onChange={(event, value) => {
          onSelect(value);
          if (value) {
            onClose()
          }
        }}
        onInputChange={(event, value) => {
          onInputChange(value);
        }}
        PaperComponent={(props) => (<div className={styles.paper}>{props.children}</div>)}
        renderInput={(params: AutocompleteRenderInputParams) => (
          <div className={`${styles.search_panel} ${"search_panel"}`} ref={params.InputProps.ref}>
            <IconButton type="submit" className={styles.search_btn} aria-label="search">
              <SearchIcon />
            </IconButton>
            <TextField
              {...params}
              className={`${styles.search_input} ${"search_input"}`}
              label="Search input"
              margin="normal"
              variant="outlined"
              InputProps={{ ...params.InputProps, type: "search" }}
            />
          </div>
        )}
        renderOption={(place: AutocompletePlaceProps) => (<div>{place.description}</div>)}
      />
    </div>
  );
};

