import { makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => ({
  origin: {
    width: '99%',
    minHeight: 60,
    '& .MuiTabs-fixed	': {
      '&>span': {
        display: 'none',
      },
    },
    '& .MuiTabs-scroller': {
      overflow: 'auto !important',
    },
    '& .MuiButtonBase-root': {
      marginRight: theme.spacing(1),
      background: '#ffff !im',
      boxShadow: '0px 4px 20px rgb(122 122 122 / 10%)',
      borderRadius: 8,
      padding: theme.spacing(0, 2),

      '& .MuiTab-wrapper': {
        flexDirection: 'inherit',
        justifyContent: 'left',
        textAlign: 'left',
        [theme.breakpoints.down('md')]: {
          textAlign: 'center',
          justifyContent: 'center',
          color: '#7E849B',
        },
      },
    },
    '& .MuiTab-textColorInherit.Mui-selected': {
      border: 'none',
      background: '#7F2FEA',
      fontSize: 16,
      lineHeight: '24px',
      borderRadius: theme.spacing(1),
      '& >span': {
        color: '#FFFFFF',
      },
    },
  },
  tabpanel: {
    '& >div': {
      padding: 0,
    },
  },
}));
