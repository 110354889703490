import React, { useMemo } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { AuthorizedHeader } from "~/Components/MainHeader/AuthorizedHeader";
import { UnauthorizedHeader } from "~/Components/MainHeader/UnauthorizedHeader";

import { homePageRoute, mainLayoutRoute, searchResultsPageRoute, venuePageRoute } from "~/constants";
import { HomePage } from "~/Containers/HomePage";
import { VenuePage } from "~/Containers/VenuePage";
import { SearchResultsPage } from "~/Containers/SearchResultsPage";
import type { MainLayoutProps } from "~/Layouts/MainLayout/interfaces";

export const MainLayout: React.FC<MainLayoutProps> = (props) => {
  const {isLoggedIn, match, location: {pathname}} = props;

  const handleClass = useMemo(() => {
    if (pathname === match.path.concat(homePageRoute)) return "";
    return "static-header-white fixed-position";
  }, [pathname, match.path]);

  return (
    <>
      {isLoggedIn
        ? <AuthorizedHeader handleClass={handleClass} />
        : <UnauthorizedHeader handleClass={handleClass} />
      }
      <main>
        <Switch>

          <Route
            path={match.path.concat(homePageRoute)}
            render={(p) => <HomePage {...p} />}
          />

          <Route
            path={match.path.concat(venuePageRoute, `/:id`)}
            render={(p) => <VenuePage {...p} />}
          />

          <Route
            path={match.path.concat(searchResultsPageRoute)}
            render={(p) => <SearchResultsPage {...p} />}
          />

          <Redirect to={mainLayoutRoute.concat(homePageRoute)} />

        </Switch>
      </main>

    </>
  );
};
