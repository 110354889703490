import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  PaymentMethodReducerState,
  VenueProps,
} from "./interfaces/paymentMethodInterfaces";

const paymentMethodReducer = createSlice({
  name: "paymentMethod",
  initialState: {
    cards: [],
    venue: {},
    message: "",
    defaultCardId: "",
  } as PaymentMethodReducerState,
  reducers: {
    loadCustomerCards(state, action: PayloadAction<Object[]>) {
      state.cards = action.payload;
    },
    addCardOnce(state, action: PayloadAction<Object>) {
      state.cards = [...state.cards, action.payload];
    },
    loadDefaultCard(state, action: PayloadAction<string>) {
      state.defaultCardId = action.payload;
    },
    fetchVenue: (state, action: PayloadAction<VenueProps>) => {
      state.venue = action.payload;
    },
    setMessage(state, action: PayloadAction<string>) {
      state.message = action.payload;
    },
    hideMessage(state) {
      state.message = "";
    },
  },
});

export const {
  loadCustomerCards,
  loadDefaultCard,
  fetchVenue,
  setMessage,
  hideMessage,
  addCardOnce,
} = paymentMethodReducer.actions;

export default paymentMethodReducer.reducer;
