import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import type { SearchReducerStateProps, CategoryProps } from "~/store/reducers/interfaces/searchInterfaces";

const initialState = {
  searchResult: [],
  amountSearchResults: 0,
  categories: [],
  isRequesting: true,
  isOpen: false
} as SearchReducerStateProps;

const searchReducer = createSlice({
  name: "search",
  initialState,
  reducers: {
    fetchSearchResult: (state, action: PayloadAction<SearchReducerStateProps>) => {
      state.searchResult = action.payload.searchResult;
      state.amountSearchResults = action.payload.amountSearchResults;
    },
    fetchCategories: (state, action: PayloadAction<CategoryProps[]>) => {
      state.categories = [{ id: 0, code: "All", description: [] }, ...action.payload];
    },
    clearSearchPageStore: () => initialState,
    searchLoading: (state, action: PayloadAction<boolean>) => {
      state.isRequesting = action.payload;
    },
    handleOpen: (state , action: any) => {
      state.isOpen = action.payload;
    }
  }
});

export const { fetchSearchResult, clearSearchPageStore, fetchCategories, searchLoading , handleOpen } = searchReducer.actions;

export default searchReducer.reducer;
