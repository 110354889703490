import React from "react";
import { Box, Typography } from '@material-ui/core';

import styles from "./Marketing2Styles.module.scss";

export const Marketing2 = () => {
  return (
    <div className={styles.marketing_2}>
      <Box className={styles.wrapper_text}>
        <Typography className={styles.text}>
          "At Glamezy we find joy in helping others thrive,
          so whatever the connection is  between us, we look forward to nurturing us".
        </Typography>
      </Box>
    </div>
  );
};
