import React, { useEffect, useState } from "react";
import moment from "moment";
import { isEmpty } from "lodash";
import { useDispatch } from "react-redux";

import { BookingHeadingBox } from "~/Containers/MyBookingsPage/components/BookingHeadingBox";
import { useAppSelector } from "~/reduxConfig";
import { myBookingsSelector, userLocationSelector } from "~/store/selectors";
import { BookingServiceItem } from "~/Containers/MyBookingsPage/components/BookingServiceItem";
import { addressExtractor, getDistance } from "~/utils/addressExtractor";
import type { MyBookingsStateProps } from "~/store/reducers/interfaces/myBookingsInterfaces";

import styles from "./BookingCardStyle.module.scss";
import { formatPrice, getGoogleMapLink } from "~/utils/helper";
import { ChangeCardDialog } from "../MyBookingDialog/ChangeCardDialog";
import { viewedDateFormat } from "~/constants";
import MapWrapper from "~/Components/Map/Map";
import { AvailableCardVenuesEntity } from "~/store/reducers/interfaces/mainInterfaces";
import LocationIcon from "~/Assets/images/location_on.png";
import { stripeApi } from "~/services/api/stripeApi";
import { onLoadBookingByIdAction, onLoadMyBookingsAction } from "~/store/actions/myBookingsActions";

export const BookingCard: React.FC = () => {
  const { bookingById } = useAppSelector<MyBookingsStateProps>(myBookingsSelector);
  const userLocation = useAppSelector(userLocationSelector);
  const [showChangeCardModal, setShowChangeCardModal] = useState(false);
  const [changingCard, setChangingCard] = useState(false);
  const [distance, setDistance] = useState<string | number>(0);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isEmpty(userLocation) || isEmpty(bookingById?.venue)) {
      return;
    }

    const { lat, lng } = userLocation;
    const { lat: venueLat, lng: venueLng } = bookingById.venue;
    const newDistance = getDistance(lat, lng, +venueLat, +venueLng);
    setDistance(newDistance);
  }, [userLocation, bookingById]);

  const onChangeCardClick = () => {
    setShowChangeCardModal(true);
  };

  const onCloseChangeCardModal = () => {
    setShowChangeCardModal(false);
  };

  const submitChangeCard = async (cardId) => {
    setChangingCard(true);
    try {
      await stripeApi.changeCardId(bookingById.id, cardId);
      dispatch(onLoadBookingByIdAction(bookingById.id));
      dispatch(onLoadMyBookingsAction());
      setShowChangeCardModal(false);
    } catch (error) {
      console.log("submitChangeCard error", error);
    }
    setChangingCard(false);
  };

  if (!bookingById) return null;

  const {
    id,
    venue,
    status,
    bookingServices,
    subtotal,
    tax,
    total,
    paymentMethod,
    type,
    adjustBookingDiscounts,
    invoice,
  } = bookingById;

  const { logo, locationName, timezone } = venue;
  const totalBooking = +invoice[0]?.totalAmount || total;
  const displayedAddress = addressExtractor(venue);
  const displayedSubtotal = subtotal / 1000 < 1 ? subtotal : (subtotal / 1000).toFixed(3);
  const displayedTotal = totalBooking / 1000 < 1 ? totalBooking : (totalBooking / 1000).toFixed(3);
  const invalidCard = !!paymentMethod && paymentMethod.status !== "valid";
  let valueDiscount = 0;
  if (adjustBookingDiscounts?.discountType === "PERCENTAGE") {
    const discountPercentage = (total / (1 - +adjustBookingDiscounts?.discountValue / 100)) * +adjustBookingDiscounts?.discountValue / 100;
    valueDiscount = discountPercentage > +adjustBookingDiscounts?.discountCap && +adjustBookingDiscounts?.discountCap !== 0 ? +adjustBookingDiscounts?.discountCap : discountPercentage
  } else {
    valueDiscount = +adjustBookingDiscounts?.discountValue > +adjustBookingDiscounts?.discountCap && +adjustBookingDiscounts?.discountCap > 0
      ? +adjustBookingDiscounts?.discountCap
      : +adjustBookingDiscounts?.discountValue
  }
  const startDateTime = bookingServices[0] ? bookingServices[0].startDateTime : new Date();
  const displayedDate = moment(startDateTime).format(viewedDateFormat);
  const bookingServicesCount = bookingServices?.reduce((total, item) => {
    if (item?.package) {
      return total = total + item.package.packageServices.length
    } else {
      return total = total + 1
    }
  }, 0)

  return (
    <>
      <div className={styles.card}>
        <BookingHeadingBox
          logo={logo}
          heading={locationName}
          address={displayedAddress}
          status={status}
          type={type}
          id={id}
        />

        <div className={styles.service_box}>
          <span>
            {bookingServicesCount} service{bookingServicesCount > 1 ? "s" : ""}
          </span>
          <time>{displayedDate}</time>
        </div>

        {bookingServices.map((bookingService) => (
          <BookingServiceItem
            key={bookingService.id}
            bookingService={bookingService}
            timezone={timezone}
          />
        ))}

        <div className={styles.subtotal}>
          <div className={styles.row}>
            <span>Subtotal</span>
            <span className={styles.cost}>
              ${formatPrice(displayedSubtotal)}
            </span>
          </div>
          <div className={styles.row}>
            <span>GST included</span>
            <span className={styles.cost}>${formatPrice(tax)}</span>
          </div>
        </div>
        <div className={styles.subtotal}>
          {adjustBookingDiscounts ? (
            <div className={styles.row}>
              <span>Discount</span>
              <span className={styles.cost_large}>
                -${formatPrice(valueDiscount)}
              </span>
            </div>
          ) : null}
          <div className={styles.row}>
            <span>Total</span>
            <span className={styles.cost_large}>
              ${formatPrice(displayedTotal)}
            </span>
          </div>
          {invalidCard && (
            <div className={styles.invalidCard}>
              <div className={styles.invalidCardContent}>
                <i className="icon-exclamation-outline" />
                <div>
                  <p>{`Card number ending in **** ${paymentMethod.card.last4} is invalid.`}</p>
                  <p>
                    Please nominate a new card prior to the booking date to
                    facilitate smooth transaction.
                  </p>
                </div>
              </div>
              <button
                className={styles.changeCardBtn}
                onClick={onChangeCardClick}
              >
                Change card
              </button>
            </div>
          )}
        </div>
      </div>

      {invalidCard && (
        <ChangeCardDialog
          cardId={paymentMethod.id}
          title={moment(bookingServices[0]?.startDateTime).format(
            viewedDateFormat
          )}
          isRequesting={changingCard}
          showModal={showChangeCardModal}
          onCloseModal={onCloseChangeCardModal}
          onChangeCard={submitChangeCard}
        />
      )}

      {venue && (
        <>
          <div className={styles.sub_row}>
            <span>Location</span>
            <a
              href={getGoogleMapLink(venue)}
              className={styles.viewOnMap}
              target="_blank"
              rel="noreferrer noopener"
            >
              View on map
            </a>
          </div>
          <div className={styles.mapWrap}>
            <MapWrapper
              venues={[venue as AvailableCardVenuesEntity]}
              isSingleView
              hideZoomControl
            />
          </div>
          <div className={styles.textMapWrap}>
            <p className={styles.address}>
              <img src={LocationIcon} alt="" />
              {displayedAddress}
            </p>
            {distance !== 0 && (
              <p className={styles.distance}>{distance}km from you</p>
            )}
          </div>
        </>
      )}
    </>
  );
};
