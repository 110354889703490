import React from "react";
import { TextareaAutosize } from "@material-ui/core";
import { Controller, useWatch } from "react-hook-form";
import type { TextAreaMaxLengthParams } from "./interfaces";

import styles from "./TextAreaMaxLengthStyle.module.scss";

export const TextAreaMaxLength: React.FC<TextAreaMaxLengthParams> = (params) => {
  const {
    formMethods,
    fieldName,
    maxLength,
    placeholder,
    textAreaClass,
  } = params;
  const {control} = formMethods;

  const length = useWatch({control: control, name: fieldName})?.length || 0;

  return (
    <Controller
      control={control}
      name={fieldName}
      render={({field: {onChange, value},}) => (
          <div className={styles.area_box}>
            <TextareaAutosize
              className={`${styles.textarea} ${styles[textAreaClass]}`}
              value={value}
              aria-label="minimum height"
              minRows={5}
              maxLength={maxLength}
              onChange={onChange}
              placeholder={placeholder}
            />
            <span className={styles.count}>
              {length}/{maxLength}
            </span>
          </div>
      )}
    />);
};
