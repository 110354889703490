import { useState } from 'react';

export const DialogMobileHook = () => {
  const [openModalSearch, setOpenModalSearch] = useState(false);

  const handleModalSearch = () => setOpenModalSearch(!openModalSearch);

  return {
    openModalSearch,
    handleModalSearch,
  };
};
