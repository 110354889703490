import React from "react";
import { Avatar } from "@material-ui/core";

import { StaffModalPicker } from "~/Components/StaffModalPicker/StaffModalPicker";
import type { SelectedServiceItemProps } from "~/Containers/BookingFlowPage/interfaces";

import styles from "./SelectedServiceStyle.module.scss";
import { SelectedServiceItemHook } from "~/Containers/BookingFlowPage/components/SelectedServiceItem/SelectedServiceItemHook";
import { formatPrice, convertDurationString } from "~/utils/helper";

export const SelectedServiceItem: React.FC<SelectedServiceItemProps> = (
  props
) => {
  const {
    pricingOptionName,
    serviceName,
    selectedStaffName,
    selectedStaff,
    price,
    fullDuration,
    timePeriod,
    bookingFormMethods,
    index,
    id,
    staff,
    amountService,
  } = SelectedServiceItemHook(props);
  const textDuration = convertDurationString(fullDuration)

  return (
    <div className={styles.form_radio_btn_row}>
      <input
        id={`radio-${id}`}
        value={index}
        name="radio"
        type="radio"
        {...bookingFormMethods.register("currentFieldIndex")}
      />
      <label htmlFor={`radio-${id}`} className={styles.form_radio_btn}>
        <div className={styles.row_holder}>
          <span>
            {serviceName}{" "}
            {amountService > 0 && (
              <span className={styles.packages}>
                {" "}
                ({amountService} services)
              </span>
            )}{" "}
            {pricingOptionName ? "• " + pricingOptionName : ""}
          </span>
          <span className={styles.cost}>${formatPrice(price)}</span>
        </div>
        <div className={styles.row_holder}>
          <div className={styles.user}>
            <Avatar
              className={styles.avatar}
              alt={`${selectedStaff?.firstName[0]}${selectedStaff?.lastName[0]}`.toUpperCase()}
              src={selectedStaff?.avatar}
            />
            <span className={styles.staffName}>{selectedStaffName} •</span>
            <StaffModalPicker
              formMethods={bookingFormMethods}
              fieldName={`selectedServices.${index}.selected.staffId`}
              staff={staff}
            />
          </div>

          <span className={styles.user}>
            <span>{timePeriod}</span>&nbsp;•&nbsp;{textDuration}
          </span>
        </div>
        <span className={styles.check}>check</span>
      </label>
    </div>
  );
};
