import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import styles from './AccordionMui.module.scss';

interface IAccordion {
  title: string;
  detail: JSX.Element[] | JSX.Element;
  handleAction?: () => void;
}

export default function AccordionMui({
  title,
  detail,
  handleAction,
}: IAccordion) {
  return (
    <Accordion className={styles.main_accordion} onClick={handleAction}>
      <AccordionSummary
        className={styles.accordionSummary}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails className={styles.accordionDetails}>
        {detail}
      </AccordionDetails>
    </Accordion>
  );
}
