import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { sessionSelector } from "../../../../store/selectors";
import {
  signInAction,
  clearMessageAction,
  signUpAction,
  signUpCodeVerificationAction,
  forgotConfirmationAction,
  resetPasswordAction,
  onChangeInformation,
  signInFacebookAction,
  additionalInfoAction,
  sendOptAction,
} from "../../../../store/actions/sessionActions";
import authCustomerApi from "../../../../services/api/authCustomerApi";
import signInSchema from "../../../../services/validationServices/signInSchema";
import { steps } from "../../../../constants";

const DialogsHook = () => {
  const dispatch = useDispatch();
  const { message } = useSelector(sessionSelector);

  const [showModal, setShowModal] = useState(false);
  const [signUpState, setSignUpState] = useState({});
  const [step, setStep] = useState(steps.signIn);
  const [errorMessage, setErrorMessage] = useState("");
  const [signUpFb, setSignUpFb] = useState(false);
  const [signInFb, setSignInFb] = useState(false);

  const {
    control,
    register,
    trigger,
    getValues,
    setValue,
    setError,
    clearErrors,
    setFocus,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(signInSchema),
    mode: "onChange",
    criteriaMode: "all",
  });

  useEffect(() => {
    dispatch(clearMessageAction());
  }, [step, dispatch]);

  useEffect(() => {
    if (!showModal) {
      reset();
      if (errorMessage) setErrorMessage("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset, showModal]);

  const hideMessage = () => {
    if (message) dispatch(clearMessageAction());
    if (errorMessage) setErrorMessage("");
  };

  const onSendAgain = () => {
    if (signUpFb || signInFb) {
      authCustomerApi.resendVerification();
    } else {
      const { phone } = getValues();

      dispatch(sendOptAction({ phoneNumber: phone }));
    }
  };

  const onSendAgainForReset = () => {
    const { emailReset } = getValues();
    authCustomerApi.forgotPassword({ email: emailReset });
  };

  const signInFacebook = (data, isSignUp) => {
    dispatch(
      signInFacebookAction(data, (phone, firstName, lastName) => {
        setValue("phone", phone);
        setValue("firstName", firstName);
        setValue("lastName", lastName);
        setStep(steps.additionalInfo);
        if (isSignUp) {
          setSignUpFb(true);
        } else {
          setSignInFb(true);
        }
      })
    );
  };

  const onSubmit = async () => {
    switch (step) {
      case steps.signIn: {
        await trigger(["emailSignIn", "passwordSignIn"]);
        const { emailSignIn: emailError, passwordSignIn: passwordError } =
          errors;

        if (emailError || passwordError) {
          return;
        } else {
          const { emailSignIn: email, passwordSignIn: password } = getValues();
          if (window.location.hostname === "customer.glamezy.com") {
            window.dataLayer.push({
              event: "login",
              user_data: {
                email,
              },
            });
          }
          dispatch(
            signInAction({ email, password }, (phone, firstName, lastName) => {
              setValue("phone", phone);
              setValue("firstName", firstName);
              setValue("lastName", lastName);
              setStep(steps.confirmation);
            })
          );
        }
        break;
      }
      case steps.signUp: {
        await trigger(["email", "password", "privacyPolicy"]);
        const {
          email: emailErr,
          password: pwErr,
          privacyPolicy: privacyErr,
        } = errors;

        if (emailErr || pwErr || privacyErr) {
          return;
        } else {
          const {
            email,
            password,
            privacyPolicy,
            receiveDiscountsAndPromotions,
          } = getValues();
          if (window.location.hostname === "customer.glamezy.com") {
            window.dataLayer.push({
              event: "sign_up",
              user_data: {
                email,
                first_name: "",
                last_name: "",
              },
            });
          }
          const { status, data } = await authCustomerApi.validateEmail(email);

          if (status === 400) {
            setErrorMessage(data.message);
          }
          if (status === 200) {
            setStep(steps.additionalInfo);
            setSignUpState((pre) => ({
              ...pre,
              email,
              password,
              privacyPolicy,
              receiveDiscountsAndPromotions,
            }));
          }
        }
        break;
      }
      case steps.additionalInfo: {
        await trigger(["firstName", "lastName", "phone"]);
        const { firstName: fErr, lastName: lErr, phone: pErr } = errors;

        if (fErr || lErr || pErr) {
          return;
        } else {
          const { firstName, lastName, phone } = getValues();
          if (signUpFb || signInFb) {
            dispatch(
              additionalInfoAction(
                {
                  firstName,
                  lastName,
                  phone,
                },
                () => setStep(steps.confirmation)
              )
            );
          } else {
            dispatch(
              sendOptAction({ phoneNumber: phone }, () => {
                setStep(steps.confirmation);
                setSignUpState((pre) => ({
                  ...pre,
                  isSignUp: true,
                  phone,
                  firstName,
                  lastName,
                }));
              })
            );
          }
        }
        break;
      }
      case steps.changePhoneNumber: {
        await trigger(["firstName", "lastName", "phone"]);
        const { firstName: fErr, lastName: lErr, phone: pErr } = errors;

        if (fErr || lErr || pErr) {
          return;
        } else {
          const { firstName, lastName, phone } = getValues();
          dispatch(
            onChangeInformation(
              {
                firstName,
                lastName,
                phone,
              },
              () => setStep(steps.confirmation)
            )
          );
        }
        break;
      }
      case steps.confirmation: {
        await trigger(["digit1", "digit2", "digit3", "digit4", "digit5"]);
        const {
          digit1: dig1Err,
          digit2: dig2Err,
          digit3: dig3Err,
          digit4: dig4Err,
          digit5: dig5Err,
        } = errors;

        if (dig1Err || dig2Err || dig3Err || dig4Err || dig5Err) {
          return;
        } else {
          const { digit1, digit2, digit3, digit4, digit5 } = getValues();
          if (signUpState?.isSignUp) {
            dispatch(
              signUpAction(
                {
                  email: signUpState.email,
                  password: signUpState.password,
                  privacyPolicy: signUpState.privacyPolicy,
                  receiveDiscountsAndPromotions:
                    signUpState.receiveDiscountsAndPromotions,
                  firstName: signUpState.firstName,
                  lastName: signUpState.lastName,
                  phone: signUpState.phone,
                  confirmationCode: digit1 + digit2 + digit3 + digit4 + digit5,
                },
                () => {
                  setShowModal(false);
                  setSignUpState({});
                  setStep(steps.signUp);
                }
              )
            );
          } else {
            dispatch(
              signUpCodeVerificationAction({
                verificationCode: digit1 + digit2 + digit3 + digit4 + digit5,
              })
            );
          }
        }
        break;
      }
      case steps.forgotPassword: {
        await trigger(["emailReset"]);
        const { emailReset: emailResetError } = errors;

        if (emailResetError) {
          return;
        } else {
          const { emailReset } = getValues();
          const { status, data } = await authCustomerApi.forgotPassword({
            email: emailReset,
          });
          if (status === 400) {
            setErrorMessage(data.message);
          }
          if (status === 200) {
            setStep(steps.forgotPasswordCode);
          }
        }
        break;
      }
      case steps.forgotPasswordCode: {
        await trigger(["digit1", "digit2", "digit3", "digit4", "digit5"]);
        const {
          digit1: dig1,
          digit2: dig2,
          digit3: dig3,
          digit4: dig4,
          digit5: dig5,
        } = errors;

        if (dig1 || dig2 || dig3 || dig4 || dig5) {
          return;
        } else {
          const { digit1, digit2, digit3, digit4, digit5, emailReset } =
            getValues();
          dispatch(
            forgotConfirmationAction(
              {
                email: emailReset,
                verificationCode: digit1 + digit2 + digit3 + digit4 + digit5,
              },
              () => setStep(steps.forgotPasswordNewPassword)
            )
          );
        }
        break;
      }
      case steps.forgotPasswordNewPassword: {
        await trigger(["newPassword", "newPasswordRepeat"]);
        const {
          newPassword: newPWErr,
          newPasswordRepeat: newPasswordRepeatErr,
        } = errors;

        if (newPWErr || newPasswordRepeatErr) {
          return;
        } else {
          const {
            digit1,
            digit2,
            digit3,
            digit4,
            digit5,
            emailReset,
            newPassword,
            newPasswordRepeat,
          } = getValues();
          if (newPassword === newPasswordRepeat) {
            dispatch(
              resetPasswordAction(
                {
                  email: emailReset,
                  verificationCode: digit1 + digit2 + digit3 + digit4 + digit5,
                  newPassword: newPassword,
                },
                () => setStep(steps.signIn)
              )
            );
            setValue("emailSignIn", emailReset);
            setValue("passwordSignIn", "");
          } else {
            setError("newPasswordRepeat", {
              type: "manual",
              message: "Please make sure your passwords match",
            });
          }
        }
        break;
      }
      default:
        return null;
    }
  };

  return {
    step,
    steps,
    errors,
    control,
    message,
    showModal,
    setStep,
    onSubmit,
    register,
    setFocus,
    setValue,
    getValues,
    hideMessage,
    onSendAgain,
    clearErrors,
    setShowModal,
    onSendAgainForReset,
    errorMessage,
    signInFacebook,
    signUpState,
    signUpFb,
    signInFb,
    setSignUpState,
    setSignUpFb,
    setSignInFb,
  };
};

export default DialogsHook;
