import axios from "~/utils/api";
import qs from "qs";

export const bookingApi = {
  getBookingServices: (params) => {
    const { venueId } = params;
    return axios.get(`/customer/booking/services/${venueId}`);
  },
  getBookingStaff: (params) => {
    const { venueId, serviceId, startDateTime, endDateTime } = params;
    return axios.get(`/customer/booking/staff/${venueId}`, {
      params: { serviceId, startDateTime, endDateTime },
    });
  },
  getCountBookingPrice: (params) => {
    return axios.get(`/customer/booking/count-booking-price`, {
      params,
      paramsSerializer: (str) => qs.stringify(str),
    });
  },
  postBookingConfirm: (data) => {
    return axios.post("customer/booking/confirm", data);
  },
  newBooking: (data) => {
    return axios.post("customer/booking", data);
  },
};
