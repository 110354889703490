import { useFormContext } from "react-hook-form";
import moment from "moment";
import { useState } from "react";

import { dateFormat } from "~/constants";
import type { SearchFormProps } from "~/Containers/SearchResultsPage/interfaces";
import { CheckMobileHook } from "~/Components/MainHeader/CheckMobileHook";

export const DatePickerHook = () => {
  const { isMobile } = CheckMobileHook();
  const { control, getValues, reset, setValue } = useFormContext<SearchFormProps>();
  const now = moment().format(dateFormat);
  const [selectedDate, setSelectedDate] = useState<Date>(() => new Date());
  const [isPickerOpen, setIsPickerOpen] = useState<boolean>(false);

  const onChangeHandler = (dateTime, onChange) => {
    const date = moment(dateTime).format(dateFormat);
    if (isMobile) {
      setValue('date', date);
    } else {
      onChange(date);
    }
  };

  const onClearHandler = (onChange) => {
    onChange(now);
    const { date, ...restFormValues } = getValues();
    reset({ ...restFormValues });
  };

  return {
    now,
    control,
    isPickerOpen,
    setIsPickerOpen,
    selectedDate,
    setSelectedDate,
    onChangeHandler,
    onClearHandler,
  };
};