import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import { Paper } from '@material-ui/core';

import PaymentMethodsHook from './PaymentMethodsHook';
import DialogMain from '~/Components/DialogMain/DialogMain';
import { Cards } from '../Cards';

import { SaveNewCard } from '~/Containers/PaymentFlowPage/components/PaymentMethods/StripePayement/SaveNewCard';
import { EditCard } from './EditCard';
import styles from './PaymentMethodsStyle.module.scss';
import { useAppSelector } from '~/reduxConfig'
import { sessionSelector } from '~/store/selectors'

export const PaymentMethods: React.FC = () => {
  const customer = useAppSelector(sessionSelector);
  const paymentMethodsHook = PaymentMethodsHook();
  const {
    sortedCards,
    defaultCardId,
    cardForm,
    addCardModal,
    setAddCardModal,
    onSubmitAddCard,
    editCardModal,
    setEditCardModal,
    onSubmitEditCard,
    onRemoveCard,
    onShowModalAndSetCardId,
  } = paymentMethodsHook;

  const { cardName } = cardForm.getValues();
  const [values, setValues] = useState({
    cardName: '',
    isDefault: true,
  });

  useEffect(() => {
    setValues({
      cardName: cardName || '',
      isDefault: true,
    });
  }, [cardName]);

  const handleChange = (newValues) => {
    setValues(newValues);
  };

  const handleSubmit = () => {
    onSubmitEditCard({
      ...values,
      stripeCustomerId: customer.stripeCustomerId,
    });
  };

  return (
    <div className='card-wrapper'>
      <Paper variant='outlined'>
        <div className={styles.text}>
          <h2 className={styles.title}>Payment</h2>
          <p className={styles.subtitle}>
            Your saved payment methods are displayed here
          </p>
        </div>

        <div>
          {sortedCards?.map((card) => (
            <Cards
              key={card.id}
              defaultCardId={defaultCardId}
              {...card}
              onShowModalAndSetCardId={onShowModalAndSetCardId}
            />
          ))}
        </div>

        <div>
          <Button
            variant='outlined'
            color='primary'
            onClick={() => setAddCardModal(true)}
            className='white-btn'
          >
            Add card
          </Button>
        </div>

        {addCardModal && (
          <DialogMain
            showModal={addCardModal}
            setShowModal={setAddCardModal}
            onSubmit={onSubmitAddCard}
            openDialogButtonClass='white-btn'
            titleDialog='Add card'
            submitButtonText='Save card'
            dialogWindowClass={'modal-dialog-medium'}
            isNotClickOutside
          >
            <SaveNewCard onClose={() => setAddCardModal(false)} />
          </DialogMain>
        )}

        {editCardModal && (
          <DialogMain
            showModal={editCardModal}
            setShowModal={setEditCardModal}
            onSubmit={handleSubmit}
            titleDialog='Change card'
            submitButtonText='Save changes'
            dialogWindowClass={'modal-dialog-medium'}
            secondButtonClass='btn-text'
            submitSecondButtonText='Delete card'
            onSubmitSecond={onRemoveCard}
          >
            <EditCard values={values} onChange={handleChange} />
          </DialogMain>
        )}
      </Paper>
    </div>
  );
};
