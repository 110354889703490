import { Dispatch } from "redux";
import { venuesApi } from "~/services/api/venuesApi";
import {
  fetchVenue,
  fetchStaff,
  fetchNearby,
  fetchServices,
  fetchReviews,
  clearVenuePageStore
} from "~/store/reducers/venueReducer";
import { GetVenueStaffByIdRequest } from "~/store/actions/interfaces/venueActionsInterfaces";
import { mainLayoutRoute } from "~/constants";
import history from "~/utils/history";

export const onLoadVenueAction = (venueId: number) => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await venuesApi.getVenueById(venueId);
      if (response.status === 200) {
        dispatch(fetchVenue(response.data));
      }
      if (response.status === 404) {
        history.push(mainLayoutRoute);
      }
    } catch (error: any) {
      console.log("error", error);
    }
  };
};

export const onLoadVenueStaffAction = (data:GetVenueStaffByIdRequest) => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await venuesApi.getVenueStaffById(data);
      dispatch(fetchStaff(response.data));
    } catch (error: any) {
      console.log("error ----", error);
    }
  };
};

export const onLoadNearbyVenuesAction = (venueId: number) => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await venuesApi.getNearbyVenuesById(venueId);
      dispatch(fetchNearby(response.data));
    } catch (error: any) {
      console.log("error", error);
    }
  };
};

export const onLoadVenueServicesAction = (venueId: number) => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await venuesApi.getVenueServicesById(venueId);
      dispatch(fetchServices(response.data));
    } catch (error: any) {
      console.log("error", error);
    }
  };
};

export const onLoadVenueReviewsAction = (venueId: number) => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await venuesApi.getVenueReviewsById(venueId);
      dispatch(fetchReviews(response.data.reviews));
    } catch (error: any) {
      console.log("error", error);
    }
  };
};

export const onClearVenuePageStoreAction = () => (dispatch: Dispatch) => {
  dispatch(clearVenuePageStore())
};