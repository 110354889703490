import React, { useEffect } from "react";
import moment from "moment";
import pluralize from "pluralize";
import type { PricingProps } from "~/Containers/VenuePage/interfaces";
import { formatPrice, convertDurationString } from "~/utils/helper";

export const Pricing: React.FC<PricingProps> = ({
  pricing,
  onChange = undefined,
  value,
  onServiceOptions,
  checked,
  isStaffPricingOption,
  staffPricingOptionId,
}) => {

  const handleChange = () => {
    onChange && onChange();
  };
  
  useEffect(() => {
    if (value === pricing.id || staffPricingOptionId === pricing.id) {
      const { price, specialPrice, duration } = pricing;

      const time = moment.duration(duration, "hours").asHours();

      onServiceOptions({
        price: `$${formatPrice(price)}`,
        specialPrice: specialPrice ? `$${formatPrice(pricing.specialPrice)}` : "",
        duration: time,
        durationTitle: pluralize("hour", time),
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, staffPricingOptionId]);

  const variantName = isStaffPricingOption ? `${pricing.variantName} • ` : `${convertDurationString(pricing.duration)} - `
  const btnTitle = `${variantName}$${formatPrice(pricing.specialPrice || pricing.price)}`

  return (
    <>
      <input
        id={`pricing-${pricing.id}`}
        type="radio"
        onChange={handleChange}
        checked={(!checked && value === pricing.id) || checked || false}
      />
      <label htmlFor={`pricing-${pricing.id}`}>{btnTitle}</label>
    </>
  );
};
