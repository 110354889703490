import * as yup from "yup";

const addCard = {
  cardName: yup.string().required().min(2),
  cardNumber: yup.string().required(),
  cardDate: yup.string().required(),
  cardCVV: yup.string().required(),
};

const addCardSchema = yup.object().shape(addCard);

export default addCardSchema;
