import { useState } from "react";
import { useFormContext } from "react-hook-form";
import moment from "moment";


import type { SearchFormProps } from "~/Containers/SearchResultsPage/interfaces";
import { dateFormat, timeFormat } from "~/constants";
import { CheckMobileHook } from "~/Components/MainHeader/CheckMobileHook";

export const TimePickerHook = () => {
  const { isMobile } = CheckMobileHook();
  const { control, getValues, reset, setValue } = useFormContext<SearchFormProps>();
  const now = moment();
  const [selectedTame, setSelectedTime] = useState<Date>(() => new Date());
  const [timeArea, setTimeArea] = useState<string>("AM");

  const [isPickerOpen, setIsPickerOpen] = useState<boolean>(false);


  const timeConverter = (dateTime) => {
    let newTime;
    const time = moment(dateTime).format(timeFormat);
    const timeHours = +moment(dateTime).format("HH");
    const timeMinutes = moment(dateTime).format("mm");
    switch (timeArea) {
      case "AM":
        newTime = timeHours <= 12
          ? time
          : moment(`${timeHours - 12}${timeMinutes}`, "hmm").format(timeFormat);
        break;
      case "PM":
        newTime = timeHours >= 12
          ? time
          : moment(`${timeHours + 12}${timeMinutes}`, "hmm").format(timeFormat);
    }
    return newTime;
  };

  const viewTimeConverter = (value) => {
    if (!value) return null;
    const nowDate = now.format(dateFormat);
    return moment(`${nowDate} ${value}`);
  };

  const onChangeHandler = (dateTime, onChange) => {
    const time = timeConverter(dateTime);
    if (isMobile) {
      setValue("time", time);
    } else {
      onChange(time);
    }
  };

  const onClearHandler = (onChange) => {
    onChange(now);
    const { time, ...restFormValues } = getValues();
    reset({ ...restFormValues });
  };

  return {
    now,
    control,
    isPickerOpen,
    setIsPickerOpen,
    selectedTame,
    setSelectedTime,
    timeArea,
    setTimeArea,
    viewTimeConverter,
    onChangeHandler,
    onClearHandler
  };
};