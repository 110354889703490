import React from "react";

import visa from "~/Assets/images/visa.svg";
import mastercard from "~/Assets/images/mastercard.svg";
import type { CardsProps } from "~/Containers/UserProfilePage/interfaces";

import styles from "./CardsStyle.module.scss";
import { CARD_VALID_STATUSES } from "~/Containers/MyBookingsPage/components/MyBookingItem";

const logoOfBrandsCards = {
  visa,
  mastercard,
};

export const Cards: React.FC<CardsProps> = ({
  defaultCardId,
  id,
  billing_details,
  card,
  status,
  onShowModalAndSetCardId,
}) => {
  const { brand, last4, exp_month, exp_year } = card;
  const month = String(exp_month).length === 1 ? `0${exp_month}` : exp_month;
  const invalidCard = !!status && !CARD_VALID_STATUSES.includes(status);

  return (
    <div className={styles.card}>
      <div className={styles.card_title}>
        <img src={logoOfBrandsCards[brand]} alt="logo" />
        <div className={styles.statusWrap}>
          {invalidCard && (
            <span className={styles.invalid}>Invalid</span>
          )}
          {defaultCardId === id && (
            <span className={styles.default}>Default</span>
          )}
        </div>
      </div>

      <ul className={styles.card_number}>
        <li>xxxx</li>
        <li>xxxx</li>
        <li>xxxx</li>
        <li>{last4}</li>
      </ul>

      <div className={styles.card_info}>
        <div>{billing_details.name}</div>
        <div>
          <span>{month}</span>/<span>{exp_year}</span>
        </div>
      </div>

      <button
        className={styles.btn_edit}
        onClick={() => onShowModalAndSetCardId(id)}
      >
        <i className="icon-edit"></i>
        Edit card
      </button>
    </div>
  );
};
