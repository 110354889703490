import axios from "~/utils/api";

export const stripeApi = {
  setupPayment: (data?: { customerId: string }) => {
    return axios.post("/stripe/setup-intent", data);
  },
  createPaymentIntent: (data: {
    amount: number;
    customerId: string;
    cardId?: any;
    receipt_email?: string;
  }) => {
    return axios.post("/stripe/create-payment-intent", data);
  },
  cancelPaymentIntent: (data: { paymentIntentId: string }) => {
    return axios.put("/stripe/cancel-payment-intent", data);
  },
  removePaymentMethod: (cardId: any) => {
    return axios.delete(`/stripe/remove-payment-method/${cardId}`);
  },
  updatePaymentMethod: (data: {
    stripeCustomerId: string;
    cardId: any;
    creditCardDto: {
      cardName: string;
      isDefault: boolean;
    };
  }) => {
    return axios.put("/stripe/update-payment-method", data);
  },
  changeCardId: (bookingId: number, cardId: any) =>
    axios.put(`/customer/bookings/${bookingId}/change-card-booking`, { cardId }),
  getCardData: (cardId: any) => axios.get(`/stripe/payment-method/${cardId}`),
};
