import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    accordionBlock: {
      boxShadow: "none",
      margin: "0 !important",
      "&::before": {
        display: "none",
      },
    },
    accordionDetails: {
      margin: "24px 0",
      padding: "0",
    },
    accordionSummary: {
      padding: "0",
    },
    heading: {
      fontSize: "18px !important",
      flexShrink: 0,
    },
  })
);