import { FlagsEntity } from '~/store/reducers/interfaces/mainInterfaces';
import styles from './Flags.module.scss';

export default function Flags({ flags }: { flags: FlagsEntity[] }) {
  return (
    <span className={styles.flags}>
      {flags.map((flag) => (
        <span key={flag.type} className={`${styles.flag} ${styles[flag.type]}`}>
          {flag.value}
        </span>
      ))}
    </span>
  );
}
