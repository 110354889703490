import React from "react";

import moment from "moment-timezone";
import { useAppSelector } from "~/reduxConfig";
import { myBookingsSelector } from "~/store/selectors";
import type { MyBookingsStateProps } from "~/store/reducers/interfaces/myBookingsInterfaces";

import styles from "./CancelBookingStyle.module.scss";

export const CancelBooking: React.FC = () => {
  const { bookingById } = useAppSelector<MyBookingsStateProps>(myBookingsSelector);

  if (!bookingById) return null;

  const { bookingServices, venue, invoice } = bookingById;
  moment.tz.setDefault(venue.timezone);

  const [firstBookingService] = bookingServices;
  const durationByHour = moment(firstBookingService.startDateTime).diff(
    moment(),
    "hours"
  );

  const percentCharge = 0.2; // || durationByHour < 8 ? 0.5 : 0.3;
  const newTotal = +invoice[0]?.totalAmount

  return (
    <div className={styles.cancelCard}>
      <p className={styles.title}>
        Your booking is coming in {durationByHour} hours
      </p>
      <p className={styles.title}>
        If you still want to cancel this booking, you will be charged
        <strong> ${(newTotal * percentCharge).toFixed(2)}</strong> (charge 20% of <strong> ${(newTotal).toFixed(2)}</strong>).
      </p>
      <p>
        More detail about our{" "}
        <a href="/general/info?value=1" target="_blank">cancel booking policy</a>:
      </p>
      <ul>
        <li>
          Before from 0 to 4hr: <strong>Charge 20%</strong>
        </li>
        {/* <li>
          Before 8 to 24hr: <strong>Charge 30%</strong>
        </li> */}
        <li>
          Before 4hr: <strong>Free cancel</strong>
        </li>
      </ul>
    </div>
  );
};
