import React from "react";

import { TotalSectionHook } from "~/Containers/BookingFlowPage/components/TotalSection/TotalSectionHook";
import { formatPrice } from "~/utils/helper";

import styles from "./TotalSectionStyle.module.scss";

export const TotalSection: React.FC = () => {
  const { countBookingPrice } = TotalSectionHook();

  if (countBookingPrice.total === 0) return null;
  return (
    <>
      <div className={styles.row}>
        <div className={styles.row_holder}>
          <span>Subtotal</span>
          <span className={styles.cost}>${formatPrice(countBookingPrice?.subtotal)}</span>
        </div>
        <div className={styles.row_holder}>
          <span>GST included</span>
          <span className={styles.cost}>${formatPrice(countBookingPrice?.tax)}</span>
        </div>
      </div>
      <div className={styles.row}>
        <div className={`${styles.row_holder} ${styles.row_total}`}>
          <span>Total</span>
          <span className={styles.cost}>${formatPrice(countBookingPrice?.total)}</span>
        </div>
      </div>
    </>
  );
};