import React, { useEffect } from 'react';
import { Tabs, Tab, Box } from '@material-ui/core';
import { useLocation } from 'react-router';

import { titlesGeneralInfo } from '~/constants';
import { Intro } from '~/Components/Intro';
import { TabPanel } from '~/Components/TabPanel';
import { CheckMobileHook } from '~/Components/MainHeader/CheckMobileHook';
import "./styles.scss";
import {
  listDefinitions,
  listDisputeResolutions,
  listGeneral,
  listIndemnity,
  listIntellectualProPerty,
  listLinkingToTheWebsite,
  listOverviewOfService,
  listUsingTheService,
  listRegistration,
  listHowMuch,
  listHowDoYouGetPaid,
  listWhatAreYour,
  listCancellationOfBooking,
  listWarranties,
  listTermination,
  listLiability,
  listLinkingToTheWebsiteSecond,
  listGeneralSecond,
  listDefinitionsSecond,
  listIndemnityPartner,
  listPersonalInformation,
  listCollection,
  personalInformation,
  listDisclosurePersonalInformation,
  listAccessManagement,
  listSecurity,
  listCookies
} from './constants';

function tabProps(index: any) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

interface Location {
  state: null | { value: number };
  search: string;
}

export const LegalPage: React.FC = (props: any) => {
  const { state: locationState, search }: Location = useLocation();
  const query = new URLSearchParams(search);
  const valueTab = query.get('value');
  const [value, setValue] = React.useState(locationState?.value || 0);
  const { isMobile } = CheckMobileHook()

  useEffect(() => {
    setValue(+valueTab || 0);
  }, [valueTab]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (locationState) {
      setValue(locationState.value);
    }
  }, [locationState]);

  return (
    <>
      <Intro heading={titlesGeneralInfo.legalTitle} />
      <div className='container-holder-tab'>
        <div className={`tab-panel ${isMobile && "tab-panel-mobile"}`}>
          <div className='col1 col1-policy'>
            <Tabs
              orientation='vertical'
              value={value}
              onChange={handleChange}
              aria-label='Vertical tabs'
              className='tabs-block'
            >
              <Tab
                className='tab-btn'
                label='Privacy Policy'
                {...tabProps(0)}
              />
              <Tab
                className='tab-btn'
                label='Cancellation policy'
                {...tabProps(1)}
              />
              <Tab
                className='tab-btn'
                label='Customer Terms and Conditions'
                {...tabProps(2)}
              />
              <Tab
                className='tab-btn'
                label='Partner Terms & Conditions'
                {...tabProps(3)}
              />
            </Tabs>
          </div>
          <div className='col2'>
            <TabPanel value={value} index={0}>
              <h1>Privacy Policy</h1>
              <p>
                Glamezy Pty Ltd (ACN 645 960 363) trading as Glamezy (
                <span className='text-bold'>“we”</span>,{' '}
                <span className='text-bold'>“us”</span> or{' '}
                <span className='text-bold'>“our”</span>), and the operation of
                our website at www.glamezy.com (
                <span className='text-bold'>“Website”</span>) and our app
                available for download (<span className='text-bold'>“App”</span>
                )(collectively, our{' '}
                <span className='text-bold'>“Platform”</span>) is committed to
                respecting your privacy.
              </p>
              <p>
                Our Privacy Policy sets outs out how we collect, use, store,
                share and disclose your personal information. By accessing and
                using our Platform and services, you freely and expressly
                consent to the collection, use, processing, storage and
                disclosure of Personal Information by us as set out in this
                Privacy Policy.
              </p>
              <h3>1. Openness and transparency</h3>
              <Box display="flex">
                <Box marginRight="8px">1.1</Box>
                <p>
                  We are committed to protecting your privacy and respecting and
                  upholding your rights under the Australian Privacy Principles (
                  <span className='text-bold'>APPs</span>) contained in the
                  Privacy Act 1988 (Cth) (
                  <span className='text-bold'>Privacy Act</span>). We ensure that
                  we will take all necessary and reasonable steps to comply with
                  the APPs and to deal with inquiries or complaints from
                  individuals about compliance with the APPs.
                </p>
              </Box>
              <h3>2. Personal information</h3>
              {
                listPersonalInformation.map((item, index) => (
                  <Box display="flex" key={index}>
                    <Box marginRight="8px">{`2.${index + 1}`}</Box>
                    <Box display="flex" flexDirection="column">
                      <p>{item.title}</p>
                      {
                        item?.listOl?.length > 0 && (
                          <ol className="olStyles">
                            {item.listOl.map((itemLi, index) => <li key={index}>{itemLi}</li>)}
                          </ol>
                        )
                      }
                    </Box>
                  </Box>
                ))
              }
              <h3>3. Collection</h3>
              {
                listCollection.map((item, index) => (
                  <Box display="flex" key={index}>
                    <Box marginRight="8px">{`3.${index + 1}`}</Box>
                    <Box display="flex" flexDirection="column">
                      <p>{item}</p>
                    </Box>
                  </Box>
                ))
              }
              <h3>4. What do we do with your personal information?</h3>
              <Box display="flex">
                <Box marginRight="8px">4.1</Box>
                <Box display="flex" flexDirection="column">
                  <p>{personalInformation.title}</p>
                  {
                    personalInformation?.listOl?.length > 0 && (
                      <ol className="olStyles">
                        {personalInformation.listOl.map((itemLi, index) => <li key={index}>{itemLi}</li>)}
                      </ol>
                    )
                  }
                </Box>
              </Box>
              <h3>5. Disclosure of Personal Information</h3>
              {
                listDisclosurePersonalInformation.map((item, index) => (
                  <Box display="flex" key={index}>
                    <Box marginRight="8px">{`5.${index + 1}`}</Box>
                    <Box display="flex" flexDirection="column">
                      <p>{item.title}</p>
                      {
                        item?.listOl?.length > 0 && (
                          <ol className="olStyles">
                            {item.listOl.map((itemLi, index) => <li key={index}>{itemLi}</li>)}
                          </ol>
                        )
                      }
                    </Box>
                  </Box>
                ))
              }
              <h3>6. Access and management</h3>
              {
                listAccessManagement.map((item, index) => (
                  <Box display="flex" key={index}>
                    <Box marginRight="8px">{`6.${index + 1}`}</Box>
                    <Box display="flex" flexDirection="column">
                      <p>{item}</p>
                    </Box>
                  </Box>
                ))
              }
              <h3>7. Direct marketing</h3>
              <Box display="flex">
                <Box marginRight="8px">7.1</Box>
                <Box display="flex" flexDirection="column">
                  <p>
                    Where we have your express or implied consent, or where we are
                    otherwise permitted by law, we may use your personal information
                    to send you information about products and services we believe
                    are suited to you and your interests or we may invite you to
                    attend special events.
                  </p>
                </Box>
              </Box>
              <Box display="flex">
                <Box marginRight="8px">7.2</Box>
                <Box display="flex" flexDirection="column">
                  <p>
                    <span className='text-underline text-bold'>
                      At any time, you may opt out of receiving direct marketing
                      communications from us
                    </span>
                    . Unless you opt out, your consent to receive direct marketing
                    communications from us and to the handling of your personal
                    information as detailed above, will continue. You can opt out by
                    following the unsubscribe instructions included in the relevant
                    marketing communication, or by contacting us in writing at
                    unsubscribe@glamezy.com.
                  </p>
                </Box>
              </Box>
              <h3>8. Cross-border disclosure</h3>
              <Box display="flex">
                <Box marginRight="8px">8.1</Box>
                <Box display="flex" flexDirection="column">
                  <p>
                    We may disclose your personal information to third party
                    recipients located in or outside of Australia in order to
                    provide our Platform, to you{' '}
                    <span className='text-bold'>(Recipients)</span>.
                  </p>
                </Box>
              </Box>
              <Box display="flex">
                <Box marginRight="8px">8.2</Box>
                <Box display="flex" flexDirection="column">
                  <p>
                    When entering into a transaction with us you consent to your
                    personal information being disclosed or transferred to such
                    Recipients and you acknowledge and agree that we have no
                    obligation to take such steps as are reasonable in the
                    circumstances to ensure that the information that is transferred
                    or disclosed to the Recipients will be treated in a manner that
                    is consistent with the APPs. You also agree that insofar as the
                    law allows, we have no liability to you or anyone else for any
                    breach by the Recipient of the APPs.
                  </p>
                </Box>
              </Box>
              <h3>9. Glamezy’s Platform</h3>
              <Box display="flex">
                <Box marginRight="8px">9.1</Box>
                <Box display="flex" flexDirection="column">
                  <p>
                    When transmitting personal information from your computer to the
                    Glamezy Platform, you must keep in mind that the transmission of
                    information over the internet is not always completely secure or
                    error-free. Other than liability that cannot lawfully be
                    excluded, we will not be liable in any way in relation to any
                    breach of security or any unintended loss or disclosure of that
                    information.
                  </p>
                </Box>
              </Box>
              <h3>10. Security</h3>
              {
                listSecurity.map((item, index) => (
                  <Box display="flex" key={index}>
                    <Box marginRight="8px">{`10.${index + 1}`}</Box>
                    <Box display="flex" flexDirection="column">
                      <p>{item}</p>
                    </Box>
                  </Box>
                ))
              }
              <h3>11. Contact information</h3>
              <Box display="flex">
                <Box marginRight="8px">11.1</Box>
                <Box display="flex" flexDirection="column">
                  <p>
                    If you require further information regarding our Privacy Policy
                    or wish to make a privacy complaint, please contact us at{' '}
                    <span className='text-bold'>contact@glamezy.com</span> or call
                    us on: <span className='text-bold'>1300 425 615.</span>
                  </p>
                </Box>
              </Box>
              <h3>12. Cookies</h3>
              {
                listCookies.map((item, index) => (
                  <Box display="flex" key={index}>
                    <Box marginRight="8px">{`12.${index + 1}`}</Box>
                    <Box display="flex" flexDirection="column">
                      <p>{item}</p>
                    </Box>
                  </Box>
                ))
              }
              <h3>13. Information about children under 18</h3>
              <Box display="flex">
                <Box marginRight="8px">13.1</Box>
                <Box display="flex" flexDirection="column">
                  <p>
                    Our Platform is not intended for users under the age of 18. We
                    acknowledge that the definition of a “minor” changes between
                    jurisdictions, however we do not knowingly seek or collect
                    Personal Information from any children below the age of 18
                    years.
                  </p>
                </Box>
              </Box>
              <h3>14. Deactivating your Account</h3>
              <Box display="flex">
                <Box marginRight="8px">14.1</Box>
                <Box display="flex" flexDirection="column">
                  <p>
                    If you wish to deactivate your Glamezy account, you may do so by
                    logging into your Glamezy account profile and follow
                    instructions as set out in this{' '}
                    <span className='text-bold'>Link</span>. You can request to
                    exercise these rights by emailing{' '}
                    <span className='text-underline text-bold'>
                      contact@glamezy.com
                    </span>.
                  </p>
                </Box>
              </Box>
              <Box display="flex">
                <Box marginRight="8px">14.2</Box>
                <Box display="flex" flexDirection="column">
                  <p>
                    We will endeavour to process your request within 30 days of
                    receiving your request. Note that we may require proof of
                    identification before we process your request.
                  </p>
                </Box>
              </Box>
              <h3>15. Third Party Offers</h3>
              <Box display="flex">
                <Box marginRight="8px">15.1</Box>
                <Box display="flex" flexDirection="column">
                  <p>
                    Occasionally, at our discretion, we may also include or offer
                    third party products or services on our Platform. By opting to
                    take up these third-party products you agree to Glamezy
                    providing your information to these third parties and you
                    acknowledge that these third party sites have separate and
                    independent privacy policies. We therefore have no
                    responsibility or liability for the content and activities of
                    these linked sites. Nonetheless, we seek to protect the
                    integrity of our site and welcome any feedback about these
                    sites.
                  </p>
                </Box>
              </Box>
              <h3>16. Miscellaneous</h3>
              <Box display="flex">
                <Box marginRight="8px">16.1</Box>
                <Box display="flex" flexDirection="column">
                  <p>
                    We reserve the right to modify this Privacy Policy in whole or
                    in part from time to time without notice and amendments will be
                    effective immediately upon posting of the amended Privacy Policy
                    on the Glamezy Platform.
                  </p>
                </Box>
              </Box>
              <h2>Dated: 01-January-2022</h2>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <h2>
                When you use our services, you’re trusting us with your
                information.
              </h2>
              <p>
                You can reschedule or cancel your appointment up to 8 hours before your appointment start time. You may be charged for a late cancellation fee or if you do not show up to your appointment.
              </p>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <h1>MARKETPLACE USER AGREEMENT</h1>
              <h3>ABOUT US</h3>
              <p>
                Glamezy provides an online platform where consumers to search,
                book and pay their beauty services appointments with nearby or
                preferred beauty business service venues.
              </p>
              <h3>AGREEMENT</h3>
              <h3>1. ABOUT THESE TERMS</h3>
              <Box display="flex">
                <Box marginRight="8px">1.1</Box>
                <p>
                  In these Terms, <span className='text-bold'>“we”</span>,{' '}
                  <span className='text-bold'>“us”</span> or{' '}
                  <span className='text-bold'>“our”</span> means Glamezy Pty Ltd
                  and includes its subsidiaries, and their respective directors,
                  employees and agents. <span className='text-bold'>“You”</span>{' '}
                  or <span className='text-bold'>“Your”</span> means the User who
                  accepts these Terms. Additional definitions are set out in
                  clause 19.
                </p>
              </Box>
              <Box display="flex">
                <Box marginRight="8px">1.2</Box>
                <p>
                  You should read these Terms carefully. They apply to your use of
                  our Platform.
                </p>
              </Box>
              <Box display="flex">
                <Box marginRight="8px">1.3</Box>
                <p>
                  By using or accessing the Website, you indicate your acceptance
                  of these Terms. By accepting these Terms, you agree to the
                  obligations imposed on you under these Terms and, in exchange,
                  we agree to provide you with access to the Website. If you do
                  not agree to these Terms you should immediately cease accessing
                  and using the Website.
                </p>
              </Box>
              <Box display="flex">
                <Box marginRight="8px">1.4</Box>
                <p>
                  We reserve all rights to alter these Terms at our discretion.
                  Each time you use the Website, it is your responsibility to be
                  aware of our current terms. Your continued use of the Website
                  following the posting of changes to these Terms will be deemed
                  your acceptance of those changes.
                </p>
              </Box>
              <Box display="flex">
                <Box marginRight="8px">1.5</Box>
                <p>
                  These Terms, as amended by us from time to time, continue to apply
                  for the duration of your use of the Website and Services or until
                  terminated in accordance with clause 9.
                </p>
              </Box>
              <h3>2. OVERVIEW OF SERVICES</h3>
              <Box display="flex">
                <Box marginRight="8px">2.1</Box>
                <p>
                  The Marketplace provides an easy and convenient way to find and
                  book Listers for beauty services. We do not supply or sell goods
                  and/or services on, or via, the Website.
                </p>
              </Box>
              <Box display="flex">
                <Box marginRight="8px">2.2</Box>
                <p>
                  To use the Services, a User must register for an account in
                  accordance with clause 4. An unregistered User may search and
                  find Listers’ profiles on the Website.
                </p>
              </Box>
              <Box display="flex">
                <Box marginRight="8px">2.3</Box>
                <p>
                  If you register for an account in accordance with clause 4 and
                  your registration is accepted by us, you will have access to the
                  Services and will be able to:
                </p>
              </Box>
              <ol className="olStyles">
                <li>search and find Listers’ profiles on the Website;</li>
                <li>
                  send booking requests to Listers who you would like to engage
                  to provide beauty services;
                </li>
                <li>
                  receive notifications when each Lister to whom a booking
                  request was sent either accepts or rejects the booking
                  request;
                </li>
                <li>
                  view a list of all Listers who have accepted a booking request
                  in order to select which Lister you would like to engage;
                </li>
                <li>
                  pay for your accepted bookings via PayPal or credit card;
                </li>
                <li>rate and review a Lister and their service offering;</li>
                <li>access your purchase history and receipts;</li>
                <li>contact our support directly; and</li>
                <li>
                  contact Listers directly to make inquiries and requests.
                </li>
              </ol>
              <Box display="flex">
                <Box marginRight="8px">2.4</Box>
                <p>
                  Registered Users may rate and review a Lister once a booking is
                  completed and will:
                </p>
              </Box>
              <ol className="olStyles">
                <li>
                  have the option to provide a rating regarding the goods and/or
                  services after they engage with a Lister for the use of any
                  goods and/or services; and
                </li>
                <li>
                  be able to rate a Lister’s goods and services (based on a
                  rating system of 1 to 5 stars), as well as give feedback
                  regarding the goods and/or services sold by the Lister.
                </li>
              </ol>
              <Box display="flex">
                <Box marginRight="8px">2.5</Box>
                <p>
                  Ratings and reviews provided by Users must be honest and
                  non-biased and at all times comply with these Terms. We reserve
                  the right to remove any reviews which we consider to be
                  malicious.
                </p>
              </Box>
              <h3>3. USING THE SERVICES</h3>
              <Box display="flex">
                <Box marginRight="8px">3.1</Box>
                <p>
                  You must ensure that your access to, and use of, the Services is
                  not illegal or otherwise prohibited by laws that apply in your
                  jurisdiction.
                </p>
              </Box>
              <Box display="flex">
                <Box marginRight="8px">3.2</Box>
                <p>
                  Any information on the Website or otherwise provided to Users is
                  supplied in good faith but we do not guarantee its accuracy or
                  completeness. You agree that it is your responsibility to make
                  enquiries as to the accuracy or completeness of any information.
                </p>
              </Box>
              <Box display="flex">
                <Box marginRight="8px">3.3</Box>
                <p>
                  You are responsible for verifying the identity and authenticity
                  of a Lister and its goods and/or services when you engage them
                  for the provision of goods and/or services. Despite our use of
                  reasonable endeavours to verify the identity of Listers on the
                  Marketplace, you acknowledge that, to the extent permitted by
                  law, we do not warrant the reliability, legitimacy, repute, or
                  credibility of any Lister, nor the capacity, ability or
                  willingness of the Lister to deliver or transact with you.
                </p>
              </Box>
              <Box display="flex">
                <Box marginRight="8px">3.4</Box>
                <p>
                  You agree that you have sole responsibility for any activity
                  that occurs on your account. You must keep your account details
                  and password secure, as you are responsible for any activity on
                  your account (whether undertaken by you or anyone else). You
                  agree to notify us immediately if you become aware of or suspect
                  any security breach or unauthorised use of your password or
                  account.
                </p>
              </Box>
              <Box display="flex">
                <Box marginRight="8px">3.5</Box>
                <p>
                  The Services may contain links to other websites. Those links
                  are provided for convenience only and may not be current. Any
                  hyperlinks or banners advertising other websites on the Website,
                  or external websites that advertise the Website, are not subject
                  to our control or privacy standards, policies and procedures. We
                  will not be responsible for the content or privacy practices
                  associated with such linked websites and we recommend that you
                  make your own enquiries as to the privacy and other policies of
                  those third parties.
                </p>
              </Box>
              <Box display="flex">
                <Box marginRight="8px">3.6</Box>
                <p>
                  You must take precautions to ensure that when accessing the
                  Services, you do not expose your telecommunications or computer
                  systems to viruses, malware or other forms of interference that
                  may damage your telecommunications or computer systems. We are
                  not responsible for any such damage to your telecommunications
                  or computer systems which may arise in connection with your
                  access to the Website and use of the Services.
                </p>
              </Box>
              <Box display="flex">
                <Box marginRight="8px">3.7</Box>
                <p>
                  While using the Website, and any associated Services, you must
                  not:
                </p>
              </Box>
              <ol className="olStyles">
                <li>
                  misuse any part of the Website by introducing viruses, trojans
                  or other material that is malicious or technologically
                  harmful;
                </li>
                <li>
                  attempt to gain unauthorised access to any part of the
                  Website, the server on which the Website is stored or any
                  server, computer or database connected to the Website;
                </li>
                <li>
                  engage in any activity that interferes with or disrupts the
                  Services or the servers and networks that host the Services;
                </li>
                <li>
                  attempt to circumvent, disable or otherwise interfere with any
                  security-related features of the Services or any features that
                  prevent or restrict the use or copying of any content or
                  enforce limitations on the use of the Services or the content
                  of the Website;
                </li>
                <li>engage in any abusive or derogatory behaviour; or</li>
                <li>
                  advocate, support or practice discrimination based on race,
                  religion, age, national origin, language, gender, sexual
                  orientation or mental or physical handicap.
                </li>
              </ol>
              <Box display="flex">
                <Box marginRight="8px">3.8</Box>
                <p>
                  We may contact you by email or provide you with information by
                  posting notices on the Website. It is your responsibility to
                  ensure that you have correct contact details registered with us,
                  that you give us written notice of any changes to your contact
                  details, and that you check your nominated email address
                  regularly for any correspondence.
                </p>
              </Box>
              <Box display="flex">
                <Box marginRight="8px">3.9</Box>
                <p>
                  We may, at our absolute discretion, terminate, suspend or delete
                  your account or restrict your access to the Services (or any
                  part of the Services) for any reason without notice. This
                  includes for security, technical, maintenance, legal or
                  regulatory reasons or due to any breach of these Terms. If we do
                  this, you may be prevented from accessing your account details
                  or other content contained in your account. We will not be
                  liable to you or any third party for doing so.
                </p>
              </Box>
              <Box display="flex">
                <Box marginRight="8px">3.10</Box>
                <p>
                  If you fail to comply with these Terms, we may, in our absolute
                  discretion and without liability:
                </p>
              </Box>
              <ol className="olStyles">
                <li>
                  immediately, temporarily or permanently withdraw your right to
                  access and use the Services and the Website (including
                  deletion of your account);
                </li>
                <li>take any other legal action against you; or</li>
                <li>
                  refer or report any suspected fraudulent, abusive or illegal
                  activity to relevant law enforcement authorities.
                </li>
              </ol>
              <h3>4. REGISTRATION</h3>
              <Box display="flex">
                <Box marginRight="8px">4.1</Box>
                <p>
                  To access certain parts of the Services, you must register with
                  us by providing us with Registration Data as requested. You may
                  not use one email address to register for multiple accounts.
                </p>
              </Box>
              <Box display="flex">
                <Box marginRight="8px">4.2</Box>
                <p>
                  We reserve the right to decline your registration request if you
                  do not pass our verification process.
                </p>
              </Box>
              <Box display="flex">
                <Box marginRight="8px">4.3</Box>
                <p>
                  If you are under the age of 18 years, we may ask you to confirm
                  that you have your parents’ or guardians’ permission and that
                  they have agreed to these Terms on your behalf. Even if we do
                  this, by submitting Registration Data to us, you acknowledge
                  that you have your parents’ or guardians’ permission and that
                  they have agreed to these Terms on your behalf.
                </p>
              </Box>
              <Box display="flex">
                <Box marginRight="8px">4.4</Box>
                <p>
                  If you are a parent or guardian who has permitted a person under
                  the age of 18 (a “<span className="text-bold">Minor</span>”) to create an account, you agree to:
                </p>
              </Box>
              <ol className="olStyles">
                <li>
                  exercise supervision over the Minor's use of our Services and
                  their personal account with us;
                </li>
                <li>
                  assume all risks associated with the Minor's use of our
                  Services and their account, including the transmission of
                  content or information to and from third parties via the
                  internet;
                </li>
                <li>
                  assume any and all liabilities resulting from the Minor's use
                  of our Services and their account;
                </li>
                <li>
                  ensure the accuracy and truthfulness of all information
                  submitted to us and the Website by the Minor; and
                </li>
                <li>
                  provide the necessary consents contained in these Terms on
                  behalf of the Minor.
                </li>
              </ol>
              <Box display="flex">
                <Box marginRight="8px">4.5</Box>
                <p>
                  We reserve the right to take legal action and seek compensation
                  from a parent or guardian of a Minor for any loss or damage we
                  may suffer as a result of, or in connection with, any
                  transaction entered into on, or via, the Website by that Minor.
                </p>
              </Box>
              <Box display="flex">
                <Box marginRight="8px">4.6</Box>
                <p>
                  Subject to clause 4.4, if you provide Registration Data to us,
                  you represent and warrant to us that the Registration Data
                  provided is true, complete, accurate and up to date, and you
                  undertake to maintain the truthfulness, completeness and
                  accuracy of your Registration Data as necessary and/or as
                  required by us.
                </p>
              </Box>
              <Box display="flex">
                <Box marginRight="8px">4.7</Box>
                <p>
                  If you provide us with Registration Data, you consent to the
                  following:
                </p>
              </Box>
              <ol className="olStyles">
                <li>
                  we may provide your Registration Data to Listers for the
                  purposes of providing you with the Services;
                </li>
                <li>
                  you may receive emails from us regarding details of your
                  registration, orders and/or purchases made through your
                  account, and/or information relating to your access and use of
                  the Services and your account; and
                </li>
                <li>
                  from time to time, we may email you regarding our Services or
                  third-party products and services which we believe may be of
                  interest to you, such as new products, features, special
                  offers and updated information. The emails may contain code
                  that enables our database to track your usage of the emails,
                  including whether the email was opened and/or what links (if
                  any) were clicked. We may combine that information with other
                  information which we have about you and may use that
                  information to improve your site experience and/or provide
                  customised email communications to you.
                </li>
              </ol>
              <Box display="flex">
                <Box marginRight="8px">4.8</Box>
                <p>
                  You acknowledge that we may use your Registration Data and any
                  other personal information provided to us in accordance with our
                  Privacy Policy.
                </p>
              </Box>
              <h3>5. PRICING</h3>
              <Box display="flex">
                <Box marginRight="8px">5.1</Box>
                <p>
                  All prices displayed on the Marketplace are subject to change
                  without notice.
                </p>
              </Box>
              <Box display="flex">
                <Box marginRight="8px">5.2</Box>
                <p>
                  All prices listed on the Marketplace will indicate the currency
                  that is applicable and will  inclusive of goods and services tax (GST) unless otherwise specified.
                </p>
              </Box>
              <h3>6. PURCHASES</h3>
              <Box display="flex">
                <Box marginRight="8px">6.1</Box>
                <p>
                  You acknowledge and agree that a listing on the Marketplace for
                  the provision of goods and/or services by a Lister constitutes
                  mere information, and does not form part of any offer to sell
                  those goods and/or services.
                </p>
              </Box>
              <Box display="flex">
                <Box marginRight="8px">6.2</Box>
                <p>
                  A registered User may communicate with Listers via the Website
                  for the purchase of goods and/or services. For the avoidance of
                  doubt, all purchases and/or payments made pursuant to any
                  agreement which utilises the Services is between you and the
                  Lister to the exclusion of us.
                </p>
              </Box>
              <Box display="flex">
                <Box marginRight="8px">6.3</Box>
                <p>
                  You are responsible for checking the suitability of services
                  offered by Listers via the Website with Listers directly.
                </p>
              </Box>
              <h3>7. PAYMENT</h3>
              <Box display="flex">
                <Box marginRight="8px">7.1</Box>
                <p>
                  Payments for goods and/or services listed via the Website may be
                  made via PayPal or credit card. We accept no responsibility for
                  transactions that occur outside the Marketplace
                </p>
              </Box>
              <Box display="flex">
                <Box marginRight="8px">7.2</Box>
                <p>Payments for beauty services must be either:</p>
              </Box>
              <ol className="olStyles">
                <li>
                  made at the time of booking with the relevant Lister via the
                  Website; or
                </li>
                <li>
                  made at the venue via the “Pay at venue” option. In this case,
                  the User’s card details will be required to make the
                  reservation and clause 8 applies for cancellation processes.
                </li>
              </ol>
              <Box display="flex">
                <Box marginRight="8px">7.3</Box>
                <p>
                  The User bears sole responsibility for verifying any terms and
                  conditions imposed by the Lister in relation to the sale of
                  goods and/or services.
                </p>
              </Box>
              <Box display="flex">
                <Box marginRight="8px">7.4</Box>
                <p>
                  We use Payment Processing Services provided by Stripe. Payments
                  submitted via Stripe are subject to the terms and conditions
                  published on the{' '}
                  <span className='text-underline'>Stripe website</span>{' '}
                  (collectively, the “
                  <span className='text-bold'>Stripe Services Agreement</span>”).
                  By placing an order and using Stripe to process payments you
                  agree to be bound by the Stripe Services Agreement, which may be
                  modified by Stripe from time to time. As a condition of enabling
                  Payment Processing Services through Stripe, you agree to provide
                  us with accurate and complete information about you and your
                  business, and you authorise us to share it and transaction
                  information related to your use of the Payment Processing
                  Services provided by Stripe in accordance with our Privacy
                  Policy.
                </p>
              </Box>
              <h3>8. CANCELLATION OF BOOKINGS</h3>
              <Box display="flex">
                <Box className='titleH3'>8.1</Box>
                <h3 className="paddingTop0">Beauty Services</h3>
              </Box>
              <ol className="olStyles">
                <li>
                  Once you have confirmed a booking for beauty services via the
                  Website and paid for it, you may cancel or reschedule the
                  booking in accordance with the following schedule:
                </li>
                <table className='table-beauty-services'>
                  <tbody>
                    <tr>
                      <th className='th-beauty-services text-bold'>
                        Notice period
                      </th>
                      <th className=' th-beauty-services text-bold'>
                        Fees and charges
                      </th>
                    </tr>
                    <tr>
                      <td className='td-beauty-services'>
                        24 hours ahead of booking
                      </td>
                      <td className='td-beauty-services'>Free cancellation</td>
                    </tr>
                    <tr>
                      <td className='td-beauty-services'>
                        8 to 24 hours ahead of booking
                      </td>
                      <td className='td-beauty-services'>
                        30% charge or free rescheduling
                      </td>
                    </tr>
                    <tr>
                      <td className='td-beauty-services'>
                        2 to 8 hours ahead of booking
                      </td>
                      <td className='td-beauty-services'>
                        50% charge or free rescheduling
                      </td>
                    </tr>
                  </tbody>
                </table>
                <li>
                  Once within the twenty-four (24) hour period before the scheduled booking time,
                  you may not cancel or reschedule your booking and you are not entitled to a refund.
                </li>
              </ol>

              {/* <p>
                If you do not attend your appointment within the first 30 minutes of
                the appointment start time, the booking will be regarded as a 'No Show'.
                Therefore, you will be charged a penalty of 30% of the total booking value.
              </p> */}
              <h3>9. TERMINATION</h3>
              <Box display="flex">
                <Box marginRight="8px">9.1</Box>
                <p>
                  In addition to our rights under clause 3, we may, at our
                  absolute discretion, terminate these Terms or cease to supply
                  you with access to the Website and/or the Services, if:
                </p>
              </Box>
              <ol className="olStyles">
                <li>
                  it transpires that you have provided false or misleading
                  information on the Website;
                </li>
                <li>
                  you are found by us to be offensive or abusive to a Lister or
                  other User; or
                </li>
                <li>
                  you fail to pay any fees or charges payable by you on time or at all.
                </li>
              </ol>
              <Box display="flex">
                <Box marginRight="8px">9.2</Box>
                <p>
                  If your access to the Website and/or the Services is terminated for any reason,
                  we will be entitled to payment for any outstanding fees or charges properly
                  incurred by us up to the date of termination and any fees or charges incurred
                  during any applicable notice period or otherwise specified in these Terms.
                </p>
              </Box>
              <h3>10. WARRANTIES</h3>
              <Box display="flex">
                <Box marginRight="8px">10.1</Box>
                <p>
                  We will, within a reasonable period of time, investigate any
                  alleged error or issue regarding any of the Services, provided
                  that you notify us in writing within seven (7) days of becoming
                  aware of the error or issue with all necessary information to be
                  able to investigate the error or issue. Notwithstanding, you
                  agree that our liability will be limited in any event to the
                  right to attempt redelivery of the Services to you.
                </p>
              </Box>
              <Box display="flex">
                <Box marginRight="8px">10.2</Box>
                <p>
                  You agree to use your reasonable endeavours to ensure that the
                  information that you supply us or any Lister is complete and
                  accurate and notify us (and, if relevant, the Lister(s)) in
                  writing if there is any change to the information supplied.
                </p>
              </Box>
              <Box display="flex">
                <Box marginRight="8px">10.3</Box>
                <p>
                  Except as provided in these Terms, no further warranty,
                  condition, undertaking or term, express or implied, statutory or
                  otherwise as to the condition, quality, performance or fitness
                  for purpose of the Services provided in accordance with these
                  Terms is given by us, other than as required by law. All implied
                  warranties are hereby excluded.
                </p>
              </Box>
              <Box display="flex">
                <Box marginRight="8px">10.4</Box>
                <p>
                  Nothing contained in these Terms excludes, restricts or modifies
                  the application of any condition, warranty or other obligation,
                  the exercise of any right or remedy, or the imposition of any
                  liability under the Competition and Consumer Act 2010 (Cth) or
                  any other national, state or territory legislation where to do
                  so is unlawful.
                </p>
              </Box>
              <h3>11. LIABILITY</h3>
              <Box display="flex">
                <Box marginRight="8px">11.1</Box>
                <p>
                  To the extent permitted by law, we will not be liable for any
                  loss, expenses, liabilities, costs or damage caused by viruses,
                  system failures or other technologically harmful material that
                  may infect your computer equipment, programs, data or other
                  material due to your use of any part of the Website or
                  downloading of any material posted on it or on any website
                  linked to it. We recommend Users ensure they have up-to-date
                  virus checking software installed.
                </p>
              </Box>
              <Box display="flex">
                <Box marginRight="8px">11.2</Box>
                <p>
                  To the extent permitted by law, we are not responsible for
                  dealing with, and not liable for, any disputes or complaints
                  made by you in relation to goods or services sold, or
                  advertised, via the Website (including any dispute or complaint
                  regarding refunds, payment, goods or services). You should
                  address such complaints directly with the Lister.
                </p>
              </Box>
              <Box display="flex">
                <Box marginRight="8px">11.3</Box>
                <p>
                  You agree that we will not be liable for any indirect,
                  consequential, special or exemplary losses, expenses or
                  liabilities, or loss of profits, loss of revenue, economic loss,
                  loss of goodwill, corruption or alteration of data, failure to
                  realise anticipated savings, loss of opportunity, expectation
                  loss or loss of production, arising out of, or in connection
                  with, the provision or use of the Services, the Marketplace, the
                  Website or these Terms.
                </p>
              </Box>
              <Box display="flex">
                <Box marginRight="8px">11.4</Box>
                <p>
                  You agree that, in any event, our maximum aggregate liability to
                  you under these Terms will be no more than AUD $100.
                </p>
              </Box>
              <Box display="flex">
                <Box marginRight="8px">11.5</Box>
                <p>
                  The User acknowledges and agrees that the limitations of
                  liability contained in this clause are a fair and reasonable
                  allocation of the commercial risk between the parties.
                </p>
              </Box>
              <Box display="flex">
                <Box marginRight="8px">11.6</Box>
                <p>
                  This clause 11 survives termination or expiry of these Terms.
                </p>
              </Box>
              <h3>12. INDEMNITY</h3>
              {
                listIndemnity.map((item, index) => (
                  <Box display="flex" key={index}>
                    <Box marginRight="8px">{`12.${index + 1}`}</Box>
                    <p>{item}</p>
                  </Box>
                ))
              }
              <h3>13. INTELLECTUAL PROPERTY</h3>
              {
                listIntellectualProPerty.map((item, index) => (
                  <Box display="flex" key={index}>
                    <Box marginRight="8px">{`13.${index + 1}`}</Box>
                    <Box display="flex" flexDirection="column">
                      <p>{item.title}</p>
                      {
                        item?.listOl?.length > 0 && (
                          <ol className="olStyles">
                            {item.listOl.map((itemLi, index) => <li key={index}>{itemLi}</li>)}
                          </ol>
                        )
                      }
                    </Box>
                  </Box>
                ))
              }
              <h3>14. UNAVOIDABLE EVENTS</h3>
              <Box display="flex">
                <Box marginRight="8px">14.1</Box>
                <p>
                  We will not be liable to you if we are prevented from, or
                  delayed in, providing the Services due to acts, events,
                  omissions or accidents beyond our reasonable control
                  (“Unavoidable Events”). Where an Unavoidable Event occurs, we
                  will attempt to recommence provision of the Services as soon as
                  reasonably practicable.
                </p>
              </Box>
              <h3>15. DISPUTE RESOLUTIONS</h3>
              {
                listDisputeResolutions.map((item, index) => (
                  <Box display="flex" key={index}>
                    <Box marginRight="8px">{`15.${index + 1}`}</Box>
                    <Box display="flex" flexDirection="column">
                      <p>{item.title}</p>
                      {
                        item?.listOl?.length > 0 && (
                          <ol className="olStyles">
                            {item.listOl.map((itemLi, index) => <li key={index}>{itemLi}</li>)}
                          </ol>
                        )
                      }
                    </Box>
                  </Box>
                ))
              }
              <h3>16. LINKING TO THE WEBSITE</h3>
              {
                listLinkingToTheWebsite.map((item, index) => (
                  <Box display="flex" key={index}>
                    <Box marginRight="8px">{`16.${index + 1}`}</Box>
                    <p>{item}</p>
                  </Box>
                ))
              }
              <h3>17. PRIVACY</h3>
              <Box display="flex">
                <Box marginRight="8px">17.1</Box>
                <p>
                  We are committed to protecting your privacy and personal
                  information. Further details about our practices relating to the
                  collection, use, disclosure and storage of your personal
                  information can be found in our Privacy Policy.
                </p>
              </Box>
              <h3>18. GENERAL</h3>
              {
                listGeneral.map((item, index) => (
                  <Box display="flex" key={index}>
                    <Box marginRight="8px">{`18.${index + 1}`}</Box>
                    <p>{item}</p>
                  </Box>
                ))
              }
              <h3>19. DEFINITIONS</h3>
              <Box display="flex">
                <Box marginRight="8px">19.1</Box>
                <p>
                  In these Terms, the following expressions have the following
                  meanings, unless otherwise stated:
                </p>
              </Box>
              <ol className="olStyles">
                {
                  listDefinitions.map((item, index) => (
                    <li key={index}>
                      <span className='text-bold'>
                        {`“${item.textBold}”`}
                      </span>{` ${item.text}`}
                    </li>
                  ))
                }
              </ol>
            </TabPanel>
            <TabPanel value={value} index={3}>
              <h1>MARKETPLACE PARTNER AGREEMENT</h1>
              <h3>ABOUT US</h3>
              <p>
                Glamezy provides an online marketplace platform where consumers can search,
                book and pay for their beauty services appointments with nearby or
                preferred beauty business service venues who have partnered with Glamezy.
              </p>
              <h3>AGREEMENT</h3>
              <h3>1. ABOUT THESE TERMS</h3>
              <Box display="flex">
                <Box marginRight="8px">1.1</Box>
                <p>
                  In these Terms, <span className='text-bold'>“we”</span>,{' '}
                  <span className='text-bold'>“us”</span> or{' '}
                  <span className='text-bold'>“our”</span> means Glamezy Pty Ltd
                  and includes its subsidiaries, and their respective directors,
                  employees and agents. <span className='text-bold'>“You”</span>{' '}
                  or <span className='text-bold'>“Your”</span> means the User who
                  accepts these Terms. Additional definitions are set out in
                  clause 19.
                </p>
              </Box>
              <Box display="flex">
                <Box marginRight="8px">1.2</Box>
                <p>
                  You should read these Terms carefully. They apply to your use of
                  our Platform.
                </p>
              </Box>
              <Box display="flex">
                <Box marginRight="8px">1.3</Box>
                <p>
                  By registering with us or listing goods or services on the Marketplace,
                  you indicate your acceptance of these Terms (including any associated Charges).
                  By accepting these Terms, you agree to the obligations imposed on you under these Terms and,
                  in exchange, we agree to provide you with access to the Services.
                  If you do not agree to these Terms you should immediately cease accessing the Website and using the Services.
                </p>
              </Box>
              <Box display="flex">
                <Box marginRight="8px">1.4</Box>
                <p>
                  We reserve all rights to alter these Terms at our discretion.
                  Each time you use the Website or Services, it is your responsibility to be aware of our current terms.
                  Your continued use of the Website and/or Services following the posting of changes to these Terms will be deemed your acceptance of those changes.
                </p>
              </Box>
              <Box display="flex">
                <Box marginRight="8px">1.5</Box>
                <p>
                  These Terms, as amended by us from time to time, continue to apply
                  for the duration of your use of the Website and Services or until
                  terminated in accordance with clause{" "}
                  <span className='text-bold'>
                    9.
                  </span>
                </p>
              </Box>
              <h3>2. OVERVIEW OF SERVICES</h3>
              {
                listOverviewOfService.map((item, index) => (
                  <Box display="flex" key={index}>
                    <Box marginRight="8px">{`2.${index + 1}`}</Box>
                    <Box display="flex" flexDirection="column">
                      <p>{item.title}</p>
                      {
                        item?.listOlMain?.length > 0 && (
                          <ol className="olStyles">
                            {item.listOlMain.map((itemMain, index) => <>
                              <li key={index}>{itemMain.title}</li>
                              {
                                itemMain?.listOlChildren?.length > 0 && (
                                  <ol className="olStylesChildren">
                                    {
                                      itemMain.listOlChildren.map((itemChildren, index) => <li key={index}>{itemChildren}</li>)
                                    }
                                  </ol>
                                )
                              }
                            </>)}
                          </ol>
                        )
                      }
                    </Box>
                  </Box>
                ))
              }
              <h3>3. USING THE SERVICES</h3>
              {
                listUsingTheService.map((item, index) => (
                  <Box display="flex" key={index}>
                    <Box marginRight="8px">{`3.${index + 1}`}</Box>
                    <p>{item}</p>
                  </Box>
                ))
              }
              <h3>4. REGISTRATION</h3>
              {
                listRegistration.map((item, index) => (
                  <Box display="flex" key={index}>
                    <Box marginRight="8px">{`4.${index + 1}`}</Box>
                    <Box display="flex" flexDirection="column">
                      <p>{item.title}</p>
                      {
                        item?.listOl?.length > 0 && (
                          <ol className="olStyles">
                            {item.listOl.map((itemLi, index) => <li key={index}>{itemLi}</li>)}
                          </ol>
                        )
                      }
                    </Box>
                  </Box>
                ))
              }
              <h3>5. HOW MUCH DO WE CHARGE FOR THE SERVICES</h3>
              {
                listHowMuch.map((item, index) => (
                  <Box display="flex" key={index}>
                    <Box marginRight="8px">{`5.${index + 1}`}</Box>
                    <p>{item}</p>
                  </Box>
                ))
              }
              <h3>6. HOW DO YOU GET PAID?</h3>
              {
                listHowDoYouGetPaid.map((item, index) => (
                  <Box display="flex" key={index}>
                    <Box marginRight="8px">{`6.${index + 1}`}</Box>
                    <Box display="flex" flexDirection="column">
                      <p>{item.title}</p>
                      {
                        item?.listOl?.length > 0 && (
                          <ol className="olStyles">
                            {item.listOl.map((itemLi, index) => <li key={index}>{itemLi}</li>)}
                          </ol>
                        )
                      }
                    </Box>
                  </Box>
                ))
              }
              <h3>7. WHAT ARE YOUR OBLIGATIONS?</h3>
              {
                listWhatAreYour.map((item, index) => (
                  <Box display="flex" key={index}>
                    <Box marginRight="8px">{`7.${index + 1}`}</Box>
                    <Box display="flex" flexDirection="column">
                      <p>{item.title}</p>
                      {
                        item?.listMain?.length > 0 && (
                          <ol className="olStyles">
                            {item.listMain.map((itemMain, index) => (
                              <li key={index}>
                                <span className='text-bold'>{itemMain.textBold}</span>
                                <br />
                                {itemMain.text}
                              </li>
                            ))}
                          </ol>
                        )
                      }
                      {
                        item?.listMainSecond?.length > 0 && (
                          <ol className="olStyles">
                            {item.listMainSecond.map((itemMainSecond, index) => <li key={index}>{itemMainSecond}</li>)}
                          </ol>
                        )
                      }
                    </Box>
                  </Box>
                ))
              }
              <h3>8. CANCELLATION OF BOOKINGS</h3>
              {
                listCancellationOfBooking.map((item, index) => (
                  <Box display="flex" key={index}>
                    <Box marginRight="8px" className='text-bold'>{`8.${index + 1}`}</Box>
                    <Box display="flex" flexDirection="column">
                      <span className='text-bold'>{item.title}</span>
                      {
                        item?.listOlMain?.length > 0 && (
                          <ol className="olStyles">
                            {item.listOlMain.map((itemMain, index) => <>
                              <li key={index}>
                                {itemMain?.title && <span>{`${itemMain?.title} `}</span>}
                                {itemMain?.textItalic && <span className='textItalic'>{`${itemMain?.textItalic}`}</span>}
                              </li>
                              {
                                itemMain?.listOlChildren?.length > 0 && (
                                  <ol className="olStylesChildren">
                                    {
                                      itemMain.listOlChildren.map((itemChildren, index) => <li key={index}>{itemChildren}</li>)
                                    }
                                  </ol>
                                )
                              }
                              {
                                itemMain?.listChildrenUnderline?.length > 0 && (
                                  <ol className="olStylesChildren">
                                    {
                                      itemMain.listChildrenUnderline.map((itemChildren, index) => <li key={index} className='textItalic'>{itemChildren}</li>)
                                    }
                                  </ol>
                                )
                              }
                            </>)}
                          </ol>
                        )
                      }
                    </Box>
                  </Box>
                ))
              }
              <h3>9. TERMINATION</h3>
              {
                listTermination.map((item, index) => (
                  <Box display="flex" key={index}>
                    <Box marginRight="8px">{`9.${index + 1}`}</Box>
                    <Box display="flex" flexDirection="column">
                      <p>{item.title}</p>
                      {
                        item?.listMain?.length > 0 && (
                          <ol className="olStyles">
                            {item.listMain.map((itemLi, index) => <li key={index}>{itemLi}</li>)}
                          </ol>
                        )
                      }
                    </Box>
                  </Box>
                ))
              }
              <h3>10. WARRANTIES</h3>
              {
                listWarranties.map((item, index) => (
                  <Box display="flex" key={index}>
                    <Box marginRight="8px">{`10.${index + 1}`}</Box>
                    <p>{item}</p>
                  </Box>
                ))
              }
              <h3>11. LIABILITY</h3>
              {
                listLiability.map((item, index) => (
                  <Box display="flex" key={index}>
                    <Box marginRight="8px">{`11.${index + 1}`}</Box>
                    <p>{item}</p>
                  </Box>
                ))
              }
              <h3>12. INDEMNITY</h3>
              {
                listIndemnityPartner.map((item, index) => (
                  <Box display="flex" key={index}>
                    <Box marginRight="8px">{`12.${index + 1}`}</Box>
                    <p>{item}</p>
                  </Box>
                ))
              }
              <h3>13. INTELLECTUAL PROPERTY</h3>
              {
                listIntellectualProPerty.map((item, index) => (
                  <Box display="flex" key={index}>
                    <Box marginRight="8px">{`13.${index + 1}`}</Box>
                    <Box display="flex" flexDirection="column">
                      <p>{item.title}</p>
                      {
                        item?.listOl?.length > 0 && (
                          <ol className="olStyles">
                            {item.listOl.map((itemLi, index) => <li key={index}>{itemLi}</li>)}
                          </ol>
                        )
                      }
                    </Box>
                  </Box>
                ))
              }
              <h3>14. PRIVACY</h3>
              <Box display="flex">
                <Box marginRight="8px">14.1</Box>
                <p>
                  We are committed to protecting your privacy and personal information. Further details about our practices
                  relating to the collection, use, disclosure and storage of your personal information can be found in our Privacy Policy.
                </p>
              </Box>
              <Box display="flex">
                <Box marginRight="8px">14.2</Box>
                <p>
                  If your venue is identified as a COVID-19 exposure site, we will work with the relevant health authorities
                  to share any staff, customers details who may have been present at the venue within an identified exposure time period.
                  The information shared will be limited to relevant information as determined by the health authorities and
                  may include (but not limited to) first name, last name, contact number and in some jurisdictions, their age range.
                </p>
              </Box>
              <h3>15. UNAVOIDABLE EVENTS</h3>
              <h3>16. We will not be liable to you if we are prevented from, or delayed in, providing the Services due to acts, events,
                omissions or accidents beyond our reasonable control (“Unavoidable Events”). Where an Unavoidable Event occurs,
                we will attempt to recommence provision of the Services as soon as reasonably practicable.
              </h3>
              <h3>17. DISPUTE RESOLUTIONS</h3>
              <Box display="flex">
                <Box marginRight="8px">17.1</Box>
                <p>
                  If you have a complaint about the performance of these Terms or the Services,
                  you will contact us at partners@glamezy.com in the first instance .
                  We will assign a dispute resolution agent within 3 business days to work directly with you and the User to step through the matter,
                  consider the complaint, determine a possible solution and notify you of the solution.
                </p>
              </Box>
              <Box display="flex">
                <Box marginRight="8px">17.2</Box>
                <p>This clause 16 survives the expiry or termination of these Terms.</p>
              </Box>
              <h3>18. LINKING TO THE WEBSITE</h3>
              {
                listLinkingToTheWebsiteSecond.map((item, index) => (
                  <Box display="flex" key={index}>
                    <Box marginRight="8px">{`18.${index + 1}`}</Box>
                    <p>{item}</p>
                  </Box>
                ))
              }
              <h3>19. GENERAL</h3>
              {
                listGeneralSecond.map((item, index) => (
                  <Box display="flex" key={index}>
                    <Box marginRight="8px">{`19.${index + 1}`}</Box>
                    <Box display="flex" flexDirection="column">
                      <p>{item.title}</p>
                      {
                        item?.listMain?.length > 0 && (
                          <ol className="olStyles">
                            {item.listMain.map((itemLi, index) => <li key={index}>{itemLi}</li>)}
                          </ol>
                        )
                      }
                    </Box>
                  </Box>
                ))
              }
              <h3>20. DEFINITIONS</h3>
              <Box display="flex">
                <Box marginRight="8px">20.1</Box>
                <p>
                  In these Terms, the following expressions have the following
                  meanings, unless otherwise stated:
                </p>
              </Box>
              <ol className="olStyles">
                {
                  listDefinitionsSecond.map((item, index) => (
                    <li key={index}>
                      <span className='text-bold'>
                        {`“${item.textBold}”`}
                      </span>{` ${item.text}`}
                    </li>
                  ))
                }
              </ol>
            </TabPanel>
          </div>
        </div>
      </div>
    </>
  );
};
