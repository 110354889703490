import React from "react";
import Slider from "react-slick";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import styles from "./VenuesNearbySliderStyle.module.scss";
import { VenueNearbySliderProps } from "~/Components/VenuesNearbySlider/interfaces";
import { VenueCard } from "~/Components/VenueCard";

export const VenuesNearbySlider: React.FC<VenueNearbySliderProps> = (props) => {
  const { title, nearbyVenues } = props;
  const slider = React.useRef(null);
  const settings = {
    dots: false,
    infinite: nearbyVenues?.length > 4 ? true : false,
    arrows: false,
    speed: 300,
    slidesToShow: nearbyVenues?.length > 4 ? 4 : nearbyVenues?.length,
    slidesToScroll: nearbyVenues?.length > 4 ? 1 : 0,
    centerMode: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          dots: false,
          infinite: true,
          arrows: false,
          speed: 300,
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: false,
        }
      },
      {
        breakpoint: 600,
        settings: {
          dots: false,
          infinite: true,
          arrows: false,
          speed: 300,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
        }
      },
    ]
  };

  return (
    <>
      <div className={styles.header}>
        <h2>{title}</h2>
        <div className={styles.buttons_block}>
          <div className={styles.buttons_box}>
            <button
              className={`${styles.button} ${nearbyVenues?.length <= 4 ? styles.disebal_btn : ''}`}
              onClick={slider.current?.slickPrev}>
              <ArrowBackIosIcon fontSize={"small"} />
            </button>
            <button
              className={`${styles.button} ${nearbyVenues?.length <= 4 ? styles.disebal_btn : ''}`}
              onClick={slider.current?.slickNext}>
              <ArrowForwardIosIcon fontSize={"small"} />
            </button>
          </div>
        </div>
      </div>
      {nearbyVenues?.length > 0 ? (
        <Slider
          {...settings}
          ref={slider}
          className={"venue-slider"}>
          {nearbyVenues?.map(venue => <VenueCard key={venue.id} venue={venue} />)}
        </Slider>
      ) : <h3 style={{ color: '#aaa' }}>Glamezy is coming soon to your city!</h3>
      }
    </>
  );
};