import { FC, useEffect, useState, ChangeEvent } from "react";
import type { RouteComponentProps } from "react-router-dom";
import { useLocation, useHistory } from "react-router-dom";
import {
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  IconButton,
  Tabs,
  Tab
} from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import partnershipImg from "~/Assets/images/partnershipProfile.png";
import { Partnership } from "~/Components/Partnership";
import { partnershipTextUSerProfile } from "~/constants";
import { TabPanel } from "~/Components/TabPanel";
import { ProfileSettings } from "~/Containers/UserProfilePage/components/ProfileSettings";
import { AccountSettings } from "~/Containers/UserProfilePage/components/AccountSettings";
import { PaymentMethods } from "~/Containers/UserProfilePage/components/PaymentMethods";
import DialogProfileMobile from "./components/DialogProfileMobile";
import ProfileSettingsHook from "./components/ProfileSettings/ProfileSettingsHook";
import { CheckMobileHook } from "~/Components/MainHeader/CheckMobileHook";

import styles from "./UserProfilePageStyle.module.scss";

function tabProps(index: any) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}
const dataMenuProfile = [
  {
    title: 'Profile settings',
    key: 'profile',
  },
  {
    title: 'Account settings',
    key: 'account',
  },
  {
    title: 'Payment methods',
    key: 'payment',
  },
];

export const UserProfilePage: FC<RouteComponentProps<any>> = () => {
  const history = useHistory();
  const { profile } = ProfileSettingsHook();
  const { isMobile } = CheckMobileHook();
  const [value, setValue] = useState(0);
  const { search } = useLocation()
  const query = new URLSearchParams(search);
  const redirect_status = query.get('redirect_status');

  useEffect(() => {
    if (redirect_status === 'succeeded') {
      setValue(2);
    }
  }, [redirect_status])

  const handleChange = (event: ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const [open, setOpen] = useState({
    isModal: false,
    typeModal: "",
    title: ""
  });

  const handleOpenModal = (data) => () =>
    setOpen({ isModal: true, typeModal: data.key, title: data.title });

  const handleCloseModal = () => setOpen({ isModal: false, typeModal: "", title: "" });

  const goToLegalPage = () => {
    history.push("/general/info")
  }

  const viewMobileMenu = () => {
    switch (open.typeModal) {
      case "profile":
        return <ProfileSettings />;
      case "account":
        return <AccountSettings />;
      case "payment":
        return <PaymentMethods />;
      default:
        break;
    }
  };

  return (
    <>
      {isMobile && (
        <section className={styles.visual}>
          <div className={styles.img_profile}>
            {profile.avatar ? <img
              className="preview my20"
              style={{
                borderRadius: "50%",
              }}
              src={profile.avatar}
              alt="avatar"
            /> : <AccountCircleIcon fontSize="large" />
            }
            <h2>
              {profile.firstName} {profile.lastName}{" "}
            </h2>
          </div>
        </section>
      )}
      {isMobile ? (
        <div className={styles.container_mobile}>
          <List className={styles.listMobile}>
            {dataMenuProfile.map((item, index) => {
              return (
                <ListItem className={styles.listItem} key={index} onClick={handleOpenModal(item)}>
                  <ListItemText
                    className={styles.listText}
                    primary={item.title}
                  />
                  <ListItemSecondaryAction onClick={handleOpenModal(item)}>
                    <IconButton
                      className={styles.iconButton}
                      edge="end"
                      aria-label="delete"
                    >
                      <ArrowForwardIosIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}

            <ListItem className={styles.listItem} onClick={goToLegalPage}>
              <ListItemText
                className={styles.listText}
                primary="Legal"
              />
              <ListItemSecondaryAction onClick={goToLegalPage}>
                <IconButton
                  className={styles.iconButton}
                  edge="end"
                  aria-label="delete"
                >
                  <ArrowForwardIosIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          </List>

          <Partnership
            handleClass="row-reverse"
            description={partnershipTextUSerProfile}
            imgUrl={partnershipImg}
            isProfile
          />
        </div>
      ) : (
        <section className={`container-holder-tab`}>
          <div className={`tab-panel ${styles.container_profile}`}>
            <div className={`col1 ${styles.col1}`}>
              <Tabs
                orientation="vertical"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs"
                className="tabs-block"
              >
                <Tab className="tab-btn" label="Profile settings" {...tabProps(0)} />
                <Tab className="tab-btn" label="Account settings" {...tabProps(1)} />
                <Tab className="tab-btn" label="Payment methods" {...tabProps(2)} />
                {/* <Tab className="tab-btn" label="My Vouchers" {...tabProps(3)} />
              <Tab className="tab-btn" label="Help and FAQ" {...tabProps(4)} /> */}
              </Tabs>
            </div>
            <div className={`${"col2"} ${styles.col2}`}>
              <TabPanel value={value} index={0}>
                <ProfileSettings />
                <div className={styles.partnership_block}>
                  <Partnership handleClass="row-reverse" description={partnershipTextUSerProfile} imgUrl={partnershipImg} isProfile />
                </div>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <AccountSettings />
                <div className={styles.partnership_block}>
                  <Partnership handleClass="row-reverse" description={partnershipTextUSerProfile} imgUrl={partnershipImg} isProfile />
                </div>
              </TabPanel>
              <TabPanel value={value} index={2}>
                <PaymentMethods />
                <div className={styles.partnership_block}>
                  <Partnership handleClass="row-reverse" description={partnershipTextUSerProfile} imgUrl={partnershipImg} isProfile />
                </div>
              </TabPanel>
              {/* <TabPanel value={value} index={3}>
              <h2>My Vouchers</h2>
            </TabPanel>
            <TabPanel value={value} index={4}>
              <h2>Help and FAQ</h2>
              <p>Have any questions? You can find answers <br/>
                here or <a href="/">contact us</a></p>
              <HelpAndFaq />
              <div className={styles.partnership_block}>
                <Partnership handleClass="row-reverse" description={partnershipTextUSerProfile} imgUrl={partnershipImg} />
              </div>
            </TabPanel> */}
            </div>
          </div>
        </section>
      )}
      <DialogProfileMobile
        open={open.isModal}
        handleCloseModal={handleCloseModal}
        title={open.title}
        content={viewMobileMenu()}
      />
    </>
  );
};
