import { useState } from 'react';

export const MuiDrawerHook = () => {
  const [isToggle, setIsToggle] = useState(false);

  const toggleDrawer = () => setIsToggle(!isToggle);

  return {
    isToggle,
    toggleDrawer,
  };
};
