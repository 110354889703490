import React from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DialogMain from "~/Components/DialogMain/DialogMain";
import Button from "@material-ui/core/Button";

import { AddServiceItem } from "~/Containers/BookingFlowPage/components/AddServiceItem";
import { AddServicesModalHook } from "~/Containers/BookingFlowPage/components/AddServicesModal/AddServicesModalHook";
import type { AddServicesModalProps } from "~/Containers/BookingFlowPage/interfaces";

import styles from "./AddServicesModalStyle.module.scss";

export const AddServicesModal: React.FC<AddServicesModalProps> = (props) => {
  const {
    isAddButtonWhite,
    isSubmitButtonDisabled,
    categories,
    fetchedServices,
    fetchedPackages,
    onPickServicesForRemove,
    onPickServicesForAdd,
    onPickServicesForChange,
    onSubmit,
    onOpenClickCheck,
    showModal,
    setShowModal,
    selectedStaffId,
    fieldArrayFormMethods,
  } = AddServicesModalHook(props);

  return (
    <div className={isAddButtonWhite ? styles.btn_box : styles.btn_dialog}>
      <Button
        variant="outlined"
        color="primary"
        onClick={onOpenClickCheck}
        className={isAddButtonWhite ? "white-btn" : "purple-btn"}
      >
        {isAddButtonWhite ? "Add/change service" : "Add service"}
      </Button>
      {showModal && (
        <DialogMain
          titleDialog="Add a service"
          showModal={showModal}
          setShowModal={setShowModal}
          openDialogButtonClass={isAddButtonWhite ? "white-btn" : "purple-btn"}
          submitButtonText="Save changes"
          submitButtonDisabled={isSubmitButtonDisabled}
          onSubmit={onSubmit}
          dialogWindowClass="modal-dialog-service"
        >
          <div className={styles.service_section}>
            {categories.map((category) => {
              const services = fetchedServices.filter((s) => s.serviceCategoryId === category.id)
              const packages = fetchedPackages.filter((s) => s.serviceCategoryId === category.id)
              return (
                <Accordion
                  defaultExpanded
                  className={styles.main_accordion}
                  key={category.id}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel-makeup"
                    className={styles.accordion_title}
                  >
                    {category.code}
                  </AccordionSummary>

                  <AccordionDetails className={styles.accordionDetails}>
                    {services.map((service) => {
                      const selectedServiceIndex = fieldArrayFormMethods.findIndex(s => s.selected?.serviceId === service.id)
                      return (
                        <AddServiceItem
                          key={service.id}
                          service={service}
                          selectedStaffId={selectedStaffId}
                          selectedServiceIndex={selectedServiceIndex}
                          selectedService={fieldArrayFormMethods[selectedServiceIndex]}
                          onPickServicesForRemove={onPickServicesForRemove}
                          onPickServicesForAdd={onPickServicesForAdd}
                          onPickServicesForChange={onPickServicesForChange}
                        />
                      )
                    })}
                    {packages.length > 0 && (
                      <div className={styles.packagesWrap}>
                        <div className={styles.packagesTitle}>
                          <div className={styles.divider}></div>
                          <div className={styles.title}>Packages</div>
                          <div className={styles.divider}></div>
                        </div>
                        <div className={styles.packages}>
                          {packages.map((packageItem) => (
                            <div
                              className={styles.divider2}
                              key={packageItem.id}
                            >
                              <AddServiceItem
                                service={packageItem}
                                selectedStaffId={selectedStaffId}
                                selectedService={fieldArrayFormMethods.find((s) => s.selected?.packageId === packageItem.id)}
                                isService={false}
                                showMoreAble={false}
                                onPickServicesForRemove={onPickServicesForRemove}
                                onPickServicesForAdd={onPickServicesForAdd}
                                onPickServicesForChange={onPickServicesForChange}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </AccordionDetails>
                </Accordion>
              )
            })}
          </div>
        </DialogMain>
      )}
    </div>
  );
};
