import * as yup from "yup";
import { validationModules } from "./yupObjectValidation";

const accountSettings = {
  phone: validationModules.defaultStringRule,
  email: validationModules.email,
  password: validationModules.simplePsw,
  digit1: validationModules.defaultConfirmationRule,
  digit2: validationModules.defaultConfirmationRule,
  digit3: validationModules.defaultConfirmationRule,
  digit4: validationModules.defaultConfirmationRule,
  digit5: validationModules.defaultConfirmationRule,
  currentPassword: validationModules.simplePsw,
  newPassword: validationModules.simplePsw,
  emailReset: validationModules.email,
  newPasswordRepeat: validationModules.simplePsw,
};

const accountSettingsSchema = yup.object().shape(accountSettings);

export default accountSettingsSchema;
