import moment from "moment";

export const timeDurationComputer = (duration: string, addExtraTime: string) => {
  const durationAsHours = moment.duration(duration).asHours();
  const extraTimeAsHours = moment.duration(addExtraTime).asHours();

  return Math.ceil((durationAsHours + extraTimeAsHours) * 2) * 0.5;
};

export const totalTimeDuration = (duration: string, addExtraTime: string) => {
  const durationAsHours = moment.duration(duration).asHours();
  const extraTimeAsHours = moment.duration(addExtraTime).asHours();
  return durationAsHours + extraTimeAsHours;
};
