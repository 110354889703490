import React, { useState } from 'react';
import { Dialog, Slide } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import { useSelector } from 'react-redux';

import HeaderSearchVenues from '~/Components/MainHeader/components/HeaderSearchValue';
import DialogMain from '~/Components/DialogMain/DialogMain';
import { HeaderSearchLocation } from '../HeaderSearchLocation';
import { useAppSelector } from '~/reduxConfig';
import { locationSelector, mainSelector } from '~/store/selectors';
import history from "~/utils/history";
import { mainLayoutRoute, searchResultsPageRoute } from '~/constants';

import useStyles from './useStyles';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogMobile({ open, handleClose }) {
  const classes = useStyles();
  const [openBlockModal, setOpenBlockModal] = useState(false);
  const { city } = useAppSelector(locationSelector);
  const { popularTags } = useSelector(mainSelector)

  const onClickTag = (value) => () => {
    if (value) {
      history.push(mainLayoutRoute.concat(`${searchResultsPageRoute}?search=${value}`));
      handleClose(false);
    }
  }

  const onOpenModal = () => setOpenBlockModal(true);

  const onCloseModal = () => setOpenBlockModal(false);
  return (
    <div>
      <Dialog
        className={classes.dialogFullScreen}
        fullScreen
        open={open}
        TransitionComponent={Transition}
      >
        <div className={classes.venues}>
          <HeaderSearchVenues handleClose={handleClose} />
        </div>
        <div className={classes.location}>
          <DialogMain
            titleDialog="Location"
            showModal={openBlockModal}
            onOpen={onOpenModal}
            onClose={onCloseModal}
            showDialogActions={false}
            openDialogButtonText={city}
            openDialogButtonClass="submit-btn-save-changes with-icon with-arrow"
          >
            <HeaderSearchLocation onClose={onCloseModal} />
          </DialogMain>
        </div>
        {
          popularTags?.length > 0 && (
            <div className={classes.popularTags}>
              <span className={classes.titlePopularTags}>Popular tags</span>
              <div className={classes.listItemPopularTags}>
                {
                  popularTags.map((item) => (
                    <div
                      key={item.id}
                      className={classes.itemPopularTags}
                      onClick={onClickTag(item.tagName)}
                    >
                      {item.tagName}
                    </div>
                  ))
                }
              </div>
            </div>
          )
        }
      </Dialog>
    </div>
  );
}
