import React from "react";
import { Avatar } from "@material-ui/core";

import { RescheduleBookingServiceItemHook } from "~/Containers/MyBookingsPage/components/RescheduleBookingServiceItem/RescheduleBookingServiceItemHook";
import type { RescheduleBookingServiceItemProps } from "~/Containers/MyBookingsPage/interfaces";

import styles from "./RescheduleBookingServiceItemStyle.module.scss";
import { formatPrice } from "~/utils/helper";

export const RescheduleBookingServiceItem: React.FC<RescheduleBookingServiceItemProps> = (props) => {
  const {
    serviceName,
    variantName,
    displayedPrice,
    avatar,
    displayedInitials,
    displayedName,
    displayedTime,
    displayedDuration,
    id,
    isPackage,
    serviceOfPackage,
    register
  } = RescheduleBookingServiceItemHook(props);

  return (
    <div className={styles.form_radio_btn_row}>
      <input
        id={`radio-${id}`}
        value={id}
        name="radio"
        type="radio"
        {...register("bookingServiceId")}
      />
      <label htmlFor={`radio-${id}`} className={styles.form_radio_btn}>
        <div className={styles.row}>
          <div className={styles.row_holder}>
            <span>
              {serviceName}
              {isPackage ? (
                <span className={styles.packages}> ({serviceOfPackage} services) </span>
              ) : (
                <>
                  &nbsp;•&nbsp;{variantName}
                </>
              )}
            </span>
            <span className={styles.cost}>${formatPrice(displayedPrice)}</span>
          </div>
          <div className={styles.row_holder}>
            <div className={styles.user}>
              <Avatar
                className={styles.avatar}
                alt={displayedInitials}
                src={avatar || "/no-img.png"} />
              {displayedName}
            </div>
            <span className={styles.time}>{displayedTime}&nbsp;•&nbsp;<span>{displayedDuration}</span> </span>
          </div>
        </div>
        <span className={styles.check}>check</span>
      </label>
    </div>
  );
};