import axios from "~/utils/api";

const homePageApi = {
  categories: () => axios.get("customer/home-page/categories"),
  otherVenues: (search) =>
    axios.get("customer/home-page/other-venues", {
      params: {
        ...search,
      },
    }),
  getAvailableVenues: (search) =>
    axios.get("customer/home-page/available-venues", {
      params: {
        ...search,
      },
    }),
  subscribe: (data) =>
    axios.post("customer/home-page/subscribe-newsletter", data),
  getPopularTags: () => axios.get("/customer/home-page/search/popular-tags"),
  getValueVenue: (search) => axios.get("/customer/search/venues", {
    params: {
      ...search,
    },
  }),
  getListPopularTag: () => axios.get("service-tags/popular"),
  getBannersService: () => axios.get("directus/banners"),
}

export default homePageApi;