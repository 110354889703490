import React from "react";
import { useFormContext } from "react-hook-form";
import { Typography, Button } from "@material-ui/core";

import UploadIcon from "~/Assets/images/subtract.svg";
import type { UploadPhotoProps } from "../../interfaces";

export const UploadPhoto: React.FC<UploadPhotoProps> = ({
  previewImage,
  selectFile,
  currentFile,
  message,
}) => {
  const { register } = useFormContext();

  return (
    <div className="upload-block">
      <div className="upload-holder">
        <div className={`avatar ${previewImage ? "active" : ""}`}>
          {previewImage ? (
            <img
              className="preview my20"
              style={{
                borderRadius: "50%",
              }}
              src={previewImage}
              alt="avatar"
            />
          ) : (
            <img src={UploadIcon} alt="Upload" />
          )}
        </div>

        <div>
          <label
            htmlFor="btn-upload"
            className={
              previewImage ? "btn-choose btn-choose-active" : "btn-choose"
            }
          >
            <input
              id="btn-upload"
              style={{ display: "none" }}
              type="file"
              accept=".jpg, .jpeg, .png"
              {...register("avatar")}
              onChange={selectFile}
            />
            <Button
              className="btn-upload"
              color="primary"
              variant="contained"
              component="span"
            >
              {currentFile ? "Change photo" : "Upload photo"}
            </Button>
          </label>

          {message && (
            <Typography variant="subtitle2" className={`upload-message`}>
              {message}
            </Typography>
          )}
        </div>
      </div>
    </div>
  );
};
