import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import type {
  VenueProps,
  VenueReducerState,
  StaffProps,
  NearbyProps,
  ServicesProps,
  VenueReviewProps,
} from "~/store/reducers/interfaces/venueInterfaces";

const initialState = {
  venue: {},
  staff: {
    venueStaffList: [],
    count: 0,
  },
  nearby: {
    nearbyVenues: [],
    amountNearbyVenues: 0,
  },
  services: {
    serviceCategories: [],
    count: 0,
  },
  reviews: []
} as VenueReducerState;

const venueReducer = createSlice({
  name: "venue",
  initialState,
  reducers: {
    fetchVenue: (state, action: PayloadAction<VenueProps>) => {
      state.venue = action.payload;
    },
    fetchStaff: (state, action: PayloadAction<StaffProps>) => {
      const { venueStaffList, count } = action.payload;
      state.staff = {
        venueStaffList,
        count
      };
    },
    fetchNearby: (state, action: PayloadAction<NearbyProps>) => {
      state.nearby = action.payload;
    },
    fetchServices: (state, action: PayloadAction<ServicesProps>) => {
      state.services = action.payload;
    },
    fetchReviews: (state, action: PayloadAction<VenueReviewProps[]>) => {
      state.reviews = action.payload || [];
    },
    clearVenuePageStore: () => initialState
  },
});

export const { fetchVenue, fetchStaff, fetchNearby, fetchServices, fetchReviews, clearVenuePageStore } = venueReducer.actions;

export default venueReducer.reducer;
