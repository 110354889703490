import { useState } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  InputLabel,
  TextField,
} from "@material-ui/core";
import { Message } from "~/Components/Message";
import "./SaveNewCardForm.css";
import {
  onGetCreditCardsAction,
  onGetDefaultCreditCardAction,
} from "~/store/actions/creditCardsAction";
import { stripeApi } from "~/services/api/stripeApi";
import { useDispatch } from "react-redux";
import { useAppSelector } from "~/reduxConfig";
import { sessionSelector } from "~/store/selectors";
import { addCardOnce } from "~/store/reducers/paymentMethodReducer";

interface SaveNewCardFormProps {
  onClose: () => void;
  showRememberCard?: boolean;
  setClientSecret?: (value: string) => void;
  createPayment?: () => void;
  error?: boolean;
  setError?: (value: string) => void; 
}
const SaveNewCardForm = ({
  onClose,
  showRememberCard = false,
  setClientSecret,
  createPayment,
  error,
  setError,
}: SaveNewCardFormProps) => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const customer = useAppSelector(sessionSelector);
  const [isDefaultCard, setIsDefaultCard] = useState(false);
  const [cardName, setCardName] = useState("");
  const [errorCardName, setErrorCardName] = useState(null);
  const [rememberCard, setRememberCard] = useState(false);
  const [isRequesting, setIsRequesting] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements || isRequesting) {
      return;
    }

    if (!cardName || cardName.length < 3) {
      setErrorCardName(
        !cardName
          ? "Card name is required"
          : "Card name must be at least 3 characters"
      );
      return;
    }

    setErrorCardName(null);
    setError(null);
    setIsRequesting(true);

    const { error: errStripe, setupIntent } = await stripe.confirmSetup({
      elements,
      confirmParams: { return_url: window.location.href },
      redirect: "if_required",
    });

    if (errStripe) {
      setError(errStripe.message || "Something went wrong");
    } else {
      if (rememberCard || !showRememberCard) {
        try {
          const respone = await stripeApi.updatePaymentMethod({
            cardId: setupIntent.payment_method,
            stripeCustomerId: customer.stripeCustomerId,
            creditCardDto: {
              cardName,
              isDefault: isDefaultCard,
            },
          });
          if (respone.status === 200) {
            dispatch(onGetCreditCardsAction());
            dispatch(onGetDefaultCreditCardAction(customer.stripeCustomerId));
            onClose();
          } else {
            setError(respone.data?.message || "Something went wrong");
          }
        } catch (err: any) {
          setError(err?.message || "Something went wrong");
        }
      } else {
        // use card once
        const res = await stripeApi.getCardData(setupIntent.payment_method);
        if (res.status === 200) {
          const newData = {
            ...res.data,
            billing_details: {
              ...res.data.billing_details,
              name: cardName,
            },
          };
          dispatch(addCardOnce(newData));
          onClose();
        } else {
          setError(res.data.message || "Something went wrong");
        }
      }
    }
    setIsRequesting(false);
  };

  const onChangeChecked = () => {
    setIsDefaultCard(!isDefaultCard);
  };

  const onChangeRememberCard = () => {
    setRememberCard(!rememberCard);
  };

  const onBlurCardName = () => {
    if (cardName.trim().length === 0) {
      setErrorCardName("Card name is required");
    }
  };

  const onChangeCardName = (e) => {
    setCardName(e.target.value);
    if (errorCardName) {
      setErrorCardName(null);
    }
  };

  const onChangePayment = () => {
    if (error) {
      setError(null);
    }
  };

  const onResetCard = () => {
    setClientSecret("")
    setTimeout(() => {
      createPayment();
    }, 1000)
  }

  return (
    <>
      {error && <Message msg={error} />}
      <form onSubmit={handleSubmit} className="stripe-payment-form">
        <Grid
          container
          item
          xs={12}
          className="input-wrapper"
          style={{ paddingBottom: 12 }}
        >
          <InputLabel className="label">Name on card</InputLabel>
          <TextField
            className="text-field"
            value={cardName}
            onBlur={onBlurCardName}
            onChange={onChangeCardName}
            variant="outlined"
            placeholder="Full name"
            error={!!errorCardName}
          />
          {errorCardName && <Message msg={errorCardName} />}
        </Grid>

        <div className={error ? "wrapper-payment-element-modal" : ""}>
          <PaymentElement onChange={onChangePayment} />
        </div>

        {showRememberCard ? (
          <div className="wrapper-reset-card-modal">
            <FormControlLabel
              className="align-items-flex-start checkboxLabel-control"
              control={
                <Checkbox
                  onChange={onChangeRememberCard}
                  checked={rememberCard}
                  color="primary"
                />
              }
              label={"Remember card"}
            />
            {
              error && (
                <button className="btn-reset-card-modal" onClick={onResetCard}>
                  Change card
                </button>
              )
            }
          </div>
        ) : (
          <div className="wrapper-reset-card-modal">
            <FormControlLabel
              className="align-items-flex-start checkboxLabel-control"
              control={
                <Checkbox
                  onChange={onChangeChecked}
                  checked={isDefaultCard}
                  color="primary"
                />
              }
              label={"Default payment method"}
            />
            {
              error && (
                <button className="btn-reset-card-modal" onClick={onResetCard}>
                  Change card
                </button>
              )
            }
          </div>
        )}

        <button
          style={{ display: "none" }}
          id="saveNewCardPaymentBtn"
          type="submit"
        >
          Submit
        </button>
      </form>
    </>
  );
};

export default SaveNewCardForm;
