import { memo } from 'react';

interface MarkerClusterProps {
  amount: number;
  lat: number;
  lng: number;
}

const MarkerCluster = ({ amount }: MarkerClusterProps) => {
  return <div className='marker-cluster'>{amount}</div>;
};

export default memo(MarkerCluster);
