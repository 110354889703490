import { useState } from "react";
import { confirmationInputNames } from "~/constants";
import nextInputFn from "../../services/defineNextInput";

const initialState = {
  digit1: "",
  digit2: "",
  digit3: "",
  digit4: "",
  digit5: "",
};

export const CurrentInput = () => {
  const [valueOfFields, setValueOfFields] = useState(initialState);

  const onSetValueOfField = (e, setValue, setFocus) => {
    const nameOfCurrentInput = e.target.name;
    const valueOfCurrentInput = e.target.value;

    const isNum = /^[\d]$/.test(valueOfCurrentInput);

    if (isNum) {
      setValueOfFields((prev) => {
        return {
          ...prev,
          [nameOfCurrentInput]: valueOfCurrentInput,
        };
      });

      setValue(nameOfCurrentInput, valueOfCurrentInput);

      const nameOfNextInput = nextInputFn(
        nameOfCurrentInput,
        confirmationInputNames
      );

      setFocus(nameOfNextInput);
    } else {
      setValueOfFields((prev) => {
        return {
          ...prev,
          [nameOfCurrentInput]: "",
        };
      });
    }
  };

  const onClearValueOfFields = () => {
    setValueOfFields(initialState);
  };

  return {
    valueOfFields,
    onSetValueOfField,
    onClearValueOfFields,
  };
};
