import React from "react";
import cx from 'clsx';

import styles from "./InfluencerStyles.module.scss";
import ImgInfluencer from '~/Assets/images/influencer.png';

export const Influencer = () => {
  return (
    <div
      className={styles.partnership_content}
    >


      <div className={styles.partnership_info}>
        <h2 className={styles.partnership_title}>
          Are you a nano, micro or macro influencer?
          <br />
          <span className={styles.partnership_subtitle}>
            Let’s Connect
          </span>
        </h2>

        <p className={cx(styles.partnership_description, styles.partnership_description_second)}>
          Do you have a passion for social media and love working with beauty & wellness businesses? Join us, gain access to exclusive salon services and Glamezy events.
        </p>

        <a
          href={'https://www.instagram.com/glamezyau/'}
          target="_blank"
          rel="noreferrer"
          className={`${styles.partnership_btn} submit-btn-save-changes`}
        >
          Explore Our Influencer Page
        </a>
      </div>

      <div className={styles.partnership_box}>
        <img src={ImgInfluencer} alt="" />
      </div>
    </div>
  );
};
