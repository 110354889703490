import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import InputMask from 'react-input-mask';
import {
  TextField,
  InputLabel,
  Grid,
  Paper,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';

import visa from '~/Assets/images/visa.svg';
import mastercard from '~/Assets/images/mastercard.svg';
import { Message } from '~/Components/Message';
import type { FormCardProps } from './interfaces';

import { StripePayment } from '~/Containers/PaymentFlowPage/components/PaymentMethods/StripePayement/StripePayment';
import styles from './FormCard.module.scss';

export const FormCard: React.FC<FormCardProps> = (props) => {
  if (props.checkboxName === 'isDefault') {
    return <FormCard2 {...props} />;
  }

  return <StripePayment {...props} onChangeLoadingBooking={props.onChangeLoadingBooking} />;
};

export const FormCard2: React.FC<FormCardProps> = ({
  hideMessage,
  message,
  checkboxLabel,
  checkboxName,
  disabledNumber,
  disabledDate,
  disabledCVV,
  disabledCheckbox,
}) => {
  const { control } = useFormContext();

  return (
    <div className='card-wrapper'>
      <Paper variant='outlined'>
        <div className={styles.title_box}>
          <span className={styles.title_card}>We accept cards:</span>
          <img src={visa} alt='logo' />
          <img src={mastercard} alt='logo' />
        </div>

        <form>
          <Grid container justifyContent='center'>
            <Grid container item xs={12} className='input-wrapper'>
              <InputLabel className='label'>Name on card</InputLabel>
              <Controller
                control={control}
                name='cardName'
                defaultValue=''
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    className='text-field'
                    onFocus={hideMessage}
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    variant='outlined'
                    error={Boolean(error)}
                  />
                )}
              />
            </Grid>

            <Grid container item xs={12} className='input-wrapper'>
              <InputLabel className='label'>Card number</InputLabel>
              <Controller
                control={control}
                name='cardNumber'
                defaultValue=''
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <InputMask
                    mask='9999 9999 9999 9999'
                    onFocus={hideMessage}
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    disabled={disabledNumber}
                  >
                    {() => (
                      <TextField
                        placeholder='XXXX-XXXX-XXXX-XXXX'
                        className='text-field'
                        variant='outlined'
                        error={Boolean(error)}
                        disabled={disabledNumber}
                      />
                    )}
                  </InputMask>
                )}
              />
            </Grid>

            <div className={styles.card_info}>
              <Grid container item xs={12} className='input-wrapper'>
                <InputLabel className='label'>Expiration date</InputLabel>
                <Controller
                  control={control}
                  name='cardDate'
                  defaultValue=''
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <InputMask
                      mask='99/99'
                      onFocus={hideMessage}
                      value={value}
                      onChange={(e) => onChange(e.target.value)}
                      disabled={disabledDate}
                    >
                      {() => (
                        <TextField
                          placeholder='MM/YY'
                          className='text-field'
                          variant='outlined'
                          error={Boolean(error)}
                          disabled={disabledDate}
                        />
                      )}
                    </InputMask>
                  )}
                />
              </Grid>

              <Grid container item xs={12} className='input-wrapper'>
                <InputLabel className='label'>CVV</InputLabel>
                <Controller
                  control={control}
                  name='cardCVV'
                  defaultValue=''
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <InputMask
                      mask='999'
                      onFocus={hideMessage}
                      value={value}
                      onChange={(e) => onChange(e.target.value)}
                      disabled={disabledCVV}
                    >
                      {() => (
                        <TextField
                          placeholder='XXX'
                          className='text-field'
                          variant='outlined'
                          error={Boolean(error)}
                          disabled={disabledCVV}
                        />
                      )}
                    </InputMask>
                  )}
                />
              </Grid>
            </div>

            <Grid item xs={12}>
              <Controller
                control={control}
                name={checkboxName}
                defaultValue={false}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <FormControlLabel
                    className='align-items-flex-start checkboxLabel-control'
                    control={
                      <Checkbox
                        onBlur={onBlur}
                        onChange={onChange}
                        checked={value}
                        inputRef={ref}
                        color='primary'
                        disabled={disabledCheckbox}
                      />
                    }
                    label={checkboxLabel}
                  />
                )}
              />
            </Grid>

            <Message msg={message} />
          </Grid>
        </form>
      </Paper>
    </div>
  );
};
