import React from "react";

import visa from "~/Assets/images/visa.svg";
import mastercard from "~/Assets/images/mastercard.svg";
import type { CardsProps } from "~/Containers/PaymentFlowPage/components/PaymentMethods/interfaces";

import styles from "./CardsStyle.module.scss";
import { CARD_VALID_STATUSES } from "~/Containers/MyBookingsPage/components/MyBookingItem";

const logoOfBrandsCards = {
  visa,
  mastercard,
};

export const Cards: React.FC<CardsProps> = ({
  id,
  billing_details,
  card,
  cardId,
  status,
  setCardId,
  onEditCard,
}) => {
  const { brand, last4, exp_month, exp_year } = card;
  const month = String(exp_month).length === 1 ? `0${exp_month}` : exp_month;

  const onChangeCard = () => {
    setCardId(id);
  };

  const onEditCardClick = () => {
    onEditCard(id);
  };

  const invalidCard = status && !(CARD_VALID_STATUSES.includes(status));

  return (
    <div className={styles.card}>
      <div className={styles.card_title}>
        <img src={logoOfBrandsCards[brand]} alt="logo" />

        <label>
          {invalidCard && <span className={styles.invalid}>Invalid</span>}
          <input
            type="radio"
            className={styles.card_checkbox}
            value={id}
            disabled={invalidCard}
            checked={cardId === id}
            onChange={onChangeCard}
          />
        </label>
      </div>

      <ul className={styles.card_number}>
        <li>xxxx</li>
        <li>xxxx</li>
        <li>xxxx</li>
        <li>{last4}</li>
      </ul>

      <div className={styles.card_info}>
        <div>{billing_details.name}</div>
        <div>
          <span>{month}</span>/<span>{exp_year}</span>
        </div>
      </div>
      {onEditCard && (
        <button
          className={styles.btn_edit}
          onClick={onEditCardClick}
        >
          <i className="icon-edit"></i>
          Edit card
        </button>
      )}
    </div>
  );
};
