import React from "react";

import { AuthorizedHeader } from "~/Components/MainHeader/AuthorizedHeader";
import { UnauthorizedHeader } from "~/Components/MainHeader/UnauthorizedHeader";
import { LegalPage } from "~/Containers/GeneralPages/LegalPage/LegalPage";

import type { GeneralLayoutProps } from "~/Layouts/GeneralLayout/interfaces";
import styles from "~/Layouts/GeneralLayout/GeneralPagesStyle.module.scss";

export const PrivacyPolicyLayout: React.FC<GeneralLayoutProps> = (props) => {
  const { isLoggedIn } = props;

  return (
    <>
      {isLoggedIn
        ? <AuthorizedHeader handleClass="static-header" />
        : <UnauthorizedHeader handleClass="static-header" />
      }
      <main>
        <section className={styles.info_section}>
          <LegalPage />
        </section>
      </main>
    </>
  );
};
