import type { CategoryProps } from "~/store/reducers/interfaces/mainInterfaces";

export const getUniqueCategories = (categories:CategoryProps[]): CategoryProps[] => {
  return Object.values(
    categories.reduce((ac, category) => {
      if (ac.hasOwnProperty(category.code)) {
        ac[category.code].description.push({
          id: category.id,
          description: category.description,
        });
      } else {
        ac[category.code] = {
          id: category.id,
          code: category.code,
          description: [
            {
              id: category.id,
              description: category.description,
            },
          ],
        };
      }

      return ac;
    }, {})
  );
};