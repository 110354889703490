import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import accountSettingsSchema from "~/services/validationServices/accountSettingsSchema";
import authCustomerApi from "~/services/api/authCustomerApi";
import { useAppSelector, useAppDispatch } from "~/reduxConfig";
import { logOutAction } from "~/store/actions/sessionActions";
import {
  onPutAccountPhoneAction,
  onChangeEmailAction,
  onChangeEmailConfirmAction,
  onChangePasswordAction,
  forgotPasswordAction,
  forgotConfirmationAction,
  resetPasswordAction,
  onChangePhoneConfirmAction
} from "~/store/actions/customerProfileAction";
import { customerProfileSelector } from "~/store/selectors";
import { hideMessage } from "~/store/reducers/customerProfileReducer";
import { stepsForProfile } from "~/constants";

const AccountSettingsHook = () => {
  const dispatch = useAppDispatch();
  const { profile, message, messageSuccess, isLoading } = useAppSelector(
    customerProfileSelector
  );

  const [showModal, setShowModal] = useState(false);
  const [step, setStep] = useState(stepsForProfile.changePhoneNumber);

  const {
    reset,
    control,
    trigger,
    register,
    setFocus,
    setValue,
    setError,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(accountSettingsSchema),
    mode: "onChange",
    criteriaMode: "all",
    defaultValues: {
      phone: "",
      email: "",
      password: "",
      digit1: "",
      digit2: "",
      digit3: "",
      digit4: "",
      digit5: "",
      currentPassword: "",
      newPassword: "",
      emailReset: "",
      newPasswordRepeat: "",
    },
  });

  useEffect(() => {
    if (!showModal) {
      clearErrors();
      reset();
    }
  }, [showModal, clearErrors, reset]);

  useEffect(() => {
    if (step === stepsForProfile.changePhoneNumber && showModal) {
      setValue("phone", profile.phone);
    }
  }, [setValue, profile, step, showModal]);

  const setStepAndShowModal = (stepNumber) => {
    setShowModal(true);
    setStep(stepNumber);
  };

  const onSendAgainForReset = () => {
    const { emailReset } = getValues();
    authCustomerApi.forgotPassword({ email: emailReset });
  };

  const onSendAgain = () => {
    if (step === stepsForProfile.changePhoneVerify) {
      const { phone } = getValues();
      authCustomerApi.changePhone({ phone })
    } else {
      const { email, password } = getValues();
      authCustomerApi.changeEmail({ newEmail: email, password });
    }
  };

  const onHideMessage = () => dispatch(hideMessage());

  const onSubmit = async () => {
    switch (step) {
      case stepsForProfile.changePhoneNumber: {
        await trigger(["phone"]);
        if (errors.phone) {
          return;
        } else {
          const { phone } = getValues();
          dispatch(
            onPutAccountPhoneAction({ phone }, () => setStep(stepsForProfile.changePhoneVerify))
          );
        }
        break;
      }

      case stepsForProfile.changePhoneVerify: {
        await trigger(["digit1", "digit2", "digit3", "digit4", "digit5"]);
        const { digit1: digit1Err, digit2: digit2Err, digit3: digit3Err, digit4: digit4Err, digit5: digit5Err } = errors;

        if (digit1Err || digit2Err || digit3Err || digit4Err || digit5Err) {
          return;
        } else {
          const { phone: newPhone, digit1, digit2, digit3, digit4, digit5 } = getValues();

          dispatch(
            onChangePhoneConfirmAction(
              {
                newPhone,
                verificationCode: digit1 + digit2 + digit3 + digit4 + digit5,
              },
              () => setShowModal(false)
            )
          );
        }
        break;
      }

      case stepsForProfile.changeEmail: {
        await trigger(["email", "password"]);
        const { email: emailErr, password: pwErr } = errors;

        if (emailErr || pwErr) {
          return;
        } else {
          const { email, password } = getValues();
          dispatch(
            onChangeEmailAction({ newEmail: email, password }, () =>
              setStep(stepsForProfile.changeEmailVerify)
            )
          );
        }
        break;
      }

      case stepsForProfile.changeEmailVerify: {
        await trigger(["digit1", "digit2", "digit3", "digit4", "digit5"]);
        const { digit1: digit1Err, digit2: digit2Err, digit3: digit3Err, digit4: digit4Err, digit5: digit5Err } = errors;

        if (digit1Err || digit2Err || digit3Err || digit4Err || digit5Err) {
          return;
        } else {
          const { email: newEmail, digit1, digit2, digit3, digit4, digit5 } = getValues();

          dispatch(
            onChangeEmailConfirmAction(
              {
                newEmail,
                verificationCode: digit1 + digit2 + digit3 + digit4 + digit5,
              },
              () => setShowModal(false)
            )
          );
        }
        break;
      }

      case stepsForProfile.changePassword: {
        await trigger(["currentPassword", "newPassword"]);
        if (errors.currentPassword || errors.newPassword) {
          return;
        } else {
          const { currentPassword, newPassword } = getValues();
          if (currentPassword === newPassword) {
            setError("newPassword", {
              type: "manual",
              message: "Please make sure your new passwords not match",
            });
          } else {
            dispatch(
              onChangePasswordAction({ currentPassword, newPassword }, () =>
                setShowModal(false)
              )
            );
          }
        }
        break;
      }

      case stepsForProfile.forgotPassword: {
        await trigger(["emailReset"]);
        if (errors.emailReset) {
          return;
        } else {
          const { emailReset } = getValues();
          dispatch(
            forgotPasswordAction(
              {
                email: emailReset,
              },
              () => setStep(stepsForProfile.forgotPasswordCode)
            )
          );
        }
        break;
      }
        

      case stepsForProfile.forgotPasswordCode: {
        await trigger(["digit1", "digit2", "digit3", "digit4", "digit5"]);
        const {
          digit1: dig1,
          digit2: dig2,
          digit3: dig3,
          digit4: dig4,
          digit5: dig5,
        } = errors;

        if (dig1 || dig2 || dig3 || dig4 || dig5) {
          return;
        } else {
          const { digit1, digit2, digit3, digit4, digit5, emailReset } =
            getValues();
          dispatch(
            forgotConfirmationAction(
              {
                email: emailReset,
                verificationCode: digit1 + digit2 + digit3 + digit4 + digit5,
              },
              () => setStep(stepsForProfile.forgotPasswordNewPassword)
            )
          );
        }
        break;
      }

      case stepsForProfile.forgotPasswordNewPassword: {
        await trigger(["newPassword", "newPasswordRepeat"]);
        if (errors.newPassword || errors.newPasswordRepeat) {
          return;
        } else {
          const {
            digit1,
            digit2,
            digit3,
            digit4,
            digit5,
            emailReset,
            newPassword,
            newPasswordRepeat,
          } = getValues();
          if (newPassword === newPasswordRepeat) {
            dispatch(
              resetPasswordAction(
                {
                  email: emailReset,
                  verificationCode: digit1 + digit2 + digit3 + digit4 + digit5,
                  newPassword: newPassword,
                },
                () => setShowModal(false)
              )
            );
          } else {
            setError("newPasswordRepeat", {
              type: "manual",
              message: "Please make sure your passwords match",
            });
          }
        }
        break;
      }
      default:
        return null;
    }
  };

  return {
    step,
    stepsForProfile,
    errors,
    control,
    profile,
    message,
    showModal,
    messageSuccess,
    setStep,
    setFocus,
    register,
    setValue,
    onSubmit,
    dispatch,
    getValues,
    clearErrors,
    onSendAgain,
    setShowModal,
    logOutAction,
    onHideMessage,
    onSendAgainForReset,
    setStepAndShowModal,
    isLoading,
  };
};

export default AccountSettingsHook;
