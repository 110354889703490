import PhoneInput from "react-phone-input-2";
import { Controller } from "react-hook-form";
import { InputLabel, Grid, Paper } from "@material-ui/core";
import { Message } from "~/Components/Message";
import "react-phone-input-2/lib/style.css";

export const ChangePhoneForm = ({ control, hideMessage, message }) => {
  return (
    <div className="card-wrapper">
      <Paper variant="outlined">
        <form>
          <Grid container justifyContent="center">
            <Grid
              container
              justifyContent="center"
              className="input-wrapper grid-padding"
            >
              <Grid item xs={12}>
                <InputLabel className="label" htmlFor="phone">
                  Phone number
                </InputLabel>
                <Controller
                  control={control}
                  name="phone"
                  render={({
                    field: { onChange, onBlur, value, ref },
                    fieldState: { error },
                  }) => (
                    <>
                      <PhoneInput
                        onBlur={onBlur}
                        onChange={onChange}
                        onFocus={hideMessage}
                        value={value}
                        id="phone"
                        country="au"
                        placeholder="XXX XXX XXX"
                        inputRef={ref}
                      />
                      <Message msg={error?.message} />
                    </>
                  )}
                />
              </Grid>
            </Grid>
            <Message msg={message} />
          </Grid>
        </form>
      </Paper>
    </div>
  );
};
