import React from 'react';
import moment from 'moment';
import { Controller, useWatch } from 'react-hook-form';
import {
  Button,
  Avatar,
  Grid,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';

import history from '~/utils/history';
import { Message } from '~/Components/Message';
import { addressExtractor } from '~/utils/addressExtractor';
import {
  viewedDateFormat,
  flowsLayoutRoute,
  bookingFlowPageRoute,
} from '~/constants';
import { Service } from '../Service';
import type { BookingInfoProps } from '../../interfaces';

import styles from './BookingInfoStyle.module.scss';
import { formatPrice } from '~/utils/helper';
import { sessionSelector } from '~/store/selectors';

export const BookingInfo: React.FC<BookingInfoProps> = (props) => {
  const {
    venue,
    params,
    message,
    expanded,
    booking,
    onSubmitConfirmBooking,
    control,
    isLoadingBooking,
    cards,
    countBookingPrice,
  } = props;
  const { cardId } = useWatch({ control });
  const { receiveDiscountsAndPromotions } = useSelector(sessionSelector);
  const { selectedServices = [], date } = booking || {};
  const { subtotal, tax, total, discount } = countBookingPrice || {};
  const venueLogo = venue.logo ? venue.logo : '/broken-image.jpg';
  let valueDiscount = 0;
  if (discount?.type === "PERCENTAGE") {
    const discountPercentage = (total / (1 - discount?.value / 100)) * discount?.value / 100;
    valueDiscount = discountPercentage > discount?.discountCap && discount?.discountCap !== 0 ? discount?.discountCap : discountPercentage
  } else {
    valueDiscount = +discount?.value > +discount?.discountCap && +discount?.discountCap > 0 ? discount?.discountCap : discount?.value
  }

  return (
    <>
      <div className={styles.heading_box}>
        <Avatar className={styles.logo} alt='VB' src={venueLogo} />
        <div className={styles.name_address}>
          <h4>{venue.locationName}</h4>

          <address>{addressExtractor(venue)}</address>
        </div>
        <div className={styles.edit_box}>
          <Button
            variant='outlined'
            color='primary'
            className='btn-text'
            id="editBookingBtn"
            type='button'
            onClick={() => {
              history.push(
                flowsLayoutRoute.concat(bookingFlowPageRoute, `/${params.id}`),
                { selectedServices }
              );
            }}
          >
            Edit
          </Button>
        </div>
      </div>

      <div className={styles.service_box}>
        <time>{moment(date).format(viewedDateFormat)}</time>
      </div>

      {selectedServices.map((service, index) => (
        <Service key={index} {...service} />
      ))}

      <div className={styles.subtotal}>
        <div className={styles.row}>
          <span>Subtotal</span>

          <span className={styles.cost}>${formatPrice(subtotal)}</span>
        </div>

        <div className={styles.row}>
          <span>GST included</span>

          <span className={styles.cost}>${formatPrice(tax)}</span>
        </div>
      </div>

      <div className={styles.total}>
        {discount ? (
          <div className={styles.row}>
            <span>Discount</span>

            <span className={styles.cost}>-${formatPrice(valueDiscount)}</span>
          </div>
        ) : null}

        <div className={styles.row}>
          <span>Total</span>

          <span className={styles.cost_large}>${formatPrice(total)}</span>
        </div>
      </div>

      {
        !receiveDiscountsAndPromotions && (
          <div className={styles.sub_row}>
            <Grid item xs={12}>
              <Controller
                control={control}
                defaultValue={false}
                name='receive'
                render={({
                  field: { onChange, onBlur, value, ref },
                  fieldState: { error },
                }) => (
                  <FormControlLabel
                    className='align-items-flex-start checkboxLabel-control'
                    control={
                      <Checkbox
                        onBlur={onBlur}
                        onChange={onChange}
                        checked={value}
                        inputRef={ref}
                        color='primary'
                        className={error ? styles.error : ''}
                      />
                    }
                    label='I am happy to receive Glamezy discounts and promotional material'
                  />
                )}
              />
            </Grid>
          </div>
        )
      }

      <div className={styles.confirm}>
        <Button
          variant='outlined'
          color='primary'
          disabled={!expanded || isLoadingBooking || (cards.length > 0 && !cardId)}
          className={`submit-btn-save-changes ${styles.btn_confirm}`}
          type='button'
          onClick={onSubmitConfirmBooking}
          endIcon={isLoadingBooking && <CircularProgress className={styles.iconLoading} />}
        >
          Confirm booking
        </Button>
        <Message msg={message} />
      </div>
    </>
  );
};
