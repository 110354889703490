import React, { useState, useEffect } from "react";
import AppBar from "@material-ui/core/AppBar";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import clsx from "clsx";

import { UserSelect } from "./components/UserSelect";
import { MoreDropDown } from "./components/MoreDropDown";
import logo from "~/Assets/images/logo.svg";
import logoScroll from "~/Assets/images/logo_scroll.svg";
import DialogMain from "~/Components/DialogMain/DialogMain";
import { HeaderSearchLocation } from "~/Components/MainHeader/components/HeaderSearchLocation";
import HeaderSearchVenues from "~/Components/MainHeader/components/HeaderSearchValue";
import { useAppSelector } from "~/reduxConfig";
import { locationSelector } from "~/store/selectors";

import { HeaderProps } from "~/Components/MainHeader/interfaces";
import { getLocationAction } from "~/store/actions/locationActions";
import { Search as SearchIcon } from "@material-ui/icons";
import styles from "./MainHeaderStyle.module.scss";
import DialogMobile from "./components/DialogsMobile";
import { CheckMobileHook } from "./CheckMobileHook";
import { DialogMobileHook } from "./components/DialogsMobile/DialogMobileHook";

import { useStyles } from "./styles";
interface Props {
  window?: () => Window;
  children: React.ReactElement;
}

function ElevationScroll(props: Props) {
  const { children, window } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });
  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

export const AuthorizedHeader: React.FC<HeaderProps> = ({ handleClass }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const { city, placeId } = useAppSelector(locationSelector);
  const { isMobile } = CheckMobileHook();

  const [openBlockModal, setOpenBlockModal] = useState(false);
  const { openModalSearch, handleModalSearch } = DialogMobileHook()

  const onOpenModal = () => {
    setOpenBlockModal(true)
  }

  const onCloseModal = () => {
    setOpenBlockModal(false)
  }

  useEffect(() => {
    if (!placeId) {
      dispatch(getLocationAction())
    }
  }, [dispatch, placeId])

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 1,
  });

  return (
    <div className={`${"header-section"} ${handleClass}`}>
      <DialogMobile open={openModalSearch} handleClose={handleModalSearch} />
      <ElevationScroll>
        <AppBar className={clsx(styles.header, {
          [styles.header_scroll]: trigger,
          [styles.header_not_main]: location.pathname !== '/main/home' && !trigger && isMobile,
          [styles.disable_transition]: isMobile
        })}>
          <div>
            <Link to="/" className={`${"header-logo"} ${styles.header_logo}`}>
              {location.pathname.includes("notification") || location.pathname.includes("info") || location.pathname.includes("about") ? (
                <img src={trigger ? logoScroll : logo} className={trigger ? "" : "logo_img"} alt="Glamezy" />
              ) : (
                <div>
                  <img src={logo} className={"logo_img"} alt="Glamezy" />
                  <img
                    src={logoScroll}
                    className={clsx(`${"logo_img_scroll"} ${styles.logo_img_scroll}`, {
                      [styles.logo_img_not_main]: location.pathname !== '/main/home' && !trigger && isMobile
                    })}
                    alt="Glamezy"
                  />
                </div>
              )}
            </Link>
            {!isMobile && (
              <>
                <DialogMain
                  titleDialog="Location"
                  showModal={openBlockModal}
                  onOpen={onOpenModal}
                  onClose={onCloseModal}
                  showDialogActions={false}
                  openDialogButtonText={city}
                  openDialogButtonClass="submit-btn-save-changes with-icon with-arrow"
                >
                  <HeaderSearchLocation onClose={onCloseModal} />
                </DialogMain>
                <HeaderSearchVenues handleClose={handleModalSearch} />
              </>
            )}
          </div>
          <div>
            {isMobile ? (
              <div
                className={clsx(classes.iconMenu, {
                  [classes.iconScroll]: trigger,
                  [classes.iconUnScroll]: !trigger,
                })}
              >
                <SearchIcon onClick={handleModalSearch} className={classes.searchIcon} />
                <UserSelect />
                <MoreDropDown />
              </div>
            ) : (
              <>
                <MoreDropDown />
                <UserSelect />
              </>
            )}
          </div>
        </AppBar>
      </ElevationScroll>
    </div>
  );
};
