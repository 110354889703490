import React from "react";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Radio from "@material-ui/core/Radio";
import Popper from "@material-ui/core/Popper";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { Controller } from "react-hook-form";
import clsx from 'clsx';

import { SortByHook } from "./SortByHook";
import { searchSortBy } from "~/constants";
import { SortByProps } from "../../interfaces";
import MuiDrawer from "~/Components/MuiDrawer";
import { MuiDrawerHook } from "~/Components/MuiDrawer/MuiDrawerHook";
import { CheckMobileHook } from "~/Components/MainHeader/CheckMobileHook";

import styles from "./SortByStyle.module.scss";

export const SortBy: React.FC<SortByProps> = ({ searchFields }) => {
  const sortByHook = SortByHook(searchFields);
  const {
    open,
    sortBy,
    control,
    anchorRef,
    setSortBy,
    handleClose,
    handleToggle,
    handleListKeyDown,
  } = sortByHook;

  const { isMobile } = CheckMobileHook();
  const { isToggle, toggleDrawer } = MuiDrawerHook();

  const ViewMobile = () => (
    <Controller
    control={control}
    name={searchSortBy.name}
    defaultValue={searchSortBy.defaultValue}
    render={({ field: { onChange, value } }) => (
      <Paper className={styles.paper}>
        <div className={styles.titleMobile}>
          Sort by
        </div>
        <ClickAwayListener onClickAway={toggleDrawer}>
          <MenuList
            autoFocusItem={open}
            id="menu-list-grow"
            onKeyDown={handleListKeyDown}
          >
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="sort"
                name="sort1"
                value={value}
                onChange={(e) => {
                  onChange(e.target.value);
                  setSortBy(e.target.value);
                  toggleDrawer();
                }}
              >
                <MenuItem className={styles.menuItem} >
                  <FormControlLabel
                    className={styles.radio}
                    value={searchSortBy.nearest.value}
                    control={<Radio />}
                    label={searchSortBy.nearest.label}
                  />
                </MenuItem>
                <MenuItem className={styles.menuItem} >
                  <FormControlLabel
                    className={styles.radio}
                    value={searchSortBy.priceLowHigh.value}
                    control={<Radio />}
                    label={searchSortBy.priceLowHigh.label}
                  />
                </MenuItem>
                <MenuItem className={styles.menuItem} >
                  <FormControlLabel
                    className={styles.radio}
                    value={searchSortBy.priceHighLow.value}
                    control={<Radio />}
                    label={searchSortBy.priceHighLow.label}
                  />
                </MenuItem>
                <MenuItem className={styles.menuItem} >
                  <FormControlLabel
                    className={styles.radio}
                    value={searchSortBy.newest.value}
                    control={<Radio />}
                    label={searchSortBy.newest.label}
                  />
                </MenuItem>
              </RadioGroup>
            </FormControl>
          </MenuList>
        </ClickAwayListener>
      </Paper>
    )}
  />
  );

  return (
    <>
      <div className={styles.selected_sort}>{searchSortBy[sortBy]?.label}</div>
      <div className={styles.sort_by}>
        <Button
          ref={anchorRef}
          aria-controls={open ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          onClick={isMobile ? toggleDrawer : handleToggle}
          className={clsx(styles.btn, {
            [styles.btn_select]: (open || isToggle)
          })}
        >
          Sort by
        </Button>

        <MuiDrawer
          anchor={"bottom"}
          open={isToggle}
          handleToggle={toggleDrawer}
        >
          {ViewMobile()}
        </MuiDrawer>

        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Controller
                control={control}
                name={searchSortBy.name}
                defaultValue={searchSortBy.defaultValue}
                render={({ field: { onChange, value } }) => (
                  <Paper className={styles.paper}>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList
                        autoFocusItem={open}
                        id="menu-list-grow"
                        onKeyDown={handleListKeyDown}
                      >
                        <FormControl component="fieldset">
                          <RadioGroup
                            aria-label="sort"
                            name="sort1"
                            value={value}
                            onChange={(e) => {
                              onChange(e.target.value);
                              setSortBy(e.target.value);
                            }}
                          >
                            <MenuItem onClick={handleClose}>
                              <FormControlLabel
                                className={styles.radio}
                                value={searchSortBy.nearest.value}
                                control={<Radio />}
                                label={searchSortBy.nearest.label}
                              />
                            </MenuItem>
                            <MenuItem onClick={handleClose}>
                              <FormControlLabel
                                className={styles.radio}
                                value={searchSortBy.priceLowHigh.value}
                                control={<Radio />}
                                label={searchSortBy.priceLowHigh.label}
                              />
                            </MenuItem>
                            <MenuItem onClick={handleClose}>
                              <FormControlLabel
                                className={styles.radio}
                                value={searchSortBy.priceHighLow.value}
                                control={<Radio />}
                                label={searchSortBy.priceHighLow.label}
                              />
                            </MenuItem>
                            <MenuItem onClick={handleClose}>
                              <FormControlLabel
                                className={styles.radio}
                                value={searchSortBy.newest.value}
                                control={<Radio />}
                                label={searchSortBy.newest.label}
                              />
                            </MenuItem>
                            {/* <MenuItem onClick={handleClose}>
                              <FormControlLabel
                                className={styles.radio}
                                value={searchSortBy.topRated.value}
                                control={<Radio />}
                                label={searchSortBy.topRated.label}
                              />
                            </MenuItem> */}
                          </RadioGroup>
                        </FormControl>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                )}
              />
            </Grow>
          )}
        </Popper>
      </div>
    </>
  );
};
