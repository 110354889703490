function SignInButton({ setShowModal }) {
  return (
    <button
      onClick={() => setShowModal(true)}
      className={"header-button-sign-in"}
      id="header-button-sign-in-ctm"
    >
      <i className="icon-user"></i> Sign in
    </button>
  );
}

export default SignInButton;
