import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  CustomerProfileReducerState,
  CustomerProfile,
} from "./interfaces/customerProfileInterfaces";

const customerProfileReducer = createSlice({
  name: "customerProfile",
  initialState: {
    profile: {
      avatar: null,
      firstName: "",
      lastName: "",
      dateOfBirth: null,
      phone: "",
      email: "",
      isConfirmed: true,
      id: null,
      privacyPolicy: false,
      receiveDiscountsAndPromotions: false,
      createdAt: "",
      updatedAt: "",
      newNotifications: [],
      lastNotifications: [],
      smsNotification: false,
      totalNotification: 0,
      totalPreviousNotification: 0,
    },
    cards: [],
    defaultCardId: "",
    message: "",
    messageSuccess: false,
    isLoading: false,
  } as CustomerProfileReducerState,
  reducers: {
    setCustomerProfile(state, action: PayloadAction<CustomerProfile>) {
      state.profile = { ...state.profile, ...action.payload };
    },
    loadCustomerCards(state, action: PayloadAction<Object[]>) {
      state.cards = action.payload;
    },
    loadDefaultCard(state, action: PayloadAction<string>) {
      state.defaultCardId = action.payload;
    },
    setMessage(state, action: PayloadAction<string>) {
      state.message = action.payload;
    },
    hideMessage(state) {
      state.message = "";
    },
    showMessageSuccess(state) {
      state.messageSuccess = true;
    },
    hideMessageSuccess(state) {
      state.messageSuccess = false;
    },
    setFirstNotification(state, action: PayloadAction<{ newNotifications: [], lastNotifications: [], totalNotification: number, totalPreviousNotification: number }>) {
      state.profile = {
        ...state.profile,
        newNotifications: action.payload.newNotifications,
        lastNotifications: action.payload.lastNotifications,
        totalNotification: action.payload.totalNotification,
        totalPreviousNotification: action.payload.totalPreviousNotification,
      }
    },
    setNotification(state, action: PayloadAction<{ newNotifications: [], lastNotifications: [], totalNotification: number, totalPreviousNotification: number }>) {
      state.profile = {
        ...state.profile,
        newNotifications: [...state.profile.newNotifications, ...action.payload.newNotifications],
        lastNotifications: [...state.profile.lastNotifications, ...action.payload.lastNotifications],
        totalNotification: action.payload.totalNotification,
        totalPreviousNotification: action.payload.totalPreviousNotification,
      }
    },
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setSMSNotification(state, action: PayloadAction<boolean>) {
      state.isLoading = false;
      state.profile = {
        ...state.profile,
        smsNotification: action.payload,
      }
    },
  },
});

export const {
  setCustomerProfile,
  loadCustomerCards,
  loadDefaultCard,
  setMessage,
  hideMessage,
  showMessageSuccess,
  hideMessageSuccess,
  setFirstNotification,
  setNotification,
  setIsLoading,
  setSMSNotification,
} = customerProfileReducer.actions;

export default customerProfileReducer.reducer;
