import { Router } from "react-router-dom";

import Routes from "./routes";
import history from "./utils/history";
import { ScrollToTop } from "~/Components/ScrollToTop";
import ErrorBoundary from "./ErrorBoundary";

function App() {
  return (
    <Router history={history}>
      <ScrollToTop>
        <ErrorBoundary>
          <Routes />
        </ErrorBoundary>
      </ScrollToTop>
    </Router>
  );
}

export default App;
