import React from 'react';
import { useWatch } from 'react-hook-form';

import { AddServicesModal } from '~/Containers/BookingFlowPage/components/AddServicesModal';
import { TotalSection } from '~/Containers/BookingFlowPage/components/TotalSection';
import { SelectedServices } from '~/Containers/BookingFlowPage/components/SelectedServices';
import { SummarySectionHook } from '~/Containers/BookingFlowPage/components/SummarySection/SummarySectionHook';
import type { SummarySectionProps } from '~/Containers/BookingFlowPage/interfaces';
import { CheckMobileHook } from '~/Components/MainHeader/CheckMobileHook';

import styles from './SummarySectionStyle.module.scss';

export const SummarySection: React.FC<SummarySectionProps> = (props) => {
  const { isMobile } = CheckMobileHook();
  const { date, viewedDate, fieldArrayFormMethods } = SummarySectionHook(props);
  const { isButton, formMethods: { control }, hourSelect, timeStartEnd } = props
  const { date: daySelect, time: timeSelect } = useWatch({ control });

  return isButton ? (
    <AddServicesModal
      daySelect={daySelect}
      timeSelect={timeSelect}
      hourSelect={hourSelect}
      timeStartEnd={timeStartEnd}
      fieldArrayFormMethods={fieldArrayFormMethods}
    />
  ) : (
    <>
      {!isMobile && date && <time className={styles.time}>{viewedDate}</time>}
      <div className={styles.rootSummary}>
        <SelectedServices />
        <TotalSection />
        <AddServicesModal
          daySelect={daySelect}
          timeSelect={timeSelect}
          hourSelect={hourSelect}
          timeStartEnd={timeStartEnd}
          fieldArrayFormMethods={fieldArrayFormMethods}
        />
      </div>
    </>
  );
};
