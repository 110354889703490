import * as yup from "yup";

const paymentFlow = {
  cardName: yup.string().required().min(3),
  cardNumber: yup.string().required(),
  cardDate: yup.string().required(),
  cardCVV: yup.string().required(),
  termsConditions: yup.boolean().oneOf([true]),
};

const paymentFlowSchema = yup.object().shape(paymentFlow);

export default paymentFlowSchema;
