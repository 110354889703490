import { useState } from "react";
import type { ValueSearch, ValueVenueProps } from "~/Components/MainHeader/interfaces";
import type { AxiosResponse } from "axios";

import homePageApi from "~/services/api/homePageApi";
import history from "~/utils/history";
import { mainLayoutRoute, searchResultsPageRoute, venuePageRoute } from "~/constants";
import { useAppSelector } from "~/reduxConfig";
import type { LocationReducerStateProps } from "~/store/reducers/interfaces/locationInterfaces";
import { locationSelector } from "~/store/selectors";

export const HeaderSearchValueHook = () => {
  const location = useAppSelector<LocationReducerStateProps>(locationSelector);

  const [venues, setVenues] = useState<ValueSearch[]>([]);
  const [inputSearch, setInputSearch] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const fetchTags = async (value) => {
    if (!value) return
    setIsLoading(true)
    try {
      const data = {
        search: value || '',
        lat: location.lat,
        lng: location.lng,
        country: location.country,
        page: 1,
        pageSize: 10,
      }
      const response: AxiosResponse<ValueVenueProps> = await homePageApi.getValueVenue(data);
      const newData = response?.data?.tags?.map((item) => {
        return {
          firstLetter: 'Tags',
          id: item.id,
          name: item.tagName,
        }
      })
      const dataVenues = response?.data?.venues?.map((item) => {
        return {
          firstLetter: 'Venues',
          id: item.id,
          name: item.locationName,
        }
      })
      setVenues(newData.concat(dataVenues) || []);
    } catch (error) {
      console.log('Error: ', error)
    }
    setIsLoading(false)
  };

  const onSearchHandler = (value) => {
    if (!value) return
    setInputSearch('')
    if (value.firstLetter === "Tags") {
      history.push(mainLayoutRoute.concat(`${searchResultsPageRoute}?search=${value.name}`));
    } else {
      history.push(mainLayoutRoute.concat(`${venuePageRoute}/${value.id}`));
    }
  };

  return { venues, setVenues, inputSearch, setInputSearch, onSearchHandler, fetchTags, isLoading };
};