export const listIndemnity = [
  "To the fullest extent permitted by law, you agree that you waive, release, discharge and relinquish any and all claims that you have now or may have against us which are connected with, arise out of, relate to or are incidental to the provision of the Website and your use of the Services.",
  "You indemnify us, and hold us harmless, from and against any and all claims, loss, damage, taxes, liabilities and/or expenses that may be incurred by us arising out of, or in connection with, your use of the Website, the Services and any breach by you of these Terms.",
  "You agree and acknowledge that we will not be liable or responsible for any loss or damage suffered by any Lister due to your actions, and you indemnify us from and against any and all claims by any Lister in relation to your actions (including content created and posted by you).",
  "This clause 12 survives termination or expiry of these Terms."
];

export const listIndemnityPartner = [
  "To the fullest extent permitted by law, you agree that you waive, release, discharge and relinquish any and all claims that you have now or may have against us which are connected with, arise out of, relate to or are incidental to the provision of the Website and your use of the Services.",
  "You indemnify us, and hold us harmless, from and against any and all claims, loss, damage, taxes, liabilities and/or expenses that may be incurred by us arising out of, or in connection with, your use of the Website, the Services and any breach by you of these Terms.",
  "You agree and acknowledge that we will not be liable or responsible for any loss or damage suffered by any User due to your actions, and you will indemnify us from and against any and all claims by any User in relation to any and all content created by you or any actions by you.",
  "This clause 12 survives termination or expiry of these Terms."
];

export const listIntellectualProPerty = [
  {
    title: "You acknowledge that all Intellectual Property Rights in the Services, Marketplace and Website are the property of us (or our licensors) and your use of, and access to, the Services, Marketplace and Website does not give you any rights, title or interest in or to the Services, Marketplace or Website. Unless expressly authorised either under these Terms or otherwise by the licensors, you may not reproduce, adapt, modify, display, perform or distribute the Services, Marketplace or Website or any part of the Services, Marketplace or Website.",
  },
  {
    title: "You may not modify or copy the layout or appearance of the Services or any computer software or code contained in the Services, nor may you decompile or disassemble, reverse engineer or otherwise attempt to discover or access any source code related to the Services.",
  },
  {
    title: "We grant you, for the duration of these Terms as determined in accordance with clause 1.5, a non-exclusive, non-transferable and revocable licence to use the Intellectual Property Rights embodied in the Services to the extent necessary for you to use the Services, Marketplace and Website for your business purposes.",
  },
  {
    title: "By uploading, posting, transmitting or otherwise making available any Material via the Services, Marketplace or Website, you:",
    listOl: [
      "grant to us a non-exclusive, worldwide, royalty-free, perpetual, irrevocable licence to use, publish, reproduce and otherwise exploit that Material in any form to enable us to provide the Services, Marketplace and Website and unconditionally waive any moral rights that you might have in respect of that Material; and",
      "represent and warrant that you either own the Intellectual Property Rights in that Material or have the necessary permission to upload, post, transmit or otherwise make available that Material via the Website.",
    ]
  },
];

export const listDisputeResolutions = [
  {
    title: "If you have a complaint about the performance of these Terms or the Services, you will:",
    listOl: [
      "if the complaint relates to a payment to a Lister, contact the relevant Lister directly about the complaint; or",
      "for any other complaints, contact us at you will contact us at team@glamezy.com. We will assign a dispute resolution agent within 3 business days to work directly with you and the User to step through the matter, consider the complaint, determine a possible solution and notify you of the solution",
    ]
  },
  {
    title: "This clause 15 survives the expiry or termination of these Terms.",
  },
];

export const listLinkingToTheWebsite = [
  "You may link to the Website, provided that you do so in a way that is fair and legal and does not damage, or take advantage of, our reputation. You must not establish a link in a way that suggests any form of association, approval or endorsement by us where none exists.",
  "You must not establish a link to the Website from any website that is not owned by you (except with the website owner’s express permission).",
  "The Website must not be framed on any other website.",
  "We reserve the right to withdraw linking permission under this clause 16 by updating these Terms on the Website.",
];

export const listGeneral = [
  "Any provision of these Terms that is found to be void or unenforceable will, to the extent that it is void or unenforceable, be severed from these Terms without affecting the enforceability or validity of any other provisions.",
  "A party’s failure or delay to exercise a power or right does not operate as a waiver of that power or right. The exercise of a power or right does not preclude its future exercise or the exercise of any other power or right.",
  "These Terms are governed by the laws of New South Wales, Australia. The parties unconditionally submit to the exclusive jurisdiction of the courts exercising jurisdiction there.",
];

export const listDefinitions = [
  {
    textBold: "Intellectual Property Rights",
    text: "means all present and future intellectual and industrial property rights throughout the world of whatever nature (whether or not registered or registrable), including, but not limited to, all rights in respect of technical information, know-how, copyright, trade marks, designs, patents, domain names, business names, logos, drawings, trade secrets, the right to have confidential information kept confidential or other proprietary rights, or any rights to registration of such rights;",
  },
  {
    textBold: "Lister",
    text: "means any person or entity who lists goods and/or services for sale on the Marketplace;",
  },
  {
    textBold: "Marketplace",
    text: "means the online portal located on or via the Website through which certain goods and services can be negotiated, bought and sold;",
  },
  {
    textBold: "Material",
    text: "means any information, data, source codes, drawings, content, text or images in any form (whether visible or not), audio recordings, video recordings, lists, sound, video, links, layout, look and feel, control features and interfaces contained on the Website, or otherwise displayed, uploaded or published on, or via, the Website;",
  },
  {
    textBold: "Payment Processing Services",
    text: "means any services that we provide pursuant to these Terms which are to be used to process payments in relation to purchases on the Marketplace;",
  },
  {
    textBold: "Privacy Policy",
    text: "means our privacy policy, available at: www.glamezy.com/legal;",
  },
  {
    textBold: "Purchaser",
    text: "means any person or entity that purchases goods and/or services listed by a Lister for sale on the Marketplace;",
  },
  {
    textBold: "Registration Data",
    text: "means information provided by you to us for the purposes of your registration to access certain sections of the Website including, but not limited to, your name, date of birth, gender and contact details;",
  },
  {
    textBold: "Services",
    text: "refers to the Marketplace and any associated services we provide pursuant to these Terms;",
  },
  {
    textBold: "Terms",
    text: "means these User Terms and all of the terms of any order placed by you through the Website;",
  },
  {
    textBold: "User",
    text: "means any person or entity using the Services, whether they are a Lister, Purchaser or a person who browses, visits or otherwise uses the Website, the Marketplace or the Services; and",
  },
  {
    textBold: "Website",
    text: "means the website located at [www.glamezy.com] or any other website nominated by us from time to time, and any associated services, software, networks or processes.",
  },
];

export const listOverviewOfService = [
  {
    title: "The Marketplace provides an easy and convenient way to find and book with the available Partners for beauty services. We do not supply or sell goods and/or services on, or via, the Website.",
  },
  {
    title: "As a Partner, you will have:",
    listOlMain: [
      {
        title: "the ability to create a profile to be displayed on the Marketplace which must include the following information:",
        listOlChildren: [
          "a profile summary;",
          "opening hours and contact details;",
          "price lists;",
          "staff names and availabilities;",
          "a display of up to [5] images relating to the Partner; and",
          "your publicly available Google reviews; and",
          "a description of the services that the Partner is able to offer to Users.",
        ]
      },
      {
        title: "the ability to receive booking requests from Users who are interested in engaging you to provide beauty services, and the ability to accept or reject such requests;",
      },
      {
        title: "the opportunity to access performance data in relation to your profile which was created, or has come into existence, as a result of your use of the Website, such as ratings and reviews from Users regarding their experience with your services;",
      },
      {
        title: "the ability to contact our support services directly; and",
      },
      {
        title: "directly (via in order to make further inquiries or updates.",
      },
    ]
  },
  {
    title: "For the avoidance of doubt, all purchases and/or payments made pursuant to any agreement which utilises the Services is between you and the end User to the exclusion of us.",
  },
];

export const listUsingTheService = [
  "Any information we provide to Partners is supplied in good faith, but we do not guarantee the accuracy or completeness of any information provided by us or any third party, including any User or other Partner. You agree that it is your responsibility to make enquiries as to the accuracy or completeness of any information which we receive from you or third parties.",
  "You agree that you will bear the sole responsibility for any activity that occurs on your account. You must keep your account details and password secure, as you are responsible for any activity on your account (whether undertaken by you or anyone else). You agree to notify us immediately if you become aware of or suspect any security breach or unauthorised use of your password or account.",
  "The Services may contain links to other websites. Those links are provided for convenience only and may not be current. Any hyperlinks or banners advertising other websites on the Website, or external websites that advertise the Website, are not subject to our control or privacy standards, policies and procedures. We will not be responsible for the content or privacy practices associated with such linked websites and we recommend that you make your own enquiries as to the privacy and other policies of those third parties.",
  "You must take precautions to ensure that when accessing the Services, you do not expose your telecommunications or computer systems to viruses, malware or other forms of interference that may damage your telecommunications or computer systems. We are not responsible for any such damage to your telecommunications or computer systems which may arise in connection with your access to the Website and use of the Services.",
  "You acknowledge that, from time to time and at our absolute discretion, you must provide us with an official working with children check for each separate state that you work in. Additionally, we may, from time to time and at our absolute discretion, request that you provide us with a police check for each state that you work in in order to use the Services.",
  "You acknowledge that we have no obligation to provide you with customer support of any kind. However, we may provide you with customer support from time to time, at our sole discretion, provided that you submit your enquiries to contact@glamezy.com.",
  "We may, at our absolute discretion, terminate, suspend or delete your account or restrict your access to the Services (or any part of the Services) for any reason without notice. This includes for security, technical, maintenance, legal or regulatory reasons or due to any breach of these Terms. If we do this, you may be prevented from accessing all or parts of the Services, your account details or other content contained in your account. We will not be liable to you for doing so.",
];

export const listRegistration = [
  {
    title: "To access the Services, you must register with us by providing us with Registration Data as requested. You may not use one email address to register for multiple accounts."
  },
  {
    title: "We reserve the right to decline your registration request if you do not pass our verification process."
  },
  {
    title: "If you are under the age of 18 years, we may ask you to confirm that you have your parents’ or guardians’ permission and that they have agreed to these Terms on your behalf. Even if we do this, by submitting Registration Data to us, you acknowledge that you have your parents’ or guardians’ permission and that they have agreed to these Terms on your behalf."
  },
  {
    title: "If you are a parent or guardian who has permitted a person under the age of 18 (a “Minor”) to create an account, you agree to:",
    listOl: [
      "exercise supervision over the Minor's use of our Services and their personal account with us;",
      "assume all risks associated with the Minor's use of our Services and their account, including the transmission of content or information to and from third parties via the internet;",
      "assume any and all liabilities resulting from the Minor's use of our Services and their account;",
      "ensure the accuracy and truthfulness of all information submitted to us and the Website by the Minor; and",
      "provide the necessary consents contained in these Terms on behalf of the Minor.",
    ]
  },
  {
    title: "We reserve the right to take legal action and seek compensation from a parent or guardian of a Minor for any loss or damage we may suffer as a result of, or in connection with, any transaction entered into on, or via, the Website by that Minor."
  },
  {
    title: "Subject to clause 4.4, you warrant that persons providing the Registration Data to us have the relevant authority to provide such information and to enter contracts on behalf of the Partner seeking registration with us, and that you have the capacity to offer the goods and/or services listed by the Partner on the Marketplace."
  },
  {
    title: "If you provide Registration Data to us, you represent and warrant to us that the Registration Data provided is true, complete, accurate and up to date, and you undertake to maintain the truthfulness, completeness and accuracy of your Registration Data as necessary and/or as required by us."
  },
  {
    title: "If you provide us with Registration Data, you consent to the following:",
    listOl: [
      "we may provide your Registration Data to Users for the purposes of providing you with the Services;",
      "you may receive emails from us confirming the details of your registration and any subscriptions to the Services made through your account, providing you with necessary information relating to your access and use of the Services and any subscriptions to the Services made through your account; and",
      "from time to time, we may email you regarding our Services or third-party products and services which we believe may be of interest to you, such as new products, services, features, special offers and updated information. The emails may contain code that enables our database to track your usage of the emails, including whether the email was opened and/or what links (if any) were clicked. We may combine that information with other information which we have about you and may use that information to improve your site experience and/or provide customised email communications to you.",
    ]
  },
  {
    title: "You allow us to refer to you in any publicity we engage in after we have provided you with the Services or during our provision to you of the Services."
  },
  {
    title: "You acknowledge that we may use your Registration Data and any other personal information provided to us in accordance with our Privacy Policy."
  },
];

export const listHowMuch = [
  "You agree that immediately upon registration in accordance with clause 4, we will charge a commission fee of that is agreed upon onboarding you to our platform, on each transaction that you enter into with a User, as set out on the Website or as notified by us from time to time (“Charges”).",
  "The Charges are deducted from the total amount paid by the User excluding any applicable taxes and credit card processing fees.",
  "You are entitled to the remainder of the transaction amount once the Charges have been deducted (“Partner Fee”).",
  "The Charges are inclusive of GST unless otherwise specified.",
  "We use Payment Processing Services provided by Stripe. Payments submitted via Stripe are subject to the terms and conditions published on the Stripe website (collectively, the “Stripe Services Agreement”). By placing an order and using Stripe to process payments you agree to be bound by the Stripe Services Agreement, which may be modified by Stripe from time to time. As a condition of enabling Payment Processing Services through Stripe, you agree to provide us with accurate and complete information about you and your business, and you authorise us to share it and transaction information related to your use of the Payment Processing Services provided by Stripe in accordance with our Privacy Policy.",
];

export const listHowDoYouGetPaid = [
  {
    title: "For getting paid, you must ensure that your business has been onboarded through our formal onboarding process that includes, onboarding your business with our payment processor Stripe and have your venue added as a connected account to Glamezy’s Stripe account.",
  },
  {
    title: "Provided that you have supplied us with valid bank account details in your business’s name, you will receive all applicable Partner Fees in Australian Dollars via electronic funds transfer from Stripe, called ‘Payout’.",
  },
  {
    title: "Partner Fees relating to beauty services at your business will be made to you within 7-days of us receiving final payment from the end User.",
  },
  {
    title: "We are entitled to accrue all applicable Partner Fees and withhold payment until payment is due under clause 6.2.",
  },
  {
    title: "If payment by a User for a transaction via the Website is rejected or does not settle, you will not be entitled to a Partner Fee on that transaction",
  },
  {
    title: "If we receive a complaint from a User about goods and/or services purchased from you:",
    listOl: [
      "we are entitled to withhold the applicable Partner Fee for that purchase and refund it to the User at our sole discretion; or",
      "if the applicable Partner Fee has already been paid to you, we may require you to immediately refund the Partner Fee as directed by us.",
    ]
  },
  {
    title: "You indemnify us for any loss, liability or cost that we directly or indirectly suffer in relation to any tax laws or rulings as a result of, or in connection with your use of the Website, the Services or your obligations under these Terms.",
  },
];

export const listWhatAreYour = [
  {
    title: "You must at all times comply with your obligations under these Terms and fulfil your obligations in a timely manner.",
  },
  {
    title: "Your obligations include, but are not limited to, the following:",
    listMain: [
      {
        textBold: "Creation and Maintenance of Profile",
        text: "You bear sole responsibility for creating a profile to be displayed via the Website. Your profile must be reflective of the actual goods and/or services offered by you, be kept up-to-date at all times and must not be misleading or deceptive. You must ensure that content uploaded to your profile does not infringe any Intellectual Property Rights.",
      },
      {
        textBold: "Defamatory Content",
        text: "You must not upload any content to the Website that promotes intolerance, racism, illegal behaviour or contains defamatory content. We reserve the right (but not the obligation) to amend or remove any content uploaded to the Website without the uploader’s consent and without notice to the uploader (including for the purposes of attempting to avoid the upload of content prohibited by this clause 7.2).",
      },
      {
        textBold: "Viruses and Hacking",
        text: "You must not misuse any part of the Website by introducing viruses, trojans or other material which is malicious or technologically harmful. You must not attempt to gain unauthorised access to any part of the Website, the server on which the Website is stored or any server, computer or database connected to the Website. You must not engage in any activity that interferes with or disrupts the Services or the servers and networks which host the Services and you must not attempt to circumvent, disable or otherwise interfere with any security-related features of the Services or any features which prevent or restrict the use or copying of any content or enforce limitations on the use of the Services or the content of the Website.",
      },
      {
        textBold: "Interactions with Users",
        text: "You must not advocate, support or practise discrimination based on race, religion, age, national origin, language, gender, sexual orientation, or mental or physical handicap. Any complaints regarding abuse or derogatory behaviour on the part of any Partner may see such Partner(s) removed from the Website, result in the termination or suspension of their account and/or lead to restricted access to the Services, at our absolute discretion.",
      },
      {
        textBold: "Service Quality",
        text: "You must ensure that all services that you sell via the Website are provided to Users with acceptable care and skill and in compliance with any applicable obligations under the Competition and Consumer Act 2010 (Cth). This includes, but is not limited to, providing beauty services that were booked via the Website.",
      },
      {
        textBold: "Willing and Able",
        text: "At the time that you list any goods and/or services via the Website, you must be willing and able to provide such goods and/or services to Users. All prices listed on the Marketplace should be in Australian Dollars and include goods and services tax (GST), unless otherwise specified. You are expected to respond to Users’ requests within 24 hours or within such other timeframe as specified by us from time to time.",
      }

    ]
  },
  {
    title: "You acknowledge and agree that, in order for us to be able to provide the Services to you without interruption you will, at your cost, at all times:",
    listMainSecond: [
      "co-operate with and reasonably assist us to provide the Services;",
      "promptly provide us with full and accurate information, data and explanations with regard to any dispute, complaint, issue or otherwise that may arise, as and when required; and",
      "inform us of any abusive or allegedly abusive behaviour from any User or Partner on the Website.",
    ]
  },
  {
    title: "You must procure all necessary consents or authorisations from any third parties which may be required from time to time in order for us to be able to provide the Services to you.",
  },
  {
    title: "If we are delayed, obliged to spend additional time or incur additional expenses in order to provide you with the Services or otherwise perform any of our obligations under these Terms by reason of your act, omission, failure to provide information (in a timely manner or at all) or your instructions, you will compensate us by reimbursing us for any additional reasonable costs and expenses incurred by us or on our behalf. In this situation, any agreed delivery or action times specified or implied by us regarding any of our obligations to you will be extended accordingly.",
  },
  {
    title: "It is your obligation to ensure that any written communication we send to you correctly reflects your details. If any changes occur to your details, you must give us written notice of these changes so that we may update your details (which change will be made within a reasonable period of time).",
  },
  {
    title: "Any goods and/or services listed on, or via, the Website is an acknowledgement by you that you meet the specified age requirement to transact with the User, accept these Terms and agree that these Terms are binding.",
  },
];

export const listCancellationOfBooking = [
  {
    title: "Cancellations within 24 hours",
    listOlMain: [
      {
        title: "Once you have confirmed a booking for beauty services via the Website, you or the relevant User may cancel or reschedule the booking at any time outside of the twenty-four (24) hour period before the scheduled booking time and there will be no charge to either party.",
      },
      {
        title: "In the case of cancellation of beauty services, we will refund the applicable Partner Fee for that booking to [us/the User] within [7] days.",
      },
      {
        title: "Once within the twenty-four (24) hour period before the scheduled booking time",
        listOlChildren: [
          "if the User cancels the booking, [see section 8.2]; and",
          "if you cancel the booking, [see section 8.3].",
        ]
      },
    ]
  },
  {
    title: "User cancellation",
    listOlMain: [
      {
        textItalic: "User cancels then the following charges schedule applies:",
        title: "Once within the twenty-four (24) hour period before the scheduled booking time, if the ",
        listChildrenUnderline: [
          "if the cancellation is between 8 to 24 hours of the scheduled booking time, then the Partner will receive 30% of the Partner Fee unless the User opts for the free option to reschedule;",
          "if the cancellation is between 2 to 8 hours of the scheduled booking time, then the Partner will receive 50% of the Partner Fee unless the User opts for the free option to reschedule;",
          "if the cancellation is between 2 to 0 hours of the scheduled booking time, then the Partner will receive 100% of the Partner Fee.",
        ]
      },
      {
        textItalic: "If the User would like to reschedule an appointment within 2 hours of the scheduled appointment, and the Partner refuses, then the User will be refunded the full amount or Charges. ",
      },
    ]
  },
  {
    title: "Partner cancellation",
    listOlMain: [
      {
        title: "If the Partner cancels on a User three times within 30 days, then we reserve the right to alter that Partners website rating or remove the Partner from the website at our sole discretion.",
      }
    ]
  }
];

export const listTermination = [
  {
    title: "In addition to our rights under clause 3, we may, at our absolute discretion, terminate these Terms or cease to supply you with the Services, if:",
    listMain: [
      "it transpires that you have provided false or misleading information on the Website;",
      "you are found by us to be offensive or abusive to a User or other Partner;",
      "you accumulate repeated reports of customer dissatisfaction, to be determined at our discretion;",
      "your business ceases to operate or you sell a majority interest in your business to a new owner; or",
      "you do not comply with your obligations under these Terms.",
    ]
  },
  {
    title: "If your access to the Services is terminated for any reason, we will be entitled to payment for all outstanding Charges properly incurred by us up to the date of termination and any Charges incurred during any applicable notice period or otherwise specified in these Terms."
  }
];

export const listWarranties = [
  "We will, within a reasonable period of time, investigate any alleged error or other issue regarding any of the Services, provided that you notify us in writing within seven (7) days of becoming aware of the error or other issue and give us all necessary information to be able to investigate the error or other issue. Notwithstanding, you agree that our liability will be limited in any event to the right to attempt redelivery of the Services to you.",
  "You agree to use your reasonable endeavours to ensure that the information you supply to any User or to us is complete and accurate and notify us (and, if relevant, the User(s)) in writing if there is any change to the information supplied.",
  "Except as provided in these Terms, no further warranty, condition, undertaking or term, express or implied, statutory or otherwise, as to the condition, quality, performance or fitness for purpose of the Services provided in accordance with these Terms is given by us, other than as required by law. All implied warranties are hereby excluded.",
  "Nothing contained in these Terms excludes, restricts or modifies the application of any condition, warranty or other obligation, the exercise of any right or remedy, or the imposition of any liability under the Competition and Consumer Act 2010 (Cth) or any other national, state or territory legislation where to do so is unlawful.",
];

export const listLiability = [
  "To the extent permitted by law, we will not be liable for any loss, expenses, liabilities, costs or damage caused by viruses, system failures or other technologically harmful material that may infect your computer equipment, computer programs, data or other proprietary material due to your use of any part of the Website or downloading of any material or content posted on it, or on any website linked to it. We recommend that you have up-to-date virus checking software installed.",
  "You acknowledge that we provide a marketplace service for connecting Users and Partners to use the Services, and are in no way involved in purchases or payments in relation to goods and/or services displayed on the Website. To the fullest extent permitted by law, we are not responsible for dealing with, and not liable for, any disputes or complaints made by you or any User in relation to the goods or services sold on, or via, the Website (including any dispute or complaint regarding refunds, payment, goods or services).",
  "You agree that we will not be liable for any indirect, consequential, special or exemplary losses, expenses or liabilities or any loss of profits, loss of revenue, economic loss, loss of goodwill, loss, corruption or alteration of data, failure to realise anticipated savings, loss of opportunity, expectation loss or loss of production arising out of, or in connection with, the provision or use of the Services, the Marketplace, the Website or these Terms.",
  "You agree that, in any event, our maximum aggregate liability to you under these Terms will be the total Charges that you have paid in the [3 months] preceding the claim.",
  "The Partner acknowledges and agrees that the limitations of liability contained in this clause are a fair and reasonable allocation of the commercial risk between the parties.",
  "This clause 11 survives termination or expiry of these Terms."
];

export const listLinkingToTheWebsiteSecond = [
  "You may link to the Website, provided that you do so in a way that is fair and legal and does not damage, or take advantage of, our goodwill. You must not establish a link in such a way as to suggest any form of association, approval or endorsement by us where none exists.",
  "You must not establish a link to the Website from any website that is not owned by you (except with the website owner’s express permission).",
  "The Website must not be framed on any other website.",
  "We reserve the right to withdraw linking permission under this clause 17 by updating these Terms on the Website.",
];

export const listGeneralSecond = [
  {
    title: "We may assign, sub-contract, or otherwise transfer any or all of its rights and/or obligations under these Terms. You may only assign, subcontract, or otherwise transfer any or all of your rights and/or obligations with our prior written consent, which can be refused at our absolute discretion.",
  },
  {
    title: "A party’s failure or delay to exercise a power or right does not operate as a waiver of that power or right. The exercise of a power or right does not preclude its future exercise or the exercise of any other power or right.",
  },
  {
    title: "If you are using the Services for the supply of goods and/or services directly to a third party, then you will:",
    listMain: [
      "procure that such third party will comply with these Terms; and",
      "indemnify us for any claim, loss, damage or expense incurred by us as a result of any breach of these Terms by such third party.",
    ]
  },
  {
    title: "We and you are independent. No contractor, agency, partnership, joint venture, fiduciary or employment relationship is created by these Terms. Neither we nor you will have, nor represent that we or you have, any authority to make any commitments on the other party's behalf except expressly pursuant to these Terms.",
  },
  {
    title: "Any provision of these Terms that is found to be void or unenforceable will, to the extent that it is void or unenforceable, be severed from these Terms without affecting the enforceability or validity of any other provisions.",
  },
  {
    title: "These Terms are governed by the laws of New South Wales, Australia. The parties unconditionally submit to the exclusive jurisdiction of the courts exercising jurisdiction there.",
  },
  {
    title: "These Terms and any document expressly incorporated in it contains the whole agreement between us and you in respect of the subject matter of these Terms and supersedes and replaces any prior written or oral agreements, representations or understandings between the parties. The parties confirm that they have not relied on any representation that is not expressly incorporated into these Terms.",
  }
];

export const listDefinitionsSecond = [
  {
    textBold: "BSVs",
    text: "means business services venues and are the registered business locations in Australia or New Zealand of the Partners who provide the beauty services.",
  },
  {
    textBold: "Charges",
    text: "means the charges set out in clause 5, calculated at the rates set out in clause 5 or as otherwise published by us on the Website from time to time;",
  },
  {
    textBold: "Intellectual Property Rights",
    text: "means all present and future intellectual and industrial property rights throughout the world of whatever nature (whether or not registered or registrable), including, but not limited to, all rights in respect of technical information, know-how, copyright, trade marks, designs, patents, domain names, business names, logos, drawings, trade secrets, the right to have confidential information kept confidential or other proprietary rights, or any rights to registration of such rights;",
  },
  {
    textBold: "Marketplace",
    text: "means the online platform located on or via the Website through which certain goods and services can be negotiated, bought or sold;",
  },
  {
    textBold: "Material",
    text: "means any information, data, source codes, drawings, content, text or images in any form (whether visible or not), audio recordings, video recordings, lists, sound, video, links, layout, look and feel, control features and interfaces contained on the Website, or otherwise displayed, uploaded or published on, or via, the Website;",
  },
  {
    textBold: "Payment Processing Services",
    text: "means any services that we provide pursuant to these Terms which are to be used to process payments in relation to subscription fees or listings on the Marketplace;",
  },
  {
    textBold: "Privacy Policy",
    text: "means our privacy policy, available at: www.glamezy.com/legal",
  },
  {
    textBold: "Purchaser",
    text: "means any person or entity that purchases goods and/or services listed by Partners for sale on the Marketplace;",
  },
  {
    textBold: "Registration Data",
    text: "means information provided by you to us for the purposes of your registration to access certain sections of the Website including, but not limited to, your name, date of birth, gender, your business or trading name, Australian Business Number, Australian Company Number, business address and contact details;",
  },
  {
    textBold: "Services",
    text: "refers to the Marketplace and any associated services we provide pursuant to these Terms;",
  },
  {
    textBold: "Terms",
    text: "means these Partner Terms, as may be amended by us from time to time, at our discretion;",
  },
  {
    textBold: "User",
    text: "means any person or entity using the Services, whether they are a Partner, Purchaser or a person who browses, visits or otherwise uses the Website, the Marketplace or the Services; and",
  },
  {
    textBold: "Website",
    text: "means the website located at www.glamezy.com or any other website nominated by us from time to time,and any associated services, software, networks or processes.",
  },
];

export const listPersonalInformation = [
  {
    title: "Personal information is information or an opinion about an individual whose identity is apparent, or can be reasonably ascertained, from that information or opinion (whether true or not, and whether recorded in a material form or not).",
  },
  {
    title: "The type of personal information we collect from you includes, without limitation, the following:",
    listOl: [
      "your full name;",
      "mobile number;",
      "address;",
      "email address;",
      "date of birth;",
      "your device ID, device type, geo-location information, computer and connection information, statistics on page views, traffic to and from the sites, ad data, IP address and standard web log information;",
      "details of the products and services provided to you or that you have enquired about, including any additional information necessary to deliver those products and services and respond to your enquiries;",
      "any additional information relating to you that you provide to us directly or indirectly through our Platform or through other websites or accounts from which you permit us to collect information;",
      "information you provide to us through customer surveys;",
      "billing information (including credit and bank details); or",
      "any other personal information that may be required in order to facilitate your dealings with us or our business partners.",
    ]
  },
];

export const listCollection = [
  "We will collect personal information only by lawful and fair means and not in an unreasonably intrusive way. Generally, we will collect personal information directly from you, and only to the extent necessary to provide our services requested by you and to carry out our administrative functions or as required by law. We will not collect sensitive information from you.",
  "We may also collect person information from you when you fill in an application form, communicate with us, visit our Platform, provide us with feedback, complete online surveys or participate in competitions. We may collect personal information about you from our business partners or from third parties.",
  "If you use a pseudonym when dealing with us or you do not provide identifiable information to us, we may not be able to provide you with any or all of our services as requested. If you wish to remain anonymous when you use our Platform, do not sign into it or provide any information that might identify you.",
  "We require individuals to provide accurate, up-to-date and complete personal information at the time it is collected.",
]

export const personalInformation = {
  title: "We use and disclose your personal information for the purposes for which the information is collected, or for a directly related purpose, including (but not limited to):",
  listOl: [
    "providing our Platform and services to you;",
    "administering, protecting, improving or optimising our Platform  (including performing data analytics, conducting research and for advertising and marketing purposes);",
    "billing you for using our Platform;",
    "facilitating appointments with our business partners listed on our Platform offered on our Platform;",
    "informing you about our Platform, as well as the services from our beauty venue providers that you have visited or shortlisted in your wishlist;",
    "informing you rewards, surveys, contests, or other promotional activities or events sponsored or managed by us or our business partners;",
    "responding to any inquiries or comments that you submit to us;",
    "verifying your identity;",
    "any other purpose you have consented to; and",
    "any use which is required or authorised by law.",
  ]
}

export const listDisclosurePersonalInformation = [
  {
    title: "From time to time (and where required by law) we may provide your personal information to health authorities for contact tracing purposes should there be a need to identify potential exposures within the current COVID-19 environment. Such information may include sharing your full name, email address, contact number and the time of your appointment.",
  },
  {
    title: "We may disclose your personal information to:",
    listOl: [
      "third-parties we ordinarily engage from time to time to perform functions on our behalf for the above purposes, including the services providers that use our Platform to offer their services to you;",
      "our beauty venue providers that you have visited or shortlisted in your wishlist;",
      "any person or entity to whom you have consented to us disclosing your personal information to;",
      "our external business advisors, auditors, lawyers, insurers and financiers; and",
      "any person or entity to whom we are required or authorised to disclose your personal information to in accordance with the law.",
    ]
  },
]

export const listAccessManagement = [
  "Subject to some exceptions provided by law, you may request access to your personal information in our customer account database, or seek correction of it, by contacting us. See section 11: Contact Information. Should we decline you access to your personal information, we will provide a written explanation setting out our reasons for doing so.",
  "We may charge a reasonable fee that is not excessive to cover the charges of retrieving your personal information from our customer account database.  We will not charge you for making the request.",
  "If you believe that we hold personal information about you that is not accurate, complete or up-to-date then you may request that your personal information be amended. We will respond to your request to correct your personal information within a reasonable timeframe, and you will not be charged a fee for correcting your personal information.",
  "If we no longer need your personal information for any of the purposes set out in this Privacy Policy, or as otherwise required by law, we will take such steps as are reasonable in the circumstances to destroy your personal information or to de-identify it.",
]

export const listSecurity = [
  "We may hold your personal information in either electronic or hard copy. We take reasonable steps to protect your personal information from misuse, interference and loss, as well as unauthorised access, modification or disclosure and we use a number of physical, administrative, personnel and technical measures to protect your personal information. For example, we use Amazon Web Services (AWS) for hosting, use encryption and a secured information gateway to protect your Personal Information.",
  "However, we cannot guarantee the security of any personal information transmitted over the internet and therefore you disclose information to us at your own risk. We will not be liable for any unauthorised access, modification or disclosure, or misuse of your personal information.",
]

export const listCookies = [
  "The Glamezy Platform may use 'cookies' or other similar tracking technologies on our Platform that help us track your Platform usage, remember your preferences, and assist with our marketing purposes.",
  "Cookies are small files that store information on your computer, TV, mobile phone or other device. They enable an entity that put the cookie on your device to recognise you across different websites, services, devices and/or browsing sessions. The data collected through Cookies will not be kept for longer than is necessary to fulfil the purposes mentioned above.",
  "We will handle any Personal Information collected by Cookies in the same way that we handle all other Personal Information.",
  "You can delete and refuse to accept browser Cookies by activating the appropriate setting on your browser. However, if you select this setting, you may be unable to access certain parts of our Platform.",
  "Unless you have adjusted your browser setting so that it will refuse Cookies, our system will issue Cookies when you direct your browser to our Platform.",
]
