import React from 'react';

import { Intro } from '~/Components/Intro';
import "./styles.scss";

import ImgAboutUs from "~/Assets/images/img-about-us.png";

const AboutUsPage: React.FC = () => {

  return (
    <>
      <Intro heading="About us" />
      <div className="wrapper-content-about-us">
        <div className="content-about-us">
          <div className="wrapper-img-about-us">
            <img src={ImgAboutUs} alt="" />
          </div>
          <div className="about-us-content">
            <h3>Our Story</h3>
            <p>
              Lockdowns were hard and isolations were even harder. We all had our share of the so called ‘New Normal’ during COVID-19 lockdowns.
              That’s when we thought of starting a journey to make a true difference to mental health and wellbeing of everyone, so we started Glamezy.
              Glamezy is YOU!, Glamezy is Me. Glamezy is all of us. This is where we begin our story!
            </p>

            <h3>Glam is now just a click away!</h3>
            <p>
              Glamezy is your one stop shop for booking your next beauty appointment at your favourite beauty venue nearby.
              When you need your hair, nails or eyebrows done or need a quick stop for a massage at your nearest spa,
              just book through the Glamezy website or app and be on your way! All your appointments are trackable and stored securely within the Glamezy app,
              easily accessible at your fingertips. Cardless, cashless and automatic payments can be done through the app too,
              just turn up at your favourite salon and let us do the rest! What are YOU waiting for?
            </p>

            <h3>Our Promise</h3>
            <p className='text-second'>
              In a world where everything is at your fingertips, we thought booking beauty services should be simple too.
              So we made getting glam easy by helping you find services nearby. Book ahead, or on the go to snap up a time that suits you.
            </p>
            <p>
              Try somewhere new or go where you already love – we’ve curated the best for you. Choose from everyday services or something more indulgent,
              because feeling glam brings zest to life.
            </p>

            <h3>Our Purpose</h3>
            <p className='text-second'>
              <span>Our focus is</span> promoting Self-Care and uplifting the mental health awareness
            </p>
            <p className='text-second'>
              <span>Why We Choose to Exist</span> Is to help bring zest to your everyday life.
            </p>
            <p className='text-second'>
              <span>Our Ambition</span> Is to be at your finger tips while being the household name for self-care
            </p>
            <p>
              <span>The Difference</span> We’ll create to see you feeling glamorous and walk with a spring in your step.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUsPage
