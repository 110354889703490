import React, { useState } from "react";
import { CheckMobileHook } from "~/Components/MainHeader/CheckMobileHook";
import { TabPanel } from "~/Components/TabPanel";
import { Tab, Tabs } from "@material-ui/core";

import { MyBookingItem } from "~/Containers/MyBookingsPage/components/MyBookingItem";
import { MyBookingsPageHook } from "~/Containers/MyBookingsPage/MyBookingsPageHook";
import LoadingScreen from "~/Components/LoadingScreen";

import styles from "./MyBookingsPageStyle.module.scss";
import useStyles from "./styles";

export const MyBookingsPage: React.FC = () => {
  const classes = useStyles();
  const { isMobile } = CheckMobileHook();
  const {
    myUpcomingBookings = [],
    countMyUpcomingBookings,
    myPastBookings = [],
    countMyPastBookings,
    isLoading,
  } = MyBookingsPageHook();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => setValue(newValue);

  const TabData = [
    {
      title: "Upcoming",
      content: (
        <>
          {myUpcomingBookings?.map((booking) => (
            <MyBookingItem booking={booking} key={booking.id} isUpcoming />
          ))}
        </>
      ),
    },
    {
      title: "Past",
      content: (
        <>
          {myPastBookings?.map((booking) => (
            <MyBookingItem booking={booking} key={booking.id} />
          ))}
        </>
      ),
    },
  ];

  return (
    <>
      {isMobile && <section className={styles.visual}></section>}
      <section className={`${"main-container-section"} ${styles.container}`}>
        {isMobile ? (
          <>
            <div>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs"
                className={classes.tabsRoot}
              >
                {TabData?.map((item: any, index: number) => (
                  <Tab
                    key={index}
                    label={item.title}
                    id={`vertical-tab-${index}`}
                    className={styles.btnTab}
                  />
                ))}
              </Tabs>

              {isLoading ? (
                <LoadingScreen />
              ) : (
                TabData.map((item: any, index: any) => (
                  <TabPanel
                    key={index}
                    value={value}
                    index={index}
                    className={styles.tabPanel}
                  >
                    {item.content}
                  </TabPanel>
                ))
              )}
            </div>
          </>
        ) : (
          <>
            <h2>
              <span>{countMyUpcomingBookings}</span> upcoming bookings
            </h2>

            <div className={styles.section_boxes}>
              {isLoading ? (
                <LoadingScreen />
              ) : (
                myUpcomingBookings.map((booking) => (
                  <MyBookingItem
                    booking={booking}
                    key={booking.id}
                    isUpcoming
                  />
                ))
              )}
            </div>

            <h2>
              <span style={{ display: "none" }}>{countMyPastBookings}</span>
              Past bookings
            </h2>
            <div className={styles.section_boxes}>
              {isLoading ? (
                <LoadingScreen />
              ) : (
                myPastBookings.map((booking) => (
                  <MyBookingItem booking={booking} key={booking.id} isPastBooking />
                ))
              )}
            </div>
          </>
        )}
      </section>
    </>
  );
};
