import React from "react";
import type { PartnershipProps } from "./interfaces";

import styles from "./PartnershipStyles.module.scss";
import { PARTNER_LANDING_PAGE } from "~/constants";

export const Partnership: React.FC<PartnershipProps> = ({
  handleClass,
  description,
  imgUrl,
  isProfile,
}) => {
  return (
    <div
      className={
        handleClass
          ? `${styles.partnership_content} ${styles.row_reverse}`
          : styles.partnership_content
      }
    >
      <div className={styles.partnership_info}>
        <h2 className={styles.partnership_title}>
          {isProfile ? "Glamezy" : "Let’s Connect and Grow Together"}
          <br />
          <span className={styles.partnership_subtitle}>
            {isProfile ? "For Partners" : "with Glamezy"}
          </span>
        </h2>

        <p className={styles.partnership_description}>{description}</p>

        <a
          href={PARTNER_LANDING_PAGE}
          target="_blank"
          rel="noreferrer"
          className={`${styles.partnership_btn} submit-btn-save-changes`}
        >
          {isProfile ? "Become a Partner" : "Get Started"}
        </a>
      </div>

      <div className={styles.partnership_box}>
        <img src={imgUrl} alt="" />
      </div>
    </div>
  );
};
