import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import moment from "moment-timezone";
import { yupResolver } from "@hookform/resolvers/yup";

import { useAppDispatch, useAppSelector } from "~/reduxConfig";
import { bookingRescheduleSchema } from "~/services/validationServices/bookingRescheduleSchema";
import { myBookingsSelector } from "~/store/selectors";
import { onLoadBookingByIdAction, onLoadMyBookingsAction } from "~/store/actions/myBookingsActions";
import { bookingFlowPageRoute, dateFormat, flowsLayoutRoute, stepsForMyBookingDialog, timeFormat } from "~/constants";
import { customerBookingsApi } from "~/services/api/customerBookingsApi";
import type { BookingDialogsProps, MyBookingFormProps } from "~/Containers/MyBookingsPage/interfaces";
import type { PutBookingRescheduleProps } from "~/store/actions/interfaces/muBookingsActionsInterfaces";
import type { MyBookingsStateProps } from "~/store/reducers/interfaces/myBookingsInterfaces";
import history from "~/utils/history";
import { SelectedValuesMyBookingProps } from "~/Containers/BookingFlowPage/interfaces";

export const MyBookingDialogHook = (props) => {
  const {
    bookingId,
    showModal,
    setShowModal,
    displayedDate,
    status,
    venueId,
    setCancelError,
    isPastBooking
  } = props as BookingDialogsProps;
  const { bookingById } = useAppSelector<MyBookingsStateProps>(myBookingsSelector);
  const { bookingServices, venue, onlineBookingSettings } = bookingById || {};
  const [step, setStep] = useState(stepsForMyBookingDialog.main);
  const [timeError, setTimeError] = useState('');
  const [dayOffError, setDayOffError] = useState('');
  const [isLoadingReschedule, setIsLoadingReschedule] = useState(false);
  const [isLoadingCancelBooking, setIsLoadingCancelBooking] = useState(false);

  moment.tz.setDefault(venue?.timezone);

  const onChangeTimeError = (value: string) => {
    setTimeError(value)
  }

  const onChangeDayOffError = (value: string) => {
    setDayOffError(value)
  }

  const rescheduleStatuses = ["confirmed", "open", "paid", "rescheduled"];
  const rebookStatuses = ["completed", "canceled"];
  const statusForReschedule = (rescheduleStatuses.some(statusItem => statusItem === status));
  const statusForRebook = (rebookStatuses.some(statusItem => statusItem === status));

  const dispatch = useAppDispatch();

  const myBookingFormMethods = useForm<MyBookingFormProps>({
    resolver: yupResolver(bookingRescheduleSchema)
  });
  const { trigger, getValues, reset } = myBookingFormMethods;

  useEffect(() => {
    showModal && dispatch(onLoadBookingByIdAction(bookingId));
    return () => {
      setStep(stepsForMyBookingDialog.main);
    };
  }, [bookingId, dispatch, showModal]);

  useEffect(() => {
    if (!bookingServices) return;
    const newData = bookingServices.map(bookingService => {
      const { staffId, serviceId, pricingOptionId, startDateTime, isAnyone } = bookingService;
      const time = moment(startDateTime).format(timeFormat);
      const date = moment() < moment(startDateTime) ? moment(startDateTime).format(dateFormat) : moment().format(dateFormat)
      return {
        ...bookingService,
        selected: {
          date,
          pricingOptionId,
          serviceId,
          staffId: isAnyone ? 0 : staffId,
          time,
        },
      };
    }) as SelectedValuesMyBookingProps[];
    reset({
      time: moment(bookingServices[0].startDateTime).format(timeFormat),
      bookingServiceId: bookingServices[0].id.toString(),
      date: moment(bookingServices[0].startDateTime).format('YYYY-MM-DD'),
      selectedServices: newData,
    });
  }, [bookingServices, reset]);

  const titleDialog =
    step === stepsForMyBookingDialog.main
      ? displayedDate
      : step === stepsForMyBookingDialog.cancel ? "Cancel booking" : "Reschedule booking";

  const setPrevStep = () => {
    setStep(prev => prev === 1 ? prev : prev - 1);
  };

  const firstDialogButton = () => {
    if (statusForRebook) {
      return {
        onSubmit: onRebookClick,
        submitButtonText: "Rebook service",
        footerBtnAlignmentClass: "btn-big",
      };
    }

    switch (step) {
      case stepsForMyBookingDialog.main:
        const disabledReschedule = bookingServices &&
          bookingServices.length > 0 &&
          moment.tz(moment().add(onlineBookingSettings?.rescheduleBookingRule || 2, "hours"), venue?.timezone) > moment.tz(bookingServices[0]?.startDateTime, venue?.timezone)

        return {
          onSubmit: onReschedule,
          submitButtonText: "Reschedule",
          disableSubmitButton: disabledReschedule,
          firstButtonClass: `white-btn ${disabledReschedule ? "white-btn-disable" : ""}`,
          footerBtnAlignmentClass: `${isPastBooking ? "big-btn-booking" : ""} footer-btn-alignment btn-big`,
          ...(!statusForReschedule ? { showDialogActions: false } : {})
        };
      case stepsForMyBookingDialog.reschedule:
        return {
          onSubmit: onRescheduleSubmit,
          submitButtonText: "Reschedule booking",
          footerBtnAlignmentClass: "btn-big",
          setPrevStep: setPrevStep,
          isLoadingSubmitButton: isLoadingReschedule,
        };
      case stepsForMyBookingDialog.cancel:
        return {
          onSubmit: onConfirmCancelBooking,
          submitButtonText: "Confirm Cancel",
          footerBtnAlignmentClass: "btn-big",
          setPrevStep: () => setStep(stepsForMyBookingDialog.main),
          isLoadingSubmitButton: isLoadingCancelBooking,
        };
      default:
        return { showDialogActions: false };
    }
  };

  const secondDialogButton = () => {
    if (statusForRebook) return {};

    switch (step) {
      case stepsForMyBookingDialog.main:
        return {
          onSubmitSecond: onCancelBooking,
          submitSecondButtonText: !isPastBooking ? "Cancel booking" : "",
          secondButtonClass: "white-btn",
          isLoadingSecondButton: isLoadingCancelBooking,
        };
      default:
        return {};
    }
  };

  const onConfirmCancelBooking = async () => {
    setIsLoadingCancelBooking(true)
    try {
      const response = await customerBookingsApi.putCancelBookingById(bookingId);
      setShowModal(false);
      if (response.status === 200) {
        dispatch(onLoadMyBookingsAction());
      } else {
        setCancelError(true);
      }
    } catch (error) {
      setCancelError(true);
    }
    setIsLoadingCancelBooking(false)
  }

  const onCancelBooking = async () => {
    const [firstBookingService] = bookingServices;
    if (firstBookingService) {
      const durationByHour = moment(firstBookingService.startDateTime).diff(moment(), 'hours');
      if (durationByHour > 4) {
        onConfirmCancelBooking();
      } else {
        setStep(stepsForMyBookingDialog.cancel);
      }
    } else {
      setShowModal(false);
    }

  };

  const onReschedule = () => {
    switch (step) {
      case stepsForMyBookingDialog.main:
        const disabledReschedule =
          moment.tz(moment().add(onlineBookingSettings?.rescheduleBookingRule || 2, "hours"), venue?.timezone) >
          moment.tz(bookingServices[0]?.startDateTime, venue?.timezone)
        if (disabledReschedule) return
        setStep(stepsForMyBookingDialog.reschedule);
        break;
      case stepsForMyBookingDialog.reschedule:
        break;
    }
  };

  const onRescheduleSubmit = async () => {
    const { date, selectedServices } = getValues();

    if (timeError) return;

    setIsLoadingReschedule(true);
    await trigger(["time", "date"]);
    const newBookingServices = selectedServices.map((item) => {
      return {
        bookingServiceId: item.id,
        time: item.startDateTime
      }
    })
    const requestProps = {
      bookingId: bookingId,
      params: {
        venueId,
        date,
        bookingServices: newBookingServices,
      }
    } as PutBookingRescheduleProps;

    const data = await customerBookingsApi.putBookingReschedule(requestProps);

    if (data?.data?.message) {
      onChangeDayOffError(data?.data?.message)
    }

    if (data?.status === 200) {
      dispatch(onLoadMyBookingsAction());
      setShowModal(false);
    }
    setIsLoadingReschedule(false);
  };

  const onRebookClick = () => {
    const rebookServices = bookingServices.map(bookingService => {
      const { staffId, serviceId, pricingOptionId, startDateTime, package: packageData } = bookingService;
      const time = moment(startDateTime).format(timeFormat);
      const date = moment() < moment(startDateTime) ? moment(startDateTime).format(dateFormat) : moment().format(dateFormat)
      return {
        date,
        pricingOptionId,
        serviceId: packageData?.id || serviceId,
        staffId,
        time,
        type: !!packageData ? "package" : "service",
      };
    });

    history.push(
      flowsLayoutRoute.concat(bookingFlowPageRoute, `/${venueId}`),
      { rebookServices }
    );
  };

  return {
    showModal,
    setShowModal,
    myBookingFormMethods,
    titleDialog,
    step,
    firstDialogButton,
    secondDialogButton,
    onChangeTimeError,
    onChangeDayOffError,
    timeError,
    dayOffError,
    venue
  };
};
