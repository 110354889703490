import React, { useState } from "react";
import { Controller } from "react-hook-form";
import {
  TextField,
  InputLabel,
  Box,
  Grid,
  Paper,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import FacebookLogin from "react-facebook-login";

import { Message } from "~/Components/Message";
import "./SignInFormStyle.scss";
import { REACT_APP_ID } from "~/constants";

import LogoFB from "../../Assets/images/logo-fb.svg";
// import LogoInstagram from "../../Assets/images/logo-insta.svg";

export const SignInForm = ({
  control,
  hideMessage,
  message,
  setStep,
  onSubmit,
  signInFacebook,
}) => {
  const onChangeEnter = (e) => {
    if (e.key === "Enter") {
      onSubmit();
    }
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const responseFacebook = (response) => {
    if (response?.accessToken) {
      const newData = {
        accessToken: response?.accessToken,
        email: response?.email || null,
      };
      return signInFacebook(newData, false);
    }
  };

  const typeOfPswInput = showPassword ? "text" : "password";
  const IconToShow = showPassword ? <VisibilityOff /> : <Visibility />;

  return (
    <div className="card-wrapper card-wrapper-second">
      <Paper variant="outlined">
        <form>
          <Grid container justifyContent="center">
            <Grid container item xs={12} className="input-wrapper">
              <InputLabel htmlFor="emailSignIn" className="label">
                Email
              </InputLabel>
              <Controller
                control={control}
                name="emailSignIn"
                defaultValue=""
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <TextField
                      id="emailSignIn"
                      className="text-field"
                      onFocus={hideMessage}
                      value={value}
                      onChange={(e) => onChange(e.target.value)}
                      variant="outlined"
                      error={Boolean(error)}
                    />
                    <Message msg={error?.message} />
                  </>
                )}
              />
            </Grid>

            <Grid
              container
              item
              xs={12}
              className="input-wrapper wrapper-password-sign-in"
            >
              <InputLabel htmlFor="passwordSignIn" className="label">
                Password
              </InputLabel>
              <Controller
                control={control}
                name="passwordSignIn"
                defaultValue=""
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <div className="wrapper-input-password-sign-in">
                    <TextField
                      id="passwordSignIn"
                      className="text-field"
                      onFocus={hideMessage}
                      value={value}
                      onChange={(e) => onChange(e.target.value)}
                      type={typeOfPswInput}
                      variant="outlined"
                      error={Boolean(error)}
                      onKeyDown={onChangeEnter}
                    />
                    <InputAdornment
                      className="wrapper-icon-show-password"
                      position="end"
                    >
                      <IconButton
                        aria-label="Toggle password showPassword"
                        onClick={handleVisibility}
                      >
                        {IconToShow}
                      </IconButton>
                    </InputAdornment>
                    <Message msg={error?.types} type="passwordMsg" />
                  </div>
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Box
                onClick={setStep}
                component="p"
                className="forgot-psw-wrapper"
              >
                Forgot password?
              </Box>
            </Grid>

            <Box className="list-sign-in-with">
              <FacebookLogin
                appId={REACT_APP_ID}
                fields="name,email,picture"
                callback={responseFacebook}
                cssClass="wrapper-login-facebook"
                textButton="Sign In with Facebook"
                icon={<img src={LogoFB} alt="logo" />}
              />
              {/* <Box className="wrapper-sign-in-with">
                <Box className="wrapper-img">
                  <img src={LogoInstagram} alt="logo" />
                </Box>
                <Box className="title">Sign In with Instagram</Box>
              </Box> */}
            </Box>

            <Message msg={message} />
          </Grid>
        </form>
      </Paper>
    </div>
  );
};
