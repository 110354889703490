import React from 'react';
import Rating from '@material-ui/lab/Rating';

import { useAppSelector } from '~/reduxConfig';
import { venueSelector } from '~/store/selectors';
import type { VenueReducerState } from '~/store/reducers/interfaces/venueInterfaces';

import styles from './VenueRatingStile.module.scss';

export function calRating(reviews) {
  if (reviews.length === 0) {
    return 0;
  }

  return Math.round(
    reviews.reduce((acc, curr) => acc + curr.rating, 0) / reviews.length
  );
}

export const VenueRating: React.FC = () => {
  const {
    venue: { googleRating, googleRatingTotal },
    reviews = [],
  } = useAppSelector<VenueReducerState>(venueSelector);

  const totalReview = googleRating || calRating(reviews);

  return (
    <div className={styles.rating}>
      <div className={styles.info}>
        <div className={styles.rating_range}>
          <span className={styles.current_rating}>{totalReview}</span>
          <span>/5</span>
        </div>
        <div>
          <div>
            <Rating
              name='read-only'
              value={+totalReview}
              readOnly
              precision={0.1}
              size='large'
            />
          </div>
          <div>
            <span>{googleRatingTotal || reviews.length}&nbsp;reviews</span>
          </div>
        </div>
      </div>

      {/* implement when backend data will be*/}

      {/*<hr />*/}
      {/*{discreteRating.map(ratingItem => (*/}
      {/*  <RatingItem*/}
      {/*    key={uuid()}*/}
      {/*    ratingItem={ratingItem}*/}
      {/*    percentIndex={percentIndex} />*/}
      {/*))}*/}
    </div>
  );
};
