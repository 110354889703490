import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { useFormContext, Controller, useWatch } from "react-hook-form";
import { useCallback, useState } from "react";
import {
  TextField,
  InputLabel,
  Checkbox,
  FormControlLabel,
  Grid,
} from "@material-ui/core";
import moment from "moment-timezone";

import "./StripeForm.css";
import { useAppSelector } from "~/reduxConfig";
import { sessionSelector } from "~/store/selectors";
import { flowsLayoutRoute, successLayoutRoute } from "~/constants";
import { FormCardProps } from "~/Components/FormCard/interfaces";
import history from "~/utils/history";
import { Message } from "~/Components/Message";
import { stripeApi } from "~/services/api/stripeApi";
import { bookingApi } from "~/services/api/bookingApi";

export default function StripeForm(props: FormCardProps) {
  const { setClientSecret, setupStripCard, onChangeLoadingBooking, errorCard, setErrorCard } = props;
  const customer = useAppSelector(sessionSelector);
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);
  const [cardName, setCardName] = useState("");
  const [errors, setErrors] = useState(null);

  const { control } = useFormContext();
  const rememberCard = useWatch({ control, name: "remember" });

  const getServices = (timezone, selectedServices) => {
    return selectedServices.map((service) => {
      const {
        pricingOptions = [],
        selected: { pricingOptionId, serviceId, staffId, date, time },
        id,
        type,
      } = service;

      const pricingOption = pricingOptions?.find((option) => option.id === pricingOptionId);

      if (type === 'package') {
        const payload = {
          time: moment.tz(`${date} ${time}`, timezone).toISOString(),
          duration: service?.duration,
          staffId,
          extraTime: '00:00:00',
          packageId: id,
        };

        return payload;
      } else {
        const { duration, addExtraTime, staffPricingOptions = [] } = pricingOption;
        const staffPricingOption = staffPricingOptions.find(opt => opt.staffId === staffId);
        const payload = {
          time: moment.tz(`${date} ${time}`, timezone).toISOString(),
          serviceId,
          pricingOptionId,
          staffPricingOptionId: staffPricingOption?.id || null,
          duration,
          staffId,
          extraTime: addExtraTime,
        };

        return payload;
      }
    });
  };

  const onCreateBooking = useCallback(async (data, cardId) => {
    if (data) {
      const {
        venue,
        rememberCard: isRemember = false,
        bookingNotes = "",
        termsConditions,
        paymentType,
        receivePromotion = false,
        booking: { selectedServices = [] },
        discountId,
        countBookingPrice,
      } = data;
      const bookingPayload = {
        venueId: venue.id,
        bookingServices: getServices(venue.timezone, selectedServices),
        bookingNotes,
        paymentType,
        isRemember,
        termsConditions,
        cardId: cardId,
        paymentAmount: countBookingPrice.total,
        receivePromotion,
        discountId,
      };
      try {
        const response = await bookingApi.newBooking(bookingPayload);
        if (response.status === 201) {
          history.push(flowsLayoutRoute.concat(successLayoutRoute));
        }
      } catch (err) {
        console.log("booking err", err);
      }
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements || isLoading) {
      return;
    }

    onChangeLoadingBooking(true)

    if (cardName.trim().length === 0) {
      setErrors({
        ...(errors || {}),
        cardName: "Required field",
      });
      onChangeLoadingBooking(false)
      return;
    }

    setErrorCard(null);
    setIsLoading(true);

    try {
      const { error: errStripe, setupIntent } = await stripe.confirmSetup({
        elements,
        confirmParams: { return_url: window.location.href },
        redirect: "if_required",
      });

      if (errStripe) {
        onChangeLoadingBooking(false)
        setErrorCard(errStripe.message || "Something went wrong");
        return;
      }
      try {
        if (rememberCard) {
          await stripeApi.updatePaymentMethod({
            cardId: setupIntent.payment_method,
            stripeCustomerId: customer.stripeCustomerId,
            creditCardDto: {
              cardName,
              isDefault: true,
            },
          });
        }
        const booking = JSON.parse(localStorage.getItem("booking"));
        await onCreateBooking(booking, setupIntent.payment_method);
      } catch (err) {
        console.log("updatePaymentMethod err", err);
      }
    } catch (err) {
      console.log("err", err);
      setErrorCard("Something wrong!!!");
    }
    setIsLoading(false);
    onChangeLoadingBooking(false)
  };

  const onBlurCardName = () => {
    if (cardName.trim().length === 0) {
      setErrors({
        ...(errors || {}),
        cardName: "Required field",
      });
    }
  };

  const onChangeCardName = (e) => {
    setCardName(e.target.value);
    if (errors?.cardName) {
      setErrors({
        ...(errors || {}),
        cardName: undefined,
      });
    }
  };

  const onChangePaymentElement = () => {
    if (errorCard) {
      setErrorCard(null);
    }
  };

  const onResetCard = () => {
    setClientSecret("")
    setTimeout(() => {
      setupStripCard();
    }, 1000)
  }

  return (
    <form className="stripe-payment-form" onSubmit={handleSubmit}>
      <Grid
        container
        item
        xs={12}
        className="input-wrapper"
        style={{ paddingBottom: 12 }}
      >
        <InputLabel
          className="label"
          style={{ fontWeight: "normal", paddingBottom: 4 }}
        >
          Name on card
        </InputLabel>
        <TextField
          className="text-field"
          value={cardName}
          onBlur={onBlurCardName}
          onChange={onChangeCardName}
          variant="outlined"
          placeholder="Full name"
          error={!!errors?.cardName}
          style={{ borderWidth: 1 }}
          disabled={!!errorCard}
        />
        {errors?.cardName && <Message msg={errors.cardName} />}
      </Grid>

      <div className={errorCard ? "wrapper-payment-element" : ""}>
        <PaymentElement
          options={{ terms: { card: "never" } }}
          onChange={onChangePaymentElement}
        />
      </div>

      <Grid item xs={12} style={{ paddingTop: 8 }} className="wrapper-reset-card">
        <Controller
          control={control}
          name={props.checkboxName}
          defaultValue={false}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <FormControlLabel
              className="align-items-flex-start checkboxLabel-control"
              control={
                <Checkbox
                  onBlur={onBlur}
                  onChange={onChange}
                  checked={value}
                  inputRef={ref}
                  color="primary"
                  disabled={props.disabledCheckbox || errorCard}
                />
              }
              label={props.checkboxLabel}
            />
          )}
        />
        {
          errorCard && (
            <button className="btn-reset-card" onClick={onResetCard}>
              Change card
            </button>
          )
        }
      </Grid>
      <button
        id="paymentSubmitBtn"
        style={{ display: "none" }}
        type="submit"
        disabled={isLoading || !stripe || !elements}
      >
        Pay
      </button>
      {errorCard && <Message msg={errorCard} />}
    </form>
  );
}
