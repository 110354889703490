import type { Dispatch } from "redux";
import { timezoneDefault } from "~/constants";
import { GoogleApi } from "~/services/api/GoogleApi";

import { DefaultLocation, setLocation } from "~/store/reducers/locationReducer";
import { setUserLocation } from '~/store/reducers/userLocationReducer';

const LocationDefaultWithPlaceId = {
  ...DefaultLocation,
  placeId: 'ChIJP3Sa8ziYEmsRUKgyFmh9AQM', // Sydney location
}

export const getLocationAction = () => {
  return async (dispatch: Dispatch) => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { coords: { latitude, longitude } } = position;
        const latlng = { lat: latitude, lng: longitude }
        const geocoder = new google.maps.Geocoder();

        geocoder.geocode({ 'location': latlng }, async function (results, status) {
          if (status === google.maps.GeocoderStatus.OK && results[0]) {
            const { address_components: address, place_id } = results[0];
            const city = address.find((item) => {
              return (item.types.includes("political") && item.types.includes("locality")) || item.types.includes("political")
            })
            const country = address.find((item) => {
              return item.types.includes("country")
            })
            const fetchTimezone = await GoogleApi.getTimezone(latlng);
            const timezone = fetchTimezone.data.timeZoneId;

            const data = {
              placeId: place_id,
              country: country.long_name,
              city: city?.long_name || 'Unknown city',
              timezone: timezone || timezoneDefault,
              ...latlng,
            }

            dispatch(setUserLocation(data))
            dispatch(setLocation(data))
          } else {
            dispatch(setLocation(LocationDefaultWithPlaceId))
          }
        });
      },
      () => {
        dispatch(setLocation(LocationDefaultWithPlaceId))
      }
    )
  };
};
