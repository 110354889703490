import creditCardsApi from "~/services/api/creditCardsApi";
import defaultApi from "~/services/api/defaultApi";
import { bookingApi } from "~/services/api/bookingApi";
import { venuesApi } from "~/services/api/venuesApi";
import { loadCustomerCards, loadDefaultCard, setMessage, fetchVenue } from "~/store/reducers/paymentMethodReducer";

const onSetError = () => {
  throw new Error("Something wrong");
};

export const onGetCreditCardsAction = () => {
  return async (dispatch) => {
    try {
      const response = await creditCardsApi.getCreditCards();
      dispatch(loadCustomerCards(response.data.data || []));

    } catch (error: any) {
      console.log("error", error);
    }
  };
};

export const onGetDefaultCreditCardAction = (customerId) => {
  return async (dispatch) => {
    try {
      const { data } = await defaultApi.getDefaultCard(customerId);
      dispatch(loadDefaultCard(data?.invoice_settings?.default_payment_method));
    } catch (error: any) {
      console.log("error", error);
    }
  };
};

export const onPostCreditCardAction = (payload, callback) => {
  return async (dispatch) => {
    try {
      const response = await creditCardsApi.postCreditCard(payload);

      if (response.status === 200) {
        dispatch(onGetCreditCardsAction());
        callback();
        return;
      }

      onSetError();
    } catch (error: any) {
      dispatch(setMessage(error.message));
    }
  };
};

export const onLoadVenueAction = (venueId) => {
  return async (dispatch) => {
    try {
      const response = await venuesApi.getVenueById(venueId);
      dispatch(fetchVenue(response.data));
    } catch (error: any) {
      console.log("error", error);
    }
  };
};

export const onCreateBookingAction = (data, callback) => {
  return async (dispatch) => {
    try {
      const response = await bookingApi.postBookingConfirm(data);

      if (response.status === 201) {
        callback();
        return;
      }

      onSetError();
    }
    catch (error: any) {
      dispatch(setMessage(error.message));
    }
  }
}