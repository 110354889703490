import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import {
  makeStyles,
  createStyles,
  Theme,
} from "@material-ui/core/styles";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { logOutAction } from "~/store/actions/sessionActions";
import { sessionSelector } from "~/store/selectors";
import {
  bookingPageRoute,
  userLayoutRoute,
  userProfilePageRoute,
  notificationPageRoute,
} from "~/constants";
import { CheckMobileHook } from '~/Components/MainHeader/CheckMobileHook';

import styles from "./UserSelectStyle.module.scss";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    paper: {
      marginRight: theme.spacing(2),
    },
  }),
);

export const UserSelect = () => {
  const dispatch = useDispatch();
  const { firstName, lastName, totalNotSeenNotification } = useSelector(sessionSelector);
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const { isMobile } = CheckMobileHook();
  const prevOpen = useRef(open);

  const handleToggle = () => {
    setOpen((s) => !s);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div className={classes.root}>
      <Button
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        className={clsx(`${styles.drop_btn} ${styles.drop_btn_user}`, {
          [`${styles.drop_btn_mobile}`]: isMobile,
        })}
      >
        <i className="icon-user" />
        {!isMobile && (
          <>
            <span className={styles.text_btn}>
              {firstName} {lastName}
            </span>
            <svg viewBox="0 0 24 24">
              <path d="M7 10l5 5 5-5z" />
            </svg>
          </>
        )}
      </Button>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal
        className={styles.popper}>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === "bottom" ? "left top" : "left bottom" }}
          >
            <Paper className={styles.drop_holder}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}
                  className={styles.MenuList}>
                  {/* <MenuItem onClick={handleClose}>Favorites</MenuItem> */}
                  <MenuItem onClick={handleClose}>
                    <NavLink exact to={userLayoutRoute.concat(bookingPageRoute)}> My bookings</NavLink>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <NavLink exact to={userLayoutRoute.concat(notificationPageRoute)}>
                      Notifications{totalNotSeenNotification > 0 && <span className={styles.notes}>{totalNotSeenNotification}</span>}
                    </NavLink>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <NavLink exact to={userLayoutRoute.concat(userProfilePageRoute)}>Profile</NavLink>
                  </MenuItem>
                  <MenuItem onClick={() => dispatch(logOutAction())} className={styles.gray_color}>Log out</MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};
