import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import type {
  MyBookingsStateProps,
  GetMyBookingsResponseProps,
  GetBookingByIdResponseProps
} from "~/store/reducers/interfaces/myBookingsInterfaces";

const initialState = {
  allBookings: {},
  bookingById: null,
  isLoading: true,
} as MyBookingsStateProps;

const myBookingsReducer = createSlice({
  name: "myBookings",
  initialState,
  reducers: {
    fetchMyBookings: (state, action: PayloadAction<GetMyBookingsResponseProps>) => {
      state.allBookings = action.payload;
      state.isLoading = false
    },
    fetchBookingById: (state, action: PayloadAction<GetBookingByIdResponseProps>) => {
      state.bookingById = action.payload;
    },
    clearMyBookingsStore: () => initialState,
  }
});

export const {
  fetchMyBookings,
  fetchBookingById,
  clearMyBookingsStore
} = myBookingsReducer.actions;

export default myBookingsReducer.reducer;
