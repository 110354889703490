import React from "react";

import type { IntroProps } from "./interfaces";
import { titlesGeneralInfo } from "~/constants";
import { CheckMobileHook } from '~/Components/MainHeader/CheckMobileHook';
import styles from "./IntroStyle.module.scss";

export const Intro: React.FC<IntroProps> = ({ heading, text, text2 }) => {
  const {
    helpAndFaq: {
      faqTitle,
    },
    legalTitle,
    aboutUsTitle,
  } = titlesGeneralInfo;
  const { isMobile } = CheckMobileHook();

  return (
    <div className={`${styles.inner_heading} ${isMobile && styles.inner_heading_mobile}`}>
      <div className={styles.inner_text}>
        <h1>{heading}</h1>
        {text &&
          <span>{text}
            {
              text2 && <span className={styles.text_2}>{" "}{text2}</span>
            }
          </span>
        }
      </div>
      {heading !== legalTitle && heading !== faqTitle && heading !== aboutUsTitle &&
        <span className={`${styles.circle} ${styles.big}`}></span>
      }
      {(heading === legalTitle) && !isMobile &&
        <>
          <span className={`${styles.circle} ${styles.small}`}></span>
          <span className={`${styles.circle} ${styles.normal}`}></span>
        </>
      }
      {heading === faqTitle &&
        <>
          <span className={`${styles.circle} ${styles.medium}`}></span>
          <span className={`${styles.circle} ${styles.normal_2}`}></span>
        </>
      }
    </div>
  )
}