import React, { useState, useEffect } from "react";
import { AccordionSummary, Collapse, Typography } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import history from "~/utils/history";
import { bookingFlowPageRoute, flowsLayoutRoute } from "~/constants";
import { TabPanel } from "~/Components/TabPanel";
import { PricePicker } from "~/Components/PricePicker";
import { ServicesHook } from "~/Containers/VenuePage/components/Services/ServicesHook";
import type { ServicesProps } from "~/Containers/VenuePage/interfaces";

import styles from "./ServicesStyles.module.scss";
import "./styles.scss";
import { convertDuration, convertDurationString } from "~/utils/helper";
import { CheckMobileHook } from "~/Components/MainHeader/CheckMobileHook";
import { useAppSelector } from "~/reduxConfig";
import { isLoggedInSelector } from "~/store/selectors";

export const Services: React.FC<ServicesProps> = (props) => {
  const {
    service,
    packageService,
    value,
    index,
    dateTime,
    getValues,
    venue,
    pricingOptions,
    serviceName,
    serviceOptions,
    servicesFormMethods,
    onServiceOptions,
    description,
  } = ServicesHook(props);
  const widthScreen = window.innerWidth;
  const { isMobile } = CheckMobileHook();
  const { noStaff, onShowModal } = props;
  const isLoggedIn = useAppSelector(isLoggedInSelector)
  const [showMore, setShowMore] = useState(false);
  const [text, setText] = useState("");
  const [enableShowMore, setEnableShowMore] = useState(
    widthScreen < 1040 && widthScreen >= 768 ? description?.split(" ").length > 16 : description?.split(" ").length > 8
  );

  const toggleShowMore = () => setShowMore(!showMore);

  useEffect(() => {
    if (enableShowMore) {
      const desArr = description?.split(" ");
      const result = showMore
        ? description
        : desArr.slice(0, widthScreen < 1040 && widthScreen >= 768 ? 16 : 8).join(" ");
      setText(result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [description, enableShowMore, showMore]);

  useEffect(() => {
    if (widthScreen < 1040 && widthScreen >= 768 ? description?.split(" ").length < 16 : description?.split(" ").length < 8) {
      setText(description);
    } else {
      setEnableShowMore(widthScreen < 1040 && widthScreen >= 768 ? description?.split(" ").length >= 16 : description?.split(" ").length >= 8);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [description]);

  const handleBooking = () => {
    if (noStaff) {
      onShowModal()
      return
    }

    const serviceId = packageService?.id || service?.id;
    const type = packageService?.id ? "package" : "service";

    if (!isLoggedIn) {
      window.venue = {
        venue_id: venue.id,
        service: {
          ...(dateTime ? { dateTime } : {}),
          ...getValues(),
          serviceId,
          type,
        },
        type: "bookService",
      };
      const element = document.getElementById("header-button-sign-up");
      element.click();
    } else {
      history.push(
        flowsLayoutRoute.concat(bookingFlowPageRoute, `/${venue.id}`),
        {
          service: {
            ...(dateTime ? { dateTime } : {}),
            ...getValues(),
            serviceId,
            type,
          },
        }
      );
    }
  };

  return (
    <TabPanel className={styles.tabPanel} value={value} index={index}>
      <div className={styles.card_service}>
        <div className={styles.service_info}>
          <div className={styles.title_box}>
            <span className={styles.title}>{serviceName}</span>
            <span className={styles.dot}>•</span>
            <span className={styles.duration}>
              {packageService?.duration
                ? convertDurationString(packageService.duration)
                : convertDuration(+serviceOptions.duration)}
            </span>
          </div>

          {/* <div className={styles.description}>{description}</div> */}
          <div className={styles.description}>
            {text && (
              <Collapse in={showMore} collapsedSize={isMobile ? 16 : 24}>
                <Typography
                  className={styles.title_2}
                  variant="body1"
                  color="textSecondary"
                >
                  {text}
                </Typography>
              </Collapse>
            )}
            {(widthScreen < 1040 && widthScreen >= 768 ? description?.split(" ").length > 16 : description?.split(" ").length > 8) && (
              <div className={`wrapper-btn-show`} onClick={toggleShowMore}>
                <AccordionSummary
                  className={`${styles.btn_show_description} `}
                  expandIcon={<ExpandMoreIcon className={showMore ? "show-more-btn" : "show-less-btn"} />}
                  aria-controls="panel1a-content"
                >
                  <Typography>{!showMore ? "Show more" : "Show less"}</Typography>
                </AccordionSummary>
              </div>
            )}
          </div>
          {pricingOptions && (
            <PricePicker
              formMethods={servicesFormMethods}
              fieldName="pricingOptionId"
              onServiceOptions={onServiceOptions}
              pricingOptions={pricingOptions}
            />
          )}
        </div>

        <div className={styles.service_book}>
          <button className={styles.btn_book} onClick={handleBooking}>
            Book
          </button>
          <div className={styles.price_box}>
            {(serviceOptions?.specialPrice || packageService?.specialPrice) ? (
              <>
                <span className={styles.old_cost}>
                  {packageService?.price
                    ? '$' + Number(packageService.price).toFixed(2)
                    : serviceOptions.price}
                </span>
                <span className={styles.cost}>
                  {packageService?.specialPrice
                    ? '$' + Number(packageService.specialPrice).toFixed(2)
                    : serviceOptions?.specialPrice}
                </span>
              </>
            ) : (
              <span className={styles.cost}>
                {packageService?.price
                  ? '$' + Number(packageService.price).toFixed(2)
                  : serviceOptions?.price}
              </span>
            )}
          </div>
        </div>
      </div>
    </TabPanel>
  );
};
