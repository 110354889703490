import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) =>
  createStyles({
    appBar: {
      position: 'relative',
      background: 'linear-gradient(323.63deg, #651fc1 0%, #631dbf 100%)',
      '& .MuiToolbar-gutters': {
        padding: theme.spacing(2, 1),
      },
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    headerDialog: {},
    dialogFullScreen: {
      '& .MuiDialog-container': {
        '& .MuiDialog-paperFullScreen': {
          borderRadius: '0px !important',
        },
      },
      '& .MuiAutocomplete-popper': {
        [theme.breakpoints.down('sm')]: {
          width: '100%',
          transform: 'translate3d(0px, 64px, 0px)',
        },
      },
    },
    btnCancel: {
      fontSize: 12,
      lineHeight: '14px',
      fontWeight: 500,
    },
    toolbar: {
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
    },
    location: {
      marginTop: theme.spacing(2),
      padding: '16px 20px 0px',
      '& >div>button': {
        width: '100%',
        borderRadius: '4px !important',
      },
    },
    venues: {},
    popularTags: {
      margin: 20,
    },
    titlePopularTags: {
      color: "#1F1E20",
      fontSize: 16,
      fontWeight: 500,
    },
    listItemPopularTags: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    itemPopularTags: {
      padding: '10px 20px',
      background: '#FFF',
      border: '1px solid #EDEEEF',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
      borderRadius: 22,
      margin: '6px 4px',
      fontSize: 12,
      fontWeight: 500,
      color: '#8461F3',
      lineHeight: '14px',
    }
  })
);
export default useStyles;
