import React, { useEffect } from 'react';
import type { RouteComponentProps } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';

import { userNotification } from '~/constants';
import styles from './UserNotificationPageStyle.module.scss';
import { customerProfileSelector, sessionSelector } from "~/store/selectors";

import { Intro } from '~/Components/Intro';
import CardNotification from './components/CardNotification';
import { getNotificationAction } from '~/store/actions/customerProfileAction';

export const UserNotificationContainer: React.FC<RouteComponentProps> = () => {
  const dispatch = useDispatch();
  const { title, youHave, notification, notifications } = userNotification;
  const { profile } = useSelector(customerProfileSelector);
  const { totalNotSeenNotification } = useSelector(sessionSelector);
  const checkMaxNotification = (profile?.newNotifications.length + profile?.lastNotifications.length) < (profile?.totalNotification + profile?.totalPreviousNotification)

  const onClickShowMore = () => {
    if (checkMaxNotification) {
      dispatch(getNotificationAction((profile?.newNotifications.length + profile?.lastNotifications.length) / 10))
    }
  }

  useEffect(() => {
    dispatch(getNotificationAction(0))
  }, [dispatch])

  const renderNotification = (sectionTitle, notificationList) => (
    <div className={`${styles.wrapper_render_notification} ${sectionTitle === 'Previous Notifications' ? styles.margin_top_60_notification : ''}`}>
      {
        notificationList?.length > 0 && (
          <div className={styles.wrapper_title_notification}>
            <span className={styles.title_notification}>{sectionTitle}</span>
          </div>
        )
      }
      {
        notificationList?.map((item, index) => (
          <CardNotification
            key={index}
            index={index}
            notification={item}
            lengthValue={notificationList.length}
          />
        ))
      }
    </div>
  )

  return (
    <section className={styles.info_section}>
      <Intro
        heading={title}
        text={youHave}
        text2={totalNotSeenNotification > 1 ? `${totalNotSeenNotification} ${notifications}` : `${totalNotSeenNotification} ${notification}`}
      />
      <div className={styles.container_notification}>
        {renderNotification("New Notifications", profile?.newNotifications)}
        {renderNotification("Previous Notifications", profile?.lastNotifications)}
        {
          (checkMaxNotification) && (
            <div className={styles.wrapper_show_more_notification}>
              <div onClick={onClickShowMore} className={styles.show_more_notification}>
                Show More
              </div>
            </div>
          )
        }
      </div>
    </section>
  );
};

