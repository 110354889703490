import React from "react";
import { InputLabel } from "@material-ui/core";
import { useFormContext } from "react-hook-form";

import { TextAreaMaxLength } from "~/Components/TextAreaMaxLength";

import styles from "./NoteStyle.module.scss";

export const Note: React.FC = () => {
  const formMethods = useFormContext();

  return (
    <div className={styles.description}>
      <InputLabel className="label" htmlFor="descriptionInput">
        Your note
      </InputLabel>
      <TextAreaMaxLength
        formMethods={formMethods}
        maxLength={500}
        fieldName="description"
        placeholder={"Add your note (Optional)"}
      />
    </div>
  );
};
