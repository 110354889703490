import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import moment from "moment";

import { useAppSelector } from "~/reduxConfig";
import { bookingSelector } from "~/store/selectors";
import { getUniqueCategories } from "~/utils/getUniqueCategories";
import type {
  BookingReducerStateProps,
  FetchedServiceForBookingProps,
} from "~/store/reducers/interfaces/bookingInterfaces";
import type {
  AddServicesModalProps,
  BookingFormProps,
  PickedForChangeServiceProps,
  SelectedServiceProps,
} from "~/Containers/BookingFlowPage/interfaces";
import type { CategoryProps } from "~/store/reducers/interfaces/mainInterfaces";
import { totalTimeDuration } from "~/utils/timeDurationComputer";
import { timeDurationConverter } from "~/utils/timeDurationConverter";

export const AddServicesModalHook = (props) => {
  const {
    fieldArrayFormMethods,
    timeSelect,
    daySelect,
    hourSelect,
    timeStartEnd,
  } = props as AddServicesModalProps;
  const { fetchedServices, fetchedPackages, staff } =
    useAppSelector<BookingReducerStateProps>(bookingSelector);
  const {
    trigger,
    setValue,
    getValues,
    formState: { isValid },
  } = useFormContext<BookingFormProps>();
  const { append, fields } = fieldArrayFormMethods;
  const { staffId, selectedServices } = getValues();

  const isAddButtonWhite = fields.length > 0;
  const [pickedServicesForRemove, setPickedServicesForRemove] = useState<PickedForChangeServiceProps[]>([]);
  const [pickedServicesForAdd, setPickedServicesForAdd] = useState<PickedForChangeServiceProps[]>([]);
  const [pickedServicesForChange, setPickedServicesForChange] = useState<PickedForChangeServiceProps[]>([]);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [isAddServicesRun, setIsAddServicesRun] = useState<boolean>(false);

  const isSubmitButtonDisabled =
    pickedServicesForRemove.length === 0 &&
    pickedServicesForAdd.length === 0 &&
    pickedServicesForChange.length === 0;

  const setInitialState = () => {
    setPickedServicesForRemove([]);
    setPickedServicesForAdd([]);
    setPickedServicesForChange([]);
  };

  const isCheckedInSelected = (checked) => {
    return fields.some((f) => f.id === checked.serviceId);
  };

  const checkedInIndex = (checked, selected) => {
    return selected.findIndex((s) => s.serviceId === checked.serviceId);
  };

  const onPickServicesForRemove = (checkedService) => {
    const isAdded = pickedServicesForAdd.find(
      (item) => item.serviceId === checkedService.serviceId
    );
    if (isAdded) {
      setPickedServicesForAdd((prev) =>
        prev.filter((s) => s.serviceId !== checkedService.serviceId)
      );
    }

    const selectedIndexInField = fields.findIndex(
      (f) => f.selected.serviceId === checkedService.serviceId
    );
    const isExistedInRemoveList = pickedServicesForRemove.find(
      (s) => s.serviceId === checkedService.serviceId
    );
    if (selectedIndexInField >= 0 && !isExistedInRemoveList) {
      setPickedServicesForRemove((prev) => [...prev, checkedService]);
    }
  };

  const onPickServicesForAdd = (checkedService) => {
    const isAddedInRemoveList = pickedServicesForRemove.find(
      (s) => s.serviceId === checkedService.serviceId
    );

    if (isAddedInRemoveList) {
      setPickedServicesForRemove((prev) =>
        prev.filter((s) => s.serviceId !== checkedService.serviceId)
      );
    }

    const isAddedInFields = fields.find(
      (f) => f.selected.serviceId === checkedService.serviceId
    );
    if (!isAddedInFields) {
      setPickedServicesForAdd((prev) => [...prev, checkedService]);
    }
  };

  const onPickServicesForChange = (checkedService) => {
    const inRemovedIndex = checkedInIndex(
      checkedService,
      pickedServicesForRemove
    );
    const inAddedIndex = checkedInIndex(checkedService, pickedServicesForAdd);
    const inChangedIndex = checkedInIndex(
      checkedService,
      pickedServicesForChange
    );

    if (inRemovedIndex !== -1) return;

    if (inAddedIndex !== -1) {
      setPickedServicesForAdd((prev) => {
        let editedServices = prev;
        const staffPricingOption = editedServices[inAddedIndex]?.staffPricingOptions?.find((item) => item.id === checkedService?.staffPricingOptionId)
        const staffId = staffPricingOption?.staffId;
        editedServices[inAddedIndex] = { ...editedServices[inAddedIndex], ...checkedService, staffId };
        return editedServices;
      });
      return;
    }

    if (inChangedIndex !== -1) {
      setPickedServicesForChange((prev) => {
        const editedServices = prev;
        editedServices[inChangedIndex] = checkedService;
        return editedServices;
      });
      return;
    }

    if (!isCheckedInSelected(checkedService)) return;
    setPickedServicesForChange((prev) => [...prev, checkedService]);
  };

  const onOpenClickCheck = async () => {
    if (timeStartEnd[hourSelect[0]] == null) return;
    if (staff.closeDateEnabled) return;
    if (daySelect && timeSelect) {
      const dateSelect = moment(`${daySelect} ${timeSelect}`);
      if (dateSelect < moment()) return;
    }
    await trigger(["date", "time"]);
    setShowModal(isValid);
  };

  const categories = getUniqueCategories(
    fetchedServices.map((service) => ({
      id: service.serviceCategoryId,
      code: service.categoryName,
      description: service.categoryDescription,
    })) as CategoryProps[]
  );

  const addServices = () => {
    let timeDuration = 0;
    const lastField = fields ? fields[fields.length - 1] : undefined;
    const { date, time } = getValues();

    if (lastField) {
      const lastPricingOption = lastField
        ? lastField.pricingOptions?.find(
          (pricingOption) =>
            pricingOption.id === lastField.selected.pricingOptionId
        )
        : undefined;
      const lastFieldDuration = lastField.duration || lastPricingOption?.duration;
      const lastFieldExtraTime = lastPricingOption?.addExtraTime;

      timeDuration = totalTimeDuration(
        lastFieldDuration,
        lastFieldExtraTime
      );
    }

    const getServiceData = (pickedService: PickedForChangeServiceProps) => {
      return fetchedServices.find(
        (service) => service.id === pickedService.serviceId
      ) as FetchedServiceForBookingProps;
    };

    const getPackageData = (pickedService: any) => {
      return fetchedPackages.find(
        (packageData) => packageData.id === pickedService.packageId
      ) as any;
    };

    const serviceForAdding = (pickedService: PickedForChangeServiceProps) => {
      const dateStaff = { date, staffId };

      if (pickedService.type === 'package') {
        const packageSelected = getPackageData(pickedService);
        const staffs = pickedService.staff.filter((s) => s.id > 0);
        // random staff
        const selectedStaffId = staffs[Math.floor(Math.random() * staffs.length)].id;
        const packageHour = moment.duration(packageSelected.duration).asHours();
        timeDuration += packageHour;
        const sServiceTime = timeDurationConverter(time, timeDuration - packageHour);

        return {
          ...packageSelected,
          selected: {
            ...dateStaff,
            time: sServiceTime,
            staffId: selectedStaffId,
            serviceId: pickedService.packageId,
            type: 'package',
          },
          type: 'package',
        }
      }

      const service = getServiceData(pickedService);

      const pricingOption = service.pricingOptions.find(
        (opt) => opt.id === pickedService.pricingOptionId
      );

      // user selected staff but service has no staff
      const serviceHaveStaff = service.staff.some(
        (s) => s.id && s.id === staffId
      );

      // anyone
      if (staffId === 0 || !serviceHaveStaff) {
        const staffsWithoutPricing = [];
        const staffsWithPricing = [];
        service.staff.forEach((s) => {
          const { staffPricingOptions = [] } = pricingOption;
          if (s.id) {
            const havePricing: any = staffPricingOptions.some(
              (sp) => sp.staffId === s.id && (sp.price > 0 || sp.specialPrice > 0)
            );
            if (havePricing) {
              staffsWithPricing.push(s)
            } else {
              const staffPricingOptionsSelect = staffPricingOptions?.find((item) => item.staffId === s.id)
              staffsWithoutPricing.push({
                ...s,
                price: staffPricingOptionsSelect?.price,
                specialPrice: staffPricingOptionsSelect?.specialPrice
              })
            }
          }
        });

        if (staffsWithoutPricing?.length > 0) {
          const staffIds = staff?.bookingStaffList?.filter((item) =>
            item?.customStaffShifts?.length > 0
          ).map((item) => item.id);
          const newDataStaffBooking = staffsWithoutPricing?.filter((item) => staffIds?.includes(item.id))
          if (newDataStaffBooking?.length > 0) {
            dateStaff.staffId = newDataStaffBooking[0].id;
          } else if (staffsWithPricing?.length) {
            const staffIds = staff?.bookingStaffList?.filter((item) =>
              item?.customStaffShifts?.length > 0
            ).map((item) => item.id);
            const newDataStaffBooking = staffsWithPricing?.filter((item) => staffIds?.includes(item.id))
            dateStaff.staffId = newDataStaffBooking[0].id;
          }
        } else if (staffsWithPricing?.length) {
          const staffIds = staff?.bookingStaffList?.filter((item) =>
            item?.customStaffShifts?.length > 0
          ).map((item) => item.id);
          const newDataStaffBooking = staffsWithPricing?.filter((item) => staffIds?.includes(item.id))
          dateStaff.staffId = newDataStaffBooking[0].id;
        }
      }

      const staffPricingOption = pricingOption.staffPricingOptions?.find(
        (opt) => opt.staffId === dateStaff.staffId
      );

      const fullServiceDuration = totalTimeDuration(
        staffPricingOption?.duration || pricingOption.duration,
        pricingOption.addExtraTime
      );
      const startServiceTime = timeDurationConverter(time, timeDuration);

      timeDuration += fullServiceDuration;

      const dataService = {
        ...service,
        selected: {
          ...dateStaff,
          time: startServiceTime,
          serviceId: pickedService.serviceId,
          pricingOptionId: pickedService.pricingOptionId,
          staffId: pickedService.staffId || dateStaff.staffId,
          staffPricingOptionId: pickedService.staffPricingOptionId,
          type: 'service',
        },
        type: 'service',
      }

      return dataService as SelectedServiceProps;
    };

    append(pickedServicesForAdd.map(serviceForAdding));
  };

  const removeServices = () => {
    const sIds = pickedServicesForRemove.map((s) => s.serviceId);
    const newSelectedServices: any = selectedServices.filter(
      (s) => !sIds.includes(s.selected.serviceId)
    );
    setValue("selectedServices", newSelectedServices);
  };

  const changeServices = () => {
    if (selectedServices.length === 0) return;
    if (pickedServicesForChange.length === 0) return;
    pickedServicesForChange.forEach((pickedService) => {
      const { serviceId, pricingOptionId } = pickedService;
      const index = fields.findIndex((field) => field.id === serviceId);

      setValue(
        `selectedServices.${index}.selected.pricingOptionId`,
        pricingOptionId
      );
    });
  };

  useEffect(() => {
    if (isAddServicesRun) {
      setInitialState();
      setIsAddServicesRun(false);
    }
  }, [isAddServicesRun]);

  const onSubmit = () => {
    if (fields.length > 0 && pickedServicesForRemove.length > 0) {
      removeServices();
    }

    setShowModal(false);
    addServices();
    setIsAddServicesRun(true);
    changeServices();
  };

  return {
    isAddButtonWhite,
    onOpenClickCheck,
    isSubmitButtonDisabled,
    categories,
    fetchedServices,
    onPickServicesForRemove,
    onPickServicesForAdd,
    onPickServicesForChange,
    onSubmit,
    isValid,
    showModal,
    setShowModal,
    selectedStaffId: staffId,
    fetchedPackages,
    fieldArrayFormMethods: fields
  };
};
