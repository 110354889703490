import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import profileSettingsSchema from "~/services/validationServices/profileSettingsSchema";
import { customerProfileSelector } from "~/store/selectors";
import { useAppSelector, useAppDispatch } from "~/reduxConfig";
import {
  onGetCustomerProfileAction,
  onPutCustomerProfileAction,
} from "~/store/actions/customerProfileAction";
import { hideMessage } from "~/store/reducers/customerProfileReducer";

const ProfileSettingsHook = () => {
  const dispatch = useAppDispatch();
  const { profile, message } = useAppSelector(customerProfileSelector);

  const [currentFile, setCurrentFile] = useState(undefined);
  const [previewImage, setPreviewImage] = useState(profile.avatar);
  const [showSubmitBtn, setShowSubmitBtn] = useState(false);

  const profileSettingsForm = useForm({
    resolver: yupResolver(profileSettingsSchema),
    mode: "onChange",
    criteriaMode: "all",
  });

  const { setValue, watch } = profileSettingsForm;

  useEffect(() => {
    dispatch(onGetCustomerProfileAction());
  }, [dispatch]);

  useEffect(() => {
    setValue("firstName", profile.firstName);
    setValue("lastName", profile.lastName);
    setPreviewImage(profile.avatar);
    if (profile.dateOfBirth) {
      const date = profile.dateOfBirth.split("-");
      setValue("day", date[2]);
      setValue("month", date[1]);
      setValue("year", date[0]);
    }
  }, [setValue, profile]);

  const firstName = watch("firstName");
  const lastName = watch("lastName");
  const day = watch("day");
  const month = watch("month");
  const year = watch("year");

  useEffect(() => {
    if (profile.dateOfBirth) {
      const date = profile.dateOfBirth.split("-");

      if (
        firstName === profile.firstName &&
        lastName === profile.lastName &&
        day === date[2] &&
        month === date[1] &&
        year === date[0]
      ) {
        setShowSubmitBtn(false);
      } else {
        setShowSubmitBtn(true);
      }
    } else {
      if (
        firstName === profile.firstName &&
        lastName === profile.lastName &&
        day === "" &&
        month === "" &&
        year === ""
      ) {
        setShowSubmitBtn(false);
      } else {
        setShowSubmitBtn(true);
      }
    }
  }, [firstName, lastName, day, month, year, profile]);

  const selectFile = (event) => {
    const file = event.target.files[0];
    if (file) {
      setCurrentFile(file);
      setPreviewImage(URL.createObjectURL(file));
      setShowSubmitBtn(true);
    }
  };

  const onHideMessage = () => dispatch(hideMessage());

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("firstName", data.firstName);
    formData.append("lastName", data.lastName);
    formData.append("dateOfBirth", `${data.year}-${data.month}-${data.day}`);
    if (currentFile) {
      formData.append("file", currentFile);
    }
    dispatch(onPutCustomerProfileAction(formData));
  };

  return {
    profileSettingsForm,
    onSubmit,
    message,
    onHideMessage,
    previewImage,
    selectFile,
    currentFile,
    showSubmitBtn,
    profile,
  };
};

export default ProfileSettingsHook;
