import React, { useState } from "react";
import { Controller } from "react-hook-form";
import {
  TextField,
  InputLabel,
  Grid,
  Paper,
  Box,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";

import { Message } from "~/Components/Message";
import "./ChangePasswordStyle.scss";

export const ChangePasswordForm = ({
  control,
  hideMessage,
  message,
  setStep,
}) => {
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showCreatePassword, setShowCreatePassword] = useState(false);

  const handleShowCurrentPassword = () => {
    setShowCurrentPassword((prev) => !prev);
  };

  const handleShowCreatePassword = () => {
    setShowCreatePassword((prev) => !prev);
  };
  return (
    <div className="card-wrapper">
      <Paper variant="outlined">
        <form>
          <Grid container justifyContent="center">
            <Grid
              container
              item
              xs={12}
              className="input-wrapper wrapper-create-password"
            >
              <InputLabel className="label">Current password</InputLabel>
              <Controller
                control={control}
                name="currentPassword"
                defaultValue=""
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <div className="wrapper-input-create-password">
                    <TextField
                      className="text-field"
                      onFocus={hideMessage}
                      value={value}
                      onChange={(e) => onChange(e.target.value)}
                      type={showCurrentPassword ? "text" : "password"}
                      variant="outlined"
                      error={Boolean(error)}
                    />
                    <InputAdornment
                      className="wrapper-icon-show-password"
                      position="end"
                    >
                      <IconButton
                        aria-label="Toggle password showPassword"
                        onClick={handleShowCurrentPassword}
                      >
                        {showCurrentPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                    <Message msg={error?.message} />
                  </div>
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Box
                onClick={setStep}
                component="p"
                className="forgot-psw-wrapper"
              >
                Forgot password?
              </Box>
            </Grid>

            <Grid
              container
              item
              xs={12}
              className="input-wrapper wrapper-create-password"
            >
              <InputLabel className="label">Create password</InputLabel>
              <Controller
                control={control}
                name="newPassword"
                defaultValue=""
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <div className="wrapper-input-create-password">
                    <TextField
                      className="text-field"
                      onFocus={hideMessage}
                      value={value}
                      onChange={(e) => onChange(e.target.value)}
                      type={showCreatePassword ? "text" : "password"}
                      variant="outlined"
                      error={Boolean(error)}
                    />
                    <InputAdornment
                      className="wrapper-icon-show-password"
                      position="end"
                    >
                      <IconButton
                        aria-label="Toggle password showPassword"
                        onClick={handleShowCreatePassword}
                      >
                        {showCreatePassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                    <Message msg={error?.message} />
                  </div>
                )}
              />
            </Grid>

            <Message msg={message} />
          </Grid>
        </form>
      </Paper>
    </div>
  );
};
