import { Dialog } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { DialogContent } from '~/Components/DialogMain/DialogComponents';

import { useStyles } from './styles';

interface IDialogProfileMobile {
  open: boolean;
  handleCloseModal: () => void;
  title: string;
  content: JSX.Element;
}

function DialogProfileMobile({ open, handleCloseModal, title, content } : IDialogProfileMobile) {
  const classes = useStyles();
  return (
    <Dialog
      fullScreen
      onClose={handleCloseModal}
      aria-labelledby="customized-dialog-title"
      open={open}
      className={classes.root}
    >
      <div className={classes.dialogTitle}>
        {title}
        <div className={classes.dialogTitleIcon} onClick={handleCloseModal}>
          <ArrowBackIosIcon />
        </div>
      </div>
      <DialogContent className={classes.dialogContent} dividers>
        {content}
      </DialogContent>
    </Dialog>
  );
}

export default DialogProfileMobile;
