import moment from "moment";

import { convertDurationString } from "~/utils/helper";
import { SelectedServiceProps } from "~/Containers/BookingFlowPage/interfaces";
import styles from "./ServiceStyle.module.scss";

export const Service: React.FC<SelectedServiceProps> = ({
  serviceName,
  selected,
  staff,
  pricingOptions = [],
  type = "service",
  ...rest
}) => {
  const isService = type === "service";
  const { staffId, pricingOptionId, date, time } = selected;

  const selectedStaff = staff.find((item) => item.id === staffId)
  const selectedStaffFullName = selectedStaff
    ? `${selectedStaff.firstName} ${selectedStaff.lastName}`
    : "Anyone";
  const selectedDateTime = `${date} ${time}`;
  const selectedPricingOptions = pricingOptions.find((option) => option.id === pricingOptionId);
  const { variantName, price, specialPrice, duration, staffPricingOptions = [] } = selectedPricingOptions || {
    specialPrice: rest.specialPrice,
    price: rest.price,
    duration: rest.duration,
  };
  const staffPricing = staffPricingOptions.find((option) => option.staffId === staffId);
  const viewPrice = `$${staffPricing?.specialPrice || staffPricing?.price || specialPrice || price}`;
  const hourDuration = moment.duration(staffPricing?.duration || duration, 'hours').asHours();
  const hourDurationString = convertDurationString(staffPricing?.duration || duration);
  const startTime = moment(selectedDateTime).format('h:mma');
  const endTime = moment(selectedDateTime).add(hourDuration, 'h').format('h:mma');

  return (
    <section>
      <div>
        <h3 className={styles.serviceName}>
          {isService ? (
            <>
              <span >{serviceName} • {variantName}&nbsp;</span>
              <span className={styles.colored}>{viewPrice}</span>
            </>
          ) : (
            <>
              <span>{rest.packageName} <span className={styles.packages}>({rest.packageServices.length} services) </span></span>
              <span className={styles.colored}>{viewPrice}</span>
            </>
          )}
        </h3>
        <span className={styles.serviceTime}>
          {selectedStaffFullName} •&nbsp;
          <span className={styles.schedule}>{startTime} - {endTime}</span>&nbsp;•&nbsp;
          {hourDurationString}
        </span>
      </div>
    </section>
  );
};
