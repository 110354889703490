import React from 'react';

import { SelectedServicesHook } from '~/Containers/BookingFlowPage/components/SelectedServices/SelectedServicesHook';
import { SelectedServiceItem } from '../SelectedServiceItem';

export const SelectedServices: React.FC = () => {
  const { selectedServices } = SelectedServicesHook();

  if (selectedServices?.length === 0) return null;

  return (
    <>
      {selectedServices?.map((service, index) => (
        <SelectedServiceItem
          selectedService={service}
          key={index}
          index={index}
        />
      ))}
    </>
  );
};
