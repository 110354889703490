import React, { useEffect, useState } from "react";
import { Paper, Switch, Button } from "@material-ui/core";

import { onChangeSendSMSNotificationAction } from "~/store/actions/customerProfileAction";
import AccountSettingsHook from "./AccountSettingsHook";
import { DialogContent } from "../DialogContent";
import styles from "./AccountSettingsStyle.module.scss";

export const AccountSettings: React.FC = () => {
  const accountSettingsHook = AccountSettingsHook();
  const {
    step,
    stepsForProfile,
    errors,
    control,
    profile,
    message,
    showModal,
    messageSuccess,
    setStep,
    setFocus,
    register,
    setValue,
    onSubmit,
    dispatch,
    getValues,
    clearErrors,
    onSendAgain,
    setShowModal,
    logOutAction,
    onHideMessage,
    onSendAgainForReset,
    setStepAndShowModal,
    isLoading,
  } = accountSettingsHook;

  const phone = profile.phone ? `+${profile.phone.toString().match(/.{1,2}/g).join(" ")}` : "-";
  const [checkSMSNotification, setCheckSMSNotification] = useState(false)

  const onChangeSendSMSNotification = () => {
    setCheckSMSNotification(!checkSMSNotification)
    dispatch(onChangeSendSMSNotificationAction(!checkSMSNotification))
  }

  useEffect(() => {
    setCheckSMSNotification(profile.smsNotification)
  }, [profile.smsNotification])

  return (
    <div className="card-wrapper">
      <DialogContent
        step={step}
        steps={stepsForProfile}
        control={control}
        message={message}
        showModal={showModal}
        setStep={setStep}
        onSubmit={onSubmit}
        hideMessage={onHideMessage}
        setShowModal={setShowModal}
        clearErrors={clearErrors}
        setFocus={setFocus}
        register={register}
        setValue={setValue}
        getValues={getValues}
        errors={errors}
        onSendAgain={onSendAgain}
        onSendAgainForReset={onSendAgainForReset}
      />
      <Paper variant="outlined">
        <div className={styles.text}>
          {messageSuccess && (
            <div className={styles.message}>Phone number update</div>
          )}
          <h2 className={styles.title}>Account</h2>
          <p className={styles.subtitle}>
            You can change e-mail, phone number and password are displayed here
          </p>
        </div>

        <div className={styles.box}>
          <h5 className={styles.box_title}>Phone number</h5>
          <Button
            className={`${styles.box_btn_edit} btn-edit`}
            onClick={() =>
              setStepAndShowModal(stepsForProfile.changePhoneNumber)
            }
          >
            <i className="icon-edit"></i>
          </Button>
          <p className={styles.box_info}>{phone}</p>
        </div>

        <div className={styles.box}>
          <h5 className={styles.box_title}>Your email</h5>
          <Button
            className={`${styles.box_btn_edit} btn-edit`}
            onClick={() => setStepAndShowModal(stepsForProfile.changeEmail)}
          >
            <i className="icon-edit"></i>
          </Button>
          <p className={styles.box_info}>{profile.email}</p>
        </div>

        <div className={styles.box}>
          <p className={styles.box_info}>Change password</p>
          <Button
            className={`${styles.box_btn_edit} btn-edit`}
            onClick={() => setStepAndShowModal(stepsForProfile.changePassword)}
          >
            <i className="icon-edit"></i>
          </Button>
        </div>

        <div className={`${styles.box} ${styles.box_2} box-sms-notification`}>
          <p className={styles.box_info}>SMS Notifications</p>
          <Switch
            checked={checkSMSNotification}
            disabled={isLoading}
            onChange={onChangeSendSMSNotification}
            color="secondary"
          />
        </div>

        <div
          onClick={() => dispatch(logOutAction())}
          className={`${styles.box} ${styles.box_btn}`}
        >
          <p className={styles.box_log_out}>Log out</p>
        </div>
      </Paper>
    </div>
  );
};
