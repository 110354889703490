import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import clsx from "clsx";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import {
  createStyles,
  makeStyles,
  Theme,
} from "@material-ui/core/styles";
import { Menu as MenuIcon } from "@material-ui/icons";

import { useAppSelector } from "~/reduxConfig";
import { sessionSelector } from "~/store/selectors";
import {
  // contactPageRoute,
  faqPageRoute,
  generalLayoutRoute,
  PARTNER_LANDING_PAGE,
} from "~/constants";
import { CheckMobileHook } from '~/Components/MainHeader/CheckMobileHook';

import styles from "./MoreDropDownStyle.module.scss";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    paper: {
      marginRight: theme.spacing(2),
    },
  })
);

export const MoreDropDown = () => {
  const { isLoggedIn } = useAppSelector(sessionSelector);
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const prevOpen = useRef(open);
  const { isMobile } = CheckMobileHook();

  const handleToggle = () => {
    setOpen((s) => !s);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div className={classes.root}>
      <Button
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        className={clsx(`${styles.btn} ${styles.drop_btn} ${"drop-btn"}`, {
          [`${styles.drop_btn_mobile}`]: isMobile,
        })}
      >
        {!isMobile ? (
          <>
            <span className={styles.text_btn}>More</span>
            <svg viewBox="0 0 24 24">
              <path d="M7 10l5 5 5-5z" />
            </svg>
          </>
        ) : (
          <MenuIcon />
        )}
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        className={styles.popper}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "left top" : "left bottom",
            }}
          >
            <Paper className={styles.drop_holder}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                  className={styles.MenuList}
                >
                  <MenuItem disabled onClick={handleClose}>
                    Download Glamezy
                  </MenuItem>
                  <MenuItem disabled onClick={handleClose}>
                    Careers
                  </MenuItem>
                  {isLoggedIn ? (
                    <MenuItem onClick={handleClose}>
                      <a
                        href={PARTNER_LANDING_PAGE}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Partner with us
                      </a>
                    </MenuItem>
                  ) : (
                    <MenuItem onClick={handleClose}>
                      <NavLink
                        exact
                        to={generalLayoutRoute.concat(faqPageRoute)}
                      >
                        Help and FAQ
                      </NavLink>
                    </MenuItem>
                  )}
                  {/* <MenuItem onClick={handleClose}>
                    <NavLink
                      exact
                      to={generalLayoutRoute.concat(contactPageRoute)}
                    >
                      Contact us
                    </NavLink>
                  </MenuItem> */}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};
