import React from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import type { RouteComponentProps } from "react-router-dom";

import { AuthorizedHeader } from "~/Components/MainHeader/AuthorizedHeader";
import {
  bookingPageRoute,
  homePageRoute,
  mainLayoutRoute,
  userProfilePageRoute,
  notificationPageRoute
} from "~/constants";
import { UserProfilePage } from "~/Containers/UserProfilePage";
import { MyBookingsPage } from "~/Containers/MyBookingsPage";
import { UserNotificationContainer } from "~/Containers/UserNotificationPage";

export const UserLayout: React.FC<RouteComponentProps> = (props) => {
  const { match } = props;
  const { pathname } = useLocation();
  const pathNotification = pathname.split('/')[2] === 'notification';

  return (
    <>
      <AuthorizedHeader handleClass={pathNotification ? "static-header" : 'static-header-white'} />
      <main>
        <Switch>

          <Route
            path={`${match.path}${notificationPageRoute}`}
            render={(p) => <UserNotificationContainer {...p} />}
          />

          <Route
            path={`${match.path}${bookingPageRoute}`}
            render={() => <MyBookingsPage />}
          />

          <Route
            path={`${match.path}${userProfilePageRoute}`}
            render={(p) => <UserProfilePage {...p} />}
          />

          <Redirect to={mainLayoutRoute.concat(homePageRoute)} />

        </Switch>
      </main>
    </>
  );
};
