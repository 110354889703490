import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useDispatch } from "react-redux";

import { UserLayout } from "~/Layouts/UserLayout";
import { GeneralLayout } from "~/Layouts/GeneralLayout";
import { FlowsLayout } from "~/Layouts/FlowsLayout";
import { MainLayout } from "~/Layouts/MainLayout";
import { MainFooter } from "~/Components/MainFooter";
import { PrivacyPolicyLayout } from '~/Layouts/PrivacyPolicyLayout';

import {
  flowsLayoutRoute,
  generalLayoutRoute,
  homePageRoute,
  mainLayoutRoute,
  userLayoutRoute,
  privacyPolicyLayoutRoute
} from "./constants";
import { RoutesHook } from "~/routesHook";
import { getListPopularTagAction } from "./store/actions/mainActions";

const Routes: React.FC = () => {
  const { isLoggedIn } = RoutesHook();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getListPopularTagAction())
  }, [dispatch])

  return (
    <>
      <Switch>

        <Route
          path={mainLayoutRoute}
          render={(props) => <MainLayout isLoggedIn={isLoggedIn} {...props} />}
        />

        <Route
          path={generalLayoutRoute}
          render={(props) => <GeneralLayout isLoggedIn={isLoggedIn} {...props} />}
        />

        <Route
          path={privacyPolicyLayoutRoute}
          render={(props) => <PrivacyPolicyLayout isLoggedIn={isLoggedIn} {...props} />}
        />

        <Route
          path={userLayoutRoute}
          render={(props) =>
            isLoggedIn
              ? <UserLayout {...props} />
              : <Redirect to={mainLayoutRoute.concat(homePageRoute)} />
          }
        />

        <Route
          path={flowsLayoutRoute}
          render={(props) =>
            isLoggedIn
              ? <FlowsLayout {...props} />
              : <Redirect to={mainLayoutRoute.concat(homePageRoute)} />
          }
        />

        <Redirect to={mainLayoutRoute.concat(homePageRoute)} />

      </Switch>
      <MainFooter />
    </>
  );
};

export default Routes;
