export const sessionSelector = (state) => state.session;
export const isLoggedInSelector = (state) => state.session.isLoggedIn;
export const mainSelector = (state) => state.main;
export const customerProfileSelector = (state) => state.customerProfile;
export const locationSelector = (state) => state.location;
export const venueSelector = (state) => state.venuePage;
export const searchSelector = (state) => state.searchPage;
export const bookingSelector = (state) => state.booking;
export const paymentMethodSelector = (state) => state.paymentMethod;
export const myBookingsSelector = (state) => state.myBookings;
export const userLocationSelector = (state) => state.userLocation;
