import { useFormContext, useWatch } from "react-hook-form";
import moment from "moment";

import { viewedDateFormat } from "~/constants";
import type { BookingFormProps, SummarySectionProps } from "~/Containers/BookingFlowPage/interfaces";

export const SummarySectionHook = (props) => {
  const {fieldArrayFormMethods} = props as SummarySectionProps;
  const {control} = useFormContext<BookingFormProps>();
  const fields = useWatch<BookingFormProps>({control});
  const {date} = fields as Required<BookingFormProps>;
  const viewedDate = moment(date).format(viewedDateFormat);

  return {date, viewedDate, fieldArrayFormMethods};
};