import React from "react";
import moment from "moment";

import { useAppSelector } from "~/reduxConfig";
import { VenueReducerState } from "~/store/reducers/interfaces/venueInterfaces";
import { venueSelector } from "~/store/selectors";
import { timeFormat, timeAmFormatShort } from "~/constants";
import AccordionMui from "~/Components/AccordionMui/AccordionMui";
import { CheckMobileHook } from "~/Components/MainHeader/CheckMobileHook";

import styles from "./BusinessHoursStyle.module.scss";

export const BusinessHours: React.FC = () => {
  const { isMobile } = CheckMobileHook();
  const {venue: {venueBusinessHours}} = useAppSelector<VenueReducerState>(venueSelector);
  if (!venueBusinessHours) return null;
  const {
    fridayDayEnd,
    fridayDayStart,
    mondayDayEnd,
    mondayDayStart,
    saturdayDayEnd,
    saturdayDayStart,
    sundayDayEnd,
    sundayDayStart,
    thursdayDayEnd,
    thursdayDayStart,
    tuesdayDayEnd,
    tuesdayDayStart,
    wednesdayDayEnd,
    wednesdayDayStart,
  } = venueBusinessHours[0]

  const timeConverter = (time) => {
    return moment(time, timeFormat).format(timeAmFormatShort)
  }
  
  const closed = "Closed";

  const View = () => <> 
  <div className={styles.item}>
    <span {...(!!sundayDayStart ? {} : {className: styles.day_off})}>Sun</span>
    { !!sundayDayStart ? `${timeConverter(sundayDayStart)} - ${timeConverter(sundayDayEnd)}` : closed }
  </div>
  <div className={styles.item}>
    <span {...(!!mondayDayStart ? {} : {className: styles.day_off})}>Mon</span>
    { !!mondayDayStart ? `${timeConverter(mondayDayStart)} - ${timeConverter(mondayDayEnd)}` : closed }
  </div>
  <div className={styles.item}>
    <span {...(!!tuesdayDayStart ? {} : {className: styles.day_off})}>Tue</span>
    { !!tuesdayDayStart ? `${timeConverter(tuesdayDayStart)} - ${timeConverter(tuesdayDayEnd)}` : closed }
  </div>
  <div className={styles.item}>
    <span {...(!!wednesdayDayStart ? {} : {className: styles.day_off})}>Wed</span>
    { !!wednesdayDayStart ? `${timeConverter(wednesdayDayStart)} - ${timeConverter(wednesdayDayEnd)}` : closed }
  </div>
  <div className={styles.item}>
    <span {...(!!thursdayDayStart ? {} : {className: styles.day_off})}>Thu</span>
    { !!thursdayDayStart ? `${timeConverter(thursdayDayStart)} - ${timeConverter(thursdayDayEnd)}` : closed }
  </div>
  <div className={styles.item}>
    <span {...(!!fridayDayStart ? {} : {className: styles.day_off})}>Fri</span>
    { !!fridayDayStart ? `${timeConverter(fridayDayStart)} - ${timeConverter(fridayDayEnd)}` : closed }
  </div>
  <div className={styles.item}>
    <span {...(!!saturdayDayStart ? {} : {className: styles.day_off})}>Sat</span>
    { !!saturdayDayStart ? `${timeConverter(saturdayDayStart)} - ${timeConverter(saturdayDayEnd)}` : closed }
  </div>
  </>

  return (
    <>
      <div className={styles.container}>
        <div className={styles.item}>
          <h2>Business hours</h2>
        </div>
        {isMobile ? (
          <AccordionMui
            title={'Business hours'}
            detail={<div className={styles.detailMobile}>{View()}</div>}
          />
        ) : (
          View()
        )}
      </div>
    </>
  );
};

