import axios from "~/utils/api";

const creditCardsApi = {
  getCreditCards: () => axios.get("customer/credit-cards"),
  postCreditCard: (payload) => axios.post("customer/credit-cards", payload),
  putCreditCard: (cardId, payload) => axios.put(`customer/credit-cards/${cardId}`, payload),
  deleteCreditCard: (cardId) => axios.delete(`customer/credit-cards/${cardId}`),
};

export default creditCardsApi;
