import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import clsx from 'clsx';

import { CategoryAutocompleteHook } from './CategoryAutocompleteHook';
import { CategoryAutocompleteProps } from '../../interfaces';
import styles from './CategoryAutocompleteStyle.module.scss';
import { MuiDrawerHook } from '~/Components/MuiDrawer/MuiDrawerHook';
import { CheckMobileHook } from '~/Components/MainHeader/CheckMobileHook';
import MuiDrawer from '~/Components/MuiDrawer';

export const CategoryAutocomplete: React.FC<CategoryAutocompleteProps> = ({
  searchFields,
}) => {
  const categoryAutocompleteHook = CategoryAutocompleteHook(searchFields);
  const {
    focus,
    checkedAll,
    categories,
    isPickerOpen,
    selectedCategories,
    setValues,
    onClearHandler,
    onChangeHandler,
    setIsPickerOpen,
    stringSelectCategories,
  } = categoryAutocompleteHook;

  const paperStyles = checkedAll
    ? styles.paper
    : `${styles.paper} ${styles.pb}`;

  const { isToggle, toggleDrawer } = MuiDrawerHook();
  const { isMobile } = CheckMobileHook();

  useEffect(() => {
    if (selectedCategories[0]?.id === 0) {
      onChangeHandler()
      if (isToggle) {
        toggleDrawer()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stringSelectCategories])

  const onChangeCategory = (data) => () => {
    const checkData = selectedCategories.find((val) => val.id === data.id)
    if (!checkData) {
      const newData = [...selectedCategories, data]
      setValues(newData)
    } else {
      const newData = selectedCategories.filter((val) => val.id !== data.id)
      setValues(newData)
    }
  }

  const ViewMobile = () => (
    <div className={styles.box}>

      <div className={paperStyles}>
        <div className={styles.list_code}>
          {
            categories?.map((item) => {
              const checkSelectCode = selectedCategories.find((val) => val.id === item.id)
              return (
                <div
                  key={item.id}
                  className={`${styles.value_code} ${checkSelectCode && styles.value_code_select}`}
                  onClickCapture={onChangeCategory(item)}
                >
                  <span>
                    {item.code}
                  </span>
                </div>
              )
            })
          }
        </div>
        <div
          className={styles.btn_box}
          onMouseDown={(event) => {
            event.preventDefault();
          }}
        >
          <div
            onClickCapture={() => {
              onChangeHandler();
              toggleDrawer();
            }}
            className={styles.btn_apply}
          >
            Apply
          </div>
          <div
            onClickCapture={() => {
              onClearHandler();
              toggleDrawer();
            }}
            className={styles.btn_clear}
          >
            Clear filter
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className={styles.box}>
      <MuiDrawer anchor={'bottom'} open={isToggle} handleToggle={toggleDrawer}>
        {ViewMobile()}
      </MuiDrawer>
      <Autocomplete
        getOptionLabel={(option) => option.code}
        multiple
        value={selectedCategories}
        id="checkboxes-tags"
        open={isPickerOpen}
        onOpen={
          // () => setIsPickerOpen((prev) => !prev)
          isMobile ? toggleDrawer : () => setIsPickerOpen((prev) => !prev)
        }
        onChange={(e, data) => setValues(data)}
        debug
        options={categories}
        className={clsx(styles.autocomplete, {
          [styles.autocomplete_selected]: (selectedCategories.length > 0 || isToggle),
        })}
        limitTags={1}
        disableCloseOnSelect
        PopperComponent={(props) => (
          <div className={styles.popper}>{props.children}</div>
        )}
        PaperComponent={(props) => (
          <div className={paperStyles}>
            {props.children}
            {
              selectedCategories?.length > 0 && selectedCategories[0]?.id !== 0 && (
                <div
                  className={styles.btn_box}
                  onMouseDown={(event) => {
                    event.preventDefault();
                  }}
                >
                  <button onClick={onChangeHandler} className={styles.btn_apply}>
                    Apply
                  </button>
                  <button onClick={onClearHandler} className={styles.btn_clear}>
                    Clear filter
                  </button>
                </div>
              )
            }
          </div>
        )}
        renderInput={(params) => (
          <TextField
            className={styles.TextField}
            {...params}
            label="Category"
            variant="outlined"
            placeholder=""
            autoFocus={focus}
          />
        )}
        renderTags={(value: any, getTagProps) => (
          <div className={styles.value_box}>
            <p className={styles.value}>{value[0].code}</p>
            {value.length > 1 && (
              <p className={styles.value_length}> +{value.length - 1}</p>
            )}
          </div>
        )}
      />
    </div>
  );
};
