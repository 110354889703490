import React, { useEffect, useMemo, useState } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Button } from "@material-ui/core";
import { ServicesProps, VenueReducerState } from "~/store/reducers/interfaces/venueInterfaces";
import { Services } from "../Services/Services";
import { CheckMobileHook } from "~/Components/MainHeader/CheckMobileHook";
import AccordionMui from "~/Components/AccordionMui/AccordionMui";

import styles from "./OurServicesStyle.module.scss";
import { useAppSelector } from "~/reduxConfig";
import { venueSelector } from "~/store/selectors";
import DialogMain from "~/Components/DialogMain/DialogMain";

function tabProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export const OurServices: React.FC<ServicesProps> = ({
  serviceCategories = [],
}) => {
  const { staff } = useAppSelector<VenueReducerState>(venueSelector);
  const [value, setValue] = useState(0);
  const { isMobile } = CheckMobileHook();
  const [moreBtn, setMoreBtn] = useState(false);
  const [countCategories, setCountCategories] = useState(false);
  const [showModalNoStaff, setShowModalNoStaff] = useState(false);
  const sortServiceCategories = serviceCategories?.filter((item) => {
    return item?.services?.length > 0 || item?.packages?.length > 0
  })

  const onShowModal = () => {
    setShowModalNoStaff(true)
  }

  const onCloseModal = () => {
    setShowModalNoStaff(false)
  }

  useEffect(() => {
    if (sortServiceCategories.length > 10) {
      setMoreBtn(true);
      setCountCategories(true);
    } else {
      setCountCategories(false);
    }
  }, [sortServiceCategories]);

  const onChangeBtn = () => {
    setMoreBtn((prev) => !prev);
  };

  const categoriesList = useMemo(() => {
    if (!isMobile && moreBtn) {
      if (value > 9) {
        setValue(0);
      }

      return [...sortServiceCategories].slice(0, 10);
    }
    return [...sortServiceCategories];
  }, [isMobile, moreBtn, sortServiceCategories, value]);

  const handleChangeValue = (val: number) => () => {
    setValue(val);
  };

  const accordionDetail = () => {
    return (
      <div className={styles.services_box}>
        <div className={styles.services_box_holder}>
          {categoriesList.map((category, index) =>
            category.services.map((service) => (
              <Services
                key={service.id}
                service={service}
                value={value}
                index={index}
                noStaff={staff?.venueStaffList?.length === 0}
                onShowModal={onShowModal}
              />
            ))
          )}
        </div>
      </div>
    );
  };

  const viewMobile = () => (
    <div>
      {categoriesList.map((category, index) => {
        return (
          <AccordionMui
            key={index}
            title={category.categoryName}
            detail={accordionDetail()}
            handleAction={handleChangeValue(index)}
          />
        );
      })}
    </div>
  );

  if (serviceCategories?.length === 0 || sortServiceCategories?.length === 0) {
    return null
  }

  if (isMobile) {
    return viewMobile();
  }

  return (
    <div>
      <div className="tab-panel tab-panel-venue">
        <div className={styles.wrapper_categories_box}>
          <h1>Our services</h1>
          <div className={styles.categories_box}>
            <Tabs
              orientation="vertical"
              value={value}
              onChange={(event, newValue) => setValue(newValue)}
              aria-label="Vertical tabs"
              className="tabs-block"
            >
              {categoriesList.map((category, index) => (
                <Tab
                  key={category.id}
                  className="tab-button"
                  label={category.categoryName}
                  {...tabProps(index)}
                />
              ))}
            </Tabs>

            {moreBtn && countCategories && (
              <Button
                onClick={onChangeBtn}
                variant="outlined"
                className={styles.btn_list}
              >
                See All
              </Button>
            )}

            {!moreBtn && countCategories && (
              <Button
                onClick={onChangeBtn}
                variant="outlined"
                className={styles.btn_list}
              >
                Show less
              </Button>
            )}
          </div>
        </div>

        <div className={styles.services_box}>
          <div className={styles.services_box_holder}>
            {categoriesList.map((category, index) => {
              return (
                <React.Fragment key={category.id}>
                  {category.services.map((service) => (
                    <Services
                      key={service.id}
                      service={service}
                      value={value}
                      index={index}
                      noStaff={staff?.venueStaffList?.length === 0}
                      onShowModal={onShowModal}
                    />
                  ))}
                  {category.packages.length > 0 && index === value && (
                    <div className={styles.packagesWrap}>
                      <div className={styles.packagesTitle}>
                        <div className={styles.divider}></div>
                        <div className={styles.title}>Packages</div>
                        <div className={styles.divider}></div>
                      </div>
                      <div className={styles.packages}>
                        {category.packages.map((item) => {
                          return (
                            <Services
                              key={item.id}
                              packageService={item}
                              value={value}
                              index={index}
                              noStaff={staff?.venueStaffList?.length === 0}
                              onShowModal={onShowModal}
                            />
                          )
                        })}
                      </div>
                    </div>
                  )}
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </div>
      {
        staff?.venueStaffList?.length === 0 && (
          <DialogMain
            titleDialog="Staff Required"
            showModal={showModalNoStaff}
            openDialogButtonClass="btn-text"
            submitButtonText={"Close"}
            onSubmit={onCloseModal}
            onClose={onCloseModal}
            dialogWindowClass="modal-dialog-service"
          >
            <p style={{ textAlign: "center", paddingTop: 20 }}>
              The venue has no employees working!
            </p>
          </DialogMain>
        )
      }
    </div>
  );
};
