import axios from "~/utils/api";
import type { PutBookingRescheduleProps } from "~/store/actions/interfaces/muBookingsActionsInterfaces";

export const customerBookingsApi = {
  getMyBookings: () => {
    return axios.get(`/customer/bookings/my-bookings`);
  },
  getBookingById: (id: number) => {
    return axios.get(`/customer/bookings/${id}`);
  },
  putBookingReschedule: (props: PutBookingRescheduleProps) => {
    const { bookingId, params } = props;
    return axios.put(
      `/customer/bookings/reschedule-booking/${bookingId}`,
      params
    );
  },
  putCancelBookingById: (id: number) => {
    return axios.put(`/customer/bookings/${id}/cancel-booking`);
  },
};
