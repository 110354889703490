import React from "react";
import { Controller } from "react-hook-form";
import { Avatar, FormControlLabel, RadioGroup } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import DialogMain from "~/Components/DialogMain/DialogMain";
import type { StaffModalPickerProps } from "~/Components/StaffModalPicker/interfaces";

import styles from "./StaffModalPickerStyle.module.scss";
import { StyledRadio } from "~/Components/StyledRadio";
import { onLoadBookingCountPriceAction } from "~/store/actions/bookingActions";
import { bookingSelector } from "~/store/selectors";

export const StaffModalPicker: React.FC<StaffModalPickerProps> = (props) => {
  const dispatch = useDispatch();
  const { staff: staffBooking } = useSelector(bookingSelector);
  const {
    formMethods: { control, getValues, setValue },
    fieldName,
    staff,
  } = props;

  const onCloseSfaffModal = (isOpen) => {
    if (!isOpen) {
      const selectedStaffId = getValues(fieldName);
      // anyone selected
      // random staff without staff pricing
      // if all staff have staff pricing, pick staff with lowest price
      if (!selectedStaffId) {
        const [, serviceIndex] = fieldName.split(".");
        const serviceSelected = getValues("selectedServices." + serviceIndex);

        const { pricingOptions, selected, staff: staffs, type } = serviceSelected;
        if (type === 'service') {
          const selectedPricingOptions = pricingOptions.find(
            (item) => item.id === selected.pricingOptionId
          );
          const staffIdsPricing = selectedPricingOptions?.staffPricingOptions?.filter(
            (item) => !!item.staffId
          )

          if (staffIdsPricing.length) {
            const staffIds = staffBooking?.bookingStaffList?.filter(
              (item) => item?.customStaffShifts?.length > 0
            ).map((item) => item.id);
            const newDataStaffBooking = staffIdsPricing?.filter((item) => staffIds?.includes(item.staffId))
            const dataStaffSelect = newDataStaffBooking.find((item) => item?.price === null && item?.specialPrice === null) || newDataStaffBooking[0]
            setValue(fieldName, dataStaffSelect.staffId);
          } else {
            const staffIds = staffBooking?.bookingStaffList?.filter(
              (item) => item?.customStaffShifts?.length > 0
            ).map((item) => item.id);
            const newDataStaffBooking = staff?.filter((item) => staffIds?.includes(item.id))
            const dataStaffSelect = newDataStaffBooking[0]
            setValue(fieldName, dataStaffSelect.id);
          }
        } else {
          const stf = staffs.find((s) => !!s.id);
          setValue(fieldName, stf.staffId);
        }
      }

      // re-calculate total price
      setTimeout(() => {
        const { selectedServices = [], venueId } = getValues();
        const payload = {
          venueId,
          services: selectedServices.map((service) => {
            if (service.type === 'service') {
              return {
                serviceId: service.id,
                pricingOptionId: service.selected.pricingOptionId,
                staffPricingOptionId: "null",
                staffId: service.selected.staffId
              }
            }

            return {
              packageId: service.selected.serviceId,
              staffId: service.selected.staffId,
            }
          }),
        };
        dispatch(onLoadBookingCountPriceAction(payload));
      }, 100);
    }
  };

  return (
    <Controller
      control={control}
      name={fieldName}
      defaultValue={getValues(fieldName)}
      render={({ field: { value, onChange } }) => {
        return (
          <DialogMain
            titleDialog="Change staff"
            openDialogButtonText="Change"
            openDialogButtonClass="btn-text"
            showDialogActions={false}
            dialogWindowClass="modal-dialog-service"
            setShowModal={onCloseSfaffModal}
          >
            <RadioGroup
              className={styles.check_block}
              value={value}
              onChange={(event) => {
                onChange(+event.target.value);
              }}
              aria-label="gender"
              name="customized-radios"
            >
              <ul className={styles.radio_holder}>
                {staff?.map((staffItem) => {
                  const { id, firstName, lastName, avatar } = staffItem;
                  const viewedName =
                    id === 0 ? `${firstName}` : `${firstName} ${lastName}`;
                  const viewedInitials =
                    id === 0
                      ? `${firstName.substr(0, 1)}`
                      : `${firstName.substr(0, 1)}${lastName.substr(0, 1)}`;

                  return (
                    <li key={id}>
                      <FormControlLabel
                        value={id}
                        control={<StyledRadio />}
                        className={`${"align-items-flex-start checkboxLabel-control avatar-check avatar-check-large"} ${styles.avatar_box
                          }`}
                        label={
                          <>
                            <Avatar
                              className={styles.img_box}
                              alt={viewedName}
                              src={avatar}
                            >
                              {viewedInitials}
                            </Avatar>
                            <span className="check-name">{viewedName}</span>
                          </>
                        }
                      />
                    </li>
                  );
                })}
              </ul>
            </RadioGroup>
          </DialogMain>
        );
      }}
    />
  );
};
