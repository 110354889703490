import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import addCardSchema from "~/services/validationServices/addCardSchema";
import { useAppSelector, useAppDispatch } from "~/reduxConfig";
import { customerProfileSelector } from "~/store/selectors";
import { hideMessage } from "~/store/reducers/customerProfileReducer";
import {
  onGetCreditCardsAction,
  onGetDefaultCreditCardAction,
  onPostCreditCardAction,
  // onPutCreditCardAction,
  onDeleteCreditCardAction,
} from "~/store/actions/creditCardsAction";
import { stripeApi } from '~/services/api/stripeApi'

const PaymentMethodsHook = () => {
  const dispatch = useAppDispatch();
  const { cards, defaultCardId, message } = useAppSelector(customerProfileSelector);

  const [cardId, setCardId] = useState();
  const [addCardModal, setAddCardModal] = useState(false);
  const [editCardModal, setEditCardModal] = useState(false);
  const [sortedCards, setSortedCards] = useState([]);
  const [pickDefault, setPickDefault] = useState(false);

  const cardForm = useForm({
    resolver: yupResolver(addCardSchema),
    mode: "onChange",
    criteriaMode: "all",
  });

  const { setValue, reset, clearErrors } = cardForm;

  useEffect(() => {
    dispatch(onGetCreditCardsAction());
  }, [dispatch]);

  useEffect(() => {
    cards.length && dispatch(onGetDefaultCreditCardAction(cards[0].customer));
  }, [dispatch, cards]);

  useEffect(() => {
    if (cards.length) {
      setSortedCards(
        [...cards].sort((a, b) => {
          if (a.id < b.id) {
            return -1;
          }
          if (a.id > b.id) {
            return 1;
          }
          return 0;
        })
      );
    } else {
      setSortedCards([]);
    }
  }, [cards]);

  useEffect(() => {
    setPickDefault(defaultCardId === cardId);
  }, [defaultCardId, cardId]);

  useEffect(() => {
    if (addCardModal) {
      reset();
      clearErrors();
      dispatch(hideMessage());
    }
    if (editCardModal) {
      clearErrors();
      dispatch(hideMessage());
    }
  }, [addCardModal, editCardModal, reset, clearErrors, dispatch]);

  const onShowModalAndSetCardId = (id) => {
    setCardId(id);
    setEditCardModal(true);
    onSetValuesForEditCard(id);
  };

  const onSetValuesForEditCard = (id) => {
    const selectedCard = cards.find((card) => card.id === id);

    const {
      billing_details: { name },
      card: { exp_month, exp_year, last4 },
    } = selectedCard;

    const month = String(exp_month).length === 1 ? `0${exp_month}` : exp_month;
    const year = String(exp_year).slice(-2);

    setValue("cardName", name);
    setValue("cardNumber", `000000000000${last4}`);
    setValue("cardDate", `${month}/${year}`);
    setValue("cardCVV", "000");
    setValue("isDefault", defaultCardId === id);
  };

  const onHideMessage = () => dispatch(hideMessage());

  const onSubmitAddCard = () => {
    document.getElementById('saveNewCardPaymentBtn')?.click();
  };

  const onSubmitAddCardOld = (data) => {
    const { cardName, cardNumber, cardDate, cardCVV, isDefault } = data;
    const date = cardDate.split("/");

    dispatch(
      onPostCreditCardAction(
        {
          cardName,
          cardNumber: cardNumber.replace(/\s/g, ""),
          expMonth: date[0] || "",
          expYear: date[1] || "",
          cvv: cardCVV,
          isDefault,
        },
        () => setAddCardModal(false)
      )
    );
  };

  const onSubmitEditCard = async (data) => {
    const { cardName, isDefault, stripeCustomerId  } = data;
    try {
      await stripeApi.updatePaymentMethod({
        cardId,
        stripeCustomerId,
        creditCardDto: {
          cardName,
          isDefault,
        },
      })
      dispatch(onGetCreditCardsAction());
    } catch (error) {
      console.log(error);
    }
    setEditCardModal(false)
  };

  const onRemoveCard = () => {
    dispatch(onDeleteCreditCardAction(cardId, () => setEditCardModal(false)));
  };

  return {
    sortedCards,
    defaultCardId,
    cardForm,
    message,
    pickDefault,
    onHideMessage,
    addCardModal,
    setAddCardModal,
    onSubmitAddCard,
    editCardModal,
    setEditCardModal,
    onSubmitEditCard,
    onRemoveCard,
    onShowModalAndSetCardId,
    onSubmitAddCardOld,
  };
};

export default PaymentMethodsHook;
