import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { LocationReducerStateProps } from '~/store/reducers/interfaces/locationInterfaces';

const userLocationReducer = createSlice({
  name: 'userLocation',
  initialState: null,
  reducers: {
    setUserLocation: (state, action: PayloadAction<LocationReducerStateProps>) => action.payload,
  },
});

export const { setUserLocation } = userLocationReducer.actions;

export default userLocationReducer.reducer;
