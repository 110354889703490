import React, { useState } from "react";
import { Controller } from "react-hook-form";
import {
  TextField,
  InputLabel,
  Grid,
  Paper,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";

import { Message } from "~/Components/Message";
import "./NewPasswordStyle.scss";

export const NewPasswordSet = ({ control, hideMessage, message }) => {
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);

  const handleShowNewPassword = () => {
    setShowNewPassword((prev) => !prev);
  };

  const handleShowRepeatPassword = () => {
    setShowRepeatPassword((prev) => !prev);
  };

  return (
    <div className="card-wrapper">
      <Paper variant="outlined">
        <form>
          <Grid container justifyContent="center">
            <Grid
              container
              item
              xs={12}
              className="input-wrapper wrapper-new-password"
            >
              <InputLabel htmlFor="newPassword" className="label">
                New password
              </InputLabel>
              <Controller
                control={control}
                name="newPassword"
                defaultValue=""
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <div className="wrapper-input-new-password">
                    <TextField
                      id="newPassword"
                      className="text-field"
                      onFocus={hideMessage}
                      value={value}
                      onChange={(e) => onChange(e.target.value)}
                      type={showNewPassword ? "text" : "password"}
                      variant="outlined"
                      error={Boolean(error)}
                    />
                    <InputAdornment
                      className="wrapper-icon-show-password"
                      position="end"
                    >
                      <IconButton
                        aria-label="Toggle password showPassword"
                        onClick={handleShowNewPassword}
                      >
                        {showNewPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                    <Message msg={error?.message} />
                  </div>
                )}
              />
            </Grid>

            <Grid
              container
              item
              xs={12}
              className="input-wrapper wrapper-new-password"
            >
              <InputLabel htmlFor="newPasswordRepeat" className="label">
                Repeat password
              </InputLabel>
              <Controller
                control={control}
                name="newPasswordRepeat"
                defaultValue=""
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <div className="wrapper-input-new-password">
                    <TextField
                      id="newPasswordRepeat"
                      className="text-field"
                      onFocus={hideMessage}
                      value={value}
                      onChange={(e) => onChange(e.target.value)}
                      type={showRepeatPassword ? "text" : "password"}
                      variant="outlined"
                      error={Boolean(error)}
                    />
                    <InputAdornment
                      className="wrapper-icon-show-password"
                      position="end"
                    >
                      <IconButton
                        aria-label="Toggle password showPassword"
                        onClick={handleShowRepeatPassword}
                      >
                        {showRepeatPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                    <Message msg={error?.message} />
                  </div>
                )}
              />
            </Grid>

            <Message msg={message} />
          </Grid>
        </form>
      </Paper>
    </div>
  );
};
