import React, { useState, useEffect } from "react";
import { AccordionSummary, Typography, Collapse } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useSelector } from 'react-redux';

import { PricePicker } from "~/Components/PricePicker";
import { AddServiceItemHook } from "~/Containers/BookingFlowPage/components/AddServiceItem/AddServiceItemHook";

import type { ServiceForSelectionProps } from "~/Containers/BookingFlowPage/interfaces";
import { bookingSelector } from "~/store/selectors";
import styles from "./AddServiceItemStyle.module.scss";
import { formatPrice } from "~/utils/helper";
import { CheckMobileHook } from "~/Components/MainHeader/CheckMobileHook";
import "./styles.scss";

export const AddServiceItem: React.FC<ServiceForSelectionProps> = (props) => {
  const {
    service,
    isService,
    pricingOptionFormMethods,
    onServiceOptions,
    defaultPricingOptionId,
    isServiceAdded,
    onChange,
    getBookingFormValues,
    setBookingFormValues,
    serviceOptions,
  } = AddServiceItemHook(props);
  const widthScreen = window.innerWidth;
  const { isMobile } = CheckMobileHook();
  const { selectedService, selectedStaffId, selectedServiceIndex } = props;
  const { staff: staffBooking } = useSelector(bookingSelector);
  const firstServiceOption = isService ? service.pricingOptions.find((item => item.price === null && item.specialPrice === null)) || service.pricingOptions[0] : null;

  const [showMore, setShowMore] = useState(false);
  const [text, setText] = useState("");
  const [enableShowMore, setEnableShowMore] = useState(
    widthScreen < 1040 && widthScreen >= 768 ? service.description?.split(" ").length > 16 : service.description?.split(" ").length > 8
  );

  useEffect(() => {
    if (enableShowMore) {
      const desArr = service.description?.split(" ");
      const result = showMore
        ? service.description
        : desArr.slice(0, widthScreen < 1040 && widthScreen >= 768 ? 16 : 8).join(" ");
      setText(result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [service.description, enableShowMore, showMore])

  useEffect(() => {
    if (widthScreen < 1040 && widthScreen >= 768 ? service.description?.split(" ").length < 16 : service.description?.split(" ").length < 8) {
      setText(service.description);
    } else {
      setEnableShowMore(widthScreen < 1040 && widthScreen >= 768 ? service.description?.split(" ").length >= 16 : service.description?.split(" ").length >= 8);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [service.description]);

  const toggleShowMore = () => setShowMore(!showMore);

  if (!firstServiceOption && isService) {
    return null;
  }

  const {
    selected = {
      staffId: selectedStaffId,
      pricingOptionId: firstServiceOption?.id,
      serviceId: isService ? firstServiceOption?.serviceId : service.id,
      type: isService ? "service" : "package",
    },
  }: any = selectedService || {};

  const handleChangeAddOrRemove = (data) => {
    onChange({
      ...data,
      serviceId: service.id,
      type: service.type,
      packageId: service.packageId,
    });
  };

  const selectedPricingOpt = service.pricingOptions?.find(
    (opt) => opt.id === selected.pricingOptionId
  );
  const priceByStaff = selectedPricingOpt?.staffPricingOptions.find(
    (s) => s.staffId === selected.staffId
  );

  const selectedOpt = selectedPricingOpt || priceByStaff || firstServiceOption;

  const onAddorRemve = () => {
    if (isService) {
      const { pricingOptionId, staffId, staffPricingOptionId } = pricingOptionFormMethods.getValues();
      const selectedPricing = service.pricingOptions.find(
        (item) => item.id === pricingOptionId
      );
      const option = selectedPricing || selectedOpt || selected;
      handleChangeAddOrRemove({
        ...option,
        pricingOptionId: option.pricingOptionId || option.id,
        staffId: staffId,
        staffPricingOptionId: staffPricingOptionId,
      });
    } else {
      handleChangeAddOrRemove(service);
    }
  };

  const onChangePricingOption = (pricingOptionId, staffPricingOptionId) => {
    if (selectedServiceIndex >= 0 && isService) {
      const { staffPricingOptions = [] } = service.pricingOptions.find((opt) => opt.id === pricingOptionId) || {};
      const staffIds = staffBooking?.bookingStaffList?.filter(
        (item) => item?.customStaffShifts?.length > 0
      ).map((item) => item.id);
      const newDataStaffBooking = staffPricingOptions?.filter((item) => staffIds?.includes(item.staffId))
      const staffOption = newDataStaffBooking?.length > 0 && staffPricingOptionId
        ? newDataStaffBooking.find((item => item.id === staffPricingOptionId)) || newDataStaffBooking[0]
        : newDataStaffBooking?.length > 0 && !staffPricingOptionId
          ? newDataStaffBooking.find((item => item.price === null && item.specialPrice === null)) || newDataStaffBooking[0]
          : service.staff.find((s) => s.id > 0);
      const { selectedServices } = getBookingFormValues();
      const newSelectedServices = selectedServices.map((item, index) => {
        if (selectedServiceIndex === index) {
          const staffId = staffOption?.staffId || staffOption?.id || item.selected.staffId;
          setBookingFormValues(`selectedServices.${selectedServiceIndex}.selected.staffId`, staffId);
          return {
            ...item,
            selected: {
              ...item.selected,
              pricingOptionId,
              staffId,
              staffPricingOptionId,
            },
          };
        }
        return item;
      });
      setBookingFormValues("selectedServices", newSelectedServices);
    }
  };

  const staffIds = service?.staff.map((item) => item.id)
  const newDataStaffBooking = staffBooking?.bookingStaffList?.filter(
    (item) =>
      item?.customStaffShifts?.length > 0 && staffIds?.includes(item.id)
  )

  if (newDataStaffBooking?.length === 0) return null;

  return (
    <div className={styles.service_wrap}>
      <div className={styles.service_box}>
        <div className={styles.description}>
          <h3>{service.packageName || service.serviceName}</h3>

          <div className={styles.description}>
            {text && (
              <Collapse in={showMore} collapsedSize={isMobile ? 16 : 24}>
                <Typography
                  className={styles.title_2}
                  variant="body1"
                  color="textSecondary"
                >
                  {text}
                </Typography>
              </Collapse>
            )}
            {(widthScreen < 1040 && widthScreen >= 768 ? service.description?.split(" ").length > 16 : service.description?.split(" ").length > 8) && (
              <div className={`wrapper-btn-show-booking`} onClick={toggleShowMore}>
                <AccordionSummary
                  className={`${styles.btn_show_description} `}
                  expandIcon={<ExpandMoreIcon className={showMore ? "show-more-btn-booking" : "show-less-btn-booking"} />}
                  aria-controls="panel1a-content"
                >
                  <Typography>{!showMore ? "Show more" : "Show less"}</Typography>
                </AccordionSummary>
              </div>
            )}
          </div>
        </div>
        <div className={styles.button_box}>
          <button
            className={`${styles.btn} ${isServiceAdded ? "" : styles.add_btn}`}
            onClick={onAddorRemve}
          >
            {isServiceAdded ? "Added" : "Add"}
          </button>
          {serviceOptions.specialPrice ? (
            <span>
              <span className={styles.old_cost}>
                {serviceOptions.price}
              </span>
              <span className={styles.cost}>
                {serviceOptions.specialPrice}
              </span>
            </span>
          ) : serviceOptions.price ? (
            <span>
              <span className={styles.cost}>
                {serviceOptions.price}
              </span>
            </span>
          ) : selectedOpt?.specialPrice ? (
            <span>
              <span className={styles.old_cost}>
                ${formatPrice(selectedOpt.price)}
              </span>
              <span className={styles.cost}>
                ${formatPrice(selectedOpt.specialPrice)}
              </span>
            </span>
          ) : service?.specialPrice ? (
            <span>
              <span className={styles.old_cost}>
                ${formatPrice(service.price)}
              </span>
              <span className={styles.cost}>
                ${formatPrice(service.specialPrice)}
              </span>
            </span>
          ) : (
            <span className={styles.cost}>
              {`$${formatPrice(selectedOpt?.price || service?.price)}`}
            </span>
          )}
        </div>
      </div>

      {
        service.pricingOptions && (
          <PricePicker
            formMethods={pricingOptionFormMethods}
            fieldName="pricingOptionId"
            onServiceOptions={onServiceOptions}
            onChangePricingOption={onChangePricingOption}
            pricingOptions={service.pricingOptions}
            defaultValue={defaultPricingOptionId}
            staff={service.staff}
            selectedService={selectedOpt}
            serviceSelected={selectedService}
          />
        )
      }
    </div>
  );
};
