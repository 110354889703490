import moment from "moment-timezone";

export const convertStartEndTime = (startTime, endTime, timezone, type) => {
  const hourStart = startTime && moment.tz(startTime, timezone).hours();
  const minStart = startTime && moment.tz(startTime, timezone).minutes() / 60;
  const hourEnd = endTime && moment.tz(endTime, timezone).hours();
  const minEnd = endTime && moment.tz(endTime, timezone).minutes() / 60;
  const startHour = hourStart + minStart;

  const hourFloor = Math.floor(startHour)
  const hourDuration = startHour - hourFloor
  let startWorkingHour = hourDuration === 0 ? startHour : hourFloor + 0.25;
  let endWorkingHour = hourEnd + minEnd;

  if (hourDuration > 0.75) {
    startWorkingHour = hourFloor + 1;
  } else if (hourDuration > 0.5) {
    startWorkingHour = hourFloor + 0.75;
  } else if (hourDuration > 0.25) {
    startWorkingHour = hourFloor + 0.5;
  }

  if (type === 'morning') {
    endWorkingHour = endWorkingHour > 12 ? 12 : endWorkingHour
  }

  if (type === 'afternoon') {
    startWorkingHour = startWorkingHour < 12 ? 12 : startWorkingHour;
    endWorkingHour = endWorkingHour > 18 ? 18 : endWorkingHour
  }

  if (type === 'evening') {
    startWorkingHour = startWorkingHour < 18 ? 18 : startWorkingHour
  }

  return {
    startWorkingHour,
    endWorkingHour
  }
}
