import { payType } from "~/constants";

class SetDataForBookingConfirm {
  constructor(data) {
    this.params = data.params || null;
    this.cardId = data.cardId || null;
    this.cardCVV = data.cardCVV || null;
    this.cardDate = data.cardDate || null;
    this.cardName = data.cardName || null;
    this.cardNumber = data.cardNumber || null;
    this.bookingNotes = data.description || null;
    this.receive = data.receive || null;
    this.remember = data.remember || null;
    this.termsConditions = data.termsConditions || null;
    this.location = data.location || null;
    this.expanded = data.expanded || null;
  }

  withCardId() {
    return {
      venueId: this.params.id,
      cardId: this.cardId,
      bookingNotes: this.bookingNotes,
      paymentType: payType[this.expanded],
      bookingServices: this.getServices(this.location.state),
    };
  }

  withoutCardId() {
    const date = this.cardDate.split("/");

    return {
      venueId: this.params.id,
      cardName: this.cardName,
      cardNumber: this.cardNumber,
      expMonth: date[0] || "",
      expYear: date[1] || "",
      cvv: this.cardCVV,
      rememberCard: this.remember,
      bookingNotes: this.bookingNotes,
      paymentType: payType[this.expanded],
      bookingServices: this.getServices(this.location.state),
    };
  }
  getServices(services) {
    return services.selectedServices.map((service) => {
      const {
        pricingOptions,
        selected: { pricingOptionId, serviceId, staffId, date, time },
        staff,
      } = service;

      const pricingOption = pricingOptions.filter((option) => option.id === pricingOptionId);
      const { duration, addExtraTime } = pricingOption[0];
      const staffService = staffId === 0 ? staff.filter(({ id }) => id !== 0) : staffId;
      const setStaffId = typeof staffService === "number" ? staffService : staffService[0].id;

      return {
        time: `${date} ${time}`,
        serviceId,
        pricingOptionId,
        staffPricingOptionId: null,
        duration,
        staffId: setStaffId,
        extraTime: addExtraTime,
      };
    });
  }
}

export default SetDataForBookingConfirm;
