import React from 'react';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import { NavLink, useHistory } from 'react-router-dom';

import logo from '~/Assets/images/logo.svg';

import styles from './CustomerHeaderStyle.module.scss';

export const CustomerHeader: React.FC = () => {
  const history = useHistory();

  const handleBack = () => {
    history.goBack();
  };

  return (
    <header className={styles.header}>
      <strong className={styles.header_logo}>
        <NavLink to='/'>
          <img src={logo} alt='Glamezy' />
        </NavLink>
      </strong>
      <button className={`${styles.back_btn} white-btn`} onClick={handleBack}>
        <ArrowBackIosRoundedIcon />
        <span>Back</span>
      </button>
    </header>
  );
};
