import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import { useDispatch } from 'react-redux';

import { RescheduleBookingServiceItem } from "~/Containers/MyBookingsPage/components/RescheduleBookingServiceItem";
import { TimeRadioPicker } from "~/Components/TimeRadioPicker";
import { DatePicker } from "~/Components/DatePicker/DatePicker";

import styles from "./RescheduleBookingStyle.module.scss";
import { RescheduleBookingHook } from "~/Containers/MyBookingsPage/components/RescheduleBooking/RescheduleBookingHook";
import type { RescheduleBookingDialogContentProps } from "~/Containers/MyBookingsPage/interfaces";
import { useAppSelector } from "~/reduxConfig";
import { MyBookingsStateProps } from "~/store/reducers/interfaces/myBookingsInterfaces";
import { bookingSelector, myBookingsSelector } from "~/store/selectors";
import { timezoneDefault } from "~/constants";
import { onLoadBookingStaffAction } from "~/store/actions/bookingActions";

export const RescheduleBooking: React.FC<RescheduleBookingDialogContentProps> = (props) => {
  const dispatch = useDispatch();
  const { onChangeTimeError, onChangeDayOffError, timeSelect, timeStartEnd } = props;
  const { bookingById } = useAppSelector<MyBookingsStateProps>(myBookingsSelector);
  const { staff } = useAppSelector(bookingSelector);
  const { selectedServices, myBookingFormMethods } = RescheduleBookingHook();
  const { date, bookingServiceId } = myBookingFormMethods.getValues();
  const [staffShift, setStaffShift] = useState({
    startDateTime: '',
    endDateTime: '',
  });

  useEffect(() => {
    const startDateTime = moment.tz(
      moment(date).format("YYYY-MM-DDT00:00:00"),
      bookingById?.venue?.timezone || timezoneDefault
    ).toISOString();
    const endDateTime = moment.tz(
      moment(date).format("YYYY-MM-DDT23:59:59"),
      bookingById?.venue?.timezone || timezoneDefault
    ).toISOString();

    dispatch(
      onLoadBookingStaffAction({ venueId: bookingById?.venue?.id, startDateTime, endDateTime })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, bookingServiceId]);

  useEffect(() => {
    if (staff?.bookingStaffList?.length > 0) {
      const serviceSelect = selectedServices.find((item) => item.id === +bookingServiceId)
      const staffShift = staff.bookingStaffList?.find((item) => item.id === serviceSelect?.staffId)
      if (staffShift?.id && staffShift?.customStaffShifts?.length > 0) {
        setStaffShift({
          startDateTime: staffShift?.customStaffShifts[0].startDateTime,
          endDateTime: staffShift?.customStaffShifts[0].endDateTime,
        })
      } else {
        setStaffShift({
          startDateTime: '',
          endDateTime: '',
        })
      }
    } else {
      setStaffShift({
        startDateTime: '',
        endDateTime: '',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [staff?.bookingStaffList])

  return (
    <div className={styles.container}>
      {/* add after implement this functionality on back*/}
      {/*<DatePicker*/}
      {/*  formMethods={myBookingFormMethods}*/}
      {/*  fieldName="date"*/}
      {/*  disablePast />*/}

      <h3>Select date</h3>
      <DatePicker
        formMethods={myBookingFormMethods}
        fieldName={"date"}
        disablePast
        timeSelect={timeSelect}
        timeStartEnd={timeStartEnd}
        onChangeTimeError={onChangeTimeError}
        onChangeDayOffError={onChangeDayOffError}
      />

      <h3>Select time</h3>
      <TimeRadioPicker
        formMethods={myBookingFormMethods}
        fieldName="time"
        timeSelect={timeSelect}
        timeStartEnd={timeStartEnd}
        onChangeTimeError={onChangeTimeError}
        serviceBooking={selectedServices}
        staffShift={staffShift}
        timezone={bookingById?.venue?.timezone || timezoneDefault}
        isReschedule
      />

      {/* add after implement this functionality on back*/}
      <div className={styles.description}>
        <p>
          Select the service to reschedule.
          You will be assigned to the next available staff.
          You may contact the salon for special requests and specific staff assignments.
        </p>
      </div>

      <div className={styles.booking_summary}>
        <strong className={styles.heading}>Booking summary</strong>
        {selectedServices.map((bookingService) => (
          <RescheduleBookingServiceItem
            key={bookingService.id}
            bookingService={bookingService}
            timezone={bookingById?.venue?.timezone || 'Australia/Sydney'}
          />
        ))}
      </div>
    </div>
  );
};
